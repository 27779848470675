import { gql } from "@apollo/client";

export const INSERT_UMRAH_PACKAGES = gql`
  mutation insertUmrahPackages($data: [brd_umrah_packages_insert_input!]!) {
    insert_brd_umrah_packages(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_UMRAH_PACKAGES = gql`
  mutation updateUmrahPackages(
    $id: uuid!
    $data: brd_umrah_packages_set_input!
  ) {
    update_brd_umrah_packages(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;

export const DELETE_BRD_UMRAH_PACKAGE = gql`
  mutation DeleteBrdUmrahPackage($id: uuid!) {
    delete_brd_umrah_packages(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
