import { gql } from "@apollo/client";

export const INSERT_TRAIN_PASSENGER = gql`
  mutation insertTrainPassenger(
    $data: [train_inq_passengers_insert_input!]!
  ) {
    insert_train_inq_passengers(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_TRAIN_PASSENGER = gql`
  mutation deleteTrainPassengers($ids: [uuid!]!) {
    delete_train_inq_passengers(
      where: { id: { _in: $ids } }
    ) {
      affected_rows
    }
  }
`;