import { gql } from "@apollo/client";

export const GET_FL_JOBS = gql`
  query getFlJobs {
    flGetJobs {
      result {
        id
        name
        active_project_count
      }
    }
  }
`;
