import { Button, Divider, TextField } from "@mui/material";
import _airportsData from "~/components/FormInputs/LocationBox/data/airports.json";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { PLACE_FL_BID } from "~/graphql/flPlaceBid/Mutation";
import { toast } from "react-toastify";
import { useState } from "react";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";
import { useUserData } from "@nhost/react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BidForm = ({ setSidePopup, prjData }: any) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [placeBid] = useMutation(PLACE_FL_BID);

  const userDetail = useUserData();

  const [description, setDescription] = useState("");

  const onSubmit = async (formData: any) => {
    try {
      const payload = {
        amount: Number(formData?.amount),
        profileId: 4127472,
        days: Number(formData?.days),
        mileStone: Number(formData?.milestone),
        prjectId: prjData?.ProjectId,
        description: description,
      };

      const res = await placeBid({
        variables: payload,
      });

      if (res?.data?.flPlaceBid?.status === "success") {
        toast.success("Bid Places SuccessFully");
        setSidePopup(false);
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return (
    <div className="md:w-[800px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic">Place Your Bid</h1>
      <Divider />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 gap-5 mt-10"
      >
        <Button
          variant="contained"
          onClick={async () => {
            const res = await getAiData(
              `Write a concise and tailored bid for a Freelancer.com project based on the provided project description. Start by addressing the project owner directly using the word 'you' to highlight their pain point. Then, position me as the expert by presenting a relevant solution or expertise that directly addresses their problem. Avoid discussing price upfront; instead, emphasize the importance of fully understanding their needs before finalizing costs with phrases like 'Let’s ensure we understand your needs clearly before discussing pricing.' Keep the bid professional, personalized, and focused on value, with no fluff or unnecessary details.
              Project Detail: ${prjData?.desc}
              Client Name: ${prjData?.clientName}
              Regards Name: ${userDetail?.displayName}
              `
            );
            await typingEffect(setDescription, res);
          }}
        >
          Generate Bid
        </Button>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <p className="font-bold">PROJECT DETAIL</p>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{prjData?.desc}</Typography>
          </AccordionDetails>
        </Accordion>
        <TextField
          label="Description"
          multiline
          minRows={7}
          value={description}
          onChange={(e: any) => setDescription(e.target.value)}
        />
        <TextField
          error={errors["amount"] ? true : false}
          {...register("amount", { required: true })}
          label="Amount"
        />
        <TextField
          error={errors["days"] ? true : false}
          {...register("days", { required: true })}
          label="No Of Days"
        />
        <TextField
          error={errors["milestone"] ? true : false}
          {...register("milestone", { required: true })}
          label="MileStone"
        />
        <Button type="submit" variant="contained">
          Place Your Bid
        </Button>
      </form>
    </div>
  );
};

export default BidForm;
