import { gql } from "@apollo/client";

export const INSERT_VISA_TICKET = gql`
  mutation insertVisaTicket($data: [visa_inq_ticket_insert_input!]!) {
    insert_visa_inq_ticket(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_VISA_TICKET = gql`
  mutation deleteVisaTicket($id: uuid!) {
    delete_visa_inq_ticket(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
