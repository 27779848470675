import { gql } from "@apollo/client";

export const UPDATE_SUGGESTION_COST = gql`
  mutation UpdateSuggestionCost(
    $id: uuid!
    $data: inq_suggestion_cost_set_input!
  ) {
    update_inq_suggestion_cost(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;
