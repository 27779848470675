import { gql } from "@apollo/client";

export const GET_SELECTED_FL_COUNTRY_WITH_BRD = gql`
  query getSelectedFlCOUNTRY($brdId: uuid!) {
    brd_fl_selected_countries(where: { brd_id: { _eq: $brdId } }) {
      id
      brd_id
      country_code
    }
  }
`;
