import React, { Children, useState } from "react";
import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import moment from "moment";
import { toast } from "react-toastify";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { UPDATE_INQUIRY } from "../../graphql/Mutation";
import InquirySelector from "../../InquirySelector";
import { options } from "../../data/options";
import NewInquiryTypes from "../../NewInquiryTypes";
import { INSERT_STAY_INQUIRY_DETAIL } from "~/graphql/stay_inq_detail/Mutation";
import { INSERT_CAR_INQUIRY_DETAIL } from "~/graphql/car_inq_detail/Mutation";
import { INSERT_TRAIN_INQUIRY_DETAIL } from "~/graphql/train_inq_detail/Mutation";
import { INSERT_VISA_INQUIRY_DETAIL } from "~/graphql/visa_inq_detail/Mutation";

const ExtendInquiry = ({ setSidePopup, defaultTabs, inquiryId }: any) => {
  const tabs: any = [];

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();

  const tripType = watch("tripType");

  const [selectedOptions, setSelectedOptions] = useState<string[]>(tabs);
  const [updateInquiry] = useMutation(UPDATE_INQUIRY);
  const [insertStayDetail] = useMutation(INSERT_STAY_INQUIRY_DETAIL);
  const [insertCarDetail] = useMutation(INSERT_CAR_INQUIRY_DETAIL);
  const [insertTrainDetail] = useMutation(INSERT_TRAIN_INQUIRY_DETAIL);
  const [insertVisaDetail] = useMutation(INSERT_VISA_INQUIRY_DETAIL)
  const client = useApolloClient();
  const { travelHouse }: any = travelHouseStore();

  const onSubmit = async (data: any) => {

    console.log("dsjhbxs", data)
    let isSuccess = true;

    try {
      if (data?.stay) {
        const rooms = data?.stay?.Travelers?.length || null;
        let totalAdults = 0;
        let totalChildren = 0;

        if (data?.stay?.Travelers) {
          data?.stay?.Travelers?.forEach((traveler: any) => {
            totalAdults += traveler.adults;
            totalChildren += traveler.children;
          });
        }

        const startDate = moment(data?.stay?.dates?.[0]?.$d);
        const endDate = moment(data?.stay?.dates?.[1]?.$d);
        const noOfDays = endDate.diff(startDate, "days") + 1;

        const res = await insertStayDetail({
          variables: {
            data: [
              {
                inq_id: inquiryId,
                adults: totalAdults,
                children: totalChildren,
                start_date: moment(data?.stay?.dates?.[0]).format("YYYY-MM-DD"),
                end_date: moment(data?.stay?.dates?.[1]).format("YYYY-MM-DD"),
                location: data?.stay?.whereTo,
                rooms: rooms,
                stay_inq_rooms: {
                  data:
                    rooms > 0
                      ? [
                        ...Array(rooms)
                          .fill(null)
                          .map((_, index) => ({
                            no_of_days: noOfDays,
                            room_number: "roomNumber",
                          })),
                      ]
                      : [],
                },
              },
            ],
          },
        });

        if (res?.data?.insert_stay_inq_detail.affected_rows < 1) {
          isSuccess = false;
        }
      }

      if (data?.cars) {
        const res = await insertCarDetail({
          variables: {
            data: [
              {
                inq_id: inquiryId,
                origin: data?.cars?.pickUp,
                destination: data?.cars?.DropOff,
                pick_date_time: moment(data?.cars?.pickupDateTime).format(
                  "YYYY-MM-DD HH:mm"
                ),
                drop_date_time: moment(data?.cars?.dropOffDateTime).format(
                  "YYYY-MM-DD HH:mm"
                ),
                with_driver: data?.cars?.withDriver,
              },
            ],
          },
        });

        if (res?.data?.insert_car_inq_detail?.affected_rows < 1) {
          isSuccess = false;
        }
      }

      if (data?.train) {
        const travelers = data?.train?.Travelers || {};

        const res = await insertTrainDetail({
          variables: {
            data: [
              {
                inq_id: inquiryId,
                origin: data?.train?.Origin,
                destination: data?.train?.Destination,
                date: moment(data?.train?.Date).format("YYYY-MM-DD"),
                train_inq_passengers: {
                  data: [
                    ...((travelers.adults || 0) > 0
                      ? Array(travelers.adults)
                        .fill(null)
                        .map((_, index) => ({
                          type: "adult",
                        }))
                      : []),

                    ...((travelers.youth || 0) > 0
                      ? Array(travelers.youth)
                        .fill(null)
                        .map(() => ({
                          type: "youth",
                        }))
                      : []),

                    ...((travelers.children || 0) > 0
                      ? Array(travelers.children)
                        .fill(null)
                        .map(() => ({
                          type: "child",
                        }))
                      : []),

                    ...((travelers.infantsOnLap || 0) > 0
                      ? Array(travelers.infantsOnLap)
                        .fill(null)
                        .map(() => ({
                          type: "infant",
                        }))
                      : []),
                  ],
                },
              },
            ],
          },
        });

        if (res?.data?.insert_train_inq_detail?.affected_rows < 1) {
          isSuccess = false;
        }
      }

      if (data?.visa) {
        const res = await insertVisaDetail({
          variables: {
            data: [
              {
                inq_id: inquiryId,
                name: data?.visa?.name,
                country: data?.visa?.country,
              }
            ]
          }
        })

        if (res?.data?.insert_visa_inq_detail?.affected_rows < 1) {
          isSuccess = false;
        }
      }

      if (data?.flights) {
        const passengers = [
          ...Array(data?.flights?.Travelers?.adults || 0)
            .fill(null)
            .map(() => ({
              type: "adult",
              inq_id: inquiryId,
            })),
          ...Array(data?.flights?.Travelers?.youth || 0)
            .fill(null)
            .map(() => ({
              type: "youth",
              inq_id: inquiryId,
            })),
          ...Array(data?.flights?.Travelers?.children || 0)
            .fill(null)
            .map(() => ({
              type: "child",
              inq_id: inquiryId,
            })),
          ...Array(data?.flights?.Travelers?.infantsOnLap || 0)
            .fill(null)
            .map(() => ({
              type: "infant",
              inq_id: inquiryId,
            })),
        ];

        const res = await updateInquiry({
          variables: {
            id: inquiryId,
            data: {
              trip_type: data?.flights?.tripType,
              to: data?.flights?.GoingTo,
              from: data?.flights?.LeavingFrom,
              cabin_class: data?.flights?.cabinClass,
              departure_datetime: data?.flights?.RoundTrip?.Dates?.[0],
              arrival_datetime: data?.flights?.RoundTrip?.Dates?.[1] || null,
            },
            passengers,
          },
        });

        if (
          !res?.data?.update_inq_list_by_pk?.id ||
          !res?.data?.insert_inq_passengers?.returning
        ) {
          isSuccess = false;
        }
      }

      if (isSuccess) {
        toast.success("Inquiry Extended Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setSidePopup(false);
      }
    } catch (err) {
      toast.error("Error inserting inquiry", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    setSelectedOptions((prev) =>
      checked ? [...prev, value] : prev.filter((option) => option !== value)
    );
    if (!checked) {
      reset({ [value]: undefined });
    }
  };

  const handleNewInquiryTypesSubmit = (inquiryTypes: any) => {
    setSelectedOptions(inquiryTypes);
  };

  return (
    <div className="md:w-[600px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic">Extend Inquiry</h1>
      <Divider />
      <Box sx={{ mb: 3 }}>
        <InquirySelector
          options={options}
          selectedOptions={selectedOptions}
          onOptionChange={handleOptionChange}
          defaultTabs={defaultTabs}
        />
      </Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <NewInquiryTypes
          register={register}
          control={control}
          handleSubmit={handleSubmit}
          errors={errors}
          watch={watch}
          setValue={setValue}
          selectedOptions={selectedOptions}
          onSubmit={handleNewInquiryTypesSubmit}
        />
        <Button
          type="submit"
          sx={{ width: "100%", marginTop: "25px" }}
          variant="contained"
        >
          Extend
        </Button>
      </Box>
    </div>
  );
};

export default ExtendInquiry;
