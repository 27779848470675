import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Controller } from "react-hook-form";
import { Badge } from "@mui/material";

const PostDetailsForm = ({
  register,
  control,
  watch,
  setValue,
  Loading,
  selectedPlatform,
}: any) => {
  const [imageBase64Array, setImageBase64Array] = useState<string[]>([]);
  const [videoBase64Array, setVideoBase64Array] = useState<string[]>([]);

  //

  const postType = watch("postType");

  const convertFileToBase64 = (file: File) => {
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setBase64Array: React.Dispatch<React.SetStateAction<string[]>>,
    fieldName: string
  ) => {
    const files = event.target.files;

    if (files) {
      try {
        const base64Array = await Promise.all(
          Array.from(files).map((file) => convertFileToBase64(file))
        );

        const filteredBase64Array = base64Array.filter(
          (item) => item !== null
        ) as string[];

        setBase64Array(filteredBase64Array);
        setValue(fieldName, filteredBase64Array);
      } catch (error) {}
    }
  };

  const handleRemoveImage = (index: number) => {
    setImageBase64Array((prevArray) => {
      const updatedArray = prevArray.filter((_, i) => i !== index);
      setValue("imageBase64Array", updatedArray); // Update form value
      return updatedArray;
    });
    toast.info("Image removed.");
  };

  const handleRemoveVideo = (index: number) => {
    setVideoBase64Array((prevArray) => {
      const updatedArray = prevArray.filter((_, i) => i !== index);
      setValue("videoBase64Array", updatedArray); // Update form value
      return updatedArray;
    });
    toast.info("Video removed.");
  };

  return (
    <div className="p-4 bg-light dark:bg-dark block sm:flex flex-col items-start shadow rounded-b-lg justify-between relative">
      <Box
        component="div"
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            sm: "1fr",
            md: "repeat(2, 1fr)",
          },
          gap: 2,
          width: "100%",
        }}
      >
        <Controller
          name="postType"
          control={control}
          defaultValue="NONE"
          render={({ field }) => (
            <TextField
              {...field}
              id="post-type"
              select
              label="Select Post Type"
              sx={{ width: "100%" }}
            >
              <MenuItem value="IMAGE">Image</MenuItem>
              {selectedPlatform.includes("Instagram") &&
                selectedPlatform.length > 1 && (
                  <MenuItem value="VIDEO">Video</MenuItem>
                )}
              {(selectedPlatform.includes("Instagram") ||
                selectedPlatform.includes("Facebook")) && [
                <MenuItem value="PHOTO_STORY">Photo Story</MenuItem>,
                <MenuItem value="VIDEO_STORY">Video Story</MenuItem>,
                <MenuItem value="REEL">Reel</MenuItem>,
              ]}
              {(selectedPlatform.includes("Facebook") ||
                selectedPlatform.includes("LinkedIn")) && (
                <MenuItem value="NONE">Text</MenuItem>
              )}
              {selectedPlatform.includes("Instagram") && (
                <MenuItem value="CAROUSEL">Carousel Post</MenuItem>
              )}
            </TextField>
          )}
        />

        <Controller
          name="postStatus"
          control={control}
          defaultValue="PUBLISHED"
          render={({ field }) => (
            <TextField
              {...field}
              id="post-status"
              select
              label="Select Post Status"
              sx={{ width: "100%" }}
            >
              {/* <MenuItem value="DRAFT">Draft</MenuItem> */}
              <MenuItem value="PUBLISHED">Publish</MenuItem>
            </TextField>
          )}
        />

        {/* <Controller
          name="visibility"
          control={control}
          defaultValue="PUBLIC"
          render={({ field }) => (
            <TextField
              {...field}
              id="post-visibility"
              select
              label="Who would you like to share with?"
              sx={{ width: "100%" }}
            >
              <MenuItem value="PUBLIC">Public</MenuItem>
              <MenuItem value="CONNECTIONS">Connections</MenuItem>
            </TextField>
          )}
        /> */}
      </Box>

      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          marginTop: 2,
        }}
      >
        <TextField
          id="post-content"
          label="Post Content"
          placeholder="Enter the content of your post"
          multiline
          rows={4}
          {...register("postContent")}
          sx={{ width: "100%" }}
        />

        {(postType === "IMAGE" ||
          postType === "PHOTO_STORY" ||
          postType === "CAROUSEL") && (
          <>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{
                marginTop: 2,
                maxWidth: 200,
                width: "auto",
                display: "inline-flex",
              }}
            >
              Upload Images
              <input
                type="file"
                accept="image/*"
                multiple
                hidden
                onChange={(event) =>
                  handleFileUpload(
                    event,
                    setImageBase64Array,
                    "imageBase64Array"
                  )
                }
              />
            </Button>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                gap: "20px",
              }}
            >
              {imageBase64Array.map((imageBase64, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: { xs: "45%", sm: "30%", md: "18%" },
                  }}
                >
                  <Badge
                    badgeContent={
                      <span className="cursor-pointer text-[13px]">x</span>
                    }
                    color="primary"
                    onClick={() => handleRemoveImage(index)}
                  >
                    <img
                      src={imageBase64}
                      alt={`Upload ${index}`}
                      onClick={(e) => e.stopPropagation()}
                      className="border bg-blue-500"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: 6,
                      }}
                    />
                  </Badge>
                </Box>
              ))}
            </Box>
          </>
        )}

        {(postType === "VIDEO" ||
          postType === "REEL" ||
          postType === "VIDEO_STORY") && (
          <>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{
                marginTop: 2,
                maxWidth: 200,
                width: "auto",
                display: "inline-flex",
              }}
            >
              Upload Videos
              <input
                type="file"
                accept="video/*"
                hidden
                onChange={(event) =>
                  handleFileUpload(
                    event,
                    setVideoBase64Array,
                    "videoBase64Array"
                  )
                }
              />
            </Button>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                gap: "20px",
                marginTop: 2,
              }}
            >
              {videoBase64Array.map((videoBase64, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: { xs: "45%", sm: "30%", md: "18%" },
                    marginBottom: 2,
                  }}
                >
                  <Badge
                    badgeContent={
                      <span className="cursor-pointer text-[13px]">x</span>
                    }
                    color="primary"
                    onClick={() => handleRemoveVideo(index)}
                  >
                    <video
                      src={videoBase64}
                      onClick={(e) => e.stopPropagation()}
                      controls
                      style={{
                        // width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        marginBottom: "10px",
                      }}
                    />
                  </Badge>
                </Box>
              ))}
            </Box>
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          disabled={Loading}
          sx={{ marginTop: 2 }}
          type="submit"
        >
          {Loading ? "Posting..." : "Create Post"}
        </Button>
      </Box>
    </div>
  );
};

export default PostDetailsForm;
