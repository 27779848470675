import {
  FormControlLabel,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormGroup,
  Checkbox,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Controller, useForm } from "react-hook-form";
import countryCodeData from "./data/countryCodeData.json";
import PhoneInput from "react-phone-input-2";
import { useState } from "react";
import useLocationData from "~/hooks/useLocationData";
import AddressBox from "~/components/FormInputs/AddressBox";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import { toast } from "react-toastify";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import useValidation from "~/hooks/useValidation";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ADD_USER } from "./graphql/Mutation";
import useRefetch from "~/hooks/useRealtime";
import { INSERT_BRD_USERS } from "~/graphql/brd_users/Mutation";
import { GET_BRD_USERS_WITH_ID } from "~/graphql/brd_users/Query";

const UserAdd = ({ defaultRole, heading, setSidePopup }: any) => {
  const { brands }: any = useAssigedBrands();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [formValue, setFormValue] = useState({
    phoneNumber: "",
  });

  // const {data}=useQuery(GET_BRD_USERS_WITH_ID,{
  //   variables:{
  //     id
  //   }
  // })

  const client = useApolloClient();

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  const { travelHouse }: any = travelHouseStore();
  const validation = useValidation();
  const [addUsers] = useMutation(ADD_USER);
  const [addBrdUser] = useMutation(INSERT_BRD_USERS);
  const refetch = useRefetch();

  const onSubmit = async (formData: any) => {
    if (formData.brands.length <= 0) {
      toast.error("At least one brand is required for update!");
      return 0;
    }
    if (!formData.brands) {
      toast.error("At least one brand is required for update!");
      return 0;
    }
    let brd_users: any = [];
    if (typeof formData.brands === "string") {
      brd_users.push({
        thp_id: travelHouse?.id,
        brd_id: formData.brands,
      });
    } else {
      formData.brands.forEach((brd_id: any) => {
        brd_users.push({
          thp_id: travelHouse?.id,
          brd_id: brd_id,
        });
      });
    }

    const resPhone = await validation.checkPhone(formData.phoneNumber);

    // resPhone &&
    //   resPhone.length > 0 &&
    //   toast.error("Phone number already exist");

    const resEmail = await validation.checkEmail(formData.userEmail);

    // resEmail && resEmail.length > 0 && toast.error("Email already exist");

    try {
      if (resPhone.length > 0 || resEmail.length > 0) {
        const resdata = await client.query({
          query: GET_BRD_USERS_WITH_ID,
          variables: {
            userId: resPhone?.[0]?.id || resEmail?.[0]?.id,
          },
        });

        if (!resdata?.data?.brd_users?.[0]?.id) {
          const res = await addBrdUser({
            variables: {
              data: brd_users?.map((item: any) => ({
                thp_id: item?.thp_id,
                brd_id: item.brd_id,
                user_id: resPhone?.[0]?.id || resEmail?.[0]?.id,
              })),
            },
          });

          if (res?.data?.insert_brd_users?.affected_rows) {
            toast.success("User added successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            reset();
            await refetch();
            setSidePopup(false);
          }
        } else {
          toast.error("Email / Phone already exist");
        }
      } else if (resPhone.length < 1 && resEmail.length < 1) {
        const res = await addUsers({
          variables: {
            user: {
              displayName: formData.userName,
              email: formData.userEmail,
              defaultRole: formData.role ? formData.role : defaultRole,
              phoneNumber: formData.phoneNumber,
              metadata: {
                country: formData.location.country
                  ? formData.location.country
                  : null,
                addressLine1: formData.location.addressLine1,
                addressLine2: formData.location.addressLine2,
                city: formData.location.city,
                postalCode: formData.location.postalCode,
                region: formData.location.region,
                alias: formData.alias ? formData.alias : null,
              },
              brd_users: {
                data: brd_users,
              },
              thp_users_profile: {
                data: {
                  basic_salary: formData?.basic_salary
                    ? formData?.basic_salary
                    : null,
                  bio: formData?.bio ? formData?.bio : null,
                  pseudo_name: formData?.pseudo_name
                    ? formData?.pseudo_name
                    : null,
                  // twitter: formData?.twitter ? formData?.twitter : null,
                  facebook: formData?.facebook ? formData?.facebook : null,
                  linkedin: formData?.linkedin ? formData?.linkedin : null,
                  discord: formData?.discord ? formData?.discord : null,
                  x: formData?.x ? formData?.x : null,
                  skype: formData?.skype ? formData?.skype : null,
                  youtube: formData?.youtube ? formData?.youtube : null,
                  instagram: formData?.instagram ? formData?.instagram : null,
                  commission: formData?.commission
                    ? formData?.commission
                    : null,
                },
              },
              locale: "en",
            },
          },
        });

        if (res.data?.insertUsers?.returning?.length > 0) {
          toast.success("User added successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reset();
          await refetch();
          setSidePopup(false);
        }
      }
    } catch (e: any) {
      toast.error("e", e?.message);
    }
  };

  return (
    <>
      <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">{heading}</h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid grid-cols-1 mt-10 gap-5">
            <TextField
              error={errors["userName"] ? true : false}
              {...register("userName", { required: true })}
              label="User Name"
              type="text"
            />
            <TextField
              error={errors["userEmail"] ? true : false}
              {...register("userEmail", {
                required: true,
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Please enter a valid email address",
                },
              })}
              label="User Email"
              type="email"
            />
            <Controller
              control={control}
              {...register("phoneNumber")}
              render={({ field, fieldState }: any) => (
                <PhoneInput
                  {...field}
                  inputProps={{
                    className:
                      "w-full py-[16px] px-[14px] pl-[50px] bg-transparent border-gray-400 dark:border-gray-600 hover:border-gray-700 dark:hover:border-gray-200 rounded-[3px] focus:border-basic focus:ring-basic",
                  }}
                  buttonStyle={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  dropdownStyle={
                    darkMode
                      ? {
                          backgroundColor: "#312D4B",
                          borderRadius: "3px",
                          color: "#9155FD",
                        }
                      : {
                          backgroundColor: "#F4F5FA",
                          borderRadius: "3px",
                          color: "#3A3541DE",
                        }
                  }
                  country={"gb"} // Set your desired default country
                  enableAreaCodes={true} // Show countries dropdown
                />
              )}
            />
            <AddressBox
              register={register}
              errors={errors}
              fieldName="location"
              setValue={setValue}
            />
            {brands &&
              brands?.map((brand: any) => {
                return (
                  <FormGroup>
                    <FormControlLabel
                      {...register("brands")}
                      control={<Checkbox />}
                      value={brand.id}
                      label={brand.name}
                    />
                  </FormGroup>
                );
              })}
          </div>
          <Button
            type="submit"
            sx={{ width: "100%", marginTop: "25px" }}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </div>
    </>
  );
};

export default UserAdd;
