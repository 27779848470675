import { gql } from "@apollo/client";

export const INSERT_SELECTED_FL_JOBS = gql`
  mutation insertSelectedFlJobs($data: [brd_fl_selected_jobs_insert_input]!) {
    insert_brd_fl_selected_jobs(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_SELECTED_FL_JOBS = gql`
  mutation deleteSelectedFlJobs($ids: [uuid!]!) {
    delete_brd_fl_selected_jobs(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;
