import React, { useEffect, useRef, useState } from "react";
import Footer from "../Footer";
import Header from "./component/Header";
import ReplyMessage from "../ReplyMessage";
import TextMessage from "./component/TextMessage";
import Recording from "./component/Recording";
import VoiceMessage from "./component/VoiceMessage";
import CloseIcon from "@mui/icons-material/Close";
import VideoMessage from "./component/VideoMessage";
import ImageMessage from "./component/ImageMessage";
import { URLs } from "~/config/enums";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { UPDATE_CONVERSATION } from "../../graphql/Query";
import { useParams } from "react-router-dom";
import DocMessage from "./component/DocMessage";
import { UPDATE_WHATSAPP_MESSAGE_STATUS } from "~/graphql/brd_whatsapp_messages_status/Mutation";
import useWhatsappMessageStatus from "~/hooks/useWhatsappMessageStatus";

export default function Messages({
  messages,
  setShowMessageInfoBar,
  setShowReplyMessage,
  handleScroll,
  setShowWhatsappChatBox,
}: any) {
  const [showEmojis, setShowEmojis] = useState(false);
  const [showRecording, setShowRecording] = useState(false);
  const chatContainerRef: any = useRef(null);
  const { conversation_id } = useParams();
  const [update] = useMutation(UPDATE_CONVERSATION);
  const [prevReplyMsgId, setPrevReplyMsgId] = useState<any>(false);
  const [prevReplyMsgElem, setPrevReplyMsgElem] = useState<any>(false);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef?.current?.scrollHeight;
    }
  }, [messages]);

  const updateLastViewTime = async (time: any) => {
    const resSuggestion = await update({
      variables: { conversation_id: conversation_id, time: time },
    });
  };

  const scrollToMessage = (messageId: any) => {
    const messageElement = document.getElementById(messageId);
    const container = document.getElementById("whatsappChatContainer"); // Replace 'whatsappChatContainer' with the ID of your chat container element

    if (prevReplyMsgElem && prevReplyMsgId !== messageId) {
      prevReplyMsgElem.style.backgroundColor = ""; // Reset background color of previous message
    }

    if (messageElement && container?.contains(messageElement)) {
      messageElement.scrollIntoView({ behavior: "smooth", block: "center" });
      messageElement.style.backgroundColor = "#E3DED6";
      setPrevReplyMsgId(messageId);
      setPrevReplyMsgElem(messageElement);
    }
  };

  if (prevReplyMsgElem) {
    setTimeout(() => {
      prevReplyMsgElem.style.backgroundColor = ""; // Reset background color of previous message
    }, 3000);
    setPrevReplyMsgElem(false);
  }

  useEffect(() => {
    // Get the reference to the scrollable div
    const scrollableDiv = document.getElementById("scrollableDiv");

    if (scrollableDiv) {
      // Add a scroll event listener
      const handleScroll = () => {
        // This function will be called whenever the scroll height changes
        console.log(
          "Scroll height changed!",
          scrollableDiv?.scrollHeight,
          scrollableDiv?.scrollTop
        );
        if (scrollableDiv?.scrollHeight <= scrollableDiv?.scrollTop) {
          const currentTime = moment().utc();
          const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");
          updateLastViewTime(formattedTime);
        }
      };

      scrollableDiv.addEventListener("scroll", handleScroll);

      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        scrollableDiv.removeEventListener("scroll", handleScroll);
      };
    } else {
    }
  }, [chatContainerRef.current]); // Empty dependency array ensures that the effect runs only once (on mount)
  return (
    <div
      className="bg-[#EFEAE2] dark:bg-[#38344e] overflow-scroll flex-grow py-[20px] overflow-x-hidden	"
      ref={chatContainerRef}
      id="whatsappChatContainer"
      onScroll={handleScroll}
    >
      {/* <div className="flex items-center justify-center">
        <p className="bg-[#FFFFFFF2] text-[13px] px-2 py-1 text-primary rounded shadow">
          12/14/2023
        </p>
      </div> */}
      {messages.map((message: any) => {
        return message.type === "text" ? (
          <TextMessage
            prevReplyMsgId={prevReplyMsgId}
            scrollToMessage={scrollToMessage}
            setShowMessageInfoBar={setShowMessageInfoBar}
            setShowReplyMessage={setShowReplyMessage}
            message={message}
            type={message.sender_role === "user" ? "recieve" : "send"}
            messageType={message.reply ? "messageReply" : ""}
            setShowWhatsappChatBox={setShowWhatsappChatBox}
            // {message.reply && (
            //     <div>
            //       <p className="text-sm mt-1">{message.reply.text}</p>
            //     </div>
            //   )}
          />
        ) : message.type === "image" ? (
          <ImageMessage
            prevReplyMsgId={prevReplyMsgId}
            scrollToMessage={scrollToMessage}
            setShowMessageInfoBar={setShowMessageInfoBar}
            message={message}
            setShowReplyMessage={setShowReplyMessage}
            type={message.sender_role === "user" ? "recieve" : "send"}
            setShowWhatsappChatBox={setShowWhatsappChatBox}
          />
        ) : message.type === "video" ? (
          <ImageMessage
            prevReplyMsgId={prevReplyMsgId}
            scrollToMessage={scrollToMessage}
            setShowMessageInfoBar={setShowMessageInfoBar}
            message={message}
            video={message.type === "video" ? true : false}
            setShowReplyMessage={setShowReplyMessage}
            type={message.sender_role === "user" ? "recieve" : "send"}
            setShowWhatsappChatBox={setShowWhatsappChatBox}
          />
        ) : message.type === "audio" ? (
          <VoiceMessage
            prevReplyMsgId={prevReplyMsgId}
            scrollToMessage={scrollToMessage}
            setShowMessageInfoBar={setShowMessageInfoBar}
            setShowReplyMessage={setShowReplyMessage}
            message={message}
            type={message.sender_role === "user" ? "recieve" : "send"}
            setShowWhatsappChatBox={setShowWhatsappChatBox}
          />
        ) : message.type === "template" ? (
          <TextMessage
            prevReplyMsgId={prevReplyMsgId}
            scrollToMessage={scrollToMessage}
            setShowReplyMessage={setShowReplyMessage}
            setShowMessageInfoBar={setShowMessageInfoBar}
            message={message}
            type={message.sender_role === "user" ? "recieve" : "send"}
            messageType={message.reply ? "messageReply" : ""}
            setShowWhatsappChatBox={setShowWhatsappChatBox}
          />
        ) : (
          message.type === "document" && (
            <DocMessage
              prevReplyMsgId={prevReplyMsgId}
              scrollToMessage={scrollToMessage}
              setShowReplyMessage={setShowReplyMessage}
              setShowMessageInfoBar={setShowMessageInfoBar}
              message={message}
              setShowWhatsappChatBox={setShowWhatsappChatBox}
              type={message.sender_role === "user" ? "recieve" : "send"}
            />
          )
        );
      })}

      {/* <TextMessage setShowReplyMessage={setShowReplyMessage} />
      <TextMessage setShowReplyMessage={setShowReplyMessage} type="recieve" />
      <TextMessage setShowReplyMessage={setShowReplyMessage} type="recieve" />
      <VoiceMessage setShowReplyMessage={setShowReplyMessage} />
      <VoiceMessage setShowReplyMessage={setShowReplyMessage} type="recieve" /> */}
      {/* <TextMessage
        setShowReplyMessage={setShowReplyMessage}
        messageType="messageReply"
      />
      <TextMessage
        setShowReplyMessage={setShowReplyMessage}
        messageType="messageReply"
        type="recieve"
      />
      <VoiceMessage
        setShowReplyMessage={setShowReplyMessage}
        messageType="messageReply"
      />
      <VoiceMessage
        setShowReplyMessage={setShowReplyMessage}
        type="recieve"
        messageType="messageReply"
      />
      <VideoMessage setShowReplyMessage={setShowReplyMessage} video={true} />
      <VideoMessage
        setShowReplyMessage={setShowReplyMessage}
        type="recieve"
        video={true}
      />
      <VideoMessage setShowReplyMessage={setShowReplyMessage} />
      <VideoMessage setShowReplyMessage={setShowReplyMessage} type="recieve" />
      <VideoMessage
        setShowReplyMessage={setShowReplyMessage}
        messageType="messageReply"
        message="photoMessage"
      />
      <VideoMessage
        setShowReplyMessage={setShowReplyMessage}
        type="recieve"
        messageType="messageReply"
        message="photoMessage"
      />
      <VideoMessage
        setShowReplyMessage={setShowReplyMessage}
        messageType="messageReply"
        message="videoMessage"
        video={true}
      />
      <VideoMessage
        setShowReplyMessage={setShowReplyMessage}
        type="recieve"
        messageType="messageReply"
        message="videoMessage"
        video={true}
      /> */}
    </div>
  );
}
