import { Document, Page, View, Image, Text } from "@react-pdf/renderer";
import { getStyles } from "./components/styles";
import PassengerComponent from "./components/passengers";

const PaymentReciept = ({ inquiry, invoice, logo }: any) => {
  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );

  const flightCosts =
    invoice?.invoice_suggestions
      ?.filter((suggestion: any) => suggestion.type === "flights") // Filter for flight suggestions
      ?.flatMap(
        (suggestion: any) => suggestion?.inq_suggestion || [] // Map to costs, ensuring it's an array
      ) || []; // Return an empty array if no costs are found

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerFirstContainer}>
            {logo && <Image style={styles.logo} src={logo} />}

            <View style={styles.brandDetail}>
              <Text style={styles.brandName}>{inquiry?.brd_list?.name}</Text>
              <Text>www.{inquiry?.brd_list?.domain}</Text>
              <Text>{inquiry?.brd_list?.brd_contact_details?.[0]?.email}</Text>
              <Text>{inquiry?.brd_list?.brd_contact_details?.[0]?.phone}</Text>
            </View>
          </View>
          <View style={styles.headerSecondContainer}>
            <Text style={styles.secondContainerText}>
              {inquiry?.brd_list?.brd_contact_details?.[0]?.address1}
            </Text>
            <Text style={styles.secondContainerText}>
              {inquiry?.brd_list?.brd_contact_details?.[0]?.city}{" "}
              {inquiry?.brd_list?.brd_contact_details?.[0]?.country}
            </Text>
          </View>
        </View>
        <View style={styles.inquiryDetailContainer}>
          <View style={styles.inquirySecondContainer}>
            <View style={styles.inquiryColumnContainer}>
              <View style={styles.brandDetail}>
                <Text>Billed to</Text>
                <Text style={styles.boldFont}>
                  {inquiry?.users?.displayName}
                </Text>
                {(inquiry?.users?.metadata?.addressLine1 ||
                  inquiry?.users?.metadata?.addressLine2 ||
                  inquiry?.users?.metadata?.postalCode ||
                  inquiry?.users?.metadata?.city) && (
                  <Text>
                    {inquiry?.users?.metadata?.addressLine1}{" "}
                    {inquiry?.users?.metadata?.addressLine2}{" "}
                    {inquiry?.users?.metadata?.postalCode}{" "}
                    {inquiry?.users?.metadata?.city}{" "}
                  </Text>
                )}
                <Text> {inquiry?.users?.phoneNumber}</Text>
                <Text> {inquiry?.users?.email}</Text>
              </View>
            </View>
            <View style={styles.inquiryColumnContainer}>
              <View style={styles.brandDetail}>
                <Text>Payable Amount</Text>
                <Text style={styles.invoiceGbp}>£{invoice?.amount}</Text>
              </View>
            </View>
          </View>
          <View>
            <View style={styles.passengerHeader}>
              <Text>Type</Text>
              <Text>DETAIL</Text>
              <Text>AMOUNT</Text>
            </View>
            <PassengerComponent inquiry={inquiry} invoice={invoice} />
          </View>
          <View style={styles.totalsContainer}>
            <View style={styles.totals}>
              <Text>Subtotal</Text>
              <Text>£{invoice?.amount - flightCosts?.[0]?.bookingFee}</Text>
            </View>
            <View style={styles.totals}>
              <Text>Booking Fee</Text>
              <Text>£{flightCosts?.[0]?.bookingFee}</Text>
            </View>
            <View style={styles.totals}>
              <Text>Grand Total</Text>
              <Text>£{invoice?.amount}</Text>
            </View>
            <View style={styles.paid}>
              <Text style={styles.boldFont}>Paid</Text>
              <Text style={styles.boldFont}>
                £
                {inquiry?.inq_transections
                  ?.filter((transaction: any) =>
                    ["paid", "partiallyRefunded"].includes(transaction.status)
                  )
                  ?.map((transaction: any) =>
                    transaction.status === "partiallyRefunded"
                      ? transaction.amount - transaction.refund_amount
                      : transaction.amount
                  )
                  ?.reduce((sum: number, amount: number) => sum + amount, 0)}
              </Text>
            </View>
            <View style={styles.totals}>
              <Text style={styles.boldFont}>Balance Due</Text>
              <Text style={styles.boldFont}>
                £
                {invoice?.amount -
                  inquiry?.inq_transections
                    ?.filter((transaction: any) =>
                      ["paid", "partiallyRefunded"].includes(transaction.status)
                    )
                    ?.map((transaction: any) =>
                      transaction.status === "partiallyRefunded"
                        ? transaction.amount - transaction.refund_amount
                        : transaction.amount
                    )
                    ?.reduce((sum: number, amount: number) => sum + amount, 0)}
              </Text>
            </View>
          </View>
          {inquiry?.inq_transections?.filter((transaction: any) =>
            ["paid", "partiallyRefunded"].includes(transaction.status)
          )?.length > 0 && (
            <View>
              <View style={styles.passengerHeader}>
                <Text>TRANSACTIONS ID</Text>
                <Text>PAYMENT MODE</Text>
                <Text>AMOUNT</Text>
              </View>
              {inquiry?.inq_transections
                ?.filter((transaction: any) =>
                  ["paid", "partiallyRefunded"].includes(transaction.status)
                )
                .map((item: any) => {
                  return (
                    <View style={styles.passengerBody}>
                      <Text> #{item.transactions_no}</Text>
                      <Text>{item.acc_payment_method.type}</Text>
                      <Text>
                        £
                        {item.status === "partiallyRefunded"
                          ? item.amount - item.refund_amount
                          : item.amount}
                      </Text>
                    </View>
                  );
                })}
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default PaymentReciept;
