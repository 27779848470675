import { gql } from "@apollo/client";

export const GET_SEO_SETTINGS = gql`
  query GetSeoSettings($brdId: uuid!, $pageType: String!) {
    brd_services_seo_settings(
      where: { brd_id: { _eq: $brdId }, page_type: { _eq: $pageType } }
    ) {
      title
      description
      default_keywords
      id
      og_image_url
      canonical_url
      page_type
    }
  }
`;


export const GET_BRD_SEO_SETTINGS_WITH_SERVICE_ID = gql`
  query getBrdSeoSettings($serviceId: uuid!) {
    brd_seo_setting(where: { serv_page_id: { _eq: $serviceId } }) {
      id
      default_title
      default_keywords
      canonical_url
      og_image_url
      default_description
    }
  }
`;

export const GET_BRD_SEO_SETTINGS_WITH_CASESTUDY_ID = gql`
  query getBrdSeoSettings($caseStudyId: uuid!) {
    brd_seo_setting(where: { caseStudy_page_id: { _eq: $caseStudyId } }) {
      id
      default_title
      default_keywords
      canonical_url
      og_image_url
      default_description
    }
  }
`;
