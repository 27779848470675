import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import TableComponent from "./components/SingleTrasaction/table";
import { toast } from "react-toastify";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import moment from "moment";
import useRefetch from "~/hooks/useRealtime";
import { useMutation } from "@apollo/client";
import { ADD_TRANSACTION } from "./graphql/Mutation";
import useAccountList from "~/hooks/useAccountList";
import useAssignedUsers from "~/hooks/useAssignedUsers";
import useBrandPaymentMethodName from "~/hooks/useBrandPaymentMethodName";

const AddManual = ({ setSidePopup }: any) => {
  const { brands }: any = useAssigedBrands();

  const [addTransaction] = useMutation(ADD_TRANSACTION);

  const refetch = useRefetch();

  const [formValue, setFormValue] = useState({
    brand: "",
  });

  const foundBrand =
    brands && brands.find((brand: any) => brand.name === formValue.brand);
  const brd_id = foundBrand ? foundBrand.id : null;

  const {
    register,
    control,
    reset,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  function isUUID(input: any) {
    const uuidPattern =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return uuidPattern.test(input);
  }

  const [debit, setDebit] = useState<any>(0);
  const [credit, setCredit] = useState<any>(0);

  const onSubmit = async (formData: any) => {
    if (debit !== credit) {
      toast.error("Debit and credit must be equal");
    } else {
      const tenDigitCode: any = generateRandom10DigitCode();
      const transaction: any = [];
      formData.row.forEach((row: any) => {
        if (row.debit || row.credit) {
          const isValid = isUUID(row.account);
          let acc_id, def_acc_id;
          if (isValid) {
            acc_id = row.account;
            def_acc_id = null;
          } else {
            def_acc_id = row.account;
            acc_id = null;
          }
          const obj = {
            brd_id: brd_id,
            acc_id: acc_id,
            def_acc_id: def_acc_id,
            payment_method_id: row.paymentMethodName,
            user_id: row.user,
            inq_id: row.inquiryNo,
            transactions_no: tenDigitCode,
            date: moment(formData.date).format("YYYY-MM-DD"),
            created_at: moment(formData.date).format("YYYY-MM-DD"),
            type: row.debit ? "debit" : "credit",
            amount: row.debit ? parseFloat(debit) : -parseFloat(credit),
          };
          transaction.push(obj);
        }
      });
      try {
        const res = await addTransaction({
          variables: {
            transactions: transaction,
          },
        });
        toast.success("Transaction added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        await refetch();
        setSidePopup(false);
      } catch (e) {}
    }
  };

  return (
    <>
      <div className="w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">Journal Entry</h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 my-10 gap-5">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("brand", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  sx={{ paddingTop: "7px" }}
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.name);
                    setFormValue({
                      ...formValue,
                      brand: data?.name,
                    });
                  }}
                  options={brands ? brands : []}
                  getOptionLabel={(option: any) => option?.name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Brand"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                        el && errors["brand"] && el.focus();
                      }}
                      error={errors["brand"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                sx={{ paddingTop: "7px" }}
                components={["DatePicker", "DatePicker"]}
              >
                <Controller
                  rules={{
                    required: true,
                  }}
                  {...register("date", { required: true })}
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <DatePicker
                      value={value}
                      inputRef={ref}
                      onChange={(date) => {
                        onChange(date?.$d);
                      }}
                      sx={{ width: "100%" }}
                      label="Date"
                      slotProps={{
                        textField: {
                          error: errors["date"] ? true : false,
                        },
                      }}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <TableComponent
            errors={errors}
            register={register}
            control={control}
            brdId={brd_id}
            watch={watch}
            setDebit={setDebit}
            setCredit={setCredit}
            getValues={getValues}
            setValue={setValue}
            debit={debit}
            credit={credit}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "100%", marginTop: "20px" }}
          >
            Submit
          </Button>
        </Box>
      </div>
    </>
  );
};

export default AddManual;
