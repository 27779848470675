import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_MAILJET_CONTACT_LIST } from "~/graphql/get_mailjet_contact_list/Query";
import brandsStore from "~/store/brandsStore/brandsStore";
import GetContactsList from "./components/GetContactsList";
import UploadCSVFile from "./components/UploadCSVFile";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  Button,
  LinearProgress,
} from "@mui/material";

const EmailContactList = () => {
  const { brands } = brandsStore();
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [genText, setGenText] = useState("Getting Destinations from Portal...");
  const [progress, setProgress] = useState(0);

  const [fetchContacts] = useLazyQuery(GET_MAILJET_CONTACT_LIST);

  const loadContactLists = async () => {
    if (!brands || brands.length === 0) return;
    setLoading(true);
    try {
      const results = await Promise.all(
        brands.map((brand: any) =>
          fetchContacts({ variables: { brdId: brand.id } })
        )
      );

      const allContacts: any = results.flatMap(
        ({ data }) => data?.get_mailjet_contact_list?.Data || []
      );

      setRows(allContacts);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadContactLists();
  }, [brands]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="relative flex flex-col gap-5">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-semibold text-primary dark:text-dark-primary">
          Contacts List
        </h1>
        <div className="flex gap-3 items-center">
          <UploadCSVFile />
          <GetContactsList
            setGenerating={setGenerating}
            setProgress={setProgress}
            setGenText={setGenText}
          />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="contact list table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="center">Subscribers Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No Data Found
                </TableCell>
              </TableRow>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.ID}>
                    <TableCell>{row.ID}</TableCell>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell align="center">{row.SubscriberCount}</TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {/* Overlay for LinearProgress */}
      {generating && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="flex flex-col items-center bg-white p-4 rounded shadow-lg w-1/2 max-w-lg">
            <p className="text-lg font-semibold mb-2">{genText}</p>
            <LinearProgress
              sx={{ width: "100%" }}
              variant="determinate"
              value={progress}
            />
            <p className="mt-2">{progress}%</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailContactList;
