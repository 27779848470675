export const VisaFormatFormData = (visaSuggestion: any) => {
    const perPersonCost = (visa_inq_suggestion_costs: any) => {
        const persons: any = []
        
        visa_inq_suggestion_costs.map((suggestion_cost: any) => {
            persons.push({
                id: suggestion_cost.id,
                cost_price: suggestion_cost.cost_price,
                sale_price: suggestion_cost.sale_price,
                inq_passenger_id: suggestion_cost.inq_passenger_id,
                first_name: suggestion_cost?.inq_passenger?.first_name,
                last_name: suggestion_cost?.inq_passenger?.last_name
            })
        })

        return persons
    }

    const defaultValues: any = {
        id: visaSuggestion.id,
        name: visaSuggestion.name,
        country: visaSuggestion.country,
        visa_expiry_date: visaSuggestion.visa_expiry_date,
        visa_inq_suggestion_cost: perPersonCost(
            visaSuggestion.visa_inq_suggestion_costs || []
        )
    }

    return defaultValues
}