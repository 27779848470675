import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import brandsStore from "~/store/brandsStore/brandsStore";
import { GET_MAILJET_SENDERS_LIST } from "~/graphql/get_mailjet_sender_list/Query";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import AddSender from "./components/AddSender";
import SidePopup from "~/components/common/SidePopup";

const EmailSendersList = () => {
  const { brands } = brandsStore();
  const [senders, setSenders] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fetchSenders] = useLazyQuery(GET_MAILJET_SENDERS_LIST);
  const [loading, setLoading] = useState(false);

  const loadSendersList = async () => {
    if (!brands || brands.length === 0) return;
    try {
      const results = await Promise.all(
        brands.map((brand: any) =>
          fetchSenders({ variables: { brdId: brand.id } })
        )
      );

      const allSenders: any = results.flatMap(
        ({ data }) => data?.get_mailjet_sender_list?.Data || []
      );
      setSenders(allSenders);
    } catch (error) {}
  };

  useEffect(() => {
    const loadSendersList = async () => {
      if (!brands || brands.length === 0) return;
      setLoading(true);
      try {
        const results = await Promise.all(
          brands.map((brand: any) =>
            fetchSenders({ variables: { brdId: brand.id } })
          )
        );

        const allSenders: any = results.flatMap(
          ({ data }) => data?.get_mailjet_sender_list?.Data || []
        );

        setSenders(allSenders);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    loadSendersList();
  }, [brands]);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="flex flex-col gap-5">
      <h1 className="text-xl font-semibold text-primary dark:text-dark-primary">
        Senders List
      </h1>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="senders table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Default Sender</TableCell>
              <TableCell align="center">Email Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : senders.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Found
                </TableCell>
              </TableRow>
            ) : (
              senders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((sender: any) => (
                  <TableRow key={sender.ID}>
                    <TableCell component="th" scope="row">
                      {sender.ID}
                    </TableCell>
                    <TableCell>{sender.Name}</TableCell>
                    <TableCell>{sender.Email}</TableCell>
                    <TableCell align="center">{sender.Status}</TableCell>
                    <TableCell align="center">
                      {new Date(sender.CreatedAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="center">
                      {sender.IsDefaultSender ? "Yes" : "No"}
                    </TableCell>
                    <TableCell align="center">{sender.EmailType}</TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={senders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <SidePopup component={<AddSender refetchSenders={loadSendersList} />} />
    </div>
  );
};

export default EmailSendersList;
