import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import UmrahPackageUpdate from "~/modules/BrandModule/segments/UmrahPackages/components/UmrahPackageUpdate";

const UmrahPackageUpdatePg = () => {
  return (
    <Layout>
      <BrandModule>
        <UmrahPackageUpdate />
      </BrandModule>
    </Layout>
  );
};

export default UmrahPackageUpdatePg;
