import { gql } from "@apollo/client";

export const INSERT_UMRAH_FARES = gql`
  mutation insertUmrahFares($data: [umrah_fares_insert_input!]!) {
    insert_umrah_fares(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_UMRAH_FARES = gql`
  mutation deleteUmrahFares($ids: [uuid!]!) {
    delete_umrah_fares(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;
