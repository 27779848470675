import useFlJobs from "~/hooks/useFlJobs";
import useSelectedFlJobsWithBrdId from "~/hooks/useSelectedFlJobsWithBrdId";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  DELETE_SELECTED_FL_JOBS,
  INSERT_SELECTED_FL_JOBS,
} from "~/graphql/brd_fl_selected_jobs/Mutation";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const ActivateSkillsComp = () => {
  const { brd_id } = useParams();

  const { flJobs } = useFlJobs();
  const { selectedFlJobsWithBrdId, refetchSelectedJobs } =
    useSelectedFlJobsWithBrdId(brd_id);

  useEffect(() => {
    refetchSelectedJobs();
  }, []);

  const [selectedSkillIds, setSelectedSkillIds] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedSkillIds(
      selectedFlJobsWithBrdId?.map((item: any) => item.project_id)
    );
  }, [selectedFlJobsWithBrdId]);

  const [insertSelectedJobs] = useMutation(INSERT_SELECTED_FL_JOBS);
  const [deleteSelectedJobs] = useMutation(DELETE_SELECTED_FL_JOBS);

  const updateSkills = async () => {
    try {
      setLoading(true);
      let status = true;
      const defIds = selectedFlJobsWithBrdId?.map(
        (item: any) => item.project_id
      );

      const insertPayload = selectedSkillIds
        ?.filter((item: any) => !defIds.includes(item))
        ?.map((skill: any) => ({
          project_id: skill,
          brd_id,
        }));

      if (insertPayload.length > 0) {
        const res = await insertSelectedJobs({
          variables: {
            data: insertPayload,
          },
        });

        if (!res?.data?.insert_brd_fl_selected_jobs?.affected_rows) {
          status = false;
        }
      }

      const deletePayload = selectedFlJobsWithBrdId
        ?.filter((item: any) => !selectedSkillIds.includes(item.project_id))
        ?.map((item: any) => item.id);

      if (deletePayload.length > 0) {
        const res = await deleteSelectedJobs({
          variables: {
            ids: deletePayload,
          },
        });

        if (!res?.data?.delete_brd_fl_selected_jobs?.affected_rows) {
          status = false;
        }
      }

      if (status === true) {
        toast.success("Updated SuccessFully");
        refetchSelectedJobs();
        setLoading(false);
      }
    } catch (err: any) {
      toast.error(err);
      refetchSelectedJobs();
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-[18px] font-bold">ACTIVATE SKILLS</h1>
        <Button variant="contained" onClick={updateSkills}>
          {loading ? "Updating..." : "Update Skills"}
        </Button>
      </div>

      <div className="grid grid-cols-5 mt-5">
        {flJobs
          ?.filter((item: any) => item.active_project_count > 0)
          ?.map((item: any, ind: any) => {
            return (
              <FormControlLabel
                key={ind}
                control={
                  <Checkbox
                    onChange={() => {
                      setSelectedSkillIds(
                        (prev: any) =>
                          prev.some((skill: any) => skill === item.id)
                            ? prev.filter((skill: any) => skill !== item.id) // Remove if already selected
                            : [...prev, item.id] // Add if not selected
                      );
                    }}
                    checked={selectedSkillIds.includes(item.id)}
                  />
                }
                label={`${item.name} (${item.active_project_count || 0})`}
              />
            );
          })}
      </div>
    </>
  );
};

export default ActivateSkillsComp;
