import Layout from "~/components/common/Layout";
import DigitalAgenBrdModule from "~/modules/DigitalAgenBrdModule";
import ActivateCountriesComp from "~/modules/DigitalAgenBrdModule/segments/ActivateCountriesComp";

const ActivateCoutriesPage = () => {
  return (
    <Layout>
      <DigitalAgenBrdModule>
        <ActivateCountriesComp />
      </DigitalAgenBrdModule>
    </Layout>
  );
};

export default ActivateCoutriesPage;
