import { useQuery, useSubscription } from "@apollo/client";
import { GET_USERP } from "./graphql/Query";
import { GET_RECENT_MESSAGE } from "./graphql/Subscription";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import { useEffect, useState } from "react";

const SingleUser = ({ addTimeText, chat, brd_phone }: any) => {
  const { loading, data, error } = useSubscription(GET_RECENT_MESSAGE, {
    variables: { brd_phone: brd_phone, user_phone: chat.user_phone },
  });

  useEffect(() => {
    if (data?.brd_whatsapp_chat.length > 0) {
      let chatNew: any = {
        ...chat,
        timestamp: data.brd_whatsapp_chat?.[0].timestamp,
        text: data.brd_whatsapp_chat?.[0].text,
        unread: 0,
      };
      addTimeText(chatNew);
    }
  }, [data?.brd_whatsapp_chat]);

  //
  return (
    <div className="px-3 flex items-center cursor-pointer border-b border-grey-lighter ">
      <div>
        <img
          className="h-12 w-12 rounded-full"
          src="https://media.istockphoto.com/id/1131164548/vector/avatar-5.jpg?s=612x612&w=0&k=20&c=CK49ShLJwDxE4kiroCR42kimTuuhvuo2FH5y_6aSgEo="
        />
      </div>
      <div className="ml-4 flex-1 py-4 text-primary dark:text-dark-primary">
        <div className="flex items-bottom justify-between leading-[2]">
          <p className="text-grey-darkest capitalize">
            {chat.user_name || chat.user_phone}
          </p>
          <p className="text-xs text-grey-darkest">
            {!chat?.timestamp ? "" : getTimeReadableFormat(chat.timestamp)}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="text-grey-dark mt-1 text-sm leading-[1]">
            {chat?.text ? chat?.text : ""}
          </p>
          {chat?.unread <= 0 ? (
            <></>
          ) : (
            <span className="bg-[green] text-[11px] font-bold text-[white] rounded-[10px] px-[7px] py-[2px]">
              {chat?.unread}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleUser;
