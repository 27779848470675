import { useApolloClient, useMutation } from "@apollo/client";
import { Autocomplete, Button, TextField } from "@mui/material";
import { useState } from "react";

import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { INSERT_BRD_PAGES_HEADINGS } from "~/graphql/brd_pages_headings/Mutation";

const AddPageHeadings = ({ pageHeadings, SectionTypes }: any) => {
  const { register, handleSubmit, setValue, reset } = useForm<any>();
  const client = useApolloClient();
  const [insertBrdPagesHeadings] = useMutation(INSERT_BRD_PAGES_HEADINGS);
  const { brd_id, pageType } = useParams();
  const [selectedSection, setSelectedSection] = useState<any>();

  const selectedSections = pageHeadings?.map((item: any) => item?.section_name);

  const onSubmit = async (data: any) => {
    try {
      const response = await insertBrdPagesHeadings({
        variables: {
          data: [
            {
              brd_id: brd_id,
              page_name: pageType,
              heading: data.heading,
              section_name: data?.sectionType,
              subheading: data.subheading,
            },
          ],
        },
      });
      if (response.data.insert_brd_pages_headings.affected_rows > 0) {
        await client.refetchQueries({
          include: "all",
        });
        toast.success("Heading submitted successfully!");
        reset();
      }
    } catch (error) {}
  };
  const helperTextHeading =
    pageType === "single-service-page"
      ? "Variable Allowed {{service_name}}"
      : pageType === "case-study"
      ? "Variable Allowed {{case_study_name}}"
      : "Variable Allowed {{service_name}}";

  return (
    <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <div className="bg-secondary mb-4 sm:p-6 rounded-lg shadow dark:bg-dark-secondary">
        <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
          Add Page Headings
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="text-primary dark:text-dark-primary flex-col"
        >
          <div className="flex flex-col space-y-4">
            <Autocomplete
              options={SectionTypes.filter(
                (item: any) => selectedSections.includes(item.value) === false
              )}
              getOptionLabel={(option: any) => option.name}
              value={
                SectionTypes.find(
                  (section: any) => section.value === selectedSection
                ) || null
              }
              onChange={(event, newValue: any) => {
                setValue("sectionType", newValue.value);
                setSelectedSection(newValue ? newValue.value : null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Section"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <TextField
              label="Heading"
              variant="outlined"
              helperText={helperTextHeading}
              fullWidth
              {...register("heading")}
              className="dark:text-dark-primary"
            />

            <TextField
              label="Sub Heading"
              variant="outlined"
              fullWidth
              {...register("subheading")}
              className="dark:text-dark-primary"
              helperText={helperTextHeading}
            />

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPageHeadings;
