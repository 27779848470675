import { useApolloClient, useMutation } from "@apollo/client";
import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { INSERT_SERVICE_TIP } from "~/graphql/brd_services_tips/Mutation";

const AddServicesTips = ({ setSidePopup }: any) => {
  const { register, handleSubmit } = useForm();
  const client = useApolloClient();
  const { service_id } = useParams();

  const [insertServiceTip, { loading }] = useMutation(INSERT_SERVICE_TIP);

  const onSubmit = async (formData: any) => {
    try {
      // Execute the mutation
      const response = await insertServiceTip({
        variables: {
          title: formData.title,
          description: formData.description,
          brd_service_id: service_id,
        },
      });

      await client.refetchQueries({
        include: "all",
      });

      if (response.data?.insert_brd_services_tips?.affected_rows > 0) {
        toast.success("Service tip added successfully!");
        setSidePopup(false);
      }
    } catch (error) {
      toast.error("Error adding service tip.");
    }
  };

  return (
    <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <div className="bg-secondary mb-4 sm:p-6 rounded-lg shadow dark:bg-dark-secondary">
        <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
          Add Services Tips
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="text-primary dark:text-dark-primary flex-col"
        >
          <div className="flex flex-col space-y-4">
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              {...register("title")}
              className="dark:text-dark-primary"
            />

            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              {...register("description")}
              className="dark:text-dark-primary"
            />

            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddServicesTips;
