import { gql } from "@apollo/client";

export const INSERT_UMRAH_VISAS = gql`
  mutation insertUmrahVisas($data: [umrah_visas_insert_input!]!) {
    insert_umrah_visas(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_UMRAH_VISAS = gql`
  mutation deleteUmrahVisas($ids: [uuid!]!) {
    delete_umrah_visas(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;
