import { TextField, FormControl } from '@mui/material';
import SuggestionModelWraper from '../../../../SuggestionNav/components/SuggestionModelWraper'
import { Controller, useForm } from "react-hook-form";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FlightPrices from '../components/FlightPrices';
import { useState } from 'react';
import Persons from './components/Persons';
import { ADD_VISA_SUGGESTION } from '../graphql/Mutation';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const VisaSuggestionForm = ({
  showModal,
  onCloseModal,
  passengers,
  visaInqId,
  setShowAddModal
}: any) => {

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsloading] = useState(false)
  const [insertVisaSuggestion] = useMutation(ADD_VISA_SUGGESTION)

  const onSubmit = async (data: any) => {
    // console.log("sdjbsd", data)
    try {
      setIsloading(true)
      const res = await insertVisaSuggestion({
        variables: {
          data: [
            {
              visa_inq_id: visaInqId,
              name: data?.visaSuggestion?.name,
              country: data?.visaSuggestion?.country,
              visa_expiry_date: data?.visaSuggestion?.expiryDate?.$d,
              visa_inq_suggestion_costs: {
                data: data?.visaSuggestion?.persons.map((person: any) => ({
                  inq_passenger_id: person.id,
                  cost_price: person.costPrice,
                  sale_price: person.offerPrice,
                })),
              },
            },
          ],
        },
      });

      if (res?.data?.insert_visa_inq_suggestion?.affected_rows > 0) {
        toast.success(`Visa Suggestions Added Successfully!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

      }
      setShowAddModal(false);
    } catch (err) {
      toast.error(`Error Inserting Visa Suggestion. ${err}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

    } finally {
      setIsloading(false)
    }
  }

  return (
    <SuggestionModelWraper isVisible={showModal} onClose={onCloseModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-gray-100 rounded-lg p-2 dark:bg-[#28243D]">
          <div className="grid grid-cols-2 gap-4 mx-3">
            <Controller
              control={control}
              name={`visaSuggestion.name`}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    <TextField
                      id={`outlined-basic-name`}
                      label="Type"
                      variant="outlined"
                      {...field}
                      error={!!error}
                      helperText={error ? "This field is required" : ""}
                    />
                  </>
                );
              }}
            />
            <Controller
              control={control}
              name={`visaSuggestion.country`}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    <TextField
                      id={`outlined-basic-country`}
                      label="Country"
                      variant="outlined"
                      {...field}
                      error={!!error}
                      helperText={error ? "This field is required" : ""}
                    />
                  </>
                );
              }}
            />
            <FormControl fullWidth>
              <Controller
                control={control}
                name="visaSuggestion.expiryDate"
                rules={{
                  required: "This field is required", // Validation to ensure the field is not empty
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["SingleInputDateField"]}>
                          <DatePicker
                            value={value || null}
                            onChange={(newValue) => {
                              onChange(newValue); // Update the form value
                            }}
                            // minDate={today} // Optional: Restrict to future dates
                            label="Visa Expiry Date"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {error && (
                        <span className="text-red-500 text-xs">
                          {error.message}
                        </span> // Display validation error
                      )}
                    </>
                  );
                }}
              />
            </FormControl>
          </div>
        </div>
        <hr className="border-gray-300 border-dashed my-4" />
        {/* <FlightPrices errors={errors} register={register} control={control} /> */}
        <Persons register={register} errors={errors} control={control} passengers={passengers} />
        <button
          className={` ${isLoading ? "bg-basic cursor-wait" : "bg-basic"
            } h-[50px] p-2 w-half px-4 rounded-lg text-white mt-2`}
          type="submit"
        >
          {isLoading ? "Creating.." : "Create Suggestion"}
        </button>
      </form>
    </SuggestionModelWraper>
  )
}

export default VisaSuggestionForm