import { gql } from "@apollo/client";

export const INSERT_FLIGHT_EXTRA_BAGGAGE = gql`
  mutation flightInq_extraBaggage(
    $data: [flightInq_extraBaggage_insert_input!]!
  ) {
    insert_flightInq_extraBaggage(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_FLIGHT_INQ_EXTRA_BAGGAGE_SELECTION = gql`
  mutation MyMutation($data: uuid!) {
    delete_flightInq_extraBaggage(where: { inq_id: { _eq: $data } }) {
      affected_rows
    }
  }
`;
