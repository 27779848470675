import { gql } from "@apollo/client";

export const PLACE_FL_BID = gql`
  mutation placeFLBid(
    $amount: Int!
    $profileId: Int!
    $days: Int!
    $mileStone: Int!
    $prjectId: Int!
    $description: String!
  ) {
    flPlaceBid(
      arg1: {
        amount: $amount
        bidder_id: $profileId
        description: $description
        milestone_percentage: $mileStone
        period: $days
        profile_id: $profileId
        project_id: $prjectId
      }
    ) {
      status
    }
  }
`;
