import React from "react";
import StaySuggestionBox from "../SuggestionBox/StaySuggestionBox";
import CarSuggestionBox from "../SuggestionBox/CarSuggestionBox";
import TrainSuggestionBox from "../SuggestionBox/TrainSuggestionBox";
import SuggestionBox from "../SuggestionBox";
import VisaSuggestionBox from "../SuggestionBox/VisaSuggestionBox";

const TabContentRenderer = ({
  tabLabel,
  suggestions,
  inquiry,
  active,
  setActive,
}: any) => {

  const renderTabContent = () => {
    switch (tabLabel) {
      case "Hotel":
        return suggestions.map((suggestion: any, i: number) => (
          <StaySuggestionBox
            key={i}
            suggestion={suggestion}
            active={active === suggestion.id}
            inquiry={inquiry}
            setActive={setActive}
          />
        ));

      case "Cars":
        return suggestions.map((suggestion: any, i: number) => (
          <>
            <CarSuggestionBox
              key={i}
              suggestion={suggestion}
              active={active === suggestion.id}
              inquiry={inquiry}
              setActive={setActive}
            />
          </> // Replace with actual CarSuggestionBox
        ));

      case "Train":
        return suggestions.map((suggestion: any, i: number) => (
          <>
            <TrainSuggestionBox
              key={i}
              suggestion={suggestion}
              active={active === suggestion.id}
              inquiry={inquiry}
              setActive={setActive}
            />
          </> // Replace with actual TrainSuggestionBox
        ));

      case "Visa":
        return suggestions.map((suggestion: any, i: number) => (
          <VisaSuggestionBox
            key={i}
            suggestion={suggestion}
            active={active === suggestion.id}
            inquiry={inquiry}
            setActive={setActive}
          />
        ))

      case "Flights":
      default:
        return suggestions.map((suggestion: any, i: number) => (
          <SuggestionBox
            key={i}
            suggestion={suggestion}
            inquiry={inquiry}
            brand_name={inquiry.brd_list.name}
            brand_url={inquiry.brd_list.subdomain}
            brand_domain={inquiry.brd_list.domain}
            user_email={inquiry?.users?.email}
            user_id={inquiry?.users?.id}
            picked_by={inquiry?.picked_by}
            selected_suggestion_id={inquiry?.suggestion_id}
            selected={inquiry.suggestion_id === suggestion.id}
            active={active === suggestion.id}
            setActive={setActive}
          />
        ));
    }
  };

  return <>{renderTabContent()}</>;
};

export default TabContentRenderer;
