import { gql } from "@apollo/client";

export const INSERT_BRD_VISAS = gql`
  mutation InsertBrdVisas($data: [brd_visas_insert_input!]!) {
    insert_brd_visas(objects: $data) {
      affected_rows
    }
  }
`;
export const DELETE_BRD_VISAS_WITH_ID = gql`
  mutation DeleteBrdVisasWithId($id: uuid!) {
    delete_brd_visas(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_BRD_VISAS = gql`
  mutation UpdateVisa(
    $id: uuid!
    $type: uuid!
    $validity: String!
    $price: bigint
  ) {
    update_brd_visas(
      where: { id: { _eq: $id } }
      _set: { type: $type, validity: $validity, price: $price }
    ) {
      affected_rows
    }
  }
`;
