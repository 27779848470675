import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import useAssignedUsers from "~/hooks/useAssignedUsers";
import useBrandPaymentMethodName from "~/hooks/useBrandPaymentMethodName";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import { ADD_TRANSACTION } from "./graphql/Mutation";
import { toast } from "react-toastify";
import { useApolloClient, useMutation } from "@apollo/client";

const AddVendorPayment = ({ setSidePopup }: any) => {
  const { brands }: any = useAssigedBrands();

  const [formValue, setFormValue] = useState({
    brand: "",
    paymentMethodName: "",
    admin: "",
  });

  const foundBrand =
    brands && brands.find((brand: any) => brand.name === formValue.brand);
  const brd_id = foundBrand ? foundBrand.id : null;

  const { paymentMethodName }: any = useBrandPaymentMethodName(brd_id);

  const { vendors }: any = useAssignedUsers(brd_id);

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const client = useApolloClient();
  const [addTransaction] = useMutation(ADD_TRANSACTION);

  const onSubmit = async (formData: any) => {
    const tenDigitCode: any = generateRandom10DigitCode();

    const transaction: any = [
      {
        brd_id: brd_id,
        def_acc_id: 2,
        user_id: formData.vendor,
        transactions_no: tenDigitCode,
        date: moment(new Date()).format("YYYY-MM-DD"),
        type: "debit",
        amount: parseFloat(formData.amount),
      },
      {
        brd_id: brd_id,
        def_acc_id: 3,
        payment_method_id: formData.paymentMethodName,
        transactions_no: tenDigitCode,
        date: moment(new Date()).format("YYYY-MM-DD"),
        type: "credit",
        amount: -formData.amount,
      },
    ];
    // ;
    if (transaction[0].amount + transaction[1].amount === 0) {
      try {
        const res = await addTransaction({
          variables: {
            transactions: transaction,
          },
        });
        toast.success("Transaction added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        await client.resetStore();
        setSidePopup(false);
      } catch (e) {
        reset();
      }
    } else {
      toast.error("Transaction Error");
    }
  };

  return (
    <>
      <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">Add Vendors</h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid grid-cols-1 mt-10 gap-5">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("brand", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.name);
                    setFormValue({
                      ...formValue,
                      brand: data?.name,
                    });
                  }}
                  options={brands ? brands : []}
                  getOptionLabel={(option: any) => option?.name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Brand"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                        el && errors["brand"] && el.focus();
                      }}
                      error={errors["brand"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("paymentMethodName", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.id);
                  }}
                  options={paymentMethodName ? paymentMethodName : []}
                  getOptionLabel={(option: any) => option?.name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Payment Method Name"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["paymentMethodName"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("vendor", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.id);
                  }}
                  options={vendors ? vendors : []}
                  getOptionLabel={(option: any) => option?.displayName}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.displayName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Vendor"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["vendor"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <TextField
              error={errors["amount"] ? true : false}
              {...register("amount", { required: true })}
              label="Amount"
              type="number"
            />
          </div>
          <Button
            type="submit"
            sx={{ width: "100%", marginTop: "25px" }}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </div>
    </>
  );
};

export default AddVendorPayment;
