import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import useNotifications from "~/hooks/useNotifications";
import {
  useFileUpload,
  useUserDefaultRole,
  useUserDisplayName,
} from "@nhost/react";
import { ALLOWED_SCREEN_SHOT_FORMATS } from "~/config/constants";
import useEmail from "~/hooks/emails/useEmail";
import { EDIT_PAY } from "./graphql/Mutation";

export default function PaymentEdit({
  setEditForm,
  transaction,
  inquiry,
}: any) {
  const { travelHouse }: any = travelHouseStore();
  const emailSend = useEmail();
  const [editPay] = useMutation(EDIT_PAY);
  const client = useApolloClient();
  const [isloading, setIsloading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any | null>(null);
  const userUserName = useUserDisplayName();
  const { upload, progress } = useFileUpload();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const alert = useNotifications();
  const userRole = useUserDefaultRole();

  const onSubmit = async (formData: any) => {
    setIsloading(true);
    formData["status"] = "pending"; // add status  to formdata

    if (selectedFile) {
      const res = await upload(selectedFile);
      formData["receipt"] = res?.id || "receipt"; // add receipt to formdata
    } else {
      formData["receipt"] = null; // add receipt to formdata
    }

    try {
      // Create Suggestion function call
      const res = await editPay({
        variables: { id: transaction.id, data: formData },
      });

      if (res.data?.update_inq_transection?.returning?.length > 0) {
        toast.success("Payment added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        await alert.newNotification(
          "accountant",
          `Pending payment approval. Please check your portal`,
          "",
          ``
        );

        if (userRole === "user") {
          await alert.newNotification(
            inquiry.picked_by,
            `Customer created the bank payment, please check your portal`,
            "",
            ``
          );
        } else {
          await alert.newNotification(
            inquiry.user_id,
            `Your payment is created by consultant, please check your portal`,
            "",
            ``
          );
        }

        await client.resetStore();
      }
      setIsloading(false);
      setEditForm(false); // hide form modal
    } catch (e) {
      setIsloading(false);
      setEditForm(false);
    }
  };

  const handleFileUpload = async (event: any) => {
    const file = event.target.files[0];
    const fileType = file.type.split("/")[1];
    if (!ALLOWED_SCREEN_SHOT_FORMATS.includes(fileType)) {
      toast.error("Invalid File format");
      setSelectedFile(null);
    } else {
      setSelectedFile({ file });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="w-full">
          <div className="mb-4">
            <div>Bank: {transaction?.acc_payment_method?.name}</div>
            <div>
              Account Title: {transaction?.acc_payment_method?.account_title}
            </div>
            <div>
              Account No: #{transaction?.acc_payment_method?.account_no}
            </div>
            <div>Sort Code: {transaction?.acc_payment_method?.swift_code}</div>
          </div>

          <div className="mb-2">
            <input
              className="border border-slate-300 py-[5px] h-[44px] px-[15px] text-[14px] rounded-[5px] w-full"
              type="text"
              placeholder="Reference No"
              {...register("ref_no", {
                required: "Reference No is required",
              })}
              aria-invalid={errors.refNo ? "true" : "false"}
            />
          </div>

          <div className="pt-4">
            <label>Receipt File</label>
            <input
              id="fileUpload"
              type="file"
              onChange={handleFileUpload}
              className="w-full px-2 py-2 border rounded focus:outline-none focus:border-green-500 leading-tight border-gray-300"
            />
            {/* <input type="file" onChange={handleFileUpload} /> */}
            {progress && <p>Upload Progress: {progress}%</p>}
          </div>
        </div>

        <button
          type="submit"
          className="bg-basic block w-full text-white px-5 py-2 rounded hover:cursor-pointer hover:bg-purple-700 transition-all mt-[20px]"
        >
          {isloading ? "Updating.." : "Update Payment"}
        </button>
      </form>
    </>
  );
}
