import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import {
  LocalizationProvider,
  DateRangePicker,
  DatePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import TravelersPopover from "~/components/FormInputs/TravelersForm";

type TravelerCategory = "children" | "youth" | "infantsOnLap" | "infantsInSeat";

// const today = dayjs();

const TrainComponent = ({ control, register, setValue, errors }: any) => {
  // const trainDetails = defaultValues
  //   ? defaultValues.find((item: any) => item.label === "Train")?.details
  //   : [];
  // const trainAdults = trainDetails?.train_inq_passengers
  //   ? trainDetails?.train_inq_passengers?.find(
  //       (item: any) => item.type === "adult"
  //     )
  //   : [];
  // const trainChildren = trainDetails?.train_inq_passengers
  //   ? trainDetails?.train_inq_passengers?.find(
  //       (item: any) => item.type === "child"
  //     )
  //   : [];
  // const trainInfants = trainDetails?.train_inq_passengers
  //   ? trainDetails?.train_inq_passengers?.find(
  //       (item: any) => item.type === "infant"
  //     )
  //   : [];
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infantsOnLap, setInfantsOnLap] = useState(0);
  const [youth, setYouth] = useState(0)
  const [infantsInSeat, setInfantsInSeat] = useState(0);

  // Initialize travelers state
  const [travelers, setTravelers] = useState<{
    [key in TravelerCategory]: string[];
  }>({
    youth: Array(youth).fill(""),
    children: Array(children).fill(""),
    infantsOnLap: Array(infantsOnLap).fill(""),
    infantsInSeat: Array(infantsInSeat).fill(""),
  });

  const handleTravelersClick = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleTravelersClose = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    setTravelers((prevState) => ({
      ...prevState,
      infantsOnLap: Array(infantsOnLap).fill(""),
      infantsInSeat: Array(infantsInSeat).fill(""),
    }));
  }, [infantsOnLap, infantsInSeat]);

  const handleAgeChange = (
    category: TravelerCategory,
    index: number,
    value: string
  ) => {
    console.log(
      `Changing age for category: ${category}, index: ${index}, value: ${value}`
    );

    setTravelers((prevState) => {
      const updatedArray = [...prevState[category]];
      updatedArray[index] = value; // Update only the specific index
      return { ...prevState, [category]: updatedArray };
    });

    setValue(`train.Travelers.${category}.${index}`, value);
  };

  const updateTravelerCount = (
    type: "adults" | "youth" | "children" | "infantsOnLap",
    action: "increment" | "decrement"
  ) => {
    let newCount;
    if (type === "adults") {
      newCount = action === "increment" ? adults + 1 : Math.max(adults - 1, 0);
      setAdults(newCount);
      setValue(`train.Travelers.adults`, newCount);
    } else if (type === "youth") {
      newCount = action === "increment" ? youth + 1 : Math.max(youth - 1, 0);
      setYouth(newCount);
      setValue(`train.Travelers.youth`, newCount);
    }
    else if (type === "children") {
      newCount =
        action === "increment" ? children + 1 : Math.max(children - 1, 0);
      setChildren(newCount);
      setValue(`train.Travelers.children`, newCount);
    } else if (type === "infantsOnLap") {
      newCount =
        action === "increment"
          ? infantsOnLap + 1
          : Math.max(infantsOnLap - 1, 0);
      setInfantsOnLap(newCount);
      setValue(`train.Travelers.infantsOnLap`, newCount);
    }

    // Update the travelers state
    setTravelers({
      youth: Array(youth).fill(""),
      children: Array(children).fill(""),
      infantsOnLap: Array(infantsOnLap).fill(""),
      infantsInSeat: Array(infantsInSeat).fill(""),
    });
  };

  return (
    <>
      {/* Origin */}
      <Controller
        control={control}
        name={`train.Origin`} // This stores the value as train.Origin or train.Destination
        rules={{ required: true }} // Add validation rules
        // defaultValue={trainDetails?.[0]?.origin}
        render={({ field, fieldState: { error } }) => {
          return (
            <TextField
              id={`outlined-basic-Origin`}
              label="Origin" // Use the original field name for the label
              variant="outlined"
              {...field} // Spread the controlled field props
              error={!!error} // Show error state if validation fails
              helperText={error ? "This field is required" : ""} // Display error message
            />
          );
        }}
      />
      {/* Destination  */}
      <Controller
        control={control}
        name={`train.Destination`} // This stores the value as train.Origin or train.Destination
        rules={{ required: true }} // Add validation rules
        // defaultValue={trainDetails?.[0]?.destination}
        render={({ field, fieldState: { error } }) => {
          return (
            <TextField
              id={`outlined-basic-Destination}`}
              label="Destination" // Use the original field name for the label
              variant="outlined"
              {...field} // Spread the controlled field props
              error={!!error} // Show error state if validation fails
              helperText={error ? "This field is required" : ""} // Display error message
            />
          );
        }}
      />
      {/* Date Range Picker */}
      <Controller
        control={control}
        name={`train.Date`}
        rules={{
          validate: (value) => (value ? true : "This field is required"), // Only check if a single date is selected
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          // const date = dayjs(trainDetails?.[0]?.date);
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select Date"
                value={value} // Ensure value is not undefined
                onChange={(newValue) => {
                  onChange(newValue); // Update the value in react-hook-form
                }}
                // minDate={today} // Ensure minDate is defined
              />
              {error && (
                <span className="text-red-500 text-xs">{error.message}</span> // Render error if exists
              )}
            </LocalizationProvider>
          );
        }}
      />

      {/* Travelers Selection */}
      <Controller
        control={control}
        name={"train.Travelers"}
        defaultValue={{ adults: 1, youth: 0, children: 0, infantsOnLap: 0 }}
        render={({ field }) => (
          <TravelersPopover
            {...field}
            open={Boolean(anchorEl2)}
            anchorEl={anchorEl2}
            handleClose={handleTravelersClose}
            handleClick={handleTravelersClick}
            adults={adults}
            setAdults={setAdults}
            youth={youth}
            setYouth={setYouth}
            setChildren={setChildren}
            setInfantsOnLap={setInfantsOnLap}
            children={children}
            infantsOnLap={infantsOnLap}
            travelers={travelers}
            handleAgeChange={handleAgeChange}
            ageOptions={Array.from({ length: 18 }, (_, i) => i.toString())}
            infantOptions={["0", "1"]}
            popoverWidth="400px"
            label="Travelers"
            updateTravelerCount={updateTravelerCount}
          />
        )}
      />
    </>
  );
};

export default TrainComponent;
