import { GET_FL_COUNTRIES } from "~/graphql/flGetCountries/Query";
import { useQuery } from "@apollo/client";

export default function useFlCountries() {
  const { data } = useQuery(GET_FL_COUNTRIES);

  return {
    flCountries: data?.flGetCountries?.countries,
  };
}
