import React, { useState } from "react";
import { Box, Button, Divider, TextField } from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { INSERT_FLIGHT_SEAT_SELECTION } from "~/graphql/flightInq_seatSelection/Mutation";
import { toast } from "react-toastify";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_VENDORS } from "../../TicketRequestForm/graphql/Query";
import { ADD_TRANSACTION } from "../../CompleteInquiry/graphql/Mutation";

const SeatSelectionForm = ({ setSidePopup, inquiry }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      vendor_id: "",
      seats: [{ cost_price: "", sale_price: "", seat_no: "" }],
    },
  });

  const [addTransaction] = useMutation(ADD_TRANSACTION);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "seats",
  });

  const { travelHouse }: any = travelHouseStore();

  const { loading, data, error } = useQuery(GET_VENDORS, {
    variables: { th_id: travelHouse.id },
  });

  const [addSeatsSelection] = useMutation(INSERT_FLIGHT_SEAT_SELECTION);

  const onSubmit = async (data: any) => {
    try {
      const payload = data?.seats.map((item: any) => ({
        inq_id: inquiry?.id,
        cost_price: parseFloat(item.cost_price),
        sale_price: parseFloat(item.sale_price),
        seat_no: item?.seat_no,
      }));

      const res = await addSeatsSelection({
        variables: {
          data: payload,
        },
      });

      if (res?.data?.insert_flightInq_seatSelection?.affected_rows) {
        toast.success("Seats Added Successfully");
        setSidePopup(false);
      }

      const rawTransactions: any = [
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 21,
          inq_id: inquiry.id,
          transactions_no: "randomNumber",
          date: new Date(),
          created_at: new Date(),
          type: "debit",
          amount: parseFloat(
            payload.reduce((sum: any, item: any) => sum + item.cost_price, 0)
          ),
        },
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 2,
          user_id: data?.vendor_id,
          inq_id: inquiry.id,
          transactions_no: "randomNumber",
          date: new Date(),
          created_at: new Date(),
          type: "credit",
          amount: -parseFloat(
            payload.reduce((sum: any, item: any) => sum + item.cost_price, 0)
          ),
        },
      ];

      const transactions = rawTransactions.filter(
        (obj: any) => obj.amount !== 0
      );

      try {
        const res = await addTransaction({
          variables: {
            transactions: transactions,
          },
        });
        toast.success("Transaction added", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch (e) {}
    } catch (err: any) {
      toast.error(err);
      setSidePopup(false);
    }
  };

  return (
    <div className="min-w-[500px] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic dark:text-dark-primary">
        Seat Selection
      </h1>
      <Divider />
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-7">
          <div className="grid grid-cols-1 gap-4 mb-5">
            <div className="">
              <label htmlFor="" className="text-[14px] mb-2 block">
                Select Vendor {"*"}
              </label>
              <select
                {...register("vendor_id", {
                  required: "Vendor is required",
                })}
                aria-invalid={errors.vendor_id ? "true" : "false"}
                className="w-full px-3 py-3 border rounded focus:outline-none focus:border-basic leading-tight border-gray-200 dark:bg-dark-primary"
              >
                <option value="">--Select Vendor--</option>
                {data?.users?.map((vendor: any) => {
                  return (
                    <option key={`${vendor?.id}`} value={`${vendor?.id}`}>
                      {vendor.displayName}
                    </option>
                  );
                })}
              </select>
              {errors.vendor_id && (
                <p role="alert" className="text-red-500 text-[12px] m-1">
                  {errors.vendor_id.message}
                </p>
              )}
            </div>
          </div>
          {fields.map((field, index) => (
            <div key={field.id} className="mb-5 border p-3 rounded">
              <div className="grid grid-cols-3 gap-5">
                <TextField
                  error={!!errors.seats?.[index]?.seat_no}
                  {...register(`seats.${index}.seat_no`, {
                    required: "Seat Number is required",
                  })}
                  label="Seat Number"
                  fullWidth
                  helperText={errors.seats?.[index]?.seat_no?.message}
                />
                <TextField
                  error={!!errors.seats?.[index]?.cost_price}
                  {...register(`seats.${index}.cost_price`, {
                    required: "Cost price is required",
                  })}
                  label="Cost Price"
                  fullWidth
                  helperText={errors.seats?.[index]?.cost_price?.message}
                />
                <TextField
                  error={!!errors.seats?.[index]?.sale_price}
                  {...register(`seats.${index}.sale_price`, {
                    required: "Sale price is required",
                  })}
                  label="Sale Price"
                  fullWidth
                  helperText={errors.seats?.[index]?.sale_price?.message}
                />
              </div>
              {fields.length > 1 && (
                <div className="flex justify-end mt-2">
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => remove(index)}
                  >
                    Remove
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-end w-full mt-3">
          <Button
            variant="outlined"
            onClick={() =>
              append({ cost_price: "", sale_price: "", seat_no: "" })
            }
          >
            Add Seat
          </Button>
        </div>
        <div className="w-full mt-5 flex justify-center">
          <Button type="submit" variant="contained" sx={{ minWidth: "100px" }}>
            Submit
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default SeatSelectionForm;
