import { Autocomplete, Box, TextField } from "@mui/material";
import _airportsData from "../../../../components/FormInputs/LocationBox/data/airports.json";
import { Controller } from "react-hook-form";
import { customSearch } from "~/components/FormInputs/LocationBox/utils";
import { useState } from "react";

const LocationBox = ({
  register,
  control,
  errors,
  fieldName,
  label,
  defaultValue,
}: any) => {
  const dataFunction = () => {
    const data = _airportsData?.find(
      (option: any) => option?.iata_code === defaultValue
    );
    return data;
  };

  const data = dataFunction();

  const index: any = data ? _airportsData?.indexOf(data) : null;

  const [value, setValue] = useState(_airportsData?.[index]);

  return (
    <>
      <Controller
        control={control}
        rules={{
          required: "This is required",
        }}
        {...register(fieldName)}
        render={({ field }: any) => (
          <Autocomplete
            {...field}
            disablePortal
            ref={field.ref}
            value={value}
            onChange={(_, data: any) => {
              field.onChange(data?.iata_code);
              setValue(data);
            }}
            filterOptions={(options, { inputValue }: any) => {
              // If input is empty, don't show any suggestions
              if (!inputValue.trim()) {
                return [];
              }

              const matchedAirports = customSearch(inputValue);

              return matchedAirports;
            }}
            options={_airportsData ? _airportsData : []}
            getOptionLabel={(option: any) =>
              `${option.city_name || ""}-${option.iata_code}`
            }
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option.city_name}-{option.iata_code}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                label={label}
                ref={field.ref}
                inputRef={(el) => {
                  field.ref(el);
                }}
                error={errors[fieldName] ? true : false}
                {...params}
              />
            )}
            noOptionsText=""
          />
        )}
      />
    </>
  );
};

export default LocationBox;
