import { gql } from "@apollo/client";

export const INSERT_CAR_INQUIRY_DETAIL = gql`
    mutation InsertCarInquiryDetail($data: [car_inq_detail_insert_input!]!) {
        insert_car_inq_detail(objects: $data) {
            affected_rows
        }
    }
`;

export const UPDATE_CAR_INQUIRY_DETAIL = gql`
  mutation updateCarInquiryDetail(
    $id: uuid!
    $origin: String
    $destination: String
    $pickDateTime: timestamptz
    $dropDateTime: timestamptz
    $withDriver: Boolean!
  ) {
    update_car_inq_detail(
      where: { id: { _eq: $id } }
      _set: {
        origin: $origin
        destination: $destination
        pick_date_time: $pickDateTime
        drop_date_time: $dropDateTime
        with_driver: $withDriver
      }
    ) {
      affected_rows
    }
  }
`;
