import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { useUserDefaultRole, useUserDisplayName } from "@nhost/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useEmail from "~/hooks/emails/useEmail";
import useNotifications from "~/hooks/useNotifications";
import { ADD_TRANSACTIONS } from "~/modules/InquiriesModule/segments/InquiryDetail/components/PaymentsCard/components/PaymentAdd/graphql/Mutations";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";

const BankPayment = ({
  inquiry,
  setSidePopup,
  mainHeading,
  paymentMethod,
}: any) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isloading, setIsloading] = useState(false);

  const paymentMethods = paymentMethod?.filter(
    (pay: any) => pay.type === "bank"
  );

  const [addTransactions] = useMutation(ADD_TRANSACTIONS);
  const userUserName = useUserDisplayName();
  const emailSend = useEmail();
  const alert = useNotifications();
  const userRole = useUserDefaultRole();
  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    setIsloading(true);
    formData["inq_id"] = inquiry.id; // add inquiry id to formdata
    formData["status"] = "request"; // add status  to formdata

    formData["ref_no"] = ""; // add receipt to formdata
    formData["receipt"] = null; // add receipt to formdata

    const tenDigitCode: any = generateRandom10DigitCode();
    formData["transactions_no"] = tenDigitCode; // add status  to formdata
    try {
      // Create Suggestion function call
      const res = await addTransactions({
        variables: { transactions: [formData] },
      });
      if (res.data?.insert_inq_transection?.returning?.length > 0) {
        const variables: any = {
          inquiryNo: inquiry?.inquiry_no,
          userFirstName: inquiry?.users?.displayName,
          userLastName: "",
          consultantName: userUserName,
          brandName: inquiry?.brd_list?.name,
          amount: formData.amount,
          bankName: paymentMethods.filter(
            (pay: any) => pay.id === formData.payment_method_id
          )[0].name,
          accountTitle: paymentMethods.filter(
            (pay: any) => pay.id === formData.payment_method_id
          )[0].account_title,
          accountNumber: paymentMethods.filter(
            (pay: any) => pay.id === formData.payment_method_id
          )[0].account_no,
          sortCode: paymentMethods.filter(
            (pay: any) => pay.id === formData.payment_method_id
          )[0].swift_code,
        };

        // ;
        await emailSend(
          8,
          inquiry.brd_id,
          inquiry.users?.id,
          inquiry.users?.email,
          inquiry.users?.phoneNumber,
          variables
        );

        toast.success("Transaction added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        await alert.newNotification(
          "accountant",
          `Pending payment approval. Please check your portal`,
          "",
          ``
        );

        if (userRole === "user") {
          await alert.newNotification(
            inquiry.picked_by,
            `Customer created the bank payment, please check your portal`,
            "",
            ``
          );
        } else {
          await alert.newNotification(
            inquiry?.users?.id,
            `Your payment is created by consultant, please check your portal`,
            "",
            ``
          );
        }

        await client.resetStore();
      }
      setIsloading(false);
      setSidePopup(false); // hide form modal
    } catch (e) {
      setIsloading(false);
      setSidePopup(false);
    }
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="grid grid-cols-1 gap-5 mt-5">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("payment_method_id", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.id);
                }}
                options={paymentMethods ? paymentMethods : []}
                getOptionLabel={(option: any) => option?.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Select Bank"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["payment_method_id"] ? true : false}
                    {...params}
                  />
                )}
              />
            )}
          />
          <TextField
            error={errors["amount"] ? true : false}
            {...register("amount", {
              required: true,
            })}
            label="Amount"
            type="number"
          />
        </div>
        <Button
          type="submit"
          sx={{ marginTop: "25px" }}
          variant="contained"
          fullWidth
        >
          {isloading ? "Adding..." : mainHeading}
        </Button>
      </Box>
    </>
  );
};

export default BankPayment;
