import { gql } from "@apollo/client";

export const INSERT_FLIGHT_SEAT_SELECTION = gql`
  mutation flightInqSeatSelection(
    $data: [flightInq_seatSelection_insert_input!]!
  ) {
    insert_flightInq_seatSelection(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_FLIGHT_INQ_SEAT_SELECTION = gql`
  mutation MyMutation($data: uuid!) {
    delete_flightInq_seatSelection(where: { inq_id: { _eq: $data } }) {
      affected_rows
    }
  }
`;
