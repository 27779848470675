import { Controller, useForm } from "react-hook-form";
import { TextField, FormControl } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Persons from "../../../../AddSuggestion/components/SuggestionForm/Visa/components/Persons";
import { useRef } from "react";
import { UPDATE_VISA_SUGGESTION, UPDATE_VISA_SUGGESTION_COST, INSERT_VISA_SUGGESTION_COST } from "../components/EditSuggestionForm/graphql/Mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const VisaEditSuggestionForm = ({
    key,
    suggestion,
    suggestion_cost,
    defaultValue,
    inquiry,
    inquiry_id,
    passengers,
    user_id,
    tab,
    setShowAddModal
}: any) => {

    const [isLoading, setIsLoading] = useState(false)
    const isInitialized = useRef(false);
    const [updateVisaSuggestion] = useMutation(UPDATE_VISA_SUGGESTION)
    const [updateVisaSuggestionCost] = useMutation(UPDATE_VISA_SUGGESTION_COST)
    const [insertVisaSuggestionCost] = useMutation(INSERT_VISA_SUGGESTION_COST)

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        watch,
        setValue,
        getValues,
        unregister,
    } = useForm({
        defaultValues: defaultValue,
    });

    useEffect(() => {
        if (defaultValue?.name) {
            setValue("visaSuggestion.name", defaultValue?.name);
        }

        if (defaultValue?.country) {
            setValue("visaSuggestion.country", defaultValue?.country);
        }

        if (defaultValue?.visa_expiry_date) {
            setValue("visaSuggestion.expiryDate", defaultValue?.visa_expiry_date);
        }

        isInitialized.current = true;

    }, [defaultValue, setValue])


    const onSubmit = async (data: any) => {

        console.log("jsdcns", data)
        let isSuccess = false
        setIsLoading(true)

        try {
            const res = await updateVisaSuggestion({
                variables: {
                    id: data?.id,
                    visaName: data?.visaSuggestion?.name,
                    country: data?.visaSuggestion?.country,
                    visaExpiryDate: data?.visaSuggestion?.expiryDate
                }
            })

            if (res?.data?.update_visa_inq_suggestion?.affected_rows > 0) {
                isSuccess = true
            }

            const suggestionCosts = data?.visa_inq_suggestion_cost;
            const persons: any = data?.visaSuggestion?.persons;

            for (const cost of suggestionCosts) {
                const { id, cost_price, sale_price, inq_passenger_id } = cost;
                const matchedPerson = persons?.find((person: any) => person.id === inq_passenger_id);

                if (matchedPerson) {
                    const { costPrice, offerPrice } = matchedPerson;

                    const res = await updateVisaSuggestionCost({
                        variables: {
                            id,
                            costPrice,
                            salePrice: offerPrice,
                            inq_passenger_id
                        }
                    });

                    if (res?.data?.update_visa_inq_suggestion_cost?.affected_rows > 0) {
                        isSuccess = true
                    }
                }
            }

            const newPersons = persons?.filter((person: any) => {
                return !suggestionCosts?.some((cost: any) => cost.inq_passenger_id === person.id);
            });

            if (newPersons.length > 0) {
                const newRows = newPersons.map((person: any) => ({
                    visa_suggestion_id: data?.id,
                    cost_price: person.costPrice,
                    sale_price: person.offerPrice,
                    inq_passenger_id: person.id
                }));

                const resp = await insertVisaSuggestionCost({
                    variables: {
                        data: newRows
                    }
                });

                if (res?.data?.insert_visa_inq_suggestion_cost?.affected_rows > 0) {
                    isSuccess = true
                }
            }

            if (isSuccess) {
                toast.success(`Suggestions Updated Successfully!`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                setShowAddModal(false);
            }

        } catch (err) {
            toast.error(`Failed to edit suggestions ${err}.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } finally {
            setIsLoading(false)
        }
    }



    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {!inquiry?.invoice_id && (
                <div className="bg-gray-100 rounded-lg p-2 dark:bg-[#28243D]">
                    <div className="grid grid-cols-2 gap-4 mx-3">
                        <Controller
                            control={control}
                            name={`visaSuggestion.name`}
                            rules={{ required: true }}
                            render={({ field, fieldState: { error } }) => {
                                return (
                                    <>
                                        <TextField
                                            id={`outlined-basic-name`}
                                            label="Type"
                                            variant="outlined"
                                            {...field}
                                            error={!!error}
                                            helperText={error ? "This field is required" : ""}
                                        />
                                    </>
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name={`visaSuggestion.country`}
                            rules={{ required: true }}
                            render={({ field, fieldState: { error } }) => {
                                return (
                                    <>
                                        <TextField
                                            id={`outlined-basic-country`}
                                            label="Country"
                                            variant="outlined"
                                            {...field}
                                            error={!!error}
                                            helperText={error ? "This field is required" : ""}
                                        />
                                    </>
                                );
                            }}
                        />
                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name="visaSuggestion.expiryDate"
                                rules={{
                                    required: "This field is required", // Validation to ensure the field is not empty
                                }}
                                render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={["SingleInputDateField"]}>
                                                    <DatePicker
                                                        value={value ? dayjs(value) : null} // Convert value to dayjs object
                                                        onChange={(newValue) => {
                                                            onChange(newValue ? dayjs(newValue).toISOString() : null); // Store as ISO string
                                                        }}
                                                        label="Visa Expiry Date"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            {error && (
                                                <span className="text-red-500 text-xs">
                                                    {error.message}
                                                </span> // Display validation error
                                            )}
                                        </>
                                    );
                                }}
                            />
                        </FormControl>
                    </div>
                </div>
            )}
            <hr className="border-gray-300 border-dashed my-4" />
            <Persons register={register} errors={errors} control={control} passengers={passengers} existingPersons={defaultValue?.visa_inq_suggestion_cost} />
            <button
                className={` ${isLoading ? "bg-basic cursor-wait" : "bg-basic"
                    } h-[50px] p-2 w-half px-4 rounded-lg text-white mt-2`}
                type="submit"
            >
                {isLoading ? "Updating.." : "Update Suggestion"}
            </button>
        </form>
    )
}

export default VisaEditSuggestionForm