import { useUserDefaultRole } from "@nhost/react";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Home from "./assets/Home";
import BreadArrow from "./assets/BreadArrow";
import { GET_BRAND_NAME } from "./graphql/Query";
import { useApolloClient } from "@apollo/client";

const BreadCrumbs = () => {
  const location = useLocation();
  const role = useUserDefaultRole();
  const client = useApolloClient();
  const [breadcrumbs, setBreadcrumbs] = useState<any>([
    {
      name: "Brands",
      link: `/${role}/brands`,
    },
  ]);

  function isUUID(str: any) {
    // Regular expression pattern for UUID (version 1 to 5)
    const uuidPattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    // Test the string against the pattern
    return uuidPattern.test(str);
  }
  // get all users id
  const getName = async (slug: any) => {
    try {
      const response = await client.query({
        query: GET_BRAND_NAME,
        variables: { brd_id: slug },
      });
      // return "test";
      return response.data.brd_list[0].name;
    } catch (error) {
      return "ERROR";
    }
  };

  let paths = location.pathname.split("/");
  paths.splice(0, 3);
  if (paths.length >= 4) {
    paths.pop();
  }

  let destinationPath = location.pathname.split("destinations/");
  let newArr: any = [...breadcrumbs];
  useEffect(() => {
    const fetch = async () => {
      if (isUUID(paths[0])) {
        paths[0] = await getName(paths[0]);
      }
      paths.forEach((path: any, index: any) => {
        newArr.push({
          name: path,
          link:
            path === "destinations" && destinationPath.length >= 2
              ? `${destinationPath[0]}destinations`
              : null,
        });
      });
      setBreadcrumbs([...newArr]);
    };
    fetch();
  }, [location]);
  //   ;

  return (
    <div>
      <ul></ul>
      <nav aria-label="Breadcrumb" className="mb-4">
        <ol className="flex items-center">
          <li className="group flex items-center">
            <Link
              to={`/${role}`}
              className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
            >
              <Home />
            </Link>
          </li>
          {breadcrumbs.map((item: any) => {
            return (
              <li className="group flex items-center">
                <BreadArrow />
                {item.link ? (
                  <Link
                    className="flex capitalize items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                    to={item.link}
                  >
                    {item.name}
                  </Link>
                ) : (
                  <span className="flex capitalize items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    {item.name}
                  </span>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

export default BreadCrumbs;
