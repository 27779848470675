import { gql } from "@apollo/client";

export const ADD_INQ_SUGGESTION = gql`
  mutation addInqSuggestion($data: [brd_seasonal_fares_insert_input!]!) {
    insert_inq_suggestion(
      objects: { brd_season: { data: { brd_seasonal_fares: { data: $data } } } }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_SEASONAL_FARES = gql`
  mutation MyMutation2($id: uuid!) {
    delete_inq_suggestion(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_INQ_SUGGESTION = gql`
  mutation MyMutation($id: uuid!, $season_id: uuid!) {
    update_inq_suggestion(
      where: { id: { _eq: $id } }
      _set: { season_id: $season_id }
    ) {
      affected_rows
    }
  }
`;
