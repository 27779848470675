import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Divider,
  TextField,
  Checkbox,
  Chip,
} from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { useEffect, useState } from "react";
import useBrdHotelsByBrdId from "~/hooks/useBrdHotelsByBrdId";
import useUmrahVisasById from "~/hooks/useUmrahVisasById";
import SideDrawer from "~/components/common/sideDrawer";
import AddUmrahFares from "../../../AddUmrahFares";
import FareCard from "~/modules/BrandModule/segments/AirlinesUpdate/components/AirlineFares/components/FareCard";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_UMRAH_PACKAGES } from "~/graphql/brd_umrah_packages/Mutation";
import moment from "moment";
import { toast } from "react-toastify";
import {
  DELETE_UMRAH_FARES,
  INSERT_UMRAH_FARES,
} from "~/graphql/umrah_fares/Mutation";
import {
  DELETE_UMRAH_HOTELS,
  INSERT_UMRAH_HOTELS,
} from "~/graphql/umrah_hotels/Mutation";
import {
  DELETE_UMRAH_VISAS,
  INSERT_UMRAH_VISAS,
} from "~/graphql/umrah_visas/Mutation";
import { useForm } from "react-hook-form";

const PackageDetail = ({ pkgData }: any) => {
  const { brd_id } = useParams();

  console.log("hfgsjfsdf", pkgData);
  const [date, setDate] = useState<any>([
    dayjs(pkgData?.start_date),
    dayjs(pkgData?.end_date),
  ]);
  const [madinahHotel, setMadinahHotel] = useState<any>(
    pkgData?.umrah_hotels
      ?.filter((item: any) => item?.brd_hotel?.city === "madinah")
      ?.map((hotel: any) => ({ ...hotel?.brd_hotel })) || []
  );
  const [makkahHotel, setMakkahHotel] = useState<any>(
    pkgData?.umrah_hotels
      ?.filter((item: any) => item?.brd_hotel?.city === "makkah")
      ?.map((hotel: any) => ({ ...hotel?.brd_hotel })) || []
  );

  const [visas, setVisas] = useState<any>(
    pkgData?.umrah_visas?.map((visa: any) => ({ ...visa?.brd_visa })) || []
  );
  const [farePopup, setFarePopup] = useState<any>(false);
  const [fares, setFares] = useState<any>(
    pkgData?.umrah_fares?.map((fare: any) => ({ ...fare?.inq_suggestion })) ||
      []
  );

  const [departure, setDeparture] = useState<any>(null);
  const [destination, setDestination] = useState<any>(null);
  const [airline, setAirline] = useState<any>(null);

  const [insertUmrahFares] = useMutation(INSERT_UMRAH_FARES);
  const [deleteUmrahFares] = useMutation(DELETE_UMRAH_FARES);
  const [updateUmrahPkgs] = useMutation(UPDATE_UMRAH_PACKAGES);
  const [deleteUmrahHotels] = useMutation(DELETE_UMRAH_HOTELS);
  const [insertUmrahHotels] = useMutation(INSERT_UMRAH_HOTELS);
  const [insertUmrahVisas] = useMutation(INSERT_UMRAH_VISAS);
  const [deleteUmrahVisas] = useMutation(DELETE_UMRAH_VISAS);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { brdHotels }: any = useBrdHotelsByBrdId(brd_id);
  const { brdVisas }: any = useUmrahVisasById(brd_id);

  const onSubmit = async (formData: any) => {
    try {
      const hotels = [
        ...madinahHotel?.map((hotel: any) => ({
          ...hotel,
          nights: formData?.madinahHotelNights,
        })),
        ...makkahHotel?.map((hotel: any) => ({
          ...hotel,
          nights: formData?.makkahHotelNights,
        })),
      ];

      let status = true;

      const packagePayload = {
        start_date: moment(date?.[0]?.$d).format("YYYY-MM-DD"),
        end_date: moment(date?.[1]?.$d).format("YYYY-MM-DD"),
        name: formData.name,
      };

      const res = await updateUmrahPkgs({
        variables: {
          id: pkgData?.id,
          data: packagePayload,
        },
      });

      if (!res?.data?.update_brd_umrah_packages?.affected_rows) {
        status = false;
      }

      const defFaresIds = pkgData?.umrah_fares?.map(
        (item: any) => item.fare_id
      );
      const selectedFaresIds = fares?.map((item: any) => item.id);

      const insertPayload = fares
        ?.filter((item: any) => !defFaresIds.includes(item.id))
        ?.map((fare: any) => ({
          fare_id: fare?.id,
          pkg_id: pkgData?.id,
        }));

      if (insertPayload?.length > 0) {
        const res = await insertUmrahFares({
          variables: {
            data: insertPayload,
          },
        });
        if (!res?.data?.insert_umrah_fares?.affected_rows) {
          status = false;
        }
      }

      const deletePayload = pkgData?.umrah_fares
        ?.filter((item: any) => !selectedFaresIds.includes(item.fare_id))
        ?.map((fare: any) => fare.id);

      if (deletePayload?.length > 0) {
        const res = await deleteUmrahFares({
          variables: {
            ids: deletePayload,
          },
        });
        if (!res?.data?.delete_umrah_fares?.affected_rows) {
          status = false;
        }
      }

      const defHotelIds = pkgData?.umrah_hotels?.map(
        (item: any) => item.hotel_id
      );
      const selectedHotelIds = hotels?.map((item: any) => item.id);

      const insertHotelPayload = hotels
        ?.filter((item: any) => !defHotelIds.includes(item.id))
        ?.map((hotel: any) => ({
          hotel_id: hotel?.id,
          nights: hotels?.filter((hotel: any) => hotel?.id === hotel?.id)?.[0]
            ?.nights,
          pkg_id: pkgData?.id,
        }));

      if (insertHotelPayload?.length > 0) {
        const res = await insertUmrahHotels({
          variables: {
            data: insertHotelPayload,
          },
        });

        if (!res?.data?.insert_umrah_hotels?.affected_rows) {
          status = false;
        }
      }

      const deleteHotelPayload = pkgData?.umrah_hotels
        ?.filter((item: any) => !selectedHotelIds.includes(item.hotel_id))
        ?.map((hotel: any) => hotel.id);

      if (deleteHotelPayload?.length > 0) {
        const res = await deleteUmrahHotels({
          variables: {
            ids: deleteHotelPayload,
          },
        });

        if (!res?.data?.delete_umrah_hotels?.affected_rows) {
          status = false;
        }
      }

      const defVisaIds = pkgData?.umrah_visas?.map((item: any) => item.visa_id);
      const selectedVisaIds = visas?.map((item: any) => item.id);

      const insertVisaPayload = visas
        ?.filter((item: any) => !defVisaIds.includes(item.id))
        ?.map((visa: any) => ({
          visa_id: visa?.id,
          pkg_id: pkgData?.id,
        }));

      if (insertVisaPayload?.length > 0) {
        const res = await insertUmrahVisas({
          variables: {
            data: insertVisaPayload,
          },
        });

        if (!res?.data?.insert_umrah_visas?.affected_rows) {
          status = false;
        }
      }

      const deleteVisaPayload = pkgData?.umrah_visas
        ?.filter((item: any) => !selectedVisaIds.includes(item.visa_id))
        ?.map((visa: any) => visa.id);

      if (deleteVisaPayload?.length > 0) {
        const res = await deleteUmrahVisas({
          variables: {
            ids: deleteVisaPayload,
          },
        });
        if (!res?.data?.delete_umrah_visas?.affected_rows) {
          status = false;
        }
      }

      if (status === true) {
        toast.success("Package Added SuccessFully");
      } else {
        toast.error("Getting Some Error");
      }
    } catch (err: any) {
      toast.error(err?.message);
    }
  };

  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
      <h1 className="text-center text-3xl text-basic">Package Detail</h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 gap-5 mt-10"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateRangePicker", "DateRangePicker"]}>
            <DemoItem component="DateRangePicker">
              <DateRangePicker
                value={date}
                onChange={(newValue: any) => setDate(newValue)}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
        <div className="grid grid-cols-2 gap-5">
          <TextField
            error={errors["name"] ? true : false}
            {...register("name", { required: true })}
            label="Name"
            defaultValue={pkgData?.name}
          />
          <TextField
            error={errors["slug"] ? true : false}
            {...register("slug", { required: true })}
            onChange={(e) => {
              const formattedValue = e.target.value
                .replace(/\s+/g, "-")
                .toLowerCase();
              e.target.value = formattedValue;
              // Call the registered onChange to update the form value
              register("slug").onChange(e);
            }}
            defaultValue={pkgData?.slug}
            label="Slug"
          />
        </div>
        <div className="grid grid-cols-2 gap-5">
          <Autocomplete
            value={madinahHotel}
            onChange={(event: any, newValue: any) => {
              setMadinahHotel(newValue);
            }}
            multiple
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option: any, value: any) =>
              option?.id === value?.id
            }
            renderOption={(props, option: any) => {
              const isSelected = madinahHotel?.some(
                (hotel: any) => hotel?.id === option?.id
              );
              return (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={isSelected}
                  />
                  {option.name}
                </li>
              );
            }}
            renderTags={(selected, getTagProps) => {
              return (
                <Chip
                  key="selected"
                  label={`${selected.length} Hotels Selected`}
                />
              );
            }}
            options={
              brdHotels?.filter((item: any) => item.city === "madinah") || []
            }
            renderInput={(params) => (
              <TextField {...params} label="Madinah Hotels" />
            )}
          />
          <TextField
            error={errors["madinahHotelNights"] ? true : false}
            {...register("madinahHotelNights", { required: true })}
            label="Nights"
            defaultValue={
              pkgData?.umrah_hotels?.filter(
                (item: any) => item?.brd_hotel?.city === "madinah"
              )?.[0]?.nights
            }
          />
        </div>
        <div className="grid grid-cols-2 gap-5">
          <Autocomplete
            value={makkahHotel}
            onChange={(event: any, newValue: any) => {
              setMakkahHotel(newValue);
            }}
            multiple
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option: any, value: any) =>
              option?.id === value?.id
            }
            renderOption={(props, option: any) => {
              const isSelected = makkahHotel?.some(
                (hotel: any) => hotel?.id === option?.id
              );
              return (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={isSelected}
                  />
                  {option.name}
                </li>
              );
            }}
            renderTags={(selected, getTagProps) => {
              return (
                <Chip
                  key="selected"
                  label={`${selected.length} Hotels Selected`}
                />
              );
            }}
            options={
              brdHotels?.filter((item: any) => item.city === "makkah") || []
            }
            renderInput={(params) => (
              <TextField {...params} label="Makkah Hotels" />
            )}
          />
          <TextField
            error={errors["makkahHotelNights"] ? true : false}
            {...register("makkahHotelNights", { required: true })}
            label="Nights"
            defaultValue={
              pkgData?.umrah_hotels?.filter(
                (item: any) => item?.brd_hotel?.city === "makkah"
              )?.[0]?.nights
            }
          />
        </div>
        <Autocomplete
          value={visas}
          onChange={(event: any, newValue: any) => {
            setVisas(newValue);
          }}
          multiple
          getOptionLabel={(option) => option?.type}
          isOptionEqualToValue={(option: any, value: any) =>
            option?.id === value?.id
          }
          renderOption={(props, option: any) => {
            const isSelected = visas?.some(
              (visa: any) => visa?.id === option?.id
            );
            return (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={isSelected}
                />
                {option?.brd_visa_type?.name}
              </li>
            );
          }}
          renderTags={(selected, getTagProps) => {
            if (selected.length > 2) {
              return [
                <Chip
                  label={selected[0]?.brd_visa_type?.name}
                  {...getTagProps({ index: 0 })}
                />,
                <Chip
                  label={selected[1]?.brd_visa_type?.name}
                  {...getTagProps({ index: 1 })}
                />,
                <Chip key="more" label={`+${selected.length - 2} more`} />,
              ];
            }

            return selected?.map((option: any, index: number) => (
              <Chip
                label={option?.brd_visa_type?.name}
                {...getTagProps({ index })}
              />
            ));
          }}
          options={brdVisas || []}
          renderInput={(params) => <TextField {...params} label="Visa Types" />}
        />
        <div className="gird grid-cols-1 gap-10">
          {fares?.map((item: any, ind: any) => {
            return <FareCard key={ind} sg={item} hideAction={true} />;
          })}
        </div>
        <Button
          onClick={() => setFarePopup(true)}
          sx={{ width: "200px" }}
          variant="contained"
        >
          Add / Remove Fares
        </Button>
        <Button type="submit" variant="contained">
          Update Package Detail
        </Button>
      </form>
      <SideDrawer
        component={
          <AddUmrahFares
            setFares={setFares}
            fares={fares}
            setDeparture={setDeparture}
            departure={departure}
            destination={destination}
            setDestination={setDestination}
            airline={airline}
            setAirline={setAirline}
          />
        }
        sidePopup={farePopup}
        setSidePopup={setFarePopup}
      />
    </div>
  );
};

export default PackageDetail;
