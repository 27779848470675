import React from "react";
import Layout from "~/components/common/Layout";
import FlProjectModule from "~/modules/FlProjectModule";
import FlProjects from "~/modules/FlProjectModule/segments/FlProjects";

const FlProjectPage = () => {
  return (
    <Layout>
      <FlProjectModule>
        <FlProjects />
      </FlProjectModule>
    </Layout>
  );
};

export default FlProjectPage;
