import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import AddUmrahPackages from "~/modules/BrandModule/segments/UmrahPackages/components/AddUmrahPackages";

const UmrahPackageAddPg = () => {
  return (
    <Layout>
      <BrandModule>
        <AddUmrahPackages />
      </BrandModule>
    </Layout>
  );
};

export default UmrahPackageAddPg;
