import { Link, useParams } from "react-router-dom";
import ShowUmrahPackages from "./components/ShowUmrahPackages";
import { Button } from "@mui/material";

const UmrahPackages = () => {
  const { brd_id } = useParams();

  return (
    <div>
      <div className="flex items-center justify-end mb-5">
        <Link to="add">
          <Button variant="contained">Add Umrah Package</Button>
        </Link>
      </div>
      <ShowUmrahPackages brd_id={brd_id} />
    </div>
  );
};

export default UmrahPackages;
