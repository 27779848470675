import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { GET_UMRAH_PACKAGES_WITH_BRD_ID } from "~/graphql/brd_umrah_packages/Query";
import { DELETE_BRD_UMRAH_PACKAGE } from "~/graphql/brd_umrah_packages/Mutation";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ShowUmrahPackages = ({ brd_id }: any) => {
  const client = useApolloClient();
  const { loading, error, data, refetch } = useQuery(
    GET_UMRAH_PACKAGES_WITH_BRD_ID,
    {
      variables: { brdId: brd_id },
    }
  );

  // Mutation for deleting Umrah packages
  const [deletePackage] = useMutation(DELETE_BRD_UMRAH_PACKAGE, {
    onCompleted: () => {
      client.refetchQueries({ include: "all" });
      toast.success("Package deleted successfully!");
    },
    onError: () => {
      toast.error("Failed to delete package. Please try again.");
    },
  });

  // Handle delete action
  const handleDelete = async (packageId: any) => {
    try {
      await deletePackage({ variables: { id: packageId } });
    } catch (error) {
      console.error(error); // Retained for debugging purposes
    }
  };

  // Error state handling
  if (error) return <p>Error fetching Umrah packages: {error.message}</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Fares</TableCell>
              <TableCell>Hotels</TableCell>
              <TableCell>Visas</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} style={{ textAlign: "center" }}>
                  Loading...
                </TableCell>
              </TableRow>
            ) : (
              data?.brd_umrah_packages?.map((packageItem: any) => (
                <TableRow key={packageItem.id}>
                  <TableCell>{packageItem.name}</TableCell>
                  <TableCell>{packageItem.start_date}</TableCell>
                  <TableCell>{packageItem.end_date}</TableCell>

                  <TableCell>
                    {`${packageItem.umrah_fares.length} ${
                      packageItem.umrah_fares.length === 1 ? "Fare" : "Fares"
                    }`}
                  </TableCell>
                  <TableCell>
                    {`${packageItem.umrah_hotels.length} ${
                      packageItem.umrah_hotels.length === 1 ? "Hotel" : "Hotels"
                    }`}
                  </TableCell>
                  <TableCell>
                    {`${packageItem.umrah_visas.length} ${
                      packageItem.umrah_visas.length === 1 ? "Visa" : "Visas"
                    }`}
                  </TableCell>
                  <TableCell>
                    <Link to={`update/${packageItem?.id}`}>
                      <Button variant="outlined" color="primary">
                        UPdate
                      </Button>
                    </Link>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleDelete(packageItem.id)}
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ShowUmrahPackages;
