import { useState, useEffect } from "react";
import PersonCost from "./components/PersonCost";
import { Button } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { REMOVE_VISA_PERSON } from "../../../../../../SuggestionBox/components/EditSuggestion/components/EditSuggestionForm/graphql/Mutation";
import { useMutation } from "@apollo/client";

const Persons = ({ passengers, control, errors, existingPersons }: any) => {
    console.log("dsjcs", existingPersons)
    const [visiblePassengers, setVisiblePassengers] = useState<any[]>([]);
    const [removeVisaPerson] = useMutation(REMOVE_VISA_PERSON)



    // Add a new passenger row
    const handleAddPerson = () => {
        setVisiblePassengers((prev) => [...prev, { id: "", offerPrice: "", costPrice: "" }]);
    };

    // Remove a passenger row
    const handleRemovePerson = async (indexToRemove: number) => {
        const passengerToRemove = visiblePassengers[indexToRemove];

        if (passengerToRemove?.id) {
            // If passenger has an `id`, it means it's an existing person - perform mutation
            try {
                await removeVisaPerson({
                    variables: { id: passengerToRemove.id },
                });
                console.log(`Passenger with ID ${passengerToRemove.id} removed successfully.`);
            } catch (error) {
                console.error("Error removing passenger:", error);
                return; // Do not remove from the UI if mutation fails
            }
        }

        setVisiblePassengers((prev) =>
            prev.filter((_, index) => index !== indexToRemove)
        );
    };

    useEffect(() => {
        if (existingPersons?.length) {
            setVisiblePassengers(existingPersons);
        }
    }, [existingPersons]);

    return (
        <div className="bg-gray-100 dark:bg-[#28243D] rounded-lg p-2 mt-4">
            <div className="flex flex-col border-b border-gray-300 w-full">
                <h1 className="flex-1 w-full font-bold text-left text-primary dark:text-dark-primary">
                    Persons
                </h1>
            </div>
            <div className="mt-2">
                {visiblePassengers.map((passenger, index) => (
                    <PersonCost
                        key={index}
                        passenger={passenger}
                        passengers={passengers}
                        control={control}
                        errors={errors}
                        index={index}
                        onRemove={() => handleRemovePerson(index)}
                    />
                ))}
            </div>
            <div className="flex justify-end">
                <Button
                    onClick={handleAddPerson}
                    className="mt-4 bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark"
                >
                    Add Person
                </Button>
            </div>
        </div>
    );
};

export default Persons;
