export const getAlias = async () => {
  try {
    const response = await fetch(
      "https://local.flygency.com/api/gmail/get-aliases",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh_token:
            "1//03SC16IKI1d7gCgYIARAAGAMSNwF-L9IrGImWgzyAzQW2lN3-DSZEmS7ei4P1_ktcZhd5TXrFWf3qlvY0tRwsgBGxRLoiytOw-VU",
        }),
      }
    );

    if (response.status === 200) {
    } else {
    }
  } catch (error) {}
};
