import moment from "moment";
import React, { useState } from "react";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { useUserDefaultRole, useUserId } from "@nhost/react";
import { URLs } from "~/config/enums";
import { getPaidAmount } from "~/modules/InquiriesModule/utils/getInquiryStats";

import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { MdDelete } from "react-icons/md";
import { MdDownloadForOffline } from "react-icons/md";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import { IoMdCloseCircle } from "react-icons/io";
import SideDrawer from "~/components/common/sideDrawer";
import {
  DELETE_TICKET,
  EDIT_TICKET_REQUEST,
} from "../../../components/TicketRequestCard/graphql/Mutation";
import { GET_VENDORS } from "../../../components/TicketRequestForm/graphql/Query";
import EditTicket from "../../../components/TicketRequestCard/components/EditTicket";
import EditFlightTicket from "./editFlightRequestTicket";

export default function FlightTicketRequestCard({
  inquiry,
  hideActions,
  ticekts,
}: any) {
  const [status, setStatus] = useState(inquiry?.status);
  const [isLoading, setIsLoading] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const [editRequest] = useMutation(EDIT_TICKET_REQUEST);
  const client = useApolloClient();

  const [deleteTicket] = useMutation(DELETE_TICKET);

  const { travelHouse }: any = travelHouseStore();

  const { loading, data, error } = useQuery(GET_VENDORS, {
    variables: { th_id: travelHouse.id },
  });

  const updateIssuanceStatus = async () => {
    setIsLoading(true);
    const payload = {
      id: inquiry.id,
      status: status,
    };
    try {
      const res = await editRequest({ variables: payload });
      if (res.data?.update_ticket_issuance_request?.returning?.length > 0) {
        toast.success("Request Status Updated Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        await client.resetStore();
      }
    } catch (e) {
      setIsLoading(false);
      toast.error(`${e}`);
    }
  };

  const handleDownloadAll = () => {
    ticekts?.[0]?.inq_tickets?.[0]?.inq_ticket_files?.forEach(
      async (item: any) => {
        if (item?.file_url) {
          const fileUrl = `${URLs.FILE_URL}${item?.file_url}`;

          try {
            // Fetch the file from the URL
            const response = await fetch(fileUrl);
            if (!response.ok) throw new Error("File download failed");

            // Convert the response to a Blob
            const blob = await response.blob();

            // const fileName = `Ticket-${item?.inq_passengers?.first_name}-${item?.inq_passengers?.last_name}`;

            const fileName = item?.inq_passengers?.last_name
              ? `Ticket-${item?.inq_passengers?.first_name}-${item?.inq_passengers?.last_name}`
              : `Ticket-${item?.inq_passengers?.first_name}`;
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", fileName);

            // Trigger the download
            link.click();

            // Clean up the URL object
            URL.revokeObjectURL(link.href);
          } catch (error) {}
        }
      }
    );
  };

  const userRole = useUserDefaultRole();
  const user_id = useUserId();

  return (
    <div className="self-stretch rounded-lg shadow w-full flex flex-col">
      <div
        className={`self-stretch flex-1 bg-secondary dark:bg-dark-secondary w-full flex flex-col p-[25px] gap-[12px]  rounded-t-lg items-start justify-between text-left text-mini text-darkslategray`}
      >
        <div className="self-stretch container w-full">
          <form className="self-stretch flex flex-col w-full">
            {userRole !== "user" && (
              <div className=" self-stretch container w-full gap-[12px] justify-between  flex flex-col flex-1">
                <div className="self-stretch relative flex items-center justify-between w-full">
                  <div className="flex items-center gap-1 text-basic dark:text-dark-primary">
                    <b>{ticekts?.[0]?.inq_tickets?.[0]?.vendor?.displayName}</b>
                  </div>

                  {(user_id === inquiry?.picked_by || userRole === "admin") &&
                    inquiry?.status !== "completed" &&
                    !hideActions && (
                      <div className="flex items-center justify-end gap-[8px]">
                        <BiSolidMessageSquareEdit
                          className="text-basic dark:text-dark-primary cursor-pointer h-5 w-5"
                          onClick={() => setHideForm(!hideForm)}
                        />
                        <MdDelete
                          onClick={async () => {
                            const res = await deleteTicket({
                              variables: {
                                id: ticekts?.[0]?.inq_tickets?.[0]?.id,
                              },
                            });

                            if (
                              res?.data?.delete_inq_ticket?.returning?.[0]?.id
                            ) {
                              toast.success("Ticket Deleted Successfully");
                            }
                          }}
                          className="text-basic dark:text-dark-primary cursor-pointer h-6 w-6"
                        />
                      </div>
                    )}
                </div>
                <div className="self-stretch flex flex-col gap-[12px] w-full">
                  <div className="self-stretch relative flex items-center gap-1 border-b border-solid border-basic dark:border-dark-primary text-basic dark:text-dark-primary">
                    <span>{`PNR:`}</span>
                    <b>{ticekts?.[0]?.inq_tickets?.[0]?.pnr}</b>
                  </div>
                  <div className="self-stretch relative flex items-center gap-0.5 border-b border-solid border-basic dark:border-dark-primary text-basic dark:text-dark-primary">
                    <span>{`Vendor Reference:`}</span>
                    <br />
                    <b>{ticekts?.[0]?.inq_tickets?.[0]?.ibe}</b>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
        {hideForm && (
          <SideDrawer
            setSidePopup={setHideForm}
            sidePopup={hideForm}
            component={
              <div className="self-stretch flex items-center dark:bg-dark-secondary gap-2 py-10 px-5 w-full">
                <EditFlightTicket
                  ticekts={ticekts}
                  inquiry={inquiry}
                  setHideForm={setHideForm}
                  paidAmount={getPaidAmount(inquiry)}
                  vendors={data.users}
                  fareExpiry={
                    inquiry.selected_suggestion?.fare_expiry || undefined
                  }
                />
              </div>
            }
          />
        )}
      </div>
      {/* Footer */}
      <div className="self-stretch flex justify-between gap-2 w-full bg-basic px-[25px] py-[20px] rounded-b-lg">
        {ticekts?.[0]?.inq_tickets?.[0]?.status === "issued" && (
          <div className="relative flex items-center gap-2 text-white">
            <span>Tickets</span>
            <MdDownloadForOffline
              onClick={handleDownloadAll}
              className="text-white h-6 w-6 cursor-pointer"
            />
          </div>
        )}

        <div className="relative flex gap-1 text-basic rounded-2xl font-medium bg-white px-2">
          <p className="capitalize">{ticekts?.[0]?.inq_tickets?.[0]?.status}</p>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="rounded-lg shadow w-full">
  //     <div
  //       className={`self-stretch  dark:bg-dark-secondary w-full flex flex-col px-[25px] pb-[50px] rounded-t-lg pt-[25px] items-start justify-center text-left text-mini text-darkslategray`}
  //     >
  //       <div className="container w-full">
  //         <form className="self-stretch flex flex-col gap-[43px] w-full">
  //           {userRole !== "user" && (
  //             <>
  //               <div className="relative flex items-center justify-between w-full">
  //                 <div className="flex items-center gap-1 text-basic dark:text-dark-primary">
  //                   <b>{ticekts?.[0]?.inq_tickets?.[0]?.vendor?.displayName}</b>
  //                 </div>

  //                 {(user_id === inquiry?.picked_by || userRole === "admin") &&
  //                   inquiry?.status !== "completed" &&
  //                   !hideActions && (
  //                     <div className="flex items-center justify-end gap-[8px]">
  //                       <BiSolidMessageSquareEdit
  //                         className="text-basic dark:text-dark-primary cursor-pointer h-5 w-5"
  //                         onClick={() => setHideForm(!hideForm)}
  //                       />
  //                       <MdDelete
  //                         onClick={async () => {
  //                           const res = await deleteTicket({
  //                             variables: {
  //                               id: ticekts?.[0]?.inq_tickets?.[0]?.id,
  //                             },
  //                           });

  //                           if (
  //                             res?.data?.delete_inq_ticket?.returning?.[0]?.id
  //                           ) {
  //                             toast.success("Ticket Deleted Successfully");
  //                           }
  //                         }}
  //                         className="text-basic dark:text-dark-primary cursor-pointer h-6 w-6"
  //                       />
  //                     </div>
  //                   )}
  //               </div>
  //               <div className="flex flex-col gap-5 w-full">
  //                 <div className="relative flex items-center gap-1 border-b border-solid border-basic dark:border-dark-primary text-basic dark:text-dark-primary">
  //                   <span>{`PNR:`}</span>

  //                   <b>{ticekts?.[0]?.inq_tickets?.[0]?.pnr}</b>
  //                 </div>
  //                 <div className="relative flex items-center gap-0.5 border-b border-solid border-basic dark:border-dark-primary text-basic dark:text-dark-primary">
  //                   <span>{`Vendor Reference:`}</span>
  //                   <br />
  //                   <b>{ticekts?.[0]?.inq_tickets?.[0]?.ibe}</b>
  //                 </div>
  //               </div>
  //             </>
  //           )}
  //         </form>
  //       </div>
  //       {hideForm && (
  //         <SideDrawer
  //           setSidePopup={setHideForm}
  //           sidePopup={hideForm}
  //           component={
  //             <div className="flex items-center dark:bg-dark-secondary gap-2 py-10 px-5 w-full">
  //               <EditFlightTicket
  //                 ticekts={ticekts}
  //                 inquiry={inquiry}
  //                 setHideForm={setHideForm}
  //                 paidAmount={getPaidAmount(inquiry)}
  //                 vendors={data.users}
  //                 fareExpiry={
  //                   inquiry.selected_suggestion?.fare_expiry || undefined
  //                 }
  //               />
  //             </div>
  //           }
  //         />
  //       )}
  //     </div>
  //     <div className="flex justify-between gap-2 w-full bg-basic px-[25px] py-[20px] rounded-b-lg">
  //       {ticekts?.[0]?.inq_tickets?.[0]?.status === "issued" && (
  //         <div className="relative flex items-center gap-2 text-white">
  //           <span>Tickets</span>
  //           <MdDownloadForOffline
  //             onClick={handleDownloadAll}
  //             className="text-white h-6 w-6 cursor-pointer"
  //           />
  //         </div>
  //       )}

  //       <div className="relative flex gap-1 text-basic rounded-2xl font-medium bg-white px-2">
  //         {/* <span>{`Status:`}</span>
  //           <br /> */}
  //         <p className="capitalize">{ticekts?.[0]?.inq_tickets?.[0]?.status}</p>
  //       </div>
  //     </div>
  //   </div>
  // );
}
