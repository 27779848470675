import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { toast } from "react-toastify";

import { useState } from "react";
import SideDrawer from "~/components/common/sideDrawer";
import EditVisas from "../EditVisas";
import { GET_BRD_VISAS_WITH_BRD_ID } from "~/graphql/brd_visas/Query";
import { DELETE_BRD_VISAS_WITH_ID } from "~/graphql/brd_visas/Mutation";

const ShowVisas = ({ brd_id }: any) => {
  const client = useApolloClient();

  // Fetching data using the query
  const { data, loading, error } = useQuery(GET_BRD_VISAS_WITH_BRD_ID, {
    variables: { brd_id },
  });

  // Mutation for deleting visa
  const [deleteVisa] = useMutation(DELETE_BRD_VISAS_WITH_ID, {
    onCompleted: () => {
      client.refetchQueries({
        include: "all", // Refetch all queries to ensure fresh data
      });
      toast.success("Visa deleted successfully.");
    },
    onError: () => {
      toast.error("Error deleting visa.");
    },
  });

  const [openPopup, setOpenPopup] = useState(false);
  const [selectedVisa, setSelectedVisa] = useState(null);

  const handleDelete = async (id: any) => {
    try {
      await deleteVisa({
        variables: { id },
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching visas: {error.message}</div>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Validity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Action</TableCell> {/* Removed BRD ID column */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.brd_visas?.map((visa: any) => (
              <TableRow key={visa.id}>
                <TableCell>{visa.brd_visa_type.name}</TableCell>
                <TableCell>{visa.validity}</TableCell>
                <TableCell>{visa.price}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setOpenPopup(true);
                      setSelectedVisa(visa);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(visa.id)}
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && (
        <SideDrawer
          component={
            <EditVisas
              selectedVisa={selectedVisa}
              setSidePopup={setOpenPopup}
              brd_id={brd_id}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowVisas;
