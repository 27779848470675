import React, { useState } from "react";
import { DELETE_ACCOUNT } from "./graphql/Mutations";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import useRefetch from "~/hooks/useRealtime";
import SidePopupBar from "~/components/common/SidePopup/components/SidePopupBar";
import EditAccount from "./Edit";

export default function DetailRow({ singleData, index }: any) {
  // const [modalOpen, setModelOpen] = useState(false);
  // const [hideForm, setHideForm] = useState(false);
  // const refetch = useRefetch();
  // const [deleteAccount] = useMutation(DELETE_ACCOUNT);
  // const execConfirm = async () => {
  //   try {
  //     const res = await deleteAccount({
  //       variables: {
  //         id: account.id,
  //       },
  //     });
  //     toast.success("Account deleted successfully", {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     });
  //     await refetch();
  //     setModelOpen(false);
  //   } catch (e: any) {
  //     setModelOpen(false);
  //     toast.error(`Please delete transactions first`, {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     });
  //
  //   }
  // };

  return (
    <>
      <tr className="">
        <td className="px-4 py-4 text-sm font-medium text-gray-700  ">
          <div className="inline-flex  gap-x-3">
            <span>{index + 1}</span>
          </div>
        </td>
        <td className="px-4 py-4 text-sm text-gray-500  capitalize">
          {singleData?.brd_list?.name}
        </td>
        <td className="px-4 py-4 text-sm text-gray-500 capitalize">
          {singleData?.name}
        </td>
        <td className="px-4 py-4 text-sm text-gray-500 capitalize">
          {singleData?.ad_id}
        </td>
        <td className="px-4 py-4 text-sm text-gray-500">
          {/* {account?.thp_id ? (
            <div className="flex gap-2 w-full">
              <button
                className="button btn  bg-primary  text-white  rounded-md p-2 px-[20px]"
                onClick={() => {
                  setHideForm(true);
                }}
              >
                Edit
              </button>
              <button
                className=" btn bg-red-500 text-white rounded-md p-2  px-[20px]"
                onClick={() => {
                  setModelOpen(true);
                }}
              >
                Delete
              </button>
            </div>
          ) : (
            <div className="inline-flex  px-3 py-1 rounded-full gap-x-2 text-emerald-500 bg-emerald-100/60 ">
              <h2 className="text-sm font-normal">Default</h2>
            </div>
          )} */}
        </td>
      </tr>

      {/* {modalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 50 }}
        >
          <div className="relative bg-white rounded-xl shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
            <h2 className="mb-6 text-2xl font-bold text-gray-700">
              Are you sure?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={(event: any) => setModelOpen(false)}
                className="px-5 py-2 font-semibold text-gray-700 bg-red-500 bg-opacity-10 rounded-full hover:bg-opacity-20"
              >
                No
              </button>
              <button
                onClick={(event: any) => execConfirm()}
                className="px-5 py-2 font-semibold text-white bg-green-500 rounded-full hover:bg-green-600"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {hideForm && (
        <SidePopupBar setShow={setHideForm} width="250px">
          <EditAccount account={account} setHideForm={setHideForm} />
        </SidePopupBar>
      )} */}
    </>
  );
}
