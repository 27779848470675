import React, { useState, useEffect } from "react";
import TicketRequestCard from "../components/TicketRequestCard";
import TicketRequestForm from "../components/TicketRequestForm";
import FlightTicketRequestCard from "./components/flightRequestCard";
import StayTicketRequestCard from "./components/stayRequestCard";
import CarTicketRequestCard from "./components/carRequestCard";
import TrainTicketRequestCard from "./components/trainRequestCard";
import VisaTicketCard from "./components/VisaTicketCard";

const SelectedRequestForm = ({
  inquiry,
  ticekts,
  tabs = [],
  setHideForm,
}: any) => {
  const [selectedTab, setSelectedTab] = useState(tabs?.[0]?.label || "Hotel");
  //   const [localTicketPurchase, setLocalTicketPurchase] = useState(false); // Local state per component

  useEffect(() => {
    if (tabs.length > 0 && tabs?.[0]?.label !== selectedTab) {
      setSelectedTab(tabs?.[0]?.label);
    }
  }, [tabs, selectedTab]);

  const renderComponent = () => {
    switch (selectedTab) {
      case "Hotel":
        return (
          <>
            {ticekts?.[0]?.stay_inq_details?.[0]?.stay_inq_tickets?.[0] ? (
              <div className="self-stretch grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4  items-start justify-start gap-[30px] text-mini">
                <StayTicketRequestCard inquiry={inquiry} ticekts={ticekts} />
                {/* <h1>Stay</h1> */}
              </div>
            ) : (
              <TicketRequestForm
                inquiry={inquiry}
                setHideForm={setHideForm}
                tabs={tabs}
                ticekts={ticekts}
              />
            )}
          </>
        );
      case "Cars":
        return (
          <>
            {ticekts?.[0]?.car_inq_details?.[0]?.car_inq_tickets?.[0] ? (
              <div className="self-stretch grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4  items-start justify-start gap-[30px] text-mini">
                <CarTicketRequestCard inquiry={inquiry} ticekts={ticekts} />
                {/* <h1>Car</h1> */}
              </div>
            ) : (
              <TicketRequestForm
                inquiry={inquiry}
                setHideForm={setHideForm}
                tabs={tabs}
                ticekts={ticekts}
              />
            )}
          </>
        );
      case "Train":
        return (
          <>
            {ticekts?.[0]?.train_inq_details?.[0]?.train_inq_tickets?.[0] ? (
              <div className="self-stretch grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4  items-start justify-start gap-[30px] text-mini">
                <TrainTicketRequestCard inquiry={inquiry} ticekts={ticekts} />
              </div>
            ) : (
              <TicketRequestForm
                inquiry={inquiry}
                setHideForm={setHideForm}
                tabs={tabs}
                ticekts={ticekts}
              />
            )}
          </>
        );
      case "Flights":
        return (
          <>
            {ticekts?.[0]?.inq_tickets?.[0] ? (
              <>
                <FlightTicketRequestCard inquiry={inquiry} ticekts={ticekts} />
              </>
            ) : (
              <TicketRequestForm
                inquiry={inquiry}
                setHideForm={setHideForm}
                tabs={tabs}
                ticekts={ticekts}
              />
            )}
          </>
        );
      case "Visa":
        return (
          <>
            {ticekts?.[0]?.visa_inq_details?.[0]?.visa_inq_tickets?.[0] ? (
              <>
                <VisaTicketCard inquiry={inquiry} ticekts={ticekts} />
              </>
            ) : (
              <TicketRequestForm
                inquiry={inquiry}
                setHideForm={setHideForm}
                tabs={tabs}
                ticekts={ticekts}
              />
            )}
          </>
        );
      default:
        return <div>No matching component found</div>;
    }
  };

  return renderComponent();
};

export default SelectedRequestForm;
