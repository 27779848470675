import { useQuery } from "@apollo/client";
import { GET_BRD_VISAS_WITH_BRD_ID } from "~/graphql/brd_visas/Query";

export default function useUmrahVisasById(brdId: any) {
  const { data } = useQuery(GET_BRD_VISAS_WITH_BRD_ID, {
    variables: {
      brd_id: brdId,
    },
  });

  return { brdVisas: data?.brd_visas };
}
