import { gql } from "@apollo/client";

export const GET_UMRAH_PACKAGES_WITH_BRD_ID = gql`
  query GetUmrahPackagesWithBrdId($brdId: uuid!) {
    brd_umrah_packages(where: { brd_id: { _eq: $brdId } }) {
      name
      start_date
      end_date
      id
      brd_id
      umrah_fares {
        id
        fare_id
        inq_suggestion {
          id
          airline_iata
          inq_suggestion_costs {
            sale_price
          }
          inq_suggestion_legs {
            cabin
            type
            inq_suggestion_leg_stops {
              depart
              arrival
            }
          }
        }
      }
      umrah_hotels {
        id
        hotel_id
        nights
        brd_hotel {
          id
          name
          coordinates
          city
        }
      }
      umrah_visas {
        id
        visa_id
        brd_visa {
          id
          type
          validity
          brd_visa_type {
            name
          }
        }
      }
    }
  }
`;

export const GET_UMRAH_PACKAGES_WITH_ID = gql`
  query GetUmrahPackagesWithId($id: uuid!) {
    brd_umrah_packages(where: { id: { _eq: $id } }) {
      name
      slug
      start_date
      end_date
      id
      brd_id
      brd_seo_settings {
        id
        default_title
        default_description
        default_keywords
        canonical_url
      }
      umrah_fares {
        id
        fare_id
        inq_suggestion {
          id
          airline_iata
          inq_suggestion_costs {
            sale_price
          }
          inq_suggestion_legs {
            cabin
            type
            inq_suggestion_leg_stops {
              depart
              arrival
            }
          }
        }
      }
      umrah_hotels {
        id
        hotel_id
        nights
        brd_hotel {
          id
          name
          coordinates
          city
        }
      }
      umrah_visas {
        id
        visa_id
        brd_visa {
          id
          type
          validity
          brd_visa_type {
            name
          }
        }
      }
    }
  }
`;
