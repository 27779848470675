import { Box, Button, Divider, TextField, InputAdornment } from "@mui/material";
import { useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  INSERT_DEFAULT_SETTING,
  UPDATE_DEFAULT_SETTING,
} from "./graphql/Mutation";
import { toast } from "react-toastify";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { typingEffect } from "~/utils/typingEffect";
import { getAiData } from "~/utils/getAiData";
import { useState } from "react";

const DestinationSetting = ({ defSettings, brd_id }: any) => {
  const [homepPageDestinationHeading, setHomePageDestinationHeading] =
    useState<any>(defSettings?.hot_destinations_heading || "");
  const [homepPageDestinationSubHeading, setHomePageDestinationSubHeading] =
    useState<any>(defSettings?.hot_destinations_sub_heading || "");
  const [homePageButtonTitle, setHomePageButtonTitle] = useState<any>(
    defSettings?.destinations_button_text || ""
  );
  const [destinationPageMainHeading, setDestinationPageMainHeading] =
    useState<any>(defSettings?.destinations_main_heading || "");
  const [
    footerLinkTitleForHotDestinations,
    setFooterLinkTitleForHotDestinations,
  ] = useState<any>(defSettings?.destinations_footer_text || "");
  const [destinationPageBlogsHeading, setDestinationPageBlogsHeading] =
    useState<any>(defSettings?.destination_blogs_heading);
  const [destinationPageBlogsSubHeading, setDestinationPageBlogsSubHeading] =
    useState<any>(defSettings?.destination_blogs_sub_heading || "");
  const [destinationPageTipsHeading, setDestinationPageTipsHeading] =
    useState<any>(defSettings?.destination_tips_heading || "");
  const [destinationPageTipsSubHeading, setDestinationPageTipsSubHeading] =
    useState<any>(defSettings?.destination_tips_sub_heading || "");
  const [destinationPageContactHeading, setDestinationPageContactHeading] =
    useState<any>(defSettings?.destination_contact_heading || "");
  const [
    destinationPageContactSubHeading,
    setDestinationPageContactSubHeading,
  ] = useState<any>(defSettings?.destination_contact_sub_heading || "");
  const [galleryHeading, setGalleryHeading] = useState<any>(
    defSettings?.gallery_heading || ""
  );
  const [gallerySubHeading, setGallerySubHeading] = useState<any>(
    defSettings?.gallery_sub_heading || ""
  );
  const [flightLinksSectionHeading, setFlightLinksSectionHeading] =
    useState<any>(defSettings?.flight_links_heading || "");
  const [flightLinksSectionSubHeading, setFlightLinksSectionSubHeading] =
    useState<any>(defSettings?.flight_links_sub_heading || "");
  const [betterDealSubmitButtonText, setBetterDealSubmitButtonText] =
    useState<any>(defSettings?.better_deal_inquiry_submit_text || "");
  const [betterDealHeading, setBetterDealHeading] = useState<any>(
    defSettings?.better_deal_heading || ""
  );
  const [reserveFareText, setReserveFaretext] = useState<any>(
    defSettings?.reserve_fare_text || ""
  );
  const [betterDealText, setBetterDealText] = useState<any>(
    defSettings?.better_deal_text || ""
  );
  const [callOnlyFareText, setCallOnlyFareText] = useState<any>(
    defSettings?.call_only_fare_text || ""
  );
  const [requestCallBackText, setRequestCallBackText] = useState<any>(
    defSettings?.request_call_back_text || ""
  );
  const [shareButtonText, setShareButtonText] = useState<any>(
    defSettings?.share_button_text || ""
  );

  const [updateDefaultSettings] = useMutation(UPDATE_DEFAULT_SETTING);
  const [insertDefaultSettings] = useMutation(INSERT_DEFAULT_SETTING);
  const client = useApolloClient();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (inpData: any) => {
    // ;
    if (defSettings?.id) {
      const res = await updateDefaultSettings({
        variables: {
          id: { id: defSettings?.id },
          data: {
            ...inpData,
          },
        },
      });

      if (res?.data?.update_brd_default_setting_by_pk?.id) {
        toast.success("Settings  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertDefaultSettings({
        variables: {
          data: {
            brd_id: brd_id,
            ...inpData,
          },
        },
      });

      if (res?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Settings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <>
      <div className="lg:w-[1000px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">
          Destination Page Setting
        </h1>
        <Divider />
        <Box
          className="mt-10"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div>
            <label className="text-basic text-2xl">For Home Page</label>
            <div className="grid md:grid-cols-2 grid-cols-1 mt-5 gap-5">
              <TextField
                error={errors["hot_destinations_heading"] ? true : false}
                {...register("hot_destinations_heading")}
                label="Homepage Destination Heading"
                type="text"
                defaultValue={defSettings?.hot_destinations_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a destination heading for a home page of travel agency without qoutes, as previous input is "Destinations to Dream About!".`
                          );
                          setValue("hot_destinations_heading", res);
                          await typingEffect(
                            setHomePageDestinationHeading,
                            res
                          );
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={homepPageDestinationHeading}
                onChange={(e: any) =>
                  setHomePageDestinationHeading(e.target.value)
                }
              />
              <TextField
                error={errors["hot_destinations_sub_heading"] ? true : false}
                {...register("hot_destinations_sub_heading")}
                label="Homepage Destination Sub Heading"
                type="text"
                defaultValue={defSettings?.hot_destinations_sub_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a destination sub heading for a home page of travel agency without qoutes, as previous input is "Dive into Our Curated Selection of the World's Top Travel Hotspots".`
                          );
                          setValue("hot_destinations_sub_heading", res);
                          await typingEffect(
                            setHomePageDestinationSubHeading,
                            res
                          );
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={homepPageDestinationSubHeading}
                onChange={(e: any) =>
                  setHomePageDestinationSubHeading(e.target.value)
                }
              />
              <TextField
                error={errors["destinations_button_text"] ? true : false}
                {...register("destinations_button_text")}
                label="Homepage Button Title For Hot Destinations"
                type="text"
                helperText="Available variables are {{depart_destination}}, {{arrival_destination}}"
                defaultValue={defSettings?.destinations_button_text}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a button title for a home page of travel agency with Available variables {{depart_destination}}, {{arrival_destination}}: without qoutes`
                          );
                          setValue("destinations_button_text", res);
                          await typingEffect(setHomePageButtonTitle, res);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={homePageButtonTitle}
                onChange={(e: any) => setHomePageButtonTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-8">
            <label className="text-basic text-2xl">For Destination Page</label>
            <div className="grid md:grid-cols-2 grid-cols-1 mt-5 gap-5">
              <TextField
                error={errors["destinations_main_heading"] ? true : false}
                {...register("destinations_main_heading")}
                label="Craft Your Destination Page Main Heading"
                type="text"
                helperText="Available variables are {{depart_destination}}, {{arrival_destination}}, {{cheap_price}}"
                defaultValue={defSettings?.destinations_main_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a main heading for a destination page of travel agency with available variables, {{depart_destination}}, {{arrival_destination}}, {{cheap_price}}: without qoutes`
                          );
                          setValue("destinations_main_heading", res);
                          await typingEffect(
                            setDestinationPageMainHeading,
                            res
                          );
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={destinationPageMainHeading}
                onChange={(e: any) =>
                  setDestinationPageMainHeading(e.target.value)
                }
              />
              <TextField
                error={errors["better_deal_inquiry_submit_text"] ? true : false}
                {...register("better_deal_inquiry_submit_text")}
                label="Better Deal Submit Button Text"
                type="text"
                defaultValue={defSettings?.better_deal_inquiry_submit_text}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a Better Deal Submit Button Text for a destination page of travel agency without qoutes, as previous input is "Better Deal".`
                          );
                          setValue("better_deal_inquiry_submit_text", res);
                          await typingEffect(
                            setBetterDealSubmitButtonText,
                            res
                          );
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={betterDealSubmitButtonText}
                onChange={(e: any) =>
                  setBetterDealSubmitButtonText(e.target.value)
                }
              />
              <TextField
                error={errors["better_deal_heading"] ? true : false}
                {...register("better_deal_heading")}
                label="Better Deal Heading"
                type="text"
                defaultValue={defSettings?.better_deal_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a Better Deal component Heading for a destination page of travel agency: without qoutes.`
                          );
                          setValue("better_deal_heading", res);
                          await typingEffect(setBetterDealHeading, res);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={betterDealHeading}
                onChange={(e: any) => setBetterDealHeading(e.target.value)}
              />
              <TextField
                error={errors["reserve_fare_text"] ? true : false}
                {...register("reserve_fare_text")}
                label="Reserve Fare Text"
                type="text"
                defaultValue={defSettings?.reserve_fare_text}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a reserve fare text for a destination page of travel agency: without qoutes.`
                          );
                          setValue("reserve_fare_text", res);
                          await typingEffect(setReserveFaretext, res);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={reserveFareText}
                onChange={(e: any) => setReserveFaretext(e.target.value)}
              />
              <TextField
                error={errors["better_deal_text"] ? true : false}
                {...register("better_deal_text")}
                label="Better Deal Text"
                type="text"
                defaultValue={defSettings?.better_deal_text}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a Better Deal Text for a destination page of travel agency: without qoutes.`
                          );
                          setValue("better_deal_text", res);
                          await typingEffect(setBetterDealText, res);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={betterDealText}
                onChange={(e: any) => setBetterDealText(e.target.value)}
              />
              <TextField
                error={errors["call_only_fare_text"] ? true : false}
                {...register("call_only_fare_text")}
                label="Call Only Fare Text"
                type="text"
                defaultValue={defSettings?.call_only_fare_text}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write call Only Fare Text for a destination page of travel agency: without qoutes.`
                          );
                          setValue("call_only_fare_text", res);
                          await typingEffect(setCallOnlyFareText, res);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={callOnlyFareText}
                onChange={(e: any) => setCallOnlyFareText(e.target.value)}
              />
              <div className="md:col-span-2">
                <TextField
                  sx={{ width: "100%" }}
                  error={errors["share_button_text"] ? true : false}
                  {...register("share_button_text")}
                  label="Share Button Text"
                  type="text"
                  defaultValue={defSettings?.share_button_text}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AutoFixHighIcon
                          color="primary"
                          sx={{ cursor: "pointer" }}
                          onClick={async () => {
                            const res = await getAiData(
                              `Write share button Text for a destination page of travel agency: without qoutes.`
                            );
                            setValue("share_button_text", res);
                            await typingEffect(setShareButtonText, res);
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  value={shareButtonText}
                  onChange={(e: any) => setShareButtonText(e.target.value)}
                />
              </div>
              <TextField
                error={errors["request_call_back_text"] ? true : false}
                {...register("request_call_back_text")}
                label="Request Call Back Text"
                type="text"
                defaultValue={defSettings?.request_call_back_text}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write request call backText for a destination page of travel agency: without qoutes.`
                          );
                          setValue("request_call_back_text", res);
                          await typingEffect(setRequestCallBackText, res);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={requestCallBackText}
                onChange={(e: any) => setRequestCallBackText(e.target.value)}
              />
              <TextField
                error={errors["destination_blogs_heading"] ? true : false}
                {...register("destination_blogs_heading")}
                label="Destination Page Blogs Heading"
                type="text"
                defaultValue={defSettings?.destination_blogs_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a blog heading for a destination page of travel agency, as previous input is "More Than Just a Flight" in 4-5 words without qoutes.`
                          );
                          setValue("destination_blogs_heading", res);
                          await typingEffect(
                            setDestinationPageBlogsHeading,
                            res
                          );
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={destinationPageBlogsHeading}
                onChange={(e: any) =>
                  setDestinationPageBlogsHeading(e.target.value)
                }
              />
              <TextField
                error={errors["destination_blogs_sub_heading"] ? true : false}
                {...register("destination_blogs_sub_heading")}
                label="Destination Page Blogs Sub Heading"
                type="text"
                defaultValue={defSettings?.destination_blogs_sub_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a blog sub heading for a destination page of travel agency, as previous input is "Discover the wonders, tips, and insights before you embark." without qoutes.`
                          );
                          setValue("destination_blogs_sub_heading", res);
                          await typingEffect(
                            setDestinationPageBlogsSubHeading,
                            res
                          );
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={destinationPageBlogsSubHeading}
                onChange={(e: any) =>
                  setDestinationPageBlogsSubHeading(e.target.value)
                }
              />
              <TextField
                error={errors["destination_tips_heading"] ? true : false}
                {...register("destination_tips_heading")}
                label="Destination Page Tips Heading"
                type="text"
                defaultValue={defSettings?.destination_tips_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a heading of tips for a destination page of travel agency without quotes, as previous input is "Travel Seasons Insight".`
                          );
                          setValue("destination_tips_heading", res);
                          await typingEffect(
                            setDestinationPageTipsHeading,
                            res
                          );
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={destinationPageTipsHeading}
                onChange={(e: any) =>
                  setDestinationPageTipsHeading(e.target.value)
                }
              />
              <TextField
                error={errors["destination_tips_sub_heading"] ? true : false}
                {...register("destination_tips_sub_heading")}
                label="Destination Page Tips Sub Heading"
                type="text"
                defaultValue={defSettings?.destination_tips_sub_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a sub heading of tips for a destination page of travel agency without quotes, as previous input is, "{{depart_destination}} to {{arrival_destination}} from {{cheap_price}}".`
                          );
                          setValue("destination_tips_sub_heading", res);
                          await typingEffect(
                            setDestinationPageTipsSubHeading,
                            res
                          );
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={destinationPageTipsSubHeading}
                onChange={(e: any) =>
                  setDestinationPageTipsSubHeading(e.target.value)
                }
              />
              <TextField
                error={errors["gallery_heading"] ? true : false}
                {...register("gallery_heading")}
                label="Gallery Heading"
                type="text"
                defaultValue={defSettings?.gallery_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a heading of Gallery for a destination page of travel agency without quotes.`
                          );
                          setValue("gallery_heading", res);
                          await typingEffect(setGalleryHeading, res);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={galleryHeading}
                onChange={(e: any) => setGalleryHeading(e.target.value)}
              />
              <TextField
                error={errors["gallery_sub_heading"] ? true : false}
                {...register("gallery_sub_heading")}
                label="Gallery Sub Heading"
                type="text"
                defaultValue={defSettings?.gallery_sub_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a sub heading of Gallery for a destination page of travel agency without quotes.`
                          );
                          setValue("gallery_sub_heading", res);
                          await typingEffect(setGallerySubHeading, res);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={gallerySubHeading}
                onChange={(e: any) => setGallerySubHeading(e.target.value)}
              />

              <TextField
                error={errors["flight_links_heading"] ? true : false}
                {...register("flight_links_heading")}
                label="Flight Links Section Heading"
                type="text"
                defaultValue={defSettings?.flight_links_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a heading of Flight Links section for a destination page of travel agency without quotes.`
                          );
                          setValue("flight_links_heading", res);
                          await typingEffect(setFlightLinksSectionHeading, res);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={flightLinksSectionHeading}
                onChange={(e: any) =>
                  setFlightLinksSectionHeading(e.target.value)
                }
              />
              <TextField
                sx={{ width: "100%" }}
                error={errors["flight_links_sub_heading"] ? true : false}
                {...register("flight_links_sub_heading")}
                label="Flight Links Section Sub Heading"
                type="text"
                defaultValue={defSettings?.flight_links_sub_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a sub heading of Flight Links section for a destination page of travel agency without quotes.`
                          );
                          setValue("flight_links_sub_heading", res);
                          await typingEffect(
                            setFlightLinksSectionSubHeading,
                            res
                          );
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={flightLinksSectionSubHeading}
                onChange={(e: any) =>
                  setFlightLinksSectionSubHeading(e.target.value)
                }
              />
              <TextField
                error={errors["destination_contact_heading"] ? true : false}
                {...register("destination_contact_heading")}
                label="Destination Page Contact Heading"
                type="text"
                defaultValue={defSettings?.destination_contact_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a heading of contacts for a destination page of travel agency without quotes.`
                          );
                          setValue("destination_contact_heading", res);
                          await typingEffect(
                            setDestinationPageContactHeading,
                            res
                          );
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={destinationPageContactHeading}
                onChange={(e: any) =>
                  setDestinationPageContactHeading(e.target.value)
                }
              />
              <TextField
                error={errors["destination_contact_sub_heading"] ? true : false}
                {...register("destination_contact_sub_heading")}
                label="Destination Page Contact Sub Heading"
                type="text"
                defaultValue={defSettings?.destination_contact_sub_heading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a sub heading of contacts for a destination page of travel agency without quotes.`
                          );
                          setValue("destination_contact_sub_heading", res);
                          await typingEffect(
                            setDestinationPageContactSubHeading,
                            res
                          );
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={destinationPageContactSubHeading}
                onChange={(e: any) =>
                  setDestinationPageContactSubHeading(e.target.value)
                }
              />
              <TextField
                error={errors["destinations_footer_text"] ? true : false}
                {...register("destinations_footer_text")}
                label="Footer Link Title For Hot Destinations"
                type="text"
                helperText="Available variables are {{depart_destination}}, {{arrival_destination}}"
                defaultValue={defSettings?.destinations_footer_text}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoFixHighIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                          const res = await getAiData(
                            `Write a footer link title for a destination page of travel agency with available variables, {{depart_destination}}, {{arrival_destination}}, {{cheap_price}}: without qoutes, as previous input is {{depart_destination}} to {{arrival_destination}}`
                          );
                          setValue("destinations_footer_text", res);
                          await typingEffect(
                            setFooterLinkTitleForHotDestinations,
                            res
                          );
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                value={footerLinkTitleForHotDestinations}
                onChange={(e: any) =>
                  setFooterLinkTitleForHotDestinations(e.target.value)
                }
              />
            </div>
          </div>
          <Button
            variant="contained"
            sx={{ marginTop: "20px", width: "100%" }}
            type="submit"
          >
            Update
          </Button>
        </Box>
      </div>
    </>
  );
};

export default DestinationSetting;
