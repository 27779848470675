import DashboardLogo from "../../../assets/svg/DsahboardLogo.svg";
import DashboardLogoWhite from "../../../assets/svg/DashboardLogoWhite.svg";

import travelHouseStore from "../../../store/travelHouse/travelHouseStore";
import commonStore from "~/store/commonStore/commonStore";
import { useClickOutside } from "@mantine/hooks";

import { Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";

import { useUserDefaultRole } from "@nhost/react";
import TravelingSideBar from "./components/TravelingSideBar";
import brandsStore from "~/store/brandsStore/brandsStore";
import EducationalSideBar from "./components/EducationalSideBar";
import ServicesSideBar from "./components/ServicesSideBar";
import DigitalAgencBar from "./components/DigitalAgencBar";

const SideBar = ({ showMenu, setShowMenu }: any) => {
  const sidebarRef = useClickOutside(() => setSidePopup(false));
  const { travelHouse }: any = travelHouseStore();
  const { sidePopup, setSidePopup } = commonStore();
  const { brands }: any = brandsStore();
  const role = useUserDefaultRole();

  const currentPath = window.location.pathname;
  const [activeDropdown, setActiveDropdown] = useState(currentPath);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div className="dark:bg-dark-primary bg-primary">
      <Box
        sx={{
          width: "260px",
          maxWidth: 360,
          // bgcolor: "#F4F5FA",
          paddingRight: "10px",
          overflowY: "scroll",
          height: "100vh",
          paddingBottom: "20px",
        }}
      >
        <div className="px-6 shadow-white dark:shadow-[#28183D] fixed bg-primary dark:bg-dark-primary w-[260px] z-10 flex items-center justify-between pt-5 shadow-2xl">
          <img
            className="w-[160px]"
            alt="Dashboard Logo"
            src={darkMode ? DashboardLogoWhite : DashboardLogo}
          />
          <IconButton
            className="lg:hidden flex"
            onClick={() => setShowMenu(!showMenu)}
          >
            <RxCross2 className="lg:hidden " size={25} />
          </IconButton>
        </div>
        {travelHouse.active_brand === "43797e0f-75ab-4629-ad24-3d74037fc3ae" ? (
          <TravelingSideBar
            role={role}
            activeDropdown={activeDropdown}
            setActiveDropdown={setActiveDropdown}
          />
        ) : travelHouse.active_brand ===
          "8091f67a-589b-4ac3-93f0-658e8e0ce967" ? (
          <EducationalSideBar
            role={role}
            activeDropdown={activeDropdown}
            setActiveDropdown={setActiveDropdown}
          />
        ) : travelHouse.active_brand ===
          "54671601-ca1f-4214-8315-9c4396c62999" ? (
          <ServicesSideBar
            role={role}
            activeDropdown={activeDropdown}
            setActiveDropdown={setActiveDropdown}
          />
        ) : travelHouse.active_brand ===
          "d3b6d500-55aa-403c-a49d-15c986579758" ? (
          <DigitalAgencBar
            role={role}
            activeDropdown={activeDropdown}
            setActiveDropdown={setActiveDropdown}
          />
        ) : null}
      </Box>
    </div>
  );
};

export default SideBar;
