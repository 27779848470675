import { GET_SELECTED_FL_JOBS_WITH_BRD } from "~/graphql/brd_fl_selected_jobs/Query";
import { useQuery } from "@apollo/client";

export default function useSelectedFlJobsWithBrdId(brdId: any) {
  const { data, refetch } = useQuery(GET_SELECTED_FL_JOBS_WITH_BRD, {
    variables: {
      brdId,
    },
  });

  return {
    selectedFlJobsWithBrdId: data?.brd_fl_selected_jobs,
    refetchSelectedJobs: refetch,
  };
}
