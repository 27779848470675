import {
  FormLabel,
  TextField,
  Button,
  Modal,
  Box,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { INSERT_TIPS } from "../../graphql/Mutation";
import { useMutation, useApolloClient } from "@apollo/client";
import { typingEffect } from "~/utils/typingEffect";
import { getAiData } from "~/utils/getAiData";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { useParams } from "react-router-dom";

const AddTip = ({
  setOpenAddTip,
  airlinesId,
  type,
  refetchPageDetail,
}: any) => {
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [title, setTitle] = useState<any>("");
  const [description, setDescription] = useState<any>("");

  const [insertTip] = useMutation(INSERT_TIPS);
  const client = useApolloClient();

  const { page_id } = useParams();

  const onSubmit = async (formData: any) => {
    const res = await insertTip({
      variables: {
        data: {
          airlines_page_id: type === "airline" ? page_id : null,
          heading: formData?.heading,
          description: formData?.description,
          flight_page_id: type === "destination" ? page_id : null,
        },
      },
    });

    if (res?.data?.insert_brd_flights_pages_tips?.affected_rows) {
      toast.success("Tip Added Successfully");
      setTitle("");
      setDescription("");
      setOpenAddTip(false);
      refetchPageDetail();
    }
  };

  return (
    <div className="bg-primary dark:bg-dark-primary rounded-lg shadow p-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          sx={{ width: "100%" }}
          error={errors["heading"] ? true : false}
          {...register("heading", { required: true })}
          label="Title"
          type="text"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AutoFixHighIcon
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  onClick={async () => {
                    const res = await getAiData(
                      `I have airlines webpage in which I have tips section. Write a tip title in 4 to 5 words for Airline Page without quotes.`
                    );
                    setValue("heading", res);
                    await typingEffect(setTitle, res);
                  }}
                />
              </InputAdornment>
            ),
          }}
          value={title}
          onChange={(e: any) => setTitle(e.target.value)}
        />
        <div className="mt-3">
          <div className="flex justify-between">
            <FormLabel>Description</FormLabel>
            <AutoFixHighIcon
              color="primary"
              sx={{ cursor: "pointer" }}
              onClick={async () => {
                const res = await getAiData(
                  `I have airlines webpage in which I have tips section. Write a tip description which have title:${title}, in 1 line for Airline Page without quotes.`
                );
                setValue("description", res);
                await typingEffect(setDescription, res);
              }}
            />
          </div>
          <textarea
            {...register("description", { required: true })}
            className="bg-transparent rounded w-full border-secondary dark:border-dark-secondary focus:border-basic focus:ring-basic h-40 p-1"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
        <div className="flex justify-between">
          <Button color="success" type="submit">
            Add
          </Button>
          <Button
            color="error"
            onClick={() => {
              setOpenAddTip(false);
            }}
          >
            Close
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddTip;
