import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from "@mui/material";
import { useState } from "react";
import SideDrawer from "~/components/common/sideDrawer";
import { GET_SERVICE_TESTIMONIALS } from "~/graphql/brd_services_testimonials/Query";

import EditTestimonial from "../EditTestimonial";

import { toast } from "react-toastify";
import { DELETE_BRD_SERVICES_TESTIMONIAL } from "~/graphql/brd_services_testimonials/Mutation";
import { useParams } from "react-router-dom";

const ShowTestimonial = () => {
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const client = useApolloClient();
  const { brd_id, service_id } = useParams();

  const [openPopup, setOpenPopup] = useState(false);

  const { data, loading, error } = useQuery(GET_SERVICE_TESTIMONIALS, {
    variables: { brdId: brd_id, serviceId: service_id },
  });
  const [deleteTestimonial] = useMutation(DELETE_BRD_SERVICES_TESTIMONIAL);
  const handleEdit = async (id: any) => {
    setOpenPopup(true);
    setSelectedTestimonial(id);
  };
  const handleDelete = async (id: any) => {
    try {
      const res = await deleteTestimonial({
        variables: { id: id },
      });

      if (res?.data?.delete_brd_services_testimonials?.affected_rows > 0) {
        toast.success("Testimonial deleted successfully.");
        await client.refetchQueries({
          include: "all",
        });
      } else {
        toast.error("Error deleting Testimonial");
      }
    } catch (error) {
      toast.error("Error deleting Testimonial.");
    }
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>FeedBack</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.brd_services_testimonials.map((testimonial: any) => (
              <TableRow key={testimonial.id}>
                <TableCell className=" min-w-[200px]">
                  {testimonial.name}
                </TableCell>
                <TableCell className=" min-w-[400px]">
                  {testimonial.feedback.length > 100
                    ? `${testimonial.feedback.slice(0, 100)}...`
                    : testimonial.feedback}
                </TableCell>
                <TableCell className=" min-w-[200px]">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEdit(testimonial)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(testimonial.id)}
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openPopup && (
        <SideDrawer
          component={
            <EditTestimonial
              testimonial={selectedTestimonial}
              setSidePopup={setOpenPopup}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowTestimonial;
