import { useApolloClient, useMutation } from "@apollo/client";
import { Button, TextField, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { UPDATE_BRD_PARTNERS } from "~/graphql/brd_partners/Mutation";
import { nhost } from "~/lib/nhost";

interface FormData {
  name: string;
  logo: FileList;
}

const EditBrandPartner = ({ partnerData, setSidePopup }: any) => {
  const [NewImageId, setNewImageId] = useState<string | null>(null);
  const [imageUploading, setImageUploading] = useState(false);
  const client = useApolloClient();
  const { brd_id } = useParams();
  const { register, handleSubmit, reset, setValue } = useForm<FormData>();
  const [updatePartner, { loading, error }] = useMutation(UPDATE_BRD_PARTNERS);

  const convertImageToWebP = async (file: any) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(
                new File([blob], file.name.replace(/\.\w+$/, ".webp"), {
                  type: "image/webp",
                })
              );
            } else {
              reject(new Error("Conversion to WebP failed"));
            }
          },
          "image/webp",
          0.8
        );
      };

      img.onerror = (error) => reject(error);
    });
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    setImageUploading(true);
    try {
      const webpFile = (await convertImageToWebP(file)) as File;
      const response = await nhost.storage.upload({ file: webpFile });
      if (response.error) {
        throw new Error(response.error.message);
      }
      setNewImageId(response.fileMetadata.id);
    } catch (uploadError) {
    } finally {
      setImageUploading(false);
    }
  };

  const onSubmit = async (data: FormData) => {
    if (NewImageId && partnerData?.logo_id) {
      const { error: imageDeleteError } = await nhost.storage.delete({
        fileId: partnerData.logo_id,
      });
      if (imageDeleteError) {
      }
    }

    try {
      const res = await updatePartner({
        variables: {
          id: partnerData?.id,
          name: data?.name,
          logo_id: NewImageId ? NewImageId : partnerData?.logo_id,
        },
      });
      reset();
      if (res?.data?.update_brd_partners?.affected_rows > 0) {
        await client.refetchQueries({ include: "all" });
      }

      if (setSidePopup) setSidePopup(false);
    } catch (mutationError) {}
  };

  //

  const imgUrl = NewImageId
    ? `${process.env.REACT_APP_NHOST_STORAGE_URL}${NewImageId}`
    : `${process.env.REACT_APP_NHOST_STORAGE_URL}${partnerData?.logo_id}`;

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Brand Partner
      </h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          required
          {...register("name")}
          defaultValue={partnerData?.name}
          style={{ marginBottom: "16px" }}
        />

        <Button
          variant="outlined"
          component="label"
          className="dark:text-dark-primary"
          disabled={imageUploading}
        >
          {imageUploading ? <CircularProgress size={20} /> : "Replace Image"}
          <input
            type="file"
            onChange={handleImageChange}
            hidden
            accept="image/*"
          />
        </Button>
        {imgUrl && (
          <img
            src={imgUrl}
            alt={partnerData?.title || "Partner Image"}
            className="max-h-[200px] rounded-lg shadow-md mt-4"
          />
        )}

        {loading ? (
          <CircularProgress className="mt-4" />
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
          >
            Update Partner
          </Button>
        )}

        {error && (
          <p className="text-red-500 mt-4">Error occurred: {error.message}</p>
        )}
      </form>
    </div>
  );
};

export default EditBrandPartner;
