import Adult from "modules/InquiriesModule/assets/svg/Adult";
import Child from "modules/InquiriesModule/assets/svg/Child";
import Infant from "modules/InquiriesModule/assets/svg/Infant";
import adult from "../../../../assets/img/Person.png"
import child from "../../../../assets/img/Child.png"
import infant from "../../../../assets/img/Infant.png"

export default function PassengerRecord({ inq_passengers }: any) {
  return (
    <div className="h-[18px] flex items-center gap-5 text-sm">
      <div className="flex flex-row items-center justify-center gap-[7px] text-white">
        <Adult />
        {/* <img src={adult} alt="" /> */}
        <div className="relative inline-block w-[6.54px] h-[16.88px] shrink-0">
          {
            inq_passengers.filter(
              (passenger: any) => passenger.type === "adult"
            ).length
          }
        </div>
      </div>
      <div className="flex flex-row items-center justify-center gap-[7px] text-white">
        <Child />
        {/* <img src={child} alt="" /> */}
        <div className="relative inline-block w-[7.36px] h-[16.88px] shrink-0">
          {
            inq_passengers.filter(
              (passenger: any) => passenger.type === "child"
            ).length
          }
        </div>
      </div>
      <div className="flex flex-row items-center justify-center gap-[7px] text-white">
        <Infant />
        {/* <img src={infant} alt="" /> */}
        <div className="relative inline-block w-[8.17px] h-[18px] shrink-0">
          {
            inq_passengers.filter(
              (passenger: any) => passenger.type === "infant"
            ).length
          }
        </div>
      </div>
    </div>
  );
}
