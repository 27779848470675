import { gql } from "@apollo/client";

export const INSERT_UMRAH_HOTELS = gql`
  mutation insertUmrahHotels($data: [umrah_hotels_insert_input!]!) {
    insert_umrah_hotels(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_UMRAH_HOTELS = gql`
  mutation deleteUmrahHotels($ids: [uuid!]!) {
    delete_umrah_hotels(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;
