import { GET_USER_BY_EMAIL, GET_USER_BY_PHONE } from "./graphql/Query";
import { useApolloClient } from "@apollo/client";

const checkPhone = async (phone: any, client: any) => {
  try {
    const response = await client.query({
      query: GET_USER_BY_PHONE,
      variables: { phone },
    });

    return response.data.users;
  } catch (error) {}
};

const checkEmail = async (email: any, client: any) => {
  try {
    const response = await client.query({
      query: GET_USER_BY_EMAIL,
      variables: { email },
    });
    return response.data.users;
  } catch (error) {}
};

const useValidation = () => {
  const client = useApolloClient();
  // Define All notification function inside notification object
  const validation = {
    // New inquery function
    checkPhone: async (phone: any) => {
      const res = await checkPhone(phone, client);
      return res;
    },
    checkEmail: async (email: any) => {
      const res = await checkEmail(email, client);
      return res;
    },
  };
  return validation;
};

export default useValidation;
