import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useFileUpload, useUserDefaultRole } from "@nhost/react";
import {
  ADD_TRANSACTION,
  EDIT_INQUIRY,
  UPDATE_TICKET_FILe,
} from "./graphql/Mutation";
import useNotifications from "hooks/useNotifications";
import { getPaidAmount } from "~/modules/InquiriesModule/utils/getInquiryStats";
import PurchaseDetail from "../../../TicketRequestForm/components/RequestForm/components/PurchaseDetail";
import moment from "moment";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import InputField from "~/components/FormInputs/InputField";
import { DateTimeBox } from "~/components/FormInputs/DateTimeBox";
import { URLs } from "~/config/enums";

export default function EditTicket({
  inquiry,
  setHideForm,
  paidAmount,
  vendors,
  fareExpiry,
}: any) {
  const [isloading, setIsloading] = useState(false);
  const [status, setStatus] = useState(inquiry?.inq_tickets[0]?.status);
  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const [updateTicketFile] = useMutation(UPDATE_TICKET_FILe);

  const [addTransaction] = useMutation(ADD_TRANSACTION);
  // const [sendEmail] = useMutation(SEND_EMAIL);
  const [ticketPurchase, setTicketPurchase] = useState(true);
  const [selectedFile, setSelectedFile] = useState<any>([]);

  const client = useApolloClient();

  const { upload, progress } = useFileUpload();

  const validatecharge = (value: any) => {
    if (Number(value) <= paidAmount) {
      return true;
    } else {
      return "Paid amount must be greater then and equal to cancelation charges";
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  }: any = useForm();
  const role = useUserDefaultRole();

  const alert = useNotifications();

  const onSubmit = async (formData: any) => {
    formData["void_time"] = moment(formData["void_time"]).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    setIsloading(true);
    let payload: any = {
      status: inquiry?.inq_tickets[0]?.status,
      pnr: formData["pnr_no"],
      ibe: formData["ibe_no"] || null,
      ticket_mis_cost: formData["ticket_mis_cost"] || null,
      void_time: formData["void_time"],
      vendor_id: formData["vendor_id"],
      cancelation_charges: formData["cancelation_charges"],
    };

    if (status === "pending_approval") {
      if (formData["ibe_no"]) {
        payload["ibe"] = formData["ibe_no"];
        payload["ticket_cost_price"] = formData["ticket_cost_price"];
        payload["ticket_attol_price"] = formData["ticket_attol_price"];
        payload["ticket_mis_cost"] = formData["ticket_mis_cost"];
        payload["status"] = "awaiting_vendor";

        let ticket_attol_price = formData["ticket_attol_price"];
        let ticket_mis_cost = formData["ticket_mis_cost"];
        let ticket_cost_price = formData["ticket_cost_price"];
        const tenDigitCode: any = generateRandom10DigitCode();
        if (ticket_attol_price) {
          ticket_attol_price = parseFloat(ticket_attol_price);
        } else {
          ticket_attol_price = 0;
        }
        if (ticket_mis_cost) {
          ticket_mis_cost = parseFloat(ticket_mis_cost);
        } else {
          ticket_mis_cost = 0;
        }

        const rawTransactions: any = [
          {
            brd_id: inquiry.brd_id,
            def_acc_id: 6,
            inq_id: inquiry.id,
            transactions_no: tenDigitCode,
            date: formData["issue_date"],
            type: "debit",
            amount: parseFloat(ticket_cost_price),
          },
          {
            brd_id: inquiry.brd_id,
            def_acc_id: 7,
            inq_id: inquiry.id,
            transactions_no: tenDigitCode,
            date: formData["issue_date"],
            type: "debit",
            amount: parseFloat(ticket_attol_price),
          },
          {
            brd_id: inquiry.brd_id,
            def_acc_id: 8,
            inq_id: inquiry.id,
            transactions_no: tenDigitCode,
            date: formData["issue_date"],
            type: "debit",
            amount: parseFloat(ticket_mis_cost),
          },
          {
            brd_id: inquiry.brd_id,
            def_acc_id: 2,
            user_id: formData["vendor_id"],
            inq_id: inquiry.id,
            transactions_no: tenDigitCode,
            date: formData["issue_date"],
            type: "credit",
            amount: -(
              parseFloat(ticket_cost_price) +
              parseFloat(ticket_attol_price) +
              parseFloat(ticket_mis_cost)
            ),
          },
        ];
        const transactions = rawTransactions.filter(
          (obj: any) => obj.amount !== 0
        );

        const res = await addTransaction({
          variables: {
            transactions: transactions,
          },
        });

        toast.success("Transaction added", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();
      } else {
        payload["status"] = "pending_approval";
      }
    } else if (status === "issued") {
      if (!selectedFile?.[0]?.file) {
        setIsloading(false);
        // toast.error("File not selected");
      }

      await Promise.all(
        selectedFile?.map(async (item: any) => {
          const res = await upload(item?.file);

          const res547 = await updateTicketFile({
            variables: {
              ticketFile: res?.id,
              id: item?.passengerID,
            },
          });
        })
      );

      payload["status"] = "issued";
    } else {
      payload["status"] = status;
    }

    try {
      //Edit Inquiry Function function call
      const res = await editInquiry({
        variables: { id: inquiry?.inq_tickets[0].id, inquiry: payload },
      });
      if (res.data?.update_inq_ticket?.returning?.length > 0) {
        toast.success("Ticket updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // if (payload["status"] === "issued") {
        //   const variables = {
        //     from: {
        //       th_name: inquiry?.brd_list?.name,
        //     },
        //     to: {
        //       email: inquiry?.users?.email,
        //     },
        //     variables: {},
        //   };
        //   const { emails, body, subject } =
        //     CustomerTemplates.sendTicketReadyNotification(variables);
        //   const emailResp = await sendEmail({
        //     variables: {
        //       emails: emails,
        //       body: body,
        //       subject,
        //     },
        //   });
        //   ;
        // }

        if (role === "consultant") {
          await alert.newNotification(
            "admin",
            `Early ticket issuance request is in the process by Admin`,
            "",
            ``
          );
        } else {
          await alert.newNotification(
            inquiry?.picked_by,
            `Early ticket issuance request is in the process by Admin`,
            "",
            ``
          );
        }

        await client.resetStore();
      }
      setIsloading(false);
      setHideForm(false); // hide form modal
    } catch (e) {
      setIsloading(false);
      setHideForm(false);
      toast.error("File not selected");
    }
  };

  const handleFileUpload = async (event: any, passengerID: any) => {
    const file = event.target.files[0];
    if (!file.type.includes("pdf")) {
      toast.error("Invalid File format");
    } else {
      setSelectedFile((prevFiles: any) => [
        ...prevFiles,
        { file: { file }, passengerID: passengerID },
      ]);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-[400px]">
      <>
        {inquiry?.inq_tickets[0]?.status === "pending_approval" ? (
          <PurchaseDetail
            ticketPurchase={ticketPurchase}
            setTicketPurchase={setTicketPurchase}
            register={register}
            errors={errors}
          />
        ) : (
          <>
            <div className="grid grid-cols-1 gap-4">
              <div className="">
                <label
                  htmlFor=""
                  className="text-[16px] text-primary dark:text-dark-primary font-medium mb-2 block"
                >
                  Select Vendor {"*"}
                </label>
                <select
                  {...register("vendor_id", {
                    required: "Vendor is required",
                  })}
                  defaultValue={inquiry?.inq_tickets[0]?.vendor_id}
                  aria-invalid={errors.vendor_id ? "true" : "false"}
                  className="w-full px-3 py-3 border rounded focus:outline-none dark:bg-dark-primary focus:border-basic leading-tight"
                >
                  <option value="">Select a Vendor</option>
                  {vendors.map((vendor: any) => {
                    return (
                      <option key={`${vendor?.id}`} value={`${vendor?.id}`}>
                        {vendor.displayName}
                      </option>
                    );
                  })}
                </select>
                {errors.vendor_id && (
                  <p role="alert" className="text-red-500 text-[12px] m-1">
                    {errors.vendor_id.message}
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <div className="">
                <label
                  htmlFor=""
                  className="text-[16px] text-primary dark:text-dark-primary font-medium mb-2 block"
                >
                  Cancelation charges {"*"}
                </label>
                <input
                  className="border py-3 h-[44px] px-[15px] text-[14px] rounded-[5px] dark:bg-dark-primary w-full focus:outline-none focus:border-basic"
                  type="number"
                  placeholder="Cancelation charges"
                  {...register("cancelation_charges", {
                    required: "Charges are required",
                    validate: validatecharge,
                  })}
                  defaultValue={inquiry?.inq_tickets[0]?.cancelation_charges}
                  aria-invalid={errors.cancelation_charges ? "true" : "false"}
                />
                {errors.cancelation_charges && (
                  <p role="alert" className="text-red-500 text-[12px] m-1">
                    {errors.cancelation_charges.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4">
              <InputField
                register={register}
                errors={errors}
                type="text"
                defaultValue={inquiry?.inq_tickets[0]?.pnr}
                fieldName="pnr_no"
                required={true}
                label=" PNR *"
                placeHolder="PNR"
                inputClasses="bg-white focus:outline-none focus:border-basic"
                labelClasses="font-medium mb-2"
              />
            </div>

            <div className="grid grid-cols-1 gap-4">
              <DateTimeBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName={`void_time`}
                label="Void Time *"
                defaultValue={inquiry?.inq_tickets[0]?.void_time}
                inputClasses={
                  "bg-white rounded-[5px] py-2 mt-[2px] dark:bg-dark-primary dark:text-dark-primary focus:outline-none focus:border-basic"
                }
                maxDate={moment(fareExpiry).format("YYYY-MM-DD")}
                labelClasses="text-primary text-[16px] mb-2 font-medium dark:text-dark-primary"
              />
            </div>

            <PurchaseDetail
              ticketPurchase={ticketPurchase}
              setTicketPurchase={setTicketPurchase}
              register={register}
              errors={errors}
              inquiry={inquiry}
            />
            <label className="mt-3">Status {"*"}</label>
            <select
              onChange={(e: any) => setStatus(e.target.value)}
              className="border py-[5px] h-[44px] px-[15px] text-[16px] dark:bg-dark-primary rounded-[5px] w-full focus:outline-none focus:border-basic"
              id=""
              defaultValue={inquiry?.inq_tickets[0]?.status}
            >
              <option value="awaiting_vendor">Awaiting Vendor</option>
              <option value="queued_pending">Queued Pending</option>
              <option value="queued">Queued</option>
              <option value="issued">Issued</option>
            </select>
            {status === "issued" && (
              <>
                {inquiry?.inq_tickets[0]?.inq_ticket_files?.map((item: any) => {
                  return (
                    <div className="pt-4">
                      <label>Ticket File {"*"}</label>
                      <input
                        id="fileUpload"
                        type="file"
                        onChange={(event: any) => {
                          handleFileUpload(event, item?.id);
                        }}
                        className="w-full px-2 py-2 border rounded dark:bg-dark-primary focus:outline-none focus:border-basic leading-tight border"
                      />
                      {item?.file_url && (
                        <p className="text-sm text-gray-500">
                          Previously uploaded: {item.id}
                        </p>
                      )}
                      {progress && <p>Upload Progress: {progress}%</p>}

                      <small className="text-[#b63434]">
                        Only PDF file is acceptable.
                      </small>
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}
        <button
          type="submit"
          className={`text-white px-5 py-2 font-medium rounded hover:cursor-pointer bg-basic w-full transition-all mt-[20px]`}
        >
          {isloading ? "Updating ..." : "Update"}
        </button>
      </>
    </form>
  );
}
