import { gql } from "@apollo/client";

export const GET_FL_COUNTRIES = gql`
  query getFlCountries {
    flGetCountries {
      countries {
        code
        name
      }
    }
  }
`;
