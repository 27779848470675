import { Button, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import Papa from "papaparse";
import { useState } from "react";
import { toast } from "react-toastify";
import { ADD_MAILJET_CSV_CONTACT_LIST } from "~/graphql/add_mailjet_csv_contactsList/Mutation";
import { useMutation } from "@apollo/client";


const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});



const UploadCSVFile = () => {

    const [loading, setLoading] = useState(false);
    const [addCsvContacts] = useMutation(ADD_MAILJET_CSV_CONTACT_LIST)

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file1 = e.target.files?.[0];
        if (!file1) return;

        setLoading(true); // Start loading

        Papa.parse(file1, {
            header: true,
            complete: async (results) => {
                try {
                    console.log("djsbcs", results)

                    // let contactsObject = results?.data

                    const contacts = results?.data
                        .filter((row: any) => row.Name && row.Email) // Remove rows with missing data
                        .map((row: any) => ({
                            Name: row.Name.trim(), // Trim whitespace
                            Email: row.Email.trim(),
                        }));

                    console.log("dshbhcs", contacts);


                    const res = await addCsvContacts({
                        variables: {
                            brdId: "43d895e7-d090-41c2-a261-508642f43d47",
                            listName: "Dummy list",
                            csvData: contacts,
                        }
                    })

                    console.log("sdhbsd", res)


                    // const payload = [
                    //     ...results?.data?.map((item: any) => ({
                    //         brd_season_id: item.SeasonId,
                    //         destination: item.Destination,
                    //         departure: item.Departure,
                    //         fares: parseFloat(item.Fares),
                    //         airline: item.Airline,
                    //         type: item.Type,
                    //         stops: item.Stops,
                    //         brd_id,
                    //     })),
                    // ];

                    // const res = await addBrdSeasFares({
                    //     variables: {
                    //         data: payload,
                    //     },
                    // });

                    // if (res?.data?.insert_brd_seasonal_fares?.affected_rows) {
                    //     toast.success(
                    //         `${res?.data?.insert_brd_seasonal_fares?.affected_rows} Seasonal Fares Added Successfully`
                    //     );
                    //     // Optionally, refetch data here
                    //     // refetchBrdSeasFares();
                    // } else {
                    //     toast.error("File is not Correct");
                    // }
                } catch (error) {
                    toast.error("An error occurred during the upload process.");
                } finally {
                    setLoading(false); // Stop loading
                }
            },
        });
    };

    return (
        <div>
            <Button
                component="label"
                variant="outlined"
                startIcon={loading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
                disabled={loading} // Disable the button while loading
            >
                {loading ? "Uploading..." : "Upload CSV File"}
                <VisuallyHiddenInput
                    type="file"
                    accept=".csv"
                    onChange={handleFileUpload}
                />
            </Button>
        </div>
    );
};

export default UploadCSVFile;
