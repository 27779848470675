// import { useApolloClient, useMutation } from "@apollo/client";
// import {
//   Button,
//   MenuItem,
//   Select,
//   TextField,
//   FormControl,
//   InputLabel,
// } from "@mui/material";
// import { useForm } from "react-hook-form";
// import { toast } from "react-toastify";
// import { INSERT_HOTEL } from "~/graphql/brd_hotels/Mutation";
// const AddHotel = ({ brd_id }: any) => {
//   const { register, handleSubmit, reset, setValue, watch } = useForm<any>();
//   const client = useApolloClient();
//   const [insertHotel, { loading, error }] = useMutation(INSERT_HOTEL);

//   const onSubmit = async (data: any) => {
//     try {
//       const response = await insertHotel({
//         variables: {
//           data: {
//             brd_id,
//             coordinates: data.coordinates,
//             name: data.name,
//             city: data.city,
//             price: data.price,
//           },
//         },
//       });

//       if (response?.data?.insert_brd_hotels?.affected_rows > 0) {
//         await client.refetchQueries({
//           include: "all",
//         });
//         toast.success("Hotel Package Added Successfully");
//         reset();
//       }
//     } catch (error) {
//       console.error("Error inserting hotel:", error);
//       toast.error("Error adding hotel package");
//     }
//   };

//   return (
//     <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
//       <div className="bg-secondary mb-4 sm:p-6 rounded-lg shadow dark:bg-dark-secondary">
//         <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
//           Add Hotel
//         </h1>
//         <form
//           onSubmit={handleSubmit(onSubmit)}
//           className="text-primary dark:text-dark-primary flex-col"
//         >
//           <div className="flex flex-col space-y-4">
//             <TextField
//               label="Name"
//               variant="outlined"
//               fullWidth
//               required
//               {...register("name")}
//               className="dark:text-dark-primary"
//             />

//             <TextField
//               label="Coordinates"
//               variant="outlined"
//               fullWidth
//               required
//               {...register("coordinates")}
//               className="dark:text-dark-primary"
//             />

//             <FormControl fullWidth>
//               <InputLabel id="city-label">City</InputLabel>
//               <Select
//                 labelId="city-label"
//                 {...register("city")}
//                 required
//                 value={watch("city") || ""}
//                 onChange={(e) => setValue("city", e.target.value)}
//                 className="dark:text-dark-primary"
//               >
//                 <MenuItem value="makkah">Makkah</MenuItem>
//                 <MenuItem value="madinah">Madinah</MenuItem>
//               </Select>
//             </FormControl>
//             <TextField
//               label="Price Per Night"
//               variant="outlined"
//               fullWidth
//               required
//               type="number"
//               {...register("price")}
//               className="dark:text-dark-primary"
//             />
//             <Button
//               type="submit"
//               variant="contained"
//               color="primary"
//               disabled={loading}
//             >
//               {loading ? "Adding..." : "Add"}
//             </Button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default AddHotel;

import { useApolloClient, useMutation } from "@apollo/client";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { INSERT_HOTEL } from "~/graphql/brd_hotels/Mutation";
import { nhost } from "~/lib/nhost";

const AddHotel = ({ brd_id }: { brd_id: string }) => {
  const { register, handleSubmit, reset, setValue, watch } = useForm<any>();
  const client = useApolloClient();
  const [insertHotel, { loading }] = useMutation(INSERT_HOTEL);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  const onSubmit = async (data: any) => {
    try {
      // Upload images to Nhost and get their IDs
      const uploadedImageIds = await Promise.all(
        selectedImages.map(async (file) => {
          const response = await nhost.storage.upload({ file });
          if (response.error) throw new Error(response.error.message);
          return response.fileMetadata.id;
        })
      );

      // Prepare gallery data
      const galleries = uploadedImageIds.map((img_id) => ({
        img_id,
      }));

      const response = await insertHotel({
        variables: {
          data: {
            brd_id,
            coordinates: data.coordinates,
            name: data.name,
            city: data.city,
            price: parseFloat(data.price), // Convert price to a number
            brd_hotels_galleries: { data: galleries }, // Add images to galleries
          },
        },
      });

      if (response?.data?.insert_brd_hotels?.affected_rows > 0) {
        await client.refetchQueries({ include: "all" });
        toast.success("Hotel added successfully");
        reset();
        setSelectedImages([]);
      }
    } catch (error) {
      console.error("Error adding hotel:", error);
      toast.error("Failed to add hotel");
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedImages(Array.from(event.target.files));
    }
  };

  const handleImageDelete = (index: number) => {
    setSelectedImages((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <div className="bg-secondary mb-4 sm:p-6 rounded-lg shadow dark:bg-dark-secondary">
        <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
          Add Hotel
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="text-primary dark:text-dark-primary flex-col"
        >
          <div className="flex flex-col space-y-4">
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              required
              {...register("name")}
            />
            <TextField
              label="Coordinates"
              variant="outlined"
              fullWidth
              required
              {...register("coordinates")}
            />
            <FormControl fullWidth>
              <InputLabel id="city-label">City</InputLabel>
              <Select
                labelId="city-label"
                {...register("city")}
                required
                value={watch("city") || ""}
                onChange={(e) => setValue("city", e.target.value)}
              >
                <MenuItem value="makkah">Makkah</MenuItem>
                <MenuItem value="madinah">Madinah</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Price Per Night"
              variant="outlined"
              fullWidth
              required
              type="number"
              {...register("price")}
            />
            <div>
              <label
                htmlFor="images"
                className="block text-primary dark:text-dark-primary mb-2"
              >
                Upload Images
              </label>
              <input
                type="file"
                id="images"
                multiple
                accept="image/*"
                onChange={handleImageChange}
                className="block w-full"
              />
              <div className="grid grid-cols-3 gap-3 mt-3">
                {selectedImages.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Selected"
                      className="w-full h-auto rounded"
                    />
                    <button
                      type="button"
                      onClick={() => handleImageDelete(index)}
                      className="absolute top-0 right-0 bg-basic flex items-center w-5 h-5 justify-center text-white p-1 rounded-full"
                    >
                      ✕
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? "Adding..." : "Add"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddHotel;
