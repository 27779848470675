import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { useState } from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { GET_ACTIVITY } from "./graphql/Query";
import { getTimeReadableFormatInq } from "~/utils/GetTimeReadable";
import WhatsappMessage from "./components/WhatsappMessage";
import InquiryCard from "~/modules/InquiriesModule/components/InquiryCard";
import SuggestionBox from "~/modules/InquiriesModule/segments/InquiryDetail/components/SuggestionCard/components/SuggestionBox";
import UserName from "./components/UserName";
import ComposeEmail from "~/components/SideForm/ComposeEmail";
import { GET_BRAND_DETAIL } from "~/graphql/brd_list/Query";
import SidePopup from "~/components/common/SidePopup";
import WhatsappSend from "~/components/SideForm/WhatsappSend";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import AddSugesstion from "~/components/common/AddSugesstion";
import { ADD_SUGGESTION } from "~/modules/InquiriesModule/segments/InquiryDetail/components/SuggestionCard/components/AddSuggestion/components/SuggestionForm/graphql/Mutation";
import { formatData } from "~/modules/InquiriesModule/segments/InquiryDetail/components/SuggestionCard/components/AddSuggestion/utils/formatData";
import { useUserDefaultRole, useUserDisplayName } from "@nhost/react";
import useEmail from "~/hooks/emails/useEmail";
import { toast } from "react-toastify";
import useNotifications from "~/hooks/useNotifications";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import moment from "moment";
import { ADD_PASSENGER } from "~/modules/InquiriesModule/segments/InquiryDetail/components/PassengersCard/graphql/Mutation";
import AddPaymentRequest from "~/components/common/CreatePaymentRequest";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import AddPassenger from "~/components/common/AddPassenger";
import PassengerBox from "~/modules/InquiriesModule/segments/InquiryDetail/components/PassengersCard/components/PassengerBox";
import InvoiceCard from "~/modules/InquiriesModule/segments/InquiryDetail/components/Invoices/components/InvoiceCard";
import PaymentCard from "~/modules/InquiriesModule/segments/InquiryDetail/components/PaymentsCard/components/PaymentCard";
import TicketRequestCard from "~/modules/InquiriesModule/segments/InquiryDetail/components/TicketRequest/components/TicketRequestCard";

export default function CustomerTimeline({ brd_id, user_id }: any) {
  const [compose, setCompose] = useState(false);
  const [isLoading, setIsloading] = useState<any>(false);

  const alert = useNotifications();
  const client = useApolloClient();

  const [addPassenger] = useMutation(ADD_PASSENGER);
  const userRole = useUserDefaultRole();

  const { data, loading, error } = useSubscription(GET_ACTIVITY, {
    variables: { user_id, brd_id },
  });

  const {
    data: cd,
    loading: cl,
    error: ce,
  } = useQuery(GET_BRAND_DETAIL, {
    variables: {
      brd_id,
    },
  });

  const dataList = data?.brd_notifications;

  const dataListNew = dataList?.sort((a: any, b: any) =>
    b.created_at.localeCompare(a.created_at)
  );

  const [addSuggestion] = useMutation(ADD_SUGGESTION);
  const userName = useUserDisplayName();
  const emailSend = useEmail();

  const createSuggestion = async (
    formData: any,
    inquiry: any,
    setSidePopup: any
  ) => {
    // ;
    formData["inq_id"] = inquiry.id;
    // ;

    setIsloading(true);
    if (!isLoading) {
      try {
        // Create Suggestion function call
        const res = await addSuggestion({
          variables: { suggestions: formatData(formData, null) },
        });

        // ;

        if (res.data?.insert_inq_suggestion?.returning?.length > 0) {
          const variables = {
            from: {
              th_name: inquiry?.brd_list.name,
            },
            to: {
              email: inquiry?.users?.email,
            },
            variables: {
              phone: inquiry?.users?.phoneNumber,
              url: inquiry.brd_list.subdomain,
            },
          };

          const emailVariables: any = {
            inquiryNo: inquiry?.inquiry_no,
            brandName: inquiry?.brd_list?.name,
            userFirstName: inquiry?.users?.displayName,
            userLastName: "",
            consultantName: userName,
          };

          // ;
          await emailSend(
            3,
            inquiry.brd_id,
            inquiry.users?.id,
            inquiry.users?.email,
            inquiry.users?.phoneNumber,
            emailVariables
          );

          toast.success(`Suggestions added successfully`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          await alert.newNotification(
            inquiry?.users?.id,
            `Your consultant added a suggestion Please review in the portal.`,
            "",
            ``
          );

          await client.refetchQueries({
            include: "all", // Consider using "active" instead!
          });
        }
        setIsloading(false); // change button loading state
        setSidePopup(false);
      } catch (e) {
        setIsloading(false);
      }
    }
  };

  const submitPassengers = async (
    formData: any,
    inquiry: any,
    setSidePopup: any
  ) => {
    // ;
    formData["inq_id"] = inquiry.id;
    formData["nationality"] = formData.nationality.code;
    formData["passport_expiry"] = formData?.passport_expiry
      ? moment(formData.passport_expiry).format("YYYY-MM-DD HH:mm:ss")
      : null;
    formData["dob"] = formData?.dob
      ? moment(formData.dob).format("YYYY-MM-DD HH:mm:ss")
      : null;

    try {
      // Create Suggestion function call
      const res = await addPassenger({
        variables: { passengers: [{ ...formData }] },
      });
      if (res.data?.insert_inq_passengers?.returning?.length > 0) {
        toast.success("Passenger added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        if (userRole === "user") {
          await alert.newNotification(
            inquiry?.picked_by,
            `Your customer has add new passenger. Please check your portal`,
            "",
            ``
          );
        } else {
          await alert.newNotification(
            user_id,
            `Your consultant has add new passenger. Please check your portal`,
            "",
            ``
          );
        }

        await client.resetStore();
      }
      //   setIsloading(false); // change button loading state
      setSidePopup(false); // hide form modal
    } catch (e) {}
  };

  if (loading) return <div>loading..</div>;
  if (error) return <div>error {error.message} </div>;

  if (cl) return <div>loading..</div>;
  if (ce) return <div>error {ce.message} </div>;

  return (
    <div className="flex flex-col">
      {dataListNew?.map((noti: any) => {
        return (
          <TimelineItem position={noti.user_id === user_id ? "right" : "left"}>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {getTimeReadableFormatInq(noti?.created_at)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="h6" component="span">
                <span className="text-[gray] text-sm w-full">
                  {noti?.template?.name || "not set"} by{" "}
                  {noti?.sender?.thp_users_profile?.[0]?.pseudo_name ||
                    noti?.sender?.displayName ||
                    noti?.send_by ||
                    "Brand"}
                </span>
              </Typography>

              {/* <div
                className={`flex  ${
                  noti.user_id === user_id ? "justify-start" : "justify-end"
                }`}
              > */}
              {(noti?.template?.id === 35 ||
                noti?.template?.id === 34 ||
                noti?.template?.id === 15 ||
                noti?.template?.id === 36) && (
                <InquiryCard
                  inquiry={noti?.payload}
                  header={false}
                  height="auto"
                />
              )}
              {(noti?.template?.id === 10 || noti?.template?.id === 28) && (
                <TicketRequestCard inquiry={noti?.payload} hideActions={true} />
              )}

              {(noti?.template?.id === 29 ||
                noti?.template?.id === 9 ||
                noti?.template?.id === 23 ||
                noti?.template?.id === 22 ||
                noti?.template?.id === 43 ||
                noti?.template?.id === 8) && (
                <PaymentCard transaction={noti?.payload} hideActions={true} />
              )}

              {(noti?.template?.id === 14 ||
                noti?.template?.id === 13 ||
                noti?.template?.id === 44) && (
                <InvoiceCard invoice={noti?.payload} hideActions={true} />
              )}

              {noti?.template?.id === 19 && (
                <PassengerBox passenger={noti?.payload} hideActions={true} />
              )}

              {(noti?.template?.id === 39 ||
                noti?.template?.id === 5 ||
                noti?.template?.id === 6 ||
                noti?.template?.id === 4 ||
                noti?.template?.id === 3) && (
                <div className="w-[auto]">
                  <SuggestionBox
                    active={true}
                    suggestion={noti?.payload}
                    hideActions={true}
                  />
                </div>
              )}

              {(noti?.template?.id === 38 ||
                noti?.template?.id === 37 ||
                noti?.template?.id === 41 ||
                noti?.template?.id === 42 ||
                noti?.template?.id === 3 ||
                noti?.template?.id === 11 ||
                noti?.template?.id === 1 ||
                noti?.template?.id === 5 ||
                noti?.template?.id === 6 ||
                noti?.template?.id === 14 ||
                noti?.template?.id === 43 ||
                noti?.template?.id === 8 ||
                noti?.template?.id === 9 ||
                noti?.template?.id === 10) &&
                noti?.payload?.text && (
                  <div className="bg-[white] w-full p-4 border-[lgihtgray] border-[1px] rounded-md justify-start flex flex-col">
                    {/* <span>{noti.payload.text}</span> */}
                    <WhatsappMessage payload={noti?.payload} />
                    {noti?.payload?.sender_id && (
                      <UserName user_id={noti.payload?.sender_id} />
                    )}
                  </div>
                )}
              {/* </div> */}
            </TimelineContent>
          </TimelineItem>
        );
      })}
      {dataListNew.length < 1 && <div>No data found</div>}

      <SidePopup
        popupType="speedDial"
        component={[
          {
            name: "createPaymentRequest",
            icon: <PaymentOutlinedIcon />,
            tooltip: "Create Payment Request",
            address: (
              <AddPaymentRequest
                mainHeading="Create Payment Request"
                userID={user_id}
                showUser={true}
              />
            ),
          },
          {
            name: "addSuggestion",
            icon: <PostAddOutlinedIcon />,
            tooltip: "Add Fare Option",
            address: (
              <AddSugesstion
                mainHeading="Create Suggestion"
                userID={user_id}
                showUser={true}
                isLoading={isLoading}
                suggestionSend={createSuggestion}
                type="createUpdate"
              />
            ),
          },
          {
            name: "addPassenger",
            icon: <PersonAddAltOutlinedIcon />,
            tooltip: "Add Passenger",
            address: (
              <AddPassenger
                submitPassengers={submitPassengers}
                mainHeading="Add Passenger"
                userID={user_id}
                showUser={true}
              />
            ),
          },
          {
            name: "sendWhatsapp",
            icon: <WhatsAppIcon />,
            tooltip: "Send Whatsapp",
            address: (
              <WhatsappSend
                user_phone={
                  data?.brd_notifications?.[0]?.activity_user?.phoneNumber
                }
                brand_token={cd?.brd_list?.[0].whatsapp_token}
                brand_phone={cd?.brd_list?.[0].phoneNumberId}
              />
            ),
          },
          {
            name: "sendEmail",
            icon: <EmailOutlinedIcon />,
            tooltip: "Send Email",
            address: (
              <ComposeEmail
                gmail_refresh_token={cd?.brd_list?.[0].gmail_refresh_token}
                user_email={data?.brd_notifications?.[0]?.activity_user?.email}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
