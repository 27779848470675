import { gql } from "@apollo/client";

export const GET_INQ_SUGGESTION = gql`
  query getInqSuggestion($condition: inq_suggestion_bool_exp!) {
    inq_suggestion(where: $condition) {
      id
      airline_iata
      inq_suggestion_costs {
        sale_price
      }
      inq_suggestion_legs {
        cabin
        type
        inq_suggestion_leg_stops {
          depart
          arrival
        }
      }
    }
  }
`;

export const GET_FARES_WITH_ID = gql`
  query GetFares($brdId: uuid) {
    inq_suggestion(where: { brd_id: { _eq: $brdId } }) {
      brd_id
      id
      inq_suggestion_costs {
        sale_price
        basic_fare
      }
      inq_suggestion_legs {
        cabin
        id
        type
        inq_suggestion_leg_stops {
          airline_iata
          arrival
          arrival_datetime
          depart
          depart_datetime
          flight_no
          id
        }
      }
    }
  }
`;

export const GET_SEASONAL_FARES_SEASON_ID = gql`
  query getSeasonalFaresSeasonId($brdId: uuid) {
    inq_suggestion(
      where: { brd_id: { _eq: $brdId }, season_id: { _is_null: false } }
    ) {
      brd_id
      id
      season_id
      brd_season {
        name
        id
      }
      inq_suggestion_costs {
        sale_price
        basic_fare
        id
      }
      inq_suggestion_legs {
        id
        cabin
        type
        inq_suggestion_leg_stops {
          airline_iata
          arrival
          arrival_datetime
          depart
          depart_datetime
          flight_no
          id
          ordering
        }
      }
    }
  }
`;
