import React, { useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import { useForm } from "react-hook-form";
// import { GET_DOMAINS_BRANDS, UPDATE_BRAND_WITH_DOMAIN } from "~/modules/BrandModule/graphql/Mutation";

import {
  ADD_DOMAIN_VERCEL,
  UPDATE_BRAND_WITH_DOMAIN,
} from "../../../graphql/Mutation";

import { SEND_EMAIL } from "~/config/Mutation";
import { AdminTemplates } from "~/utils/TemplatesAll";
import { sendGenericEmail } from "~/utils/sendEmail";
import {
  GET_EMAILS,
  GET_TRAVEL_HOUSE_BY_ID,
} from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import { useUserData } from "@nhost/react";

const AddBrand = ({ brand, adminUsers }: any) => {
  const [savingLoading, setSavingLoading] = useState(false);
  const { travelHouse }: any = travelHouseStore();
  const [sendEmail] = useMutation(SEND_EMAIL);
  const formMethods = useForm();
  const { register, handleSubmit, reset } = formMethods;
  const userData = useUserData();

  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );
  const [updateBrandWithDomain] = useMutation(UPDATE_BRAND_WITH_DOMAIN);
  const client = useApolloClient();
  const onSubmit = async (formData: any) => {
    setSavingLoading(true);
    // try {
    const responseADDDomain = await client.query({
      query: ADD_DOMAIN_VERCEL,
      variables: {
        domain: formData.domain,
      },
    });

    // const sendGridDns = responseADDDomain.data.addDomainVercel.sendGrid.dns;
    // const sendGridId = responseADDDomain.data.addDomainVercel.sendGrid.id;

    //

    const response = await updateBrandWithDomain({
      variables: {
        brandId: brand.id,
        domain: formData.domain,
        // sendgrid_domain_id: sendGridId,
      },
    });

    await client.resetStore();

    // const sendGridDnsDetails = `
    //       - Type: ${sendGridDns.dkim1.type}
    //       - Name: ${sendGridDns.dkim1.host}
    //       - Target: ${sendGridDns.dkim1.data}

    //       - Type: ${sendGridDns.dkim2.type}
    //       - Name: ${sendGridDns.dkim2.host}
    //       - Target: ${sendGridDns.dkim2.data}

    //       - Type: ${sendGridDns.mail_cname.type}
    //       - Name: ${sendGridDns.mail_cname.host}
    //       - Target: ${sendGridDns.mail_cname.data}
    //       `;

    //   adminUsers.forEach(async (email: any) => {
    //     const variables = {
    //       to: {
    //         email: email,
    //       },
    //       variables: {
    //         portalDomain: formData.domain,
    //         sendGridDnsDetails: sendGridDnsDetails,
    //       },
    //     };
    //     const { emails, body, subject } =
    //       AdminTemplates.AddDnsRecordsSG(variables);

    //     try {
    //       const emailData = {
    //         emails: emails,
    //         body: body,
    //         subject,
    //         from: "info@" + response?.data?.update_brd_list_by_pk?.domain,
    //       };
    //       if (travelHouseData?.thp_list[0]?.email_refresh_token) {
    //         const response = await client.query({
    //           query: GET_EMAILS,
    //           variables: {
    //             refresh_token: travelHouseData?.thp_list[0]?.email_refresh_token,
    //             sendEmail: true,
    //             to: emails,
    //             subject: subject,
    //             body: body,
    //             from: userData?.metadata?.alias,
    //             th_id: travelHouse?.id,
    //             alias: false,
    //           },
    //         });
    //       } else {
    //         const emailResp = await sendGenericEmail(emailData, sendEmail);
    //       }

    //       // Handle success or do any additional logic here
    //     } catch (error) {
    //       // Handle error
    //
    //     }
    //   }
    // );
    toast.success("Domain updated successfully for the brand!");
    setSavingLoading(false);
    reset();
    // } catch (error: any) {
    //   toast.error(`Error updating domain`);
    //   setSavingLoading(false);
    //   reset();
    // }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="domain_verified"
            className="block mb-2 text-sm font-medium text-green-700 dark:text-green-500"
          >
            Brand Domain
          </label>

          <div className="flex">
            <span className="inline-flex items-center px-3 text-sm text-green-900 bg-green-500 border border-r-0 border-green-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-900 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 18"
              >
                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"></path>
              </svg>
            </span>
            <input
              type="text"
              {...register(`domain`, {
                required: true,
              })}
              id="domain_verified"
              className="bg-green-50 border border-green-500 text-green-900 placeholder-green-700 text-sm rounded-none rounded-r-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-green-100 dark:border-green-400"
              placeholder="Add Domain"
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-full">
          <button
            type="submit"
            className="text-white bg-blue-700 cursor-pointer hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            {savingLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddBrand;
