import React from "react";
import PackageDetail from "./components/PackageDetail";
import { GET_UMRAH_PACKAGES_WITH_ID } from "~/graphql/brd_umrah_packages/Query";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import BrandSeoSetting from "~/components/common/BrandSeoSetting";
import { toast } from "react-toastify";
import {
  INSERT_SEO,
  UPDATE_SEO,
} from "../../../DestinationUpdate/components/SeoSettings/graphql/Mutation";

const UmrahPackageUpdate = () => {
  const { pkg_Id, brd_id } = useParams();

  const { data, loading } = useQuery(GET_UMRAH_PACKAGES_WITH_ID, {
    variables: {
      id: pkg_Id,
    },
  });

  const [insertSeo] = useMutation(INSERT_SEO);
  const [updateSeo] = useMutation(UPDATE_SEO);

  const client = useApolloClient();

  const insertSeoSettings = async (inpData: any) => {
    if (data?.brd_umrah_packages?.[0].brd_seo_settings?.[0]?.id) {
      const res = await updateSeo({
        variables: {
          id: { id: data?.brd_umrah_packages?.[0].brd_seo_settings?.[0]?.id },
          data: {
            default_title: inpData?.title,
            default_description: inpData?.description,
            default_keywords: inpData?.keywords,
            canonical_url: inpData?.canonicalUrl,
            og_image_url: inpData?.openGraphImgUrl,
          },
        },
      });

      if (res?.data?.update_brd_seo_setting_by_pk?.id) {
        toast.success("SEO  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertSeo({
        variables: {
          data: {
            brd_id: brd_id,
            default_title: inpData?.title,
            default_description: inpData?.description,
            default_keywords: inpData?.keywords,
            canonical_url: inpData?.canonicalUrl,
            og_image_url: inpData?.openGraphImgUrl,

            umrahPackage_id: data?.brd_umrah_packages?.[0]?.id,
          },
        },
      });

      if (res?.data?.insert_brd_seo_setting_one?.id) {
        toast.success("SEO  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <PackageDetail pkgData={data?.brd_umrah_packages?.[0]} />
      <BrandSeoSetting
        seo={data?.brd_umrah_packages?.[0].brd_seo_settings?.[0]}
        insertSeoSettings={insertSeoSettings}
        pageType="Umrah Package Page"
      />
    </div>
  );
};

export default UmrahPackageUpdate;
