import { gql } from "@apollo/client";

export const INSERT_BRD_VISAS_TYPES = gql`
  mutation InsertBrdVisasTypes($data: [brd_visa_types_insert_input!]!) {
    insert_brd_visa_types(objects: $data) {
      affected_rows
    }
  }
`;
export const DELETE_BRD_VISAS_TYPES_WITH_ID = gql`
  mutation DeleteBrdVisasTypesWithId($id: uuid!) {
    delete_brd_visa_types(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
