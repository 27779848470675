import { gql } from "@apollo/client";

export const INSERT_SUGGESTION = gql`
  mutation InsertSuggestion($data: inq_suggestion_insert_input!) {
    insert_inq_suggestion_one(object: $data) {
      id
    }
  }
`;

export const UPDATE_SUGGESTION = gql`
  mutation UpdateSuggestion($id: uuid!, $data: inq_suggestion_set_input!) {
    update_inq_suggestion(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;
