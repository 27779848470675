import React, { useEffect, useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { DELETE_SUGGESTION } from "../graphql/Mutation";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import moment from "moment";
function capitalizeFirstLetter(string: String) {
  if (!string) return ""; // Return empty string if input is null/undefined/empty
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const TrainSuggestionBox = ({
  suggestion,
  selected_suggestion_id,
  selected,
  active,
  picked_by,
  user_email,
  brand_domain,
  brand_url,
  brand_name,
  user_id,
  inquiry,
  hideActions,
  setActive,
}: any) => {

  const [showAddModal, setShowAddModal] = useState<any>(false);
  const [showCostBreakdownModal, setShowCostBreakdownModal] = useState(false);
  const [deleteSuggestion] = useMutation(DELETE_SUGGESTION);
  const [wait, setWait] = useState(false);
  const client = useApolloClient();

  const userRole = useUserDefaultRole();
  const userLoggedIn = useUserId();
  useEffect(() => {
    setActive(suggestion.id);
  }, [suggestion]);
  const totalCost =
    suggestion?.train_inq_suggestion_costs?.reduce((sum: any, obj: any) => {
      return (
        sum +
        (parseFloat(obj.basic_fare) || 0) + // Safely parse basic fare
        (parseFloat(obj.tax_price) || 0) // Safely parse tax price
      );
    }, 0) || 0; // Default to 0 if undefined

  const totalSale =
    suggestion?.train_inq_suggestion_costs?.reduce((sum: any, obj: any) => {
      return sum + (parseFloat(obj.sale_price) || 0); // Safely parse sale price
    }, 0) || 0; // Default to 0 if undefined

  return (
    <>
      {active && (
        <div className="w-full">
          <div
            className={` ${active ? `show` : `hide`
              } py-[25px] px-[20px] bg-white dark:bg-dark-secondary overflow-hidden flex flex-col gap-[20px] w-full text-left text-xs text-darkslategray shadow`}
          >
            {suggestion ? (
              <div className="w-full flex flex-col gap-[20px]">
                <div className="flex justify-between w-full">
                  <div className="text-basic dark:text-dark-primary w-full">
                    <b>ORIGIN: </b>
                    <b>{capitalizeFirstLetter(suggestion.origin)}</b>
                  </div>
                  <div className="text-basic dark:text-dark-primary w-full text-right">
                    <b>DESTINATION: </b>
                    <b>
                      {capitalizeFirstLetter(suggestion?.destination) || "N/A"}
                    </b>
                  </div>
                </div>
                <div className="flex justify-start w-full">
                  <div className="text-basic dark:text-dark-primary w-full">
                    <b>CABIN CLASS: </b>
                    <b>{capitalizeFirstLetter(suggestion.cabin_class)}</b>
                  </div>
                  <div className="text-basic dark:text-dark-primary w-full text-right">
                    <b>TRAIN NAME: </b>
                    <b>{capitalizeFirstLetter(suggestion.train_name)}</b>
                  </div>
                </div>
                <div className="flex justify-start w-full">
                  <div className="text-basic dark:text-dark-primary w-full">
                    <b>PNR:</b>
                    <b>{capitalizeFirstLetter(suggestion.pnr)}</b>
                  </div>
                </div>

                <div className="flex justify-between items-center w-full gap-4">
                  {/* Start date on the left */}
                  <div className="font-medium text-xl text-basic dark:text-dark-primary">
                    {moment(suggestion.start_date).zone(0).format("DD-MM-YYYY")}
                  </div>
                  <div className="font-medium text-xl text-basic dark:text-dark-primary">
                    {moment(suggestion.time).format("HH:mm")}
                  </div>

                  {/* End date on the right */}
                  <div className="font-medium text-xl text-basic dark:text-dark-primary text-right">
                    {moment(suggestion.end_date).zone(0).format("DD-MM-YYYY")}
                  </div>
                </div>
              </div>
            ) : (
              <p>No suggestions available</p>
            )}
          </div>

          <div className="flex items-center justify-end h-24 rounded-b-lg bg-basic flex-1 px-[20px] w-full">
            <div className="flex justify-end gap-10 w-full">
              <div className="text-white flex gap-1 items-center">
                <strong>Cost:</strong>
                <div className="relative inline-block shrink-0 text-[20px] font-bold">
                  £{totalCost}
                </div>
              </div>
              <div className="text-white flex gap-1 items-center">
                <strong>Sale:</strong>
                <div className="relative inline-block shrink-0 text-[20px] font-bold">
                  £{totalSale}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TrainSuggestionBox;
