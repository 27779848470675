import moment from "moment";

export const carFormatData = (formData: any, pdfId: any) => {
  const stayId = formData?.car_inq_details?.inq_id;

  if (!stayId) {
    throw new Error(
      "Car Inquiry ID is missing. Cannot proceed with formatting data."
    );
  }

  return [
    {
      car_inq_id: stayId,
      origin: formData?.cars?.pickUp,
      destination: formData?.cars?.dropOff,
      start_date: moment(formData?.cars?.dates[0]?.$d).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      end_date: moment(formData?.cars?.dates[1]?.$d).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      car_type: formData?.cars?.carType,
      pnr: formData?.pnr,
      car_suggestion_pdf: pdfId,
      car_inq_suggestion_costs: {
        data: [...(formData?.suggestion_cost || [])],
      },
    },
  ];
};

export const getDate = (dateInp: any) => {
  // const newDate = new Date();

  const dateString = dateInp;
  const dateParts = dateString.split("/");
  const timeParts = dateParts[2].split(" ");

  // Extract the date components
  const day = parseFloat(dateParts[0]);
  const month = parseFloat(dateParts[1]) - 1; // Months are zero-based (0-11)
  const year = parseFloat(timeParts[0]);

  // Extract the time components
  const time = timeParts[1].split(":");
  const hour = parseFloat(time[0]);
  const minute = parseFloat(time[1]);

  // Create the Date object
  const dateObj = new Date(year, month, day, hour, minute);
  return dateObj;
};
