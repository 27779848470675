import moment from "moment";

export const stayFormatData = (formData: any, pdfId: any, inquiry: any) => {
  const stayId = formData?.stay_inq_details?.inq_id;

  if (!stayId) {
    throw new Error(
      "Stay Inquiry ID is missing. Cannot proceed with formatting data."
    );
  }

  return [
    {
      stay_inq_id: stayId,
      where_to: formData?.stay?.whereTo,
      start_date: moment(formData?.stay?.dates[0]?.$d).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      end_date: moment(formData?.stay?.dates[1]?.$d).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      hotel_name: formData?.stay?.HotelName,
      room_type: formData?.stay?.roomType,
      pnr: formData?.pnr,
      stay_suggestion_pdf: pdfId,
      stay_inq_suggestion_costs: {
        data: [...(formData?.suggestion_cost || [])],
      },
    },
  ];
};
