import { Divider, Button } from "@mui/material";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker, DatePicker } from "@mui/x-date-pickers-pro";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import CityBox from "~/components/FormInputs/CityBox/CityBox";
import { UPDATE_CAR_INQUIRY_DETAIL } from "~/graphql/car_inq_detail/Mutation";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { toast } from "react-toastify";

const EditCar = ({
  setSidePopup,
  carId,
  origin,
  destination,
  pickDateTime,
  dropDateTime,
  withDriver,
}: any) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      cars: {
        pickUp: origin || "",
        DropOff: destination || "",
        pickupDateTime: pickDateTime
          ? dayjs(new Date(pickDateTime).toISOString().slice(0, 16))
          : null,
        dropOffDateTime: dropDateTime
          ? dayjs(new Date(dropDateTime).toISOString().slice(0, 16))
          : null,
        withDriver: withDriver || false,
      },
    },
  });

  const [updateCarDetail] = useMutation(UPDATE_CAR_INQUIRY_DETAIL);

  const onSubmit = async (data: any) => {
    try {
      const res = await updateCarDetail({
        variables: {
          id: carId,
          origin: data?.cars?.pickUp,
          destination: data?.cars?.DropOff,
          pickDateTime: moment(data?.cars?.pickupDateTime?.$d).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          dropDateTime: moment(data?.cars?.dropOffDateTime?.$d).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          withDriver: data?.cars?.withDriver,
        },
      });

      if (res?.data?.update_car_inq_detail?.affected_rows > 0) {
        toast.success("Car Details Updated Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setSidePopup(false);
      }
    } catch (err) {
      toast.error("Error Updating Car Details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log("Error: ", err);
    }
  };

  return (
    <div className="min-w-[500px] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-center text-2xl text-basic dark:text-dark-primary">
          Edit Car Details
        </h2>
        <Divider />
        <div className="grid grid-cols-1 mt-10 gap-5">
          <Controller
            control={control}
            name="cars.pickUp"
            rules={{ required: "This is required" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <CityBox
                  value={value}
                  onChange={onChange}
                  setValue={setValue}
                  fieldName="pickUp"
                  label="Pick up"
                  errors={errors}
                />
                {error && (
                  <span className="text-red-500 text-xs">{error.message}</span>
                )}
              </>
            )}
          />
          <Controller
            control={control}
            name="cars.DropOff"
            rules={{ required: "This is required" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <CityBox
                  value={value}
                  onChange={onChange}
                  setValue={setValue}
                  fieldName="cars.DropOff"
                  label="Drop Off"
                  errors={errors}
                />
                {error && (
                  <span className="text-red-500 text-xs">{error.message}</span>
                )}
              </>
            )}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <Controller
                control={control}
                name="cars.pickupDateTime"
                rules={{ required: "Pick-up date is required" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      value={field.value}
                      onChange={(date) => field.onChange(date)}
                      sx={{ width: "100%" }}
                      label="Pick-Up Date"
                    />
                    {error && (
                      <span className="text-red-500 text-xs">
                        {error.message}
                      </span>
                    )}
                  </>
                )}
              />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <Controller
                control={control}
                name="cars.dropOffDateTime"
                rules={{ required: "Drop-off date is required" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      value={field.value}
                      onChange={(date) => field.onChange(date)}
                      sx={{ width: "100%" }}
                      label="Drop-Off Date"
                    />
                    {error && (
                      <span className="text-red-500 text-xs">
                        {error.message}
                      </span>
                    )}
                  </>
                )}
              />
            </DemoContainer>
          </LocalizationProvider>


          <FormGroup className="">
            <FormControlLabel
              control={
                <Checkbox
                  {...register("cars.withDriver")}
                  color="primary"
                  defaultChecked={withDriver === "Yes" ? true : false}
                  onChange={(e) =>
                    setValue("cars.withDriver", e.target.checked)
                  }
                />
              }
              label="With Driver"
            />
          </FormGroup>
        </div>
        <div className="mt-5">
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditCar;
