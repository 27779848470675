import React, { useEffect, useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  CAR_ADD_SUGGESTION_COST,
  CAR_EDIT_SUGGESTION_COST,
  CAR_UPDATE_SUGGESTION,
} from "../../EditSuggestion/components/EditSuggestionForm/graphql/Mutation";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { FormControl } from "@mui/material";
import CityBox from "~/components/FormInputs/CityBox/CityBox";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NewPassengerEditCost from "../components/EditSuggestionForm/components/NewPassengerEditCost";

import dayjs, { Dayjs } from "dayjs";
import { useUserDisplayName, useUserId } from "@nhost/react";
import useEmail from "~/hooks/emails/useEmail";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import useNotifications from "~/hooks/useNotifications";
import { toast } from "react-toastify";
import { CarTypeSelector } from "../../../../AddSuggestion/components/SuggestionForm/Cars/components/CarType";
import CarPassengerEditCost from "../components/EditSuggestionForm/components/NewPassengerEditCost/CarPassengerEditCost";

const EditCarSuggestionBox = ({
  defaultValue,
  inquiry_id,
  inquiry,
  suggestion,
  user_id,
  passengers,
  suggestion_cost,
  setShowAddModal,
  tab,
}: any) => {
  const [editSuggestion] = useMutation(CAR_UPDATE_SUGGESTION);
  const [editSuggestionCost] = useMutation(CAR_EDIT_SUGGESTION_COST);
  const [addSuggestionCost] = useMutation(CAR_ADD_SUGGESTION_COST);
  const userName = useUserDisplayName();
  const emailSend = useEmail();

  const userId = useUserId();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const client = useApolloClient();
  //button loading state
  const [isLoading, setIsloading] = useState<any>(false);
  // Define react hooks form
  const alert = useNotifications();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
    unregister,
  } = useForm({
    defaultValues: defaultValue,
  });

  // Use effect to set the room type if defaultValues are provided
  React.useEffect(() => {
    if (defaultValue[0]?.car_type) {
      setValue("car_type", defaultValue?.car_type); // Set the room_type value
    }
  });
  // Submit form function
  const onSubmit = async (formData: any) => {
    setIsloading(true);
    if (!isLoading) {
      formData["id"] = suggestion.id; // Add inquiry id to formData

      try {
        const startDate =
          formData?.car?.dates && formData.car.dates[0]
            ? formData.car.dates[0]
            : defaultValue.start_date;

        const endDate =
          formData?.car?.dates && formData.car.dates[1]
            ? formData.car.dates[1]
            : defaultValue.end_date;

        // Construct the suggestion object
        const inq_suggestion: any = {
          origin: formData.origin,
          destination: formData.destination,
          car_type: formData.car_type,
          start_date: startDate,
          end_date: endDate,
          // Include other fields you want to update here
        };

        await updateInqListUser({
          variables: { id: inquiry.id, updated_by: userId },
        });

        const resSuggestion = await editSuggestion({
          variables: { id: formData.id, suggestion: inq_suggestion },
        });

        // Add Suggestion Cost

        const passengers =
          inquiry?.car_inq_details?.[0]?.car_inq_passengers || [];

        if (passengers.length > 0) {
          if (
            !formData.suggestion_cost ||
            formData.suggestion_cost.length === 0
          ) {
            let suggestion_cost_add: any = [];

            // Prepare new cost data
            formData.suggestion_cost_add.map((sca: any) => {
              sca["car_suggestion_id"] = formData.id; // Add the suggestion ID
              suggestion_cost_add.push(sca);
            });

            const resSuggestionCostAdd = await addSuggestionCost({
              variables: { suggestion_cost: suggestion_cost_add },
            });
          } else {
            formData.suggestion_cost.map(async (cost: any) => {
              const suggestionCost = { ...cost };
              const id = suggestionCost["id"];

              const resSuggestionCost = await editSuggestionCost({
                variables: { id: id, suggestion_cost: suggestionCost },
              });
            });
          }
        }

        await client.resetStore();

        const variables: any = {
          inquiryNo: inquiry?.inquiry_no,
          brandName: inquiry?.brd_list?.name,
          userFirstName: inquiry?.users?.displayName,
          userLastName: "",
          consultantName: userName,
        };

        await emailSend(
          4,
          inquiry.brd_id,
          inquiry.users?.id,
          inquiry.users?.email,
          inquiry.users?.phoneNumber,
          variables
        );

        toast.success("Suggestions edit successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        await alert.newNotification(
          user_id,
          "Your consultant updated a suggestion. Please review in the portal.",
          "",
          ""
        );

        setIsloading(false);
        setShowAddModal(false);
      } catch (error) {
        toast.error("Failed to edit suggestions", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setIsloading(false);
      }
    }
  };

  const handleDateRangeChange = (newValue: [Dayjs | null, Dayjs | null]) => {
    // You can update the form value or perform any other actions here
    const [start, end] = newValue;
    if (start && end) {
      // If both start and end dates are provided, you can update them in your form state
      setValue("car.dates", [start, end]);
    } else {
      // Handle cases where one of the dates is null
      setValue("car.dates", [defaultValue.start_date, defaultValue.end_date]);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!inquiry?.invoice_id && (
        <div className="bg-gray-100 rounded-lg p-2 dark:bg-[#28243D]">
          <div className="grid grid-cols-2 gap-4 mx-3">
            {/* Where To Field */}
            <FormControl className="mx-3 mt-3" fullWidth>
              <Controller
                control={control}
                name="origin"
                rules={{ required: true }}
                render={({ field }) => (
                  <CityBox
                    {...field}
                    label="Origin"
                    errors={errors}
                    register={register}
                    control={control}
                    setValue={setValue}
                    unregister={unregister}
                    getValues={getValues}
                  />
                )}
              />
            </FormControl>
            <FormControl className="mx-3" fullWidth>
              <Controller
                control={control}
                name="destination"
                rules={{ required: true }}
                render={({ field }) => (
                  <CityBox
                    {...field}
                    label="Origin"
                    errors={errors}
                    register={register}
                    control={control}
                    setValue={setValue}
                    unregister={unregister}
                    getValues={getValues}
                  />
                )}
              />
            </FormControl>

            {/* Dates Field */}
            <FormControl className="mx-3" fullWidth>
              <Controller
                control={control}
                name="car.dates"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  const startDate = dayjs(defaultValue?.start_date);
                  const endDate = dayjs(defaultValue?.end_date);

                  return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["SingleInputDateRangeField"]}
                        sx={{ margin: 0, padding: 0 }}
                      >
                        <DateRangePicker
                          value={[startDate, endDate]} // Controlled value
                          onChange={(newValue) => {
                            onChange(newValue); // Update the form value
                            handleDateRangeChange(newValue); // Call the custom handler
                          }}
                        />
                        {error && (
                          <span className="error">{error.message}</span>
                        )}{" "}
                      </DemoContainer>
                    </LocalizationProvider>
                  );
                }}
              />
            </FormControl>
            <FormControl className="mx-3" fullWidth>
              <Controller
                control={control}
                name="car_type"
                rules={{ required: true }}
                render={({ field }) => (
                  <CarTypeSelector
                    {...field}
                    label="Car Type"
                    errors={errors}
                    register={register}
                    control={control}
                    setValue={setValue} // Use the new function
                    unregister={unregister}
                    getValues={getValues}
                  />
                )}
              />
            </FormControl>
            {/* <FormControl className="mx-3 " fullWidth>
            <CarTypeSelector register={register} errors={errors} />
          </FormControl> */}
          </div>
        </div>
      )}
      <hr className="border-gray-300 border-dashed my-4" />

      <CarPassengerEditCost
        passengers={passengers}
        suggestion_cost={suggestion_cost}
        errors={errors}
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
        inquiry={inquiry}
        type={tab}
      />
      <button
        className={` ${
          isLoading ? "bg-basic cursor-wait" : "bg-basic"
        } h-[50px] p-2 w-half px-4 rounded-lg text-white mt-2`}
        type="submit"
      >
        {isLoading ? "Updating.." : "Update Suggestion"}
      </button>
    </form>
  );
};

export default EditCarSuggestionBox;
