import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Autocomplete, Box, Divider, TextField } from "@mui/material";
import { useUserDisplayName } from "@nhost/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { initialDefaultValue } from "~/data/InitalDefaultValue";
import { GET_USER_INQ_LIST } from "~/graphql/inq_list/Query";
import useEmail from "~/hooks/emails/useEmail";
import useInquiryDetail from "~/hooks/useInquiryDetail";
import useNotifications from "~/hooks/useNotifications";
import GDSStatement from "~/modules/InquiriesModule/segments/InquiryDetail/components/SuggestionCard/components/AddSuggestion/components/GDSStatement";
import { ADD_SUGGESTION } from "~/modules/InquiriesModule/segments/InquiryDetail/components/SuggestionCard/components/AddSuggestion/components/SuggestionForm/graphql/Mutation";
import AddManually from "./components/AddManually";

import useUserInquiryList from "~/hooks/useUserInquiryList";

const AddSugesstion = ({
  mainHeading,
  showUser,
  userID,
  setSidePopup,
  suggestionSend,
  isLoading,
  type,
  seasons,
  fare,
}: any) => {
  const [showForm, setShowForm] = useState<any>(
    type === "createUpdate" ? false : true
  );

  const [inqID, setInqId] = useState<any>();

  const [defaultValue, setDefaultValue] = useState<any>(initialDefaultValue);

  const userName = useUserDisplayName();
  const emailSend = useEmail();
  const alert = useNotifications();
  const client = useApolloClient();

  const { inquiryList }: any = useUserInquiryList(userID && userID);

  const { inquiryDetail }: any = useInquiryDetail(inqID && inqID);
  const inquiry = inquiryDetail?.inq_list[0];

  const submitSugesstion = async (formData: any) => {
    suggestionSend(formData, inquiry, setSidePopup, type);
    setSidePopup(false);
  };

  return (
    <>
      <div className="md:w-[800px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">{mainHeading}</h1>
        <Divider sx={{ marginBottom: "30px" }} />
        {showUser && (
          <Autocomplete
            disablePortal
            onChange={(_, data: any) => {
              setInqId(data?.inquiry_no);
            }}
            options={inquiryList ? inquiryList : []}
            getOptionLabel={(option: any) => option?.inquiry_no}
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option?.inquiry_no}
              </Box>
            )}
            renderInput={(params) => (
              <TextField label="Inquiry Number" {...params} />
            )}
          />
        )}

        {showForm ? (
          <AddManually
            submitSugesstion={submitSugesstion}
            mainHeading={mainHeading}
            passengers={inquiry?.inq_passengers}
            type={type}
            isLoading={isLoading}
            isLoadingText="Creating..."
            defaultValue={defaultValue}
            seasons={seasons}
            fare={fare}
            setSidePopup={setSidePopup}
          />
        ) : (
          <GDSStatement
            setShowForm={setShowForm}
            arrival={inquiry?.to}
            setDefaultValue={setDefaultValue}
          />
        )}
      </div>
    </>
  );
};

export default AddSugesstion;
