import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Autocomplete } from "@mui/material";
import Badge from "@mui/material/Badge";
import CloseIcon from "@mui/icons-material/Close";
import { nhost } from "~/lib/nhost";
import { useQuery } from "@apollo/client";
import { GET_BRD_SERVICES_CATEGORY } from "~/graphql/brd_services_category/Query";
import { useApolloClient, useMutation } from "@apollo/client";

import { toast } from "react-toastify";

import { useParams } from "react-router-dom";
import { INSERT_BRD_PARTNERS } from "~/graphql/brd_partners/Mutation";
import { IconButton } from "@mui/material";
import brandsStore from "~/store/brandsStore/brandsStore";

type FormValues = {
  name: string;
  logo: FileList;
  service_category_id: string;
};

const AddBrandPartner: React.FC = () => {
  const [insertBrdPartners] = useMutation(INSERT_BRD_PARTNERS);
  const client = useApolloClient();
  const { register, handleSubmit, reset, setValue } = useForm<FormValues>();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
  const { brands } = brandsStore();
  const brdIds = brands.length > 0 ? brands.map((brand: any) => brand.id) : [];
  const [logoUrl, setLogoUrl] = useState<any>(null);
  const [logoId, setLogoId] = useState<any>(null);

  const { brd_id } = useParams();

  const uploadImage = async (file: File): Promise<string> => {
    try {
      const response = await nhost.storage.upload({ file });
      if (response.error) throw new Error(response.error.message);
      return response.fileMetadata.id;
    } catch (error) {
      throw error;
    }
  };

  const convertImageToWebP = async (file: any) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(
                new File([blob], file.name.replace(/\.\w+$/, ".webp"), {
                  type: "image/webp",
                })
              );
            } else {
              reject(new Error("Conversion to WebP failed"));
            }
          },
          "image/webp",
          0.8
        );
      };

      img.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    // Reset the input value when logoUrl or logoId is reset to ""
    if (!logoUrl && !logoId) {
      reset({ logo: undefined });
    }
  }, [logoUrl, logoId, reset]);

  const { data, loading, error } = useQuery(GET_BRD_SERVICES_CATEGORY, {
    variables: { brdIds },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      if (logoId) {
        const partnerData = {
          name: data.name,
          logo_id: logoId,
          brd_id: brd_id,
          // service_category_id: data.service_category_id,
        };
        const response = await insertBrdPartners({
          variables: { data: [partnerData] },
        });
        if (response?.data?.insert_brd_partners?.affected_rows > 0) {
          await client.refetchQueries({ include: "all" });
          toast.success("Partner added successfully!");
          reset();
          setLogoUrl(null);
          setLogoId(null);
        } else {
          toast.error("Error adding partner, please try again.");
        }
      } else {
        toast.error("Logo is required to add the partner.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleImageRemove = async () => {
    if (logoUrl && logoId) {
      const { error: imageDeleteError } = await nhost.storage.delete({
        fileId: logoId,
      });
      if (imageDeleteError) {
        toast.error("Failed to delete old image");
      } else {
        setLogoUrl(null);
        setLogoId(null);
      }
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Brand Partner
      </h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="text-primary dark:text-dark-primary flex-col"
      >
        <div className="flex flex-col space-y-4">
          <TextField
            label="Partner Name"
            variant="outlined"
            fullWidth
            {...register("name", { required: true })}
            className="dark:text-dark-primary"
          />
          {/* <Autocomplete
            options={data?.brd_services_category || []}
            getOptionLabel={(option: any) => option.name}
            fullWidth
            onChange={(event, newValue) => {
              setSelectedCategoryId(newValue?.id || "");
              setValue("service_category_id", newValue?.id || "");
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Select Category"
                InputLabelProps={{ className: "dark:text-dark-primary" }}
                variant="outlined"
                fullWidth
              />
            )}
            className="dark:text-dark-primary"
          /> */}

          {logoUrl && (
            <Badge
              color="primary"
              badgeContent={
                <IconButton size="small" onClick={handleImageRemove}>
                  <CloseIcon
                    fontSize="small"
                    className="text-primary"
                    color="action"
                  />
                </IconButton>
              }
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              sx={{
                "& .MuiBadge-badge": {
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                },
              }}
            >
              <img src={logoUrl} alt="Uploaded" style={{ maxWidth: "100%" }} />
            </Badge>
          )}

          <Button
            variant="outlined"
            component="label"
            className="dark:text-dark-primary"
          >
            Upload Logo
            <input
              type="file"
              hidden
              {...register("logo", { required: true })}
              onChange={async (e) => {
                const file = e.target.files?.[0];
                if (file) {
                  const webpFile = (await convertImageToWebP(file)) as File;
                  const id = await uploadImage(webpFile);
                  setLogoId(id);
                  const uploadedImgUrl = `${process.env.REACT_APP_NHOST_STORAGE_URL}${id}`;
                  setLogoUrl(uploadedImgUrl);
                }
              }}
            />
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Add Partner
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddBrandPartner;
