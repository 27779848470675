import DistinationLine from "../../assets/svg/DistinationLine";
import moment from "moment";
import TickBox from "../../assets/svg/TickBox";
import Money from "../../assets/svg/Money";
import AirPlane from "../../assets/svg/AirPlane";
import Edit from "../../assets/img/edit.png";
import testEdit from "../../assets/img/Vector.png";
import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
import { useState } from "react";
import InquiryCardHead from "./components/InquiryCardHead";
import PassengerRecord from "./components/PassengerRecord";
import InquiryCardEdit from "./components/InquiryCardEdit";
import { getCityName } from "~/utils/utilsFunctions";
import From from "../../assets/img/From-light.svg";
import To from "../../assets/img/To-light.svg";
import FromDark from "../../assets/img/From-dark.svg";
import ToDark from "../../assets/img/To-dark.svg";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import { useEffect } from "react";
import SideDrawer from "~/components/common/sideDrawer";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";

const InquiryCard = ({ inquiry, header, height, editAble }: any) => {
  const [edit, setEdit] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);

    const handleStorageChange = () => {
      const updatedData = localStorage.getItem("darkMode");
      setDarkMode(updatedData ? JSON.parse(updatedData) : false);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  console.log("ajcj", inquiry);

  return (
    <div
      className={`flex-1 w-full rounded-2xl bg-secondary dark:bg-dark-secondary text-primary dark:to-dark-primary shadow h-full flex flex-col pt-0 px-0 box-border items-end justify-between text-left text-xs
    `}
    >
      <InquiryCardHead inquiry={inquiry} show={header} />

      {editAble && (
        <BiSolidMessageSquareEdit
          className="cursor-pointer text-basic dark:text-dark-primary m-3.5 w-5 h-5 absolute"
          onClick={() => {
            setEdit(!edit);
          }}
        />
      )}
      <div className="w-full text-primary dark:text-dark-primary flex flex-col justify-between rounded-2xl h-full ">
        <div className="self-stretch flex flex-col my-5 px-[30px] mt-5 flex-grow">
          <div className="h-full flex flex-col items-start justify-center gap-5">
            <div className="bg-basic h-20 w-20 rounded-full flex items-center justify-center">
              <FlightTakeoffIcon
                className="text-center text-white"
                fontSize="large"
              />
            </div>
            <div className="flex flex-row justify-between items-center w-full gap-3">
              <div className=" flex gap-3 flex-col">
                <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base">
                  ORIGIN
                </div>
                <div className="relative text-3xl font-medium text-basic dark:text-dark-primary">
                  {getCityName(inquiry?.from)}{" "}
                  <span className="text-xl">({inquiry?.from})</span>
                </div>
              </div>
              <div className=" flex gap-3 flex-col">
                <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base text-right">
                  DESTINATION
                </div>
                <div className="relative text-3xl font-medium text-basic dark:text-dark-primary text-right ">
                  {getCityName(inquiry.to)}{" "}
                  <span className="text-xl">({inquiry?.to})</span>
                  {/* {inquiry.trip_type === "oneWay" ? (
                    <span>-</span>
                  ) : (
                    getCityName(inquiry?.to)
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex md:flex-row px-8 py-5 items-center justify-between bg-basic rounded-b-2xl h-[150px]">
          <div className="flex flex-col items-start justify-center w-full">
            <div className="flex flex-col items-start justify-start gap-1 text-white font-medium">
              <div className="self-stretch relative text-base">
                {" "}
                {moment(inquiry.departure_datetime).format("DD-MM-YYYY")}
              </div>
              <div className="flex flex-row items-center justify-center gap-[6px]">
                {inquiry.flexible_departure ? (
                  <TickBox />
                ) : (
                  <div className="relative box-border w-[11px] h-[11px] border-[1px] border-solid border-darkgray"></div>
                )}
                <div className="relative text-base">Flexible</div>
              </div>
              {/* <div className="hidden md:inline-block">
                  <AirPlane />
                </div> */}
              <div className="relative inline-block text-base shrink-0">
                <span>{`Preferred Airline: `}</span>
                <span className="font-bold">{inquiry.preferred_airline}</span>
              </div>
              <div className="flex flex-row items-center justify-start gap-1 mt-2">
                {/* <Money /> */}
                <div className="relative inline-block text-base shrink-0">
                  <span>Approx Budget:</span>
                  <b>{inquiry?.approx_budget}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-end justify-center w-full">
            <div className="flex flex-col items-end justify-start gap-1 text-white font-medium">
              <div className="self-stretch relative text-end text-base">
                {inquiry.trip_type === "oneWay" ? (
                  <span>-</span>
                ) : (
                  moment(inquiry.arrival_datetime).format("DD-MM-YYYY")
                )}
              </div>
              <div className="flex flex-row items-center justify-center gap-[6px]">
                {inquiry.flexible_arrival ? (
                  <TickBox />
                ) : (
                  <div className="relative box-border w-[11px] h-[11px] border-[1px] border-solid border-darkgray"></div>
                )}
                <div className="relative text-base">Flexible</div>
              </div>
              {/* <div className="hidden md:inline-block">
                  <AirPlane />
                </div> */}
              <div className="flex justify-end gap-1 text-base">
                <span>{`Direct Flight: `}</span>
                <span className="">{inquiry.direct_flight ? "Yes" : "No"}</span>
              </div>
              <div className="mt-2">
                <PassengerRecord inq_passengers={inquiry?.inq_passengers} />
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col items-end justify-start w-full bg-blue-500">
              <div className="flex flex-row items-start justify-start gap-1 text-white font-medium bg-red-500">
                <img
                  className="relative w-auto h-[13px] w-[100px]"
                  alt=""
                  src="/vector17.svg"
                />
                <div className="relative inline-block h-3 shrink-0">
                  <div className="self-stretch relative">
                    {inquiry.trip_type === "oneWay" ? (
                      <span>-</span>
                    ) : (
                      moment(inquiry.arrival_datetime).format("DD-MM-YYYY")
                    )}
                  </div>
                  <div className="flex flex-row items-center justify-center gap-[6px]">
                    {inquiry.flexible_arrival ? (
                      <TickBox />
                    ) : (
                      <div className="relative box-border w-[11px] h-[11px] border-[1px] border-solid border-darkgray"></div>
                    )}
                    <div className="relative">Flexible</div>
                  </div>
                  <span>{`Direct Flight: `}</span>
                  <span className="">
                    {inquiry.direct_flight ? "Yes" : "No"}
                  </span>
                  <PassengerRecord inq_passengers={inquiry?.inq_passengers} />
                </div>
              </div>
            </div> */}
        </div>
      </div>
      <SideDrawer
        setSidePopup={setEdit}
        sidePopup={edit}
        component={<InquiryCardEdit inquiry={inquiry} setEdit={setEdit} />}
      />
    </div>
  );
};

InquiryCard.defaultProps = {
  header: true,
  height: "300px",
  editAble: false,
};

export default InquiryCard;
