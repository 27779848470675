import { MdDelete } from "react-icons/md";
import { DELETE_FLIGHT_INQ_SEAT_SELECTION } from "~/graphql/flightInq_seatSelection/Mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

interface SeatSelection {
  inq_id: string;
  cost_price: number;
  sale_price: number;
  seat_no: string | number;
}

interface Inquiry {
  id: any;
  flightInq_seatSelections?: SeatSelection[];
}

interface SeatsCardProps {
  inquiry: Inquiry;
}

const SeatsCard: React.FC<SeatsCardProps> = ({ inquiry }) => {
  const [deleteSeatSelection] = useMutation(DELETE_FLIGHT_INQ_SEAT_SELECTION);

  const handleDelete = async () => {
    const inq_id = inquiry?.id;
    if (inq_id) {
      try {
        const { data } = await deleteSeatSelection({
          variables: { data: inq_id },
        });
        const affectedRows =
          data?.delete_flightInq_seatSelection?.affected_rows;

        if (affectedRows > 0) {
          toast.success("Seat selection deleted successfully!");
        } else {
          toast.error("No seat selection was deleted.");
        }
      } catch (error) {
        toast.error("An error occurred while deleting seat selection.");
      }
    }
  };

  return (
    <div className="rounded-lg shadow w-full flex flex-col">
      <div className="self-stretch flex-1 bg-secondary dark:bg-dark-secondary flex flex-col p-[25px] rounded-t-lg pt-[25px] items-start justify-between text-left text-mini text-darkslategray">
        <div className="self-stretch container w-full flex flex-col gap-[12px] justify-between">
          <div className="self-stretch flex items-center justify-between gap-1 text-basic dark:text-dark-primary">
            <b>Selected Seats</b>
            <div className="flex items-center justify-end gap-[8px]">
              <MdDelete
                className="text-basic dark:text-dark-primary cursor-pointer h-6 w-6"
                onClick={handleDelete}
              />
            </div>
          </div>

          <div className="self-stretch flex flex-col gap-[12px] w-full">
            {inquiry?.flightInq_seatSelections
              ?.slice(0, 4)
              .map((seat, index) => (
                <div
                  key={seat?.inq_id || index}
                  className="self-stretch relative flex flex-col border-b border-solid border-basic dark:border-dark-primary text-basic dark:text-dark-primary"
                >
                  <div className="flex justify-between">
                    <span className="font-bold mr-1">{`${index + 1}.`}</span>
                    <div className="flex justify-between gap-5 w-full">
                      <div>
                        <span>Seat No:</span>
                        <b className="ml-2">{seat?.seat_no}</b>
                      </div>
                      <div>
                        <span>Cost Price:</span>
                        <b className="ml-2">£{seat?.cost_price}</b>
                      </div>
                      <div>
                        <span>Sale Price:</span>
                        <b className="ml-2">£{seat?.sale_price}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-2 w-full bg-basic px-[25px] py-[20px] rounded-b-lg">
        <div className="relative flex gap-1 text-basic rounded-2xl font-medium bg-white px-2">
          <p className="capitalize">
            Total Sale Price:
            <b className="ml-1">
              £
              {inquiry?.flightInq_seatSelections?.reduce(
                (total, seat) => total + seat.sale_price,
                0
              )}
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SeatsCard;
