import { useEffect } from "react";

const VisaSuggestionBox = ({
    key,
    suggestion,
    active,
    inquiry,
    setActive
}: any) => {

    useEffect(() => {
        setActive(suggestion.id);
    }, [suggestion]);

    const totalCostPrice =
        suggestion?.visa_inq_suggestion_costs?.reduce((sum: number, obj: any) => {
            const costPrice = parseFloat(obj.cost_price) || 0;
            return sum + costPrice;
        }, 0) || 0;

    // Calculate the total sale price for all passengers
    const totalSalePrice =
        suggestion?.visa_inq_suggestion_costs?.reduce((sum: number, obj: any) => {
            const salePrice = parseFloat(obj.sale_price) || 0;
            return sum + salePrice;
        }, 0) || 0;

    return (
        <>
            {active && (
                <div className="w-full">
                    <div
                        className="py-[25px] px-[20px] bg-white dark:bg-dark-secondary overflow-hidden flex flex-col gap-[20px] w-full text-left text-xs text-darkslategray shadow"
                        onClick={setActive}
                    >
                        {suggestion ? (
                            <div className="w-full flex flex-col gap-[20px]">
                                <div className="flex justify-between w-full">
                                    <div className="flex flex-col">
                                        <div className="text-basic dark:text-dark-primary w-full">
                                            <b>Type </b>
                                            <span>{suggestion.name || "N/A"}</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-basic dark:text-dark-primary w-full">
                                            <b>Country: </b>
                                            <span>{suggestion.country || "N/A"}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="flex flex-col">
                                        <div className="text-basic dark:text-dark-primary w-full">
                                            <b>Persons: </b>
                                            <span>{suggestion.visa_inq_suggestion_costs.length || "N/A"}</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-basic dark:text-dark-primary w-full">
                                            <b>Expiry Date: </b>
                                            <span>{suggestion.visa_expiry_date || "N/A"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>No suggestion available</div>
                        )}
                    </div>

                    <div className="flex items-center justify-end h-24 bg-basic flex-1 px-5 rounded-b-lg">
                        <div className="flex gap-10">
                            <div className="text-white flex gap-1 items-center">
                                <strong>Cost:</strong>
                                <div className="text-2xl font-bold">£{totalCostPrice || "N/A"}</div>
                            </div>
                            <div className="text-white flex gap-1 items-center">
                                <strong>Sale:</strong>
                                <div className="text-2xl font-bold">£{totalSalePrice || "N/A"}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default VisaSuggestionBox