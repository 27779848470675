import React, { useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_SERVICES_FAQS } from "~/graphql/services_faqs/Query"; // Your query file path
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
} from "@mui/material";

import { DELETE_SERVICES_FAQS } from "~/graphql/services_faqs/Mutation";
import { toast } from "react-toastify";
import EditServicesFaqs from "../EditServicesFaqs";
import SideDrawer from "~/components/common/sideDrawer";

const ShowServicesFaqs = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { brd_id, service_id } = useParams();
  const client = useApolloClient();
  const [deleteFAQ] = useMutation(DELETE_SERVICES_FAQS);
  console.log("ids:", brd_id, service_id);
  const { loading, error, data } = useQuery(GET_SERVICES_FAQS, {
    variables: { brd_id, service_id },
  });
  const [selectedFAQ, setSelectedFAQ] = useState<any>(null);

  const handleEdit = (faq: any) => {
    setIsPopupOpen(true);
    setSelectedFAQ(faq);
  };

  const handleDelete = async (id: string) => {
    try {
      const { data } = await deleteFAQ({ variables: { id } });
      if (data.delete_services_faqs.affected_rows > 0) {
        await client.refetchQueries({ include: "all" });
        toast.success("FAQ deleted successfully!");
      }
    } catch (error) {
      toast.error("Error deleting FAQ");
    }
  };

  const formatDescription = (description: string) => {
    return description.length > 150
      ? `${description.slice(0, 150)}...`
      : description;
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className=" min-w-[1100px] ">
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.services_faqs.map((faq: any) => (
              <TableRow key={faq.id}>
                <TableCell className=" min-w-[200px]">
                  {faq.question.length > 15
                    ? `${faq.question.slice(0, 15)}...`
                    : faq.question}
                </TableCell>
                <TableCell className=" min-w-[400px]">
                  {faq.description.length > 60
                    ? `${faq.description.slice(0, 60)}...`
                    : faq.description}
                </TableCell>
                <TableCell className=" min-w-[200px]">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEdit(faq)}
                    style={{ marginRight: 8 }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    // startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(faq.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPopupOpen && (
        <SideDrawer
          component={
            <EditServicesFaqs faq={selectedFAQ} setSidePopup={setIsPopupOpen} />
          }
          sidePopup={isPopupOpen}
          setSidePopup={setIsPopupOpen}
        />
      )}
    </>
  );
};

export default ShowServicesFaqs;
