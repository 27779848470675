import moment from "moment";
import { Document, Page, View, Image, Text } from "@react-pdf/renderer";
import { splitLocation } from "~/utils/splitLocation";
import vector81 from "../../../images/dottedImg.svg";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { getStyles } from "../../../pdfDocument/styles";
import car from "../../../images/car.png";
const CarInvoicePDF = ({ invoice, inquiry }: any) => {
  const carSuggestions = invoice?.invoice_suggestions?.filter(
    (suggestion: any) => suggestion.type === "car"
  )?.[0]?.car_inq_suggestion;

  const { cityName: originCity, remainingAddress: originAddress } =
    splitLocation(carSuggestions?.origin);
  const { cityName: destCity, remainingAddress: destAddress } = splitLocation(
    carSuggestions?.destination
  );

  if (!carSuggestions) return <div>No stay suggestions found</div>;
  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );
  return (
    <View style={styles.inquiryDetailContainer}>
      <View>
        <View style={styles.flightDetailHeader}>
          <Text style={styles.flightTypeText}>Car Invoice</Text>
          <View style={styles.luggageSubContainer}>
            <Image style={styles.iconSize} src={car} />
            <Text style={styles.flightCabinText}>
              {carSuggestions?.car_type}
            </Text>
          </View>
        </View>
        <View style={styles.flightDetailBody}>
          <View style={styles.flightLegsParentContainer}>
            <View style={styles.flightLegs}>
              <View style={styles.flightLegContainers}>
                <View style={styles.flightLegSubContainers}>
                  <Text style={styles.flightLegArrDep}>{originCity}</Text>
                  <Text>({originAddress})</Text>
                </View>
                <Text style={styles.flightLegDateTime}>
                  {moment(carSuggestions?.start_date).format(
                    "DD-MM-YYYY HH:mm"
                  )}
                </Text>
              </View>

              <Image style={styles.dottedImg} src={vector81} />
              <View style={styles.flightLegContainerRight}>
                <View style={styles.flightLegSubContainerRight}>
                  <Text style={styles.flightLegArrDep}>{destCity}</Text>
                  <Text>({destAddress})</Text>
                </View>
                <Text style={styles.flightLegDateTime}>
                  {moment(carSuggestions?.end_date).format("DD-MM-YYYY HH:mm")}
                </Text>
              </View>
            </View>
          </View>
          {/* );
                  })} */}
          <View style={styles.flightLegSubContainerRight}>
            {/* <View style={styles.luggageContainer}>
              <View style={styles.luggageSubContainer}>
                
                <Text style={styles.luggageText}>
                  {carSuggestions?.car_type}
                </Text>
              </View>
            </View> */}
            <View style={styles.flightCabinContainer}>
              {carSuggestions?.car_inq_suggestion_costs?.map(
                (cost: any, idx: any) => (
                  <Text key={idx} style={styles.flightCabinText}>
                    Sale Price: £ {cost?.sale_price}
                  </Text>
                )
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default CarInvoicePDF;
