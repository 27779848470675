import moment from "moment";
import { useEffect, useState } from "react";
// import React, { useState } from "react";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
// import PassengerEdit from "../components/PassengerEdit";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { FaTimes } from "react-icons/fa";
import { useApolloClient, useMutation } from "@apollo/client";

import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import Edit from "../../../../assets/img/Vector.svg";
import SideDrawer from "~/components/common/sideDrawer";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import { DELETE_STAY_ROOMS } from "../../components/PassengerBox/graphql/Mutation";
import StayPassengerEdit from "../../components/PassengerBox/components/NewPassengerEdit/StayPassengerEdit";
// import NewPassengerEdit from "../components/NewPassengerEdit";
interface PassengerData {
  basic_fare?: number; // or the correct type (e.g., string)
  tax_price?: number; // or the correct type
  atol_price?: number; // or the correct type
  sale_price?: number; // or the correct type
}

const StayPassengerBox = ({
  passenger,
  inquiry,
  selected_suggestion_id,
  totalPassengers,
  hideActions,
  tabLabel,
  roomNumber,
}: any) => {
  // Get role from cookies
  const role = useUserDefaultRole();
  const [hideForm, setHideForm] = useState(false);
  const [deleteStayPassenger] = useMutation(DELETE_STAY_ROOMS);
  const [wait, setWait] = useState(false);
  const client = useApolloClient();
  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const userDefaultRole = useUserDefaultRole();
  const user_id = useUserId();

  // Filter the prices based on the selected suggestion ID and tab label
  const [passengerData, setPassengerData] = useState<PassengerData | null>(
    null
  );

  useEffect(() => {
    let newPassengerData;

    // Ensure passenger data is available before filtering
    if (passenger) {
      switch (tabLabel) {
        case "Stay":
          newPassengerData = passenger.stay_inq_suggestion_costs?.filter(
            (sc: any) => sc.stay_suggestion_id === selected_suggestion_id
          )?.[0];
          break;
      }

      // Set the passenger data
      setPassengerData(newPassengerData);
    }
  }, [tabLabel, passenger, selected_suggestion_id]); // Add any dependencies you need

  const removePassenger = async (id: string, tabLabel: string) => {
    setWait(true);
    let res;
    try {
      // Dynamically call the appropriate mutation based on the tabLabel
      switch (tabLabel) {
        case "Stay":
          res = await deleteStayPassenger({ variables: { id } });
          break;
      }

      // Update the inquiry list after deletion
      await updateInqListUser({
        variables: { id: inquiry.id, updated_by: user_id },
      });

      // Check for success response
      if (res?.data?.delete_stay_inq_rooms_by_pk?.id) {
        toast.success("Rooms removed successfully");
        await client.resetStore();
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast.error("An error occurred while removing the Room.");
    } finally {
      setWait(false);
    }
  };

  return (
    <>
      {!hideForm ? (
        <div className="pt-[25px] rounded-xl shadow-md relative bg-white dark:bg-dark-secondary">
          {/* {!selected_suggestion_id && totalPassengers > 1 && ( */}
          <button
            disabled={wait}
            onClick={() => removePassenger(passenger?.id, tabLabel)}
            className="bg-basic dark:bg-[#CFCCE6] h-[20px] w-[20px] rounded-full text-white flex justify-center items-center absolute top-[-5px] right-[-5px]"
          >
            {wait ? "..." : <FaTimes className="dark:text-[#312D4B]" />}
          </button>
          {/* )} */}
          <div className="self-stretch min-h-[300px] px-[25px] bg-white dark:bg-dark-secondary flex flex-col text-left text-mini text-darkslategray">
            <div className="self-stretch flex flex-row items-center justify-between text-sm mb-7">
              <div className="flex flex-row items-start justify-start text-">
                <div className="flex flex-row items-center justify-start">
                  <b className="relative capitalize text-basic dark:text-dark-primary">
                    Room# {roomNumber}
                  </b>
                </div>
              </div>
              {!inquiry?.selected_suggestion &&
                !hideActions &&
                (user_id === inquiry?.picked_by ||
                  userDefaultRole === "admin") &&
                inquiry?.status !== "completed" && (
                  // <img src={Edit} alt="" className="cursor-pointer h-4" onClick={() => setHideForm(true)} />
                  <BiSolidMessageSquareEdit
                    className="cursor-pointer text-basic dark:text-dark-primary h-5 w-5"
                    onClick={() => setHideForm(true)}
                  />
                )}
            </div>
            {/* <div className="self-stretch flex flex-row items-start justify-between text-sm">
            <div className="flex flex-row items-start justify-start">
              <div className="flex flex-row items-center justify-start">
                <p>Gender: </p>
                <b className="relative capitalize">
                  {" "}
                  {passenger?.gender || (
                    <span className="text-[red] text-[11px] px-2 inline-block italic">
                      Missing!
                    </span>
                  )}
                </b>
              </div>
            </div>
            </div> */}
            <div className="flex flex-col gap-7">
              <div className="self-stretch flex flex-row pt-0 px-0 items-center justify-start border-b-[1px] border-basic dark:border-dark-primary">
                <div className="flex-1 rounded-8xs flex flex-col items-start justify-start">
                  <div className="self-stretch relative text-basic dark:text-dark-primary flex flex-col gap-1 leading-[130%]">
                    <p className="m-0 font-medium">Booked By</p>
                    <p className="m-0 text-basic dark:text-dark-primary text-lg">
                      <p className="font-medium">
                        {passenger?.booked_by || (
                          <span className="text-[11px] px-2 inline-block italic">
                            N/A
                          </span>
                        )}{" "}
                        {/* {passenger?.last_name} */}
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row pt-0 justify-between px-0 border-b-[1px] border-basic dark:border-dark-primary">
                <div className="flex-1 flex flex-row items-center justify-start">
                  <div className="relative text-basic dark:text-dark-primary flex flex-col gap-1">
                    <p className="m-0 font-medium">No. of Days</p>
                    <p className="m-0 text-basic dark:text-dark-primary text-lg">
                      <p className="font-medium">
                        {passenger?.no_of_days || 0}
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-between  border-b-[1px] border-basic dark:border-dark-primary">
                <div className="rounded-8xs flex flex-col items-start justify-start">
                  <div className="self-stretch relative text-basic dark:text-dark-primary flex flex-col gap-1">
                    <p className="m-0 font-medium">Room No</p>
                    <p className="m-0 text-basic dark:text-dark-primary text-lg">
                      <p className="font-medium">
                        {passenger?.room_number || (
                          <span className="text-[11px] px-2 inline-block italic">
                            N/A
                          </span>
                        )}
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between bg-basic rounded-b-lg text-white px-[25px] py-[15px]">
            {
              role !== "user" && (
                // passengerData.map((passengerItem, index) => (
                <>
                  <div className="flex flex-row items-start">
                    <div className="rounded-8xs flex flex-col items-start justify-start mr-4">
                      <div className="self-stretch relative flex">
                        <p className="m-0">Cost:</p>
                        <p className="m-0 font-medium">
                          {passengerData?.basic_fare || (
                            <span className="text-[11px] px-2 inline-block italic">
                              N/A
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="rounded-8xs flex flex-col items-start justify-start mr-4">
                      <div className="self-stretch relative flex">
                        <p className="m-0">Tax:</p>
                        <p className="m-0 font-medium">
                          {passengerData?.tax_price || (
                            <span className="text-[11px] px-2 inline-block italic">
                              N/A
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="rounded-8xs flex flex-col items-start justify-start mr-4">
                      <div className="self-stretch relative flex">
                        <p className="m-0">Atol:</p>
                        <p className="m-0 font-medium">
                          {passengerData?.atol_price || (
                            <span className="text-[11px] px-2 inline-block italic">
                              N/A
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-8xs flex flex-col items-start justify-start mr-4">
                    <div className="self-stretch relative flex">
                      <p className="m-0">Sale:</p>
                      <p className="m-0 font-medium">
                        {passengerData?.sale_price || (
                          <span className="text-[11px] px-2 inline-block italic">
                            N/A
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </>
              )
              // ))
            }
          </div>
        </div>
      ) : (
        <SideDrawer
          setSidePopup={setHideForm}
          sidePopup={hideForm}
          component={
            // <></>
            <StayPassengerEdit
              inquiry_id={inquiry?.id}
              user_id={inquiry?.users?.id}
              picked_by={inquiry?.picked_by}
              hideForm={setHideForm}
              passenger={passenger}
              tabLabel={tabLabel}
            />
          }
        />
      )}
    </>
  );
};

export default StayPassengerBox;
