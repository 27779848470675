import { useState, useEffect } from "react";
import { Divider, Button } from "@mui/material";
import CityBox from "~/components/FormInputs/CityBox/CityBox";
import RoomForm from "~/components/FormInputs/RoomForm";
import { Controller, useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { UPDATE_STAY_INQUIRY_DETAIL } from "~/graphql/stay_inq_detail/Mutation";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { toast } from "react-toastify";

const EditStay = ({
  setSidePopup,
  stayId,
  location,
  startDate,
  endDate,
  rooms,
  adults,
  children,
}: any) => {
  const today = dayjs();
  const [selectedCityName, setSelectedCityName] = useState(location || "");
  const [updateStayInq] = useMutation(UPDATE_STAY_INQUIRY_DETAIL);

  const [defaultRooms, setDefaultRooms] = useState<any>([]);

  useEffect(() => {
    const newRooms = [];
    for (let i = 0; i < rooms; i++) {
      if (i === 0) {
        newRooms.push({
          adults: new Array(adults).length > 1 ? adults - 1 : adults,
          children: children,
        });
      } else {
        newRooms.push({ adults: 1, children: 0 });
      }
    }
    setDefaultRooms(newRooms);
  }, [rooms, adults, children]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      stay: {
        whereTo: location || "",
        dates: [
          startDate ? dayjs(startDate) : null,
          endDate ? dayjs(endDate) : null,
        ],
        Travelers: [
          {
            adults: adults || 1,
            children: children || 0,
          },
        ],
      },
    },
  });

  useEffect(() => {
    reset({
      stay: {
        whereTo: location || "",
        dates: [
          startDate ? dayjs(startDate) : null,
          endDate ? dayjs(endDate) : null,
        ],
        Travelers: [
          {
            adults: adults || 1,
            children: children || 0,
          },
        ],
      },
    });
  }, [location, startDate, endDate, rooms, adults, children, reset]);

  const handleDateRangeChange = (newValue: any) => {};

  const onSubmit = async () => {
    const formValues = getValues();

    const stayDetails = formValues?.stay;
    const travelers = stayDetails?.Travelers || [];
    const dates: any = stayDetails?.dates || [];

    if (!stayDetails || travelers.length === 0 || dates.length < 2) {
      return;
    }

    try {
      const res = await updateStayInq({
        variables: {
          id: stayId,
          adults: travelers.reduce((acc, traveler) => acc + traveler.adults, 0),
          children: travelers.reduce(
            (acc, traveler) => acc + traveler.children,
            0
          ),
          end_date: moment(dates[1]?.$d).format("YYYY-MM-DD"),
          rooms: travelers.length,
          location: stayDetails?.whereTo,
          start_date: moment(dates[0]?.$d).format("YYYY-MM-DD"),
        },
      });

      if (res?.data?.update_stay_inq_detail?.returning?.[0]?.id) {
        toast.success("Stay Details Updated Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setSidePopup(false);
      }
    } catch (err) {
      toast.error("Error Updating Stay Details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <div className="min-w-[500px] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-center text-2xl text-basic dark:text-dark-primary">
          Edit Stay Details
        </h2>
        <Divider />
        <div className="grid grid-cols-1 mt-10 gap-5">
          <Controller
            control={control}
            name="stay.whereTo"
            rules={{ required: "This is required" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <CityBox
                  value={value}
                  onChange={(val: any) => {
                    onChange(val);
                    setSelectedCityName(val);
                  }}
                  setValue={setValue}
                  fieldName="stay.whereTo"
                  label="Where to"
                  errors={errors}
                  setSelectedCityName={setSelectedCityName}
                />
                {error && (
                  <span className="text-red-500 text-xs">{error.message}</span>
                )}
              </>
            )}
          />
          <Controller
            control={control}
            name="stay.dates"
            rules={{
              validate: (value) =>
                value && value[0] && value[1] ? true : "This field is required",
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const dateRangeValue: [Dayjs | null, Dayjs | null] =
                Array.isArray(value) && value.length === 2
                  ? [value[0], value[1]]
                  : [null, null];

              return (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["SingleInputDateRangeField"]}
                      sx={{ margin: 0, padding: 0 }}
                    >
                      <DateRangePicker
                        value={dateRangeValue}
                        onChange={(newValue) => {
                          onChange(
                            newValue &&
                              Array.isArray(newValue) &&
                              newValue.length === 2
                              ? [newValue[0], newValue[1]]
                              : [null, null]
                          );
                          handleDateRangeChange(
                            newValue as [Dayjs | null, Dayjs | null]
                          );
                        }}
                        minDate={today}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {error && (
                    <span className="text-red-500 text-xs">
                      {error.message}
                    </span>
                  )}
                </>
              );
            }}
          />
          <Controller
            control={control}
            name="stay.Travelers"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <RoomForm
                value={defaultRooms}
                onChange={onChange}
                setValue={setValue}
                fieldName="Travelers"
                label="Travelers"
                errors={error}
              />
            )}
          />
        </div>
        <div className="mt-5">
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditStay;
