import { gql } from "@apollo/client";

export const GET_INQUIRIES = gql`
  query GetInquiries($condition: inq_ticket_bool_exp!) {
    inq_ticket(where: $condition, order_by: { created_at: desc }) {
      ibe
      id
      inq_id
      pnr
      refundable
      status
      url
      vendor_id
      cancelation_charges
      void_time
      ticket_cost_price
      ticket_attol_price
      ticket_mis_cost
      inq_list {
        id
        brd_id
        inquiry_no
        picked_by
        inq_transections {
          amount
          status
        }
        users {
          email
          phoneNumber
        }
        selected_suggestion {
          fare_expiry
          inq_suggestion_costs {
            sale_price
          }
        }
      }
    }
  }
`;
