import { useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";
import { Divider } from "@mui/material";

// Import Data
import {
  PassengersType,
  PassengersGenders,
} from "../../../../data/InitalDefaultValue";

// Import Components
import InputField from "components/FormInputs/InputField";
import CountryBox from "components/FormInputs/CountryBox";
import { CalendarBox } from "components/FormInputs/CalendarBox";
import SelectBox from "components/FormInputs/SelectBox";

// Import GraphQl
import { EDIT_PASSENGER } from "../../../../graphql/Mutation";
import useNotifications from "hooks/useNotifications";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";

const PassengerEdit = ({
  inquiry_id,
  passenger,
  hideForm,
  user_id,
  picked_by,
}: any) => {
  const client = useApolloClient();
  const [editPassenger] = useMutation(EDIT_PASSENGER);
  const alert = useNotifications();
  const userRole = useUserDefaultRole();
  //  Use react hook
  const selectedType = PassengersType.filter(
    (ps: any) => ps.value === passenger.type
  );

  const userId = useUserId();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      type: passenger.type,
      gender: passenger.gender,
      first_name: passenger.first_name,
      last_name: passenger.last_name,
      dob: passenger.dob || null,
      nationality: passenger.nationality,
      passport_no: passenger.passport_no,
      passport_expiry: passenger.passport_expiry || null,
    },
  });

  // Save Passsenger function
  const onSubmit = async (data: any) => {
    data["id"] = passenger.id;
    data["nationality"] = data?.nationality?.code || "";
    data["passport_expiry"] = data?.passport_expiry
      ? moment(data?.passport_expiry).format("YYYY-MM-DD HH:mm:ss")
      : null;
    data["dob"] = data?.dob
      ? moment(data?.dob).format("YYYY-MM-DD HH:mm:ss")
      : null;

    try {
      await updateInqListUser({
        variables: { id: inquiry_id, updated_by: userId },
      });
      // Create passenger function call
      const res = await editPassenger({
        variables: { ...data },
      });

      if (res.data?.update_inq_passengers?.returning?.length > 0) {
        toast.success("Passenger added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        if (userRole === "user") {
          await alert.newNotification(
            picked_by,
            `Your customer has edit passenger. Please check your portal`,
            "",
            ``
          );
        } else {
          await alert.newNotification(
            user_id,
            `Your consultant has edit passenger. Please check your portal`,
            "",
            ``
          );
        }

        await client.resetStore();
      }
      //   setIsloading(false); // change button loading state
      hideForm(false); // hide form modal
    } catch (e) {
      hideForm(false);
    }
  };

  function isValidDateFormat(dateString: any) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  return (
    <>
      <div className="h-full min-w-[500px] bg-white dark:bg-dark-primary flex flex-col p-[25px] items-center gap-[20px] text-left text-mini shadow text-darkslategray">
        <h2 className="text-center text-2xl text-basic dark:text-dark-primary">
          Edit Passenger
        </h2>
        <Divider />
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="self-stretch flex gap-5 flex-row pt-0  justify-between px-0 items-center">
            <SelectBox
              errors={errors}
              control={control}
              fieldName={"type"}
              label="Type"
              options={PassengersType}
              selectedOption={
                PassengersType.filter(
                  (ps: any) => ps.value === passenger.type
                )[0]
              }
              optionContainerClasses="dark:bg-dark-secondary"
              inputClasses="border rounded border-gray-200 pl-3 focus:outline-none focus:border-basic dark:text-dark-primary dark:bg-dark-primary font-normal text-primary"
              labelClasses="dark:text-dark-primary"
              checkIconClasses="text-basic"
              optionClasses="text-primary dark:text-dark-primary"
              optionHoverClasses="bg-primary dark:bg-dark-primary"
            />
            <SelectBox
              errors={errors}
              control={control}
              fieldName={"gender"}
              label="Gender"
              options={PassengersGenders}
              selectedOption={
                PassengersGenders.filter(
                  (ps: any) => ps.value === passenger.gender
                )[0]
              }
              optionContainerClasses="dark:bg-dark-secondary"
              inputClasses="border rounded border-gray-200 pl-3 focus:outline-none focus:border-basic dark:text-dark-primary dark:bg-dark-primary font-normal text-primary"
              labelClasses="dark:text-dark-primary"
              checkIconClasses="text-basic"
              optionClasses="text-primary dark:text-dark-primary"
              optionHoverClasses="bg-primary dark:bg-dark-primary"
              containerClasses="w-[50px]"
            />
          </div>
          <div className="self-stretch flex flex-row pt-0 px-0 items-center justify-start">
            <div className="flex-1 rounded-8xs flex flex-col items-start justify-start">
              <div className="self-stretch relative leading-[130%]">
                <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 mt-2 items-center w-full">
                  <InputField
                    label="First Name"
                    register={register}
                    type="text"
                    required={true}
                    errors={errors}
                    placeHolder="First Name"
                    fieldName={`first_name`}
                    defaultValue={getValues("first_name")}
                    inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal focus:outline-none focus:border-basic"
                  />
                  <InputField
                    label="Last Name"
                    register={register}
                    type="text"
                    errors={errors}
                    placeHolder="Last Name"
                    fieldName={`last_name`}
                    defaultValue={getValues("last_name")}
                    inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal focus:outline-none focus:border-basic"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row pt-0  justify-between px-0 items-center">
            <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 mt-2 items-center w-full">
              <CalendarBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName="dob"
                label="DOB"
                defaultValue={
                  isValidDateFormat(getValues("dob")) ? getValues("dob") : null
                }
                inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal focus:outline-none focus:border-basic border border-[#E5E7EB]"
                labelClasses="mb-2 text-primary dark:text-dark-primary font-normal"
              />
              <CountryBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName="nationality"
                label="Nationality"
                defaultValue={getValues("nationality")}
                inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal focus:outline-none focus:border-basic"
                labelClasses="mb-2 text-primary dark:text-dark-primary font-normal"
                optionBoxClasses="dark:bg-dark-secondary"
                optionClasses="dark:text-dark-primary"
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 mt-2 items-center w-full">
              <InputField
                label="Passport No."
                register={register}
                type="text"
                errors={errors}
                placeHolder="Passport No."
                fieldName={`passport_no`}
                defaultValue={getValues("passport_no")}
                inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal focus:outline-none focus:border-basic"
                labelClasses="text-sm mb-2"
              />
              <CalendarBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName="passport_expiry"
                label="Passport Expiry"
                defaultValue={
                  isValidDateFormat(getValues("passport_expiry"))
                    ? getValues("passport_expiry")
                    : null
                }
                labelClasses="mb-2 text-primary dark:text-dark-primary font-normal"
                inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal focus:outline-none focus:border-basic border border-[#E5E7EB]"
              />
            </div>
          </div>
          <div className="flex gap-5 w-full mt-3">
            {/* <button
              className="w-full button btn bg-basic text-white rounded-md p-2"
              onClick={() => {
                hideForm(false);
              }}
            >
              Close
            </button> */}
            <button
              className="w-full btn bg-basic text-white rounded-md p-2"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PassengerEdit;
