import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { GET_PAGE_DETAILS } from "./graphql/Query";
import DestinationPageContent from "./components/DestinationPageContent";
import FareCardSettings from "./components/FareCardSettings";
import DestinationDetail from "../DestinationAdd/components/DestinationDetail/DestinationDetail";
import { UPDATE_DESTINATION } from "./components/DestinationDetail/graphql/Mutation";
import { useFileUpload } from "@nhost/react";
import { toast } from "react-toastify";
import { DELETE_TIP } from "../FlightTips/graphql/Mutation";
import FareCardSetting from "./components/FareCardSettings/FareCardSetting";
import { UPDATE_FARE_SETTINGS } from "./components/FareCardSettings/graphql/Mutation";
import {
  INSERT_SEO,
  UPDATE_SEO,
} from "./components/SeoSettings/graphql/Mutation";
import BrandTipSection from "~/components/common/BrandTipSection";
import {
  INSERT_TIPS,
  UPDATED_TIPS,
} from "../AirlinesUpdate/components/TipsSection/graphql/Mutation";
import BrandSeoSetting from "~/components/common/BrandSeoSetting";
import DestinationFares from "./components/DestinationFares";

const DestinationUpdate = () => {
  const { page_id } = useParams();

  const { loading, data, refetch } = useQuery(GET_PAGE_DETAILS, {
    variables: { page_id: page_id },
  });

  const pageData = data?.brd_flights_pages?.at(0);
  const tips = pageData?.brd_flights_pages_tips;

  const [logoFile, setLogoFile] = useState<any | null>(null);
  const [featuredFile, setFeatureFile] = useState<any | null>(null);

  const { upload, progress: logoUploadProgress } = useFileUpload();
  const [updateDestination] = useMutation(UPDATE_DESTINATION);
  const [updateTip] = useMutation(UPDATED_TIPS);
  const [deleteTip] = useMutation(DELETE_TIP);
  const [insertTip] = useMutation(INSERT_TIPS);
  const [updateFareSettings] = useMutation(UPDATE_FARE_SETTINGS);
  const [insertSeo] = useMutation(INSERT_SEO);
  const [updateSeo] = useMutation(UPDATE_SEO);
  const client = useApolloClient();

  if (loading) return <p>Loading ...</p>;
  // ;

  const updateDestinationData = async (inpData: any) => {
    if (logoFile) {
      const logoUploadRes = await upload(logoFile);
      inpData["backgroundImage"] = logoUploadRes?.id || null;
    } else {
      inpData["image"] = pageData?.featured_image;
    }
    if (featuredFile) {
      const featuredFileRes = await upload(featuredFile);
      inpData["featuredImage"] = featuredFileRes?.id || null;
    } else {
      inpData["featureImage"] = pageData?.featured_destination_image;
    }
    let originName;
    if (inpData?.departure?.city_name?.toLowerCase()) {
      originName = inpData?.departure?.city_name?.toLowerCase();
    } else {
      originName = pageData?.origin_name.toLowerCase();
    }
    let destinationName;
    if (inpData?.arrival?.city_name?.toLowerCase()) {
      destinationName = inpData?.destination?.city_name?.toLowerCase();
    } else {
      destinationName = pageData?.destination_name.toLowerCase();
    }
    const res = await updateDestination({
      variables: {
        id: pageData?.id,
        data: {
          origin_name: inpData?.departure?.name,
          destination_name: inpData?.arrival?.name,
          origin_iata: inpData?.departure?.iata_code,
          destination_iata: inpData?.arrival?.iata_code,
          featured_image: inpData?.backgroundImage || null,
          // show_in_footer: inpData?.footerFlightLinks,
          airline_iata: inpData?.airline.iata,
          base_price: inpData?.basePrice ? inpData?.basePrice : 0,
          main_heading: inpData?.mainHeading,
          slug: inpData?.slug
            ? inpData?.slug
            : `${originName.replaceAll(
                " ",
                "-"
              )}-to-${destinationName?.replaceAll(" ", "-")}`,
          show_in_home: inpData?.homePageHotDestination,
          show_featured_destination: inpData?.featureDestination,
          featured_destination_image: inpData?.featuredImage,
          offline_fares: inpData?.offline_fares,
        },
      },
    });
    if (res?.data?.update_brd_flights_pages?.affected_rows > 0) {
      toast.success("Destination Updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // await client.refetchQueries({
      //   include: "all",
      // });
    }
  };

  const updateTipsSection = async (inpData: any) => {
    inpData?.tips?.map(async (ft: any) => {
      if (ft?.id) {
        // ;
        if (ft?.title !== "") {
          const finalObj: any = {
            heading: ft?.title,
            description: ft?.content,
          };
          if (ft?.icon?.length > 0) {
            const iconRes = await upload(ft?.icon[0]);
            finalObj["icon_url"] = iconRes?.id || null;
          }
          const res = await updateTip({
            variables: {
              id: { id: ft.id },
              data: finalObj,
            },
          });

          if (res?.data?.update_brd_flights_pages_tips_by_pk?.id) {
            toast.success("tips data updated successfully");
            await client.refetchQueries({
              include: "all",
            });
          }
        } else if (ft?.title === "" && ft?.content === "") {
          // ;
          const res = await deleteTip({
            variables: {
              id: ft?.id,
            },
          });
        }
      } else {
        if (ft?.title) {
          const finalObj: any = {
            heading: ft?.title,
            description: ft?.content,
            flight_page_id: pageData?.id,
          };
          if (ft?.icon?.length > 0) {
            // ;
            const iconRes = await upload(ft?.icon[0]);
            // ;
            finalObj["icon_url"] = iconRes?.id || null;
          }
          const res = await insertTip({
            variables: {
              data: finalObj,
            },
          });
          if (res?.data?.insert_brd_flights_pages_tips_one?.id) {
            toast.success("Tips data updated successfully");
            await client.refetchQueries({
              include: "all",
            });
          }
        }
      }
    });
  };

  const updateFareCard = async (inpData: any) => {
    const res = await updateFareSettings({
      variables: {
        id: { id: pageData?.id },
        data: inpData,
      },
    });
    if (res?.data?.update_brd_flights_pages_by_pk?.id) {
      toast.success("Fare settings added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.resetStore();
    }
  };

  const insertSeoSettings = async (inpData: any) => {
    if (pageData.brd_seo_settings?.[0]?.id) {
      const res = await updateSeo({
        variables: {
          id: { id: pageData.brd_seo_settings?.[0]?.id },
          data: {
            default_title: inpData?.title,
            default_description: inpData?.description,
            default_keywords: inpData?.keywords,
            canonical_url: inpData?.canonicalUrl,
            og_image_url: inpData?.openGraphImgUrl,
          },
        },
      });

      if (res?.data?.update_brd_seo_setting_by_pk?.id) {
        toast.success("SEO  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertSeo({
        variables: {
          data: {
            brd_id: pageData?.brd_id,
            default_title: inpData?.title,
            default_description: inpData?.description,
            default_keywords: inpData?.keywords,
            canonical_url: inpData?.canonicalUrl,
            og_image_url: inpData?.openGraphImgUrl,

            flight_page_id: page_id,
          },
        },
      });

      if (res?.data?.insert_brd_seo_setting_one?.id) {
        toast.success("SEO  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  const updateSingleTip = async (inpData: any) => {
    const updatePromises = inpData?.tips?.map(async (ft: any) => {
      if (ft?.title !== "") {
        const finalObj: any = {
          heading: ft?.title,
          description: ft?.content,
        };
        if (ft?.icon?.length > 0) {
          const iconRes = await upload(ft?.icon[0]);
          finalObj["icon_url"] = iconRes?.id || null;
        }
        await updateTip({
          variables: {
            id: { id: ft.id },
            data: finalObj,
          },
        });
      }
    });
    Promise.all(updatePromises).then(() => {
      toast.success("Tips updated successfully");
    });
  };

  if (pageData) {
    return (
      <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 ">
        <div className="mb-4 col-span-full xl:mb-2">
          <DestinationDetail
            insertDestinationData={updateDestinationData}
            setLogoFile={setLogoFile}
            setFeatureFile={setFeatureFile}
            pageData={pageData}
          />
          <BrandTipSection
            type="destination"
            tips={tips}
            updateSingleTip={updateSingleTip}
            refetchPageDetail={refetch}
          />
          <FareCardSetting
            insertFareCard={updateFareCard}
            pageData={pageData}
          />
          <DestinationFares />
          <DestinationPageContent page={pageData} />

          <BrandSeoSetting
            seo={pageData.brd_seo_settings?.[0]}
            insertSeoSettings={insertSeoSettings}
            pageType="Destination Page"
          />
        </div>
      </div>
    );
  } else {
    return <p>No page details found</p>;
  }
};

export default DestinationUpdate;
