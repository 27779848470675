import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, TextField, InputAdornment } from "@mui/material";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { nhost } from "~/lib/nhost";
import brandsStore from "~/store/brandsStore/brandsStore";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { INSERT_SERVICE } from "~/graphql/brd_services/Mutation";
import { toast } from "react-toastify";
import { GET_BRD_SERVICES_CATEGORY } from "~/graphql/brd_services_category/Query";
import { Autocomplete } from "@mui/material";
import { useParams } from "react-router-dom";
import ContentEditor from "~/components/common/ContentEditor";

type FormValues = {
  name: string;
  title_color: string;
  slug: string;
  description: string;
  price: number;
  image: FileList;
  brd_id?: string;
  category_id?: string;
  bg_color: string;
  imageBG: FileList;
};

const AddService = () => {
  const [insertService] = useMutation(INSERT_SERVICE);
  const client = useApolloClient();
  const { register, handleSubmit, setValue, reset } = useForm<FormValues>();
  const [content, setContent] = useState<any>("");
  const [Loading, setLoading] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
  const { brands } = brandsStore();
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageBGUrl, setImageBGUrl] = useState<string>("");
  const [imageId, setImageId] = useState<string | null>(null);
  const [imageBGId, setImageBGId] = useState<string | null>(null);
  const [title, setTitle] = useState<any>("");
  const [description, setDescription] = useState<any>("");

  const brdIds = brands.length > 0 ? brands.map((brand: any) => brand.id) : [];
  const { brd_id } = useParams();

  const convertImageToWebP = async (file: any) => {
    return new Promise((resolve, reject): any => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(
                new File([blob], file.name.replace(/\.\w+$/, ".webp"), {
                  type: "image/webp",
                })
              );
            } else {
              reject(new Error("Conversion to WebP failed"));
            }
          },
          "image/webp",
          0.8
        );
      };

      img.onerror = (error) => reject(error);
    });
  };

  const uploadImage = async (file: File): Promise<string> => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await nhost.storage.upload({
        file,
      });
      if (response.error) throw new Error(response.error.message);
      return response.fileMetadata.id;
    } catch (error) {
      throw error;
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true);
    try {
      if (imageId) {
        const serviceData = {
          title: title,
          title_color: data.title_color,
          bg_color: data.bg_color,
          description: description,
          detailed_description: content,
          price: data.price,
          img_id: imageId,
          bg_img_id: imageBGId,
          brd_id: brd_id,
          service_category_id: data.category_id || selectedCategoryId,
          slug: data.slug,
        };

        const response = await insertService({
          variables: { data: serviceData },
        });

        if (response?.data?.insert_brd_services?.affected_rows > 0) {
          client.refetchQueries({ include: "all" });
          toast.success("Service Added Successfully!");
          reset();
          setTitle("");
          setDescription("");
          setContent("");
          setImageUrl("");
          setImageBGUrl("");
          setImageId(null);
          setImageBGId(null);
          await client.refetchQueries({ include: "all" });
        } else {
          toast.error("Error adding service, please try again.");
        }
      } else {
        toast.error("Image is required to add the service.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const { data, loading, error } = useQuery(GET_BRD_SERVICES_CATEGORY, {
    variables: { brdIds },
  });

  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      const { error: imageDeleteError } = await nhost.storage.delete({
        fileId: imageId,
      });
      if (imageDeleteError) {
        // return;
      } else {
        setImageUrl("");
        setImageId(null);
      }
    }
  };
  const handleImageBGRemove = async () => {
    if (imageBGUrl && imageBGId) {
      const { error: imageBGDeleteError } = await nhost.storage.delete({
        fileId: imageBGId,
      });
      if (imageBGDeleteError) {
        // return;
      } else {
        setImageBGUrl("");
        setImageBGId(null);
      }
    }
  };

  const handleSlugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const slugValue = inputValue.replace(/\s+/g, "-");
    setValue("slug", slugValue);
  };

  return (
    <div className="w-full rounded-lg p-5 bg-secondary dark:bg-dark-secondary">
      <h1 className="text-xl text-primary dark:text-dark-primary mb-6">
        Add Services
      </h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="text-primary dark:text-dark-primary flex-col "
      >
        <div className="flex flex-col space-y-4">
          <div className="grid grid-cols-3 gap-3">
            <Autocomplete
              options={data?.brd_services_category || []}
              getOptionLabel={(option: any) => option.name}
              fullWidth
              onChange={(event, newValue) => {
                setSelectedCategoryId(newValue?.id || "");
                setValue("category_id", newValue?.id || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Category"
                  InputLabelProps={{ className: "dark:text-dark-primary" }}
                  variant="outlined"
                  fullWidth
                />
              )}
              className="dark:text-dark-primary"
            />
            <TextField
              label="Title"
              type="text"
              fullWidth
              InputProps={{
                endAdornment: title && (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={async () => {
                        const res = await getAiData(
                          `Write an alternative title for ${title} without quotes.`
                        );
                        await typingEffect(setTitle, res);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Heading Color"
              variant="outlined"
              fullWidth
              {...register("title_color")}
              className="dark:text-dark-primary"
            />
          </div>
          <div className="grid grid-cols-3 gap-3">
            <TextField
              label="Background Color"
              variant="outlined"
              helperText="Add opacity adjusted color"
              fullWidth
              {...register("bg_color")}
              className="dark:text-dark-primary"
            />
            <TextField
              label="Service Slug"
              variant="outlined"
              fullWidth
              {...register("slug", { required: true })}
              className="dark:text-dark-primary"
              onChange={handleSlugChange}
            />
            <TextField
              label="Price"
              variant="outlined"
              type="number"
              fullWidth
              {...register("price", { required: true, valueAsNumber: true })}
              className="dark:text-dark-primary"
            />
          </div>
          <TextField
            {...register("description", { required: true })}
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={3}
            InputProps={{
              endAdornment: title && (
                <InputAdornment position="end">
                  <AutoFixHighIcon
                    color="primary"
                    sx={{ cursor: "pointer" }}
                    onClick={async () => {
                      const res = await getAiData(
                        `Write a short description for my service as its title is  "${title}" without qoutes and to the point.`
                      );
                      await typingEffect(setDescription, res);
                    }}
                  />
                </InputAdornment>
              ),
            }}
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
          />
          <div className="col-span-6 sm:col-full ck-editor relative">
            <label htmlFor="" className="text-primary dark:text-dark-primary">
              Detail Content
            </label>
            <ContentEditor content={content} setContent={setContent} />
            {title && (
              <AutoFixHighIcon
                color="primary"
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "100px",
                  right: "20px",
                }}
                onClick={async () => {
                  setLoading(true);
                  setContent("Generating...");
                  const res = await getAiData(
                    `Write best long and detailed content like previous content for my service as its title is  "${title}": Proper formatted: with proper spacing and headings in inner HTML.`
                  );
                  setContent(res);
                  setLoading(false);
                  setContent(res);
                  // await typingEffect(setContent, res);
                }}
              />
            )}
          </div>
          {imageUrl && (
            <Box position="relative" display="inline-block">
              <IconButton
                size="small"
                onClick={handleImageRemove}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bgcolor: "primary.main",
                  color: "white",
                  zIndex: 1,
                  "&:hover": {
                    bgcolor: "primary.dark",
                  },
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              <img src={imageUrl} alt="Uploaded" style={{ width: "300px" }} />
            </Box>
          )}

          <div className="flex items-center justify-start gap-2">
            <Button
              variant="outlined"
              component="label"
              className="dark:text-dark-primary w-[250px]"
            >
              Upload Thumbnail
              <input
                type="file"
                hidden
                {...register("image", { required: true })}
                onChange={async (e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    const webpFile: any = await convertImageToWebP(file);
                    //
                    const id = await uploadImage(webpFile);
                    setImageId(id);
                    const uploadedImgUrl = `${process.env.REACT_APP_NHOST_STORAGE_URL}${id}`;
                    setImageUrl(uploadedImgUrl);
                  }
                }}
              />
            </Button>
            <p className=" text-[10px]">
              Image Size: <br />
              300*450
            </p>
          </div>
          {imageBGUrl && (
            <Box position="relative" display="inline-block">
              <IconButton
                size="small"
                onClick={handleImageBGRemove}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bgcolor: "primary.main",
                  color: "white",
                  zIndex: 1,
                  "&:hover": {
                    bgcolor: "primary.dark",
                  },
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              <img src={imageBGUrl} alt="Uploaded" style={{ width: "300px" }} />
            </Box>
          )}
          {/* for bgHeader */}
          <div className="flex items-center justify-start gap-2">
            <Button
              variant="outlined"
              component="label"
              className="dark:text-dark-primary w-[250px]"
            >
              Upload Background
              <input
                type="file"
                hidden
                {...register("imageBG", { required: true })}
                onChange={async (e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    const webpFile: any = await convertImageToWebP(file);
                    //
                    const id = await uploadImage(webpFile);
                    setImageBGId(id);
                    const uploadedImgBGUrl = `${process.env.REACT_APP_NHOST_STORAGE_URL}${id}`;
                    setImageBGUrl(uploadedImgBGUrl);
                  }
                }}
              />
            </Button>{" "}
            <p className=" text-[10px]">
              Image Size: <br />
              1280*500
            </p>
          </div>
          <div className="flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="w-[10%]"
            >
              {Loading ? "Adding..." : "Add"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddService;
