import { gql } from "@apollo/client";

export const INSERT_SELECTED_FL_COUNTRY = gql`
  mutation insertSelectedFlCOUNTRY(
    $data: [brd_fl_selected_countries_insert_input]!
  ) {
    insert_brd_fl_selected_countries(objects: $data) {
      affected_rows
    }
  }
`;

export const DELETE_SELECTED_FL_COUNTRY = gql`
  mutation deleteSelectedFlCOUNTRY($ids: [uuid!]!) {
    delete_brd_fl_selected_countries(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;
