import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useState } from "react";
import TipsSectionCard from "./components/TipsSectionCard";
import AddTip from "./components/AddTip";

const BrandTipSection = ({
  tips,
  updateSingleTip,
  addTip,
  type,
  refetchPageDetail,
}: any) => {
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [openAddtip, setOpenAddTip] = useState<any>(false);

  const handleAddTip = () => {
    setOpenAddTip(true);
  };

  const onSubmit = (inpData: any) => {
    updateSingleTip(inpData);
  };

  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
      <div className="flex justify-between mb-5">
        <h1 className="text-xl">Important Tips Section</h1>
        <Button variant="contained" onClick={handleAddTip}>
          Add more tips
        </Button>
      </div>
      {openAddtip && (
        <AddTip
          setOpenAddTip={setOpenAddTip}
          airlinesId={tips?.[0]?.airlines_page_id}
          type={type}
          refetchPageDetail={refetchPageDetail}
        />
      )}
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 mt-7">
          {tips?.map((tip: any, index: number) => (
            <TipsSectionCard
              key={index}
              index={index}
              ft={tip}
              register={register}
              setValue={setValue}
              errors={errors}
              cardType="defaultCard"
            />
          ))}
        </div>
        <Button type="submit" sx={{ marginTop: "20px" }} variant="contained">
          Save Value Section
        </Button>
      </Box>
    </div>
  );
};

export default BrandTipSection;
