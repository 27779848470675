import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CustomList from "../CustomList";
import TouchAppIcon from "@mui/icons-material/TouchApp";

const DigitalAgencBar = ({ role, activeDropdown, setActiveDropdown }: any) => {
  return (
    <>
      {role === "admin" ? (
        <div className="text-primary mt-[70px] z-30">
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Projects",
                icon: <TouchAppIcon />,
                link: "/admin/digitalAgen/projects",
              },
            ]}
          />
        </div>
      ) : null}
    </>
  );
};

export default DigitalAgencBar;
