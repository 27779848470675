import React from "react";
import InputField from "~/components/FormInputs/InputField";

export default function StayCostRow({
  suggestion_cost_detail,
  register,
  errors,
  index,
  watch,
  inquiry,
  type,
}: any) {
  const passenger = (() => {
    switch (type) {
      case "Stay":
        return suggestion_cost_detail?.stay_inq_room;
    }
  })();

  const basicFare = watch(`suggestion_cost.${index}.basic_fare`);
  const tax = watch(`suggestion_cost.${index}.tax_price`);
  const atol = watch(`suggestion_cost.${index}.atol_price`);

  return (
    <div className="flex flex-row gap-4">
      <div className="w-[60%] pt-[30px] text-primary dark:text-dark-primary">
        {passenger?.booked_by}
      </div>
      {/* Flight Offer Price */}
      <InputField
        label="Basic Fare"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Basic Fare"
        fieldName={`suggestion_cost.${index}.basic_fare`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
      {/* Flight Cost Price */}
      <InputField
        label="Tax"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Tax"
        fieldName={`suggestion_cost.${index}.tax_price`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
      <InputField
        label="Atol"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Atol"
        fieldName={`suggestion_cost.${index}.atol_price`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
      />
      {/* Flight Cost Price */}
      <InputField
        label={`Sale Price ( cost - ${
          (basicFare ? parseFloat(basicFare) : 0) +
          (tax ? parseFloat(tax) : 0) +
          (atol ? parseFloat(atol) : 0)
        })`}
        register={register}
        errors={errors}
        type="number"
        placeHolder="Sale Price"
        fieldName={`suggestion_cost.${index}.sale_price`}
        required={true}
        inputClasses="focus:outline-none focus:border-basic"
        disabled={inquiry?.invoice_id ? true : false}
      />
    </div>
  );
}
