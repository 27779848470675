import { Avatar, Checkbox, Chip, Tooltip } from "@mui/material";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import UnReadCount from "./UnReadCount";
import { useNavigate, useParams } from "react-router-dom";
import { useUserDefaultRole } from "@nhost/react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UnreadCount from "./UnReadCount/UnreadCount";
import { useMutation, useSubscription } from "@apollo/client";
import { GET_UNREAD_COUNT } from "./UnReadCount/graphql/Query";
import { useEffect, useState } from "react";
import useWhatsappMessageStatus from "~/hooks/useWhatsappMessageStatus";
import { UPDATE_WHATSAPP_MESSAGE_STATUS } from "~/graphql/brd_whatsapp_messages_status/Mutation";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LabelIcon from "@mui/icons-material/Label";

const ChatRow = ({
  conversation,
  user,
  setContactNumbers,
  label = false,
  setShowWhatsappChatBox,
  setShowWhatsappSidebar,
  selectedConversations,
  setSelectedConversations,
}: any) => {
  const navigate = useNavigate();
  const role = useUserDefaultRole();

  const [messagesStatuses, setMessageStatuses] = useState<any>([]);

  useEffect(() => {
    const userMessages = conversation?.brd_whatsapp_messages?.filter(
      (item: any) => {
        return item.sender_role === "user";
      }
    );

    const messageStatus = userMessages?.map((item: any) => {
      return item.brd_whatsapp_messages_statuses;
    });

    const unreadMessageStatus = messageStatus?.filter((item: any) => {
      return item?.[0].key === "delivered";
    });

    setMessageStatuses(unreadMessageStatus);
  }, [conversation]);

  // const { messagesStatuses } = useWhatsappMessageStatus(
  //   conversation?.id && conversation?.id
  // );

  const [updateMessageStatus] = useMutation(UPDATE_WHATSAPP_MESSAGE_STATUS);

  const seenUnreadMessages = () => {
    messagesStatuses?.map(async (item: any) => {
      await updateMessageStatus({
        variables: {
          id: item[0].id,
          data: { key: "read" },
        },
      });
    });
  };

  const senderName = conversation?.brd_whatsapp_messages
    ?.filter((item: any) => {
      return item?.sender?.displayName?.length > 0;
    })
    .pop();

  return (
    <>
      <div
        className="bg-whatsappSecondaryBg dark:bg-dark-secondary  hover:bg-whatsappPrimaryBg cursor-pointer"
        onClick={() => {
          if (!selectedConversations) {
            !user &&
              navigate(
                `/${role}/whatsapp/${label ? "community/" : ""}${
                  conversation?.id
                }`
              );

            const isSmallScreen = window.innerWidth < 768;
            if (isSmallScreen) {
              setShowWhatsappSidebar && setShowWhatsappSidebar(false);
            }
            setShowWhatsappChatBox && setShowWhatsappChatBox(true);

            setContactNumbers &&
              setContactNumbers((prevNumbers: any) => {
                if (
                  prevNumbers.some((prevUser: any) => prevUser.id === user.id)
                ) {
                  return prevNumbers;
                } else {
                  return [...prevNumbers, user];
                }
              });

            seenUnreadMessages();
          }
        }}
      >
        <div className="flex items-center pt-1 ml-3 gap-2">
          <p className="rounded text-white px-[6px] text-[10px] bg-green-600">
            {conversation?.brd_list?.name}
          </p>
          {conversation?.consultant_detail?.displayName && (
            <Tooltip
              placement="top"
              title={conversation?.consultant_detail?.displayName}
            >
              <SupportAgentIcon sx={{ fontSize: "17px" }} />
            </Tooltip>
          )}
          {conversation?.whatsapp_labels?.length > 0 && (
            <Tooltip
              placement="top"
              title={
                <div className="flex items-center gap-1">
                  {conversation?.whatsapp_labels?.map((item: any) => {
                    return (
                      <p className="text-white">{item?.label_detail?.name},</p>
                    );
                  })}
                </div>
              }
            >
              <LabelIcon sx={{ fontSize: "17px" }} />
            </Tooltip>
          )}
        </div>
        <div className="flex items-center">
          {selectedConversations && (
            <div className="pl-[15px]">
              <Checkbox
                checked={selectedConversations?.includes(conversation.id)}
                onClick={() => {
                  if (selectedConversations?.includes(conversation.id)) {
                    const newSelectedConv = selectedConversations?.filter(
                      (item: any) => item !== conversation.id
                    );
                    setSelectedConversations(newSelectedConv);
                  } else {
                    setSelectedConversations([
                      ...selectedConversations,
                      conversation.id,
                    ]);
                  }
                }}
              />
            </div>
          )}

          <div className="pl-[15px] pb-[13px]">
            <AccountCircleIcon
              sx={{
                color: "#DFE5E7",
                fontSize: "50px",
              }}
            />
          </div>
          <div className="pb-[13px] px-[15px] border-b dark:border-dark-secondary w-full">
            <div className="flex items-center justify-between">
              <h1 className="text-[16px] text-black dark:text-white">
                {conversation?.whatsapp_name
                  ? conversation?.whatsapp_name
                  : user?.displayName
                  ? user?.displayName
                  : conversation?.name}{" "}
                {label && `(${conversation?.brd_community_users?.length})`}
              </h1>
              <p className="text-[12px] dark:text-dark-primary">
                {conversation?.last_message_time
                  ? getTimeReadableFormat(conversation?.last_message_time)
                  : "00:00"}
              </p>
            </div>
            <div className="flex items-center justify-between w-full">
              <p className="text-[14px] h-[18px] overflow-hidden truncate w-[150px] dark:text-dark-primary">
                {conversation?.last_message
                  ? conversation?.last_message
                  : user?.phoneNumber
                  ? user?.phoneNumber
                  : "..."}
              </p>
              <div className="flex items-center gap-2 w-[150px] justify-end">
                {senderName?.sender?.displayName && (
                  <p className="text-[12px] text-end dark:text-dark-primary overflow-hidden truncate w-[130px]">
                    {senderName?.sender?.displayName}
                  </p>
                )}

                {
                  // label ? (
                  //   <span className="text-[12px] flex ">
                  //     <span>
                  //       {" "}
                  //       {conversation.brd_whatsapp_community_users.length}{" "}
                  //     </span>
                  //     {/* <span>Members</span> */}
                  //   </span>
                  // ) : (
                  //   <UnReadCount
                  //     conversation_id={conversation?.id}
                  //     last_view_time={
                  //       conversation?.last_view_time || conversation?.created_at
                  //     }
                  //     active={conversation_id === conversation?.id ? true : false}
                  //   />
                  // )
                }
                <UnreadCount messagesStatuses={messagesStatuses} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatRow;
