import useFlCountries from "~/hooks/useFlCountries";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useState, useEffect } from "react";
import useSelectedFlCountryWithBrdId from "~/hooks/useSelectedFlCountryWithBrdId";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
  INSERT_SELECTED_FL_COUNTRY,
  DELETE_SELECTED_FL_COUNTRY,
} from "~/graphql/brd_fl_selected_countries/Mutation";
import { Button } from "@mui/material";

const ActivateCountriesComp = () => {
  const { brd_id } = useParams();

  const { flCountries } = useFlCountries();

  const [loading, setLoading] = useState(false);

  const [selectedCountryCode, setSelectedCountryCode] = useState<any>([]);

  const { selectedFlCountryWithBrdId, refetchSelectedCountry } =
    useSelectedFlCountryWithBrdId(brd_id);

  useEffect(() => {
    refetchSelectedCountry();
  }, []);

  useEffect(() => {
    setSelectedCountryCode(
      selectedFlCountryWithBrdId?.map((item: any) => item.country_code)
    );
  }, [selectedFlCountryWithBrdId]);

  const [insertSelectedCountry] = useMutation(INSERT_SELECTED_FL_COUNTRY);
  const [deleteSelectedCountry] = useMutation(DELETE_SELECTED_FL_COUNTRY);

  const updateSkills = async () => {
    try {
      setLoading(true);
      let status = true;
      const defIds = selectedFlCountryWithBrdId?.map(
        (item: any) => item.country_code
      );

      const insertPayload = selectedCountryCode
        ?.filter((item: any) => !defIds.includes(item))
        ?.map((countryCode: any) => ({
          country_code: countryCode,
          brd_id,
        }));

      if (insertPayload.length > 0) {
        const res = await insertSelectedCountry({
          variables: {
            data: insertPayload,
          },
        });

        if (!res?.data?.insert_brd_fl_selected_countries?.affected_rows) {
          status = false;
        }
      }

      const deletePayload = selectedFlCountryWithBrdId
        ?.filter(
          (item: any) => !selectedCountryCode.includes(item.country_code)
        )
        ?.map((item: any) => item.id);

      if (deletePayload.length > 0) {
        const res = await deleteSelectedCountry({
          variables: {
            ids: deletePayload,
          },
        });

        if (!res?.data?.delete_brd_fl_selected_countries?.affected_rows) {
          status = false;
        }
      }

      if (status === true) {
        toast.success("Updated SuccessFully");
        refetchSelectedCountry();
        setLoading(false);
      }
    } catch (err: any) {
      toast.error(err);
      refetchSelectedCountry();
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-[18px] font-bold">ACTIVATE COUNTRIES</h1>
        <Button variant="contained" onClick={updateSkills}>
          {loading ? "Updating..." : "Update Countries"}
        </Button>
      </div>
      <div className="grid grid-cols-5 mt-5">
        {flCountries?.map((item: any) => {
          return (
            <FormControlLabel
              control={<Checkbox />}
              onChange={() => {
                setSelectedCountryCode(
                  (prev: any) =>
                    prev.some((code: any) => code === item.code)
                      ? prev.filter((code: any) => code !== item.code) // Remove if already selected
                      : [...prev, item.code] // Add if not selected
                );
              }}
              checked={selectedCountryCode.includes(item.code)}
              label={item.name}
            />
          );
        })}
      </div>
    </>
  );
};

export default ActivateCountriesComp;
