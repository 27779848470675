import React, { useState } from "react";
import { Box } from "@mui/material";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import SuggestionNav from "./components/SuggestionNav";
import TabContentRenderer from "./components/TabContentRenderer";

const TabPanel = ({ children, value, index, ...other }: any) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="h-full"
    >
      {value === index && <Box sx={{ p: 0, height: "100%" }}>{children}</Box>}
    </div>
  );
};

interface InquiryTabPanelProps {
  value: number;
  tabs: { label: string; details: any[] }[];
  inquiry: any;
}

const NewSuggestionCard: React.FC<InquiryTabPanelProps> = ({
  value,
  tabs,
  inquiry,
}) => {
  const user_id = useUserId();
  const defaultRole = useUserDefaultRole();
  const [active, setActive] = useState<any>(tabs?.[0]?.label); // Set the first tab as active

  // Function to get suggestions based on the active tab
  const getSuggestionsForTab = (tabLabel: string) => {
    switch (tabLabel) {
      case "Hotel":
        return inquiry?.stay_inq_details[0]?.stay_inq_suggestions || [];
      case "Cars":
        return inquiry?.car_inq_details[0]?.car_inq_suggestions || [];
      case "Train":
        return inquiry?.train_inq_details[0]?.train_inq_suggestions || [];
      case "Visa":
        return inquiry?.visa_inq_details[0]?.visa_inq_suggestions || [];
      case "Flights":
      default:
        return inquiry?.inq_suggestions || [];
    }
  };

  return (
    <>
      {tabs.map((tab, index) => {
        const filteredSuggestions = getSuggestionsForTab(tab.label);

        return (
          <TabPanel key={index} value={value} index={index}>
            <div className="flex flex-col h-full">
              {/* Pass the filtered suggestions to SuggestionNav */}
              <SuggestionNav
                suggestions={filteredSuggestions}
                active={active}
                setActive={setActive}
                inquiry={inquiry}
                tab={tab.label}
              />

              <div className="self-stretch flex flex-col items-start justify-start">
                {/* Pass the filtered suggestions to TabContentRenderer */}
                <TabContentRenderer
                  tabLabel={tab.label}
                  suggestions={filteredSuggestions} // Pass the filtered suggestions
                  inquiry={inquiry}
                  active={active}
                  setActive={setActive}
                />
              </div>
            </div>
          </TabPanel>
        );
      })}
    </>
  );
};

export default NewSuggestionCard;
