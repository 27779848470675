import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  TextField,
  FormControl,
  Autocomplete,
  Box,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { GET_BRD_VISA_TYPES } from "~/graphql/brd_visa_types/Query";
import { INSERT_BRD_VISAS } from "~/graphql/brd_visas/Mutation";

const AddVisas = ({ brd_id }: any) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<any>();

  const client = useApolloClient();
  const [insertVisa, { loading }] = useMutation(INSERT_BRD_VISAS);

  const { data } = useQuery(GET_BRD_VISA_TYPES, {
    variables: {
      brd_id,
    },
  });

  const onSubmit = async (data: any) => {
    try {
      const response = await insertVisa({
        variables: {
          data: [
            {
              brd_id,
              type: data.type,
              validity: data.validity,
              price: data.price,
            },
          ],
        },
      });

      if (response?.data?.insert_brd_visas?.affected_rows > 0) {
        await client.refetchQueries({
          include: "all",
        });
        reset();
        toast.success("Visa Added Successfully");
      }
    } catch (error) {
      console.error("Error inserting visa:", error);
      toast.error("Error adding visa");
    }
  };

  return (
    <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <div className="bg-secondary mb-4 sm:p-6 rounded-lg shadow dark:bg-dark-secondary">
        <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
          Add Visas
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="text-primary dark:text-dark-primary flex-col"
        >
          <div className="flex flex-col space-y-4">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("type", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.id);
                  }}
                  options={data?.brd_visa_types ? data?.brd_visa_types : []}
                  getOptionLabel={(option: any) => option?.name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Type"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                        el && errors["type"] && el.focus();
                      }}
                      error={errors["type"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />

            <TextField
              label="Validity"
              variant="outlined"
              fullWidth
              required
              {...register("validity")}
              className="dark:text-dark-primary"
            />
            <TextField
              label="Price"
              variant="outlined"
              type="number"
              fullWidth
              required
              {...register("price")}
              className="dark:text-dark-primary"
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? "Adding..." : "Add"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddVisas;
