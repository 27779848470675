import { gql } from "@apollo/client";

export const ADD_SUGGESTION = gql`
  mutation MyMutation($suggestions: [inq_suggestion_insert_input!]!) {
    insert_inq_suggestion(objects: $suggestions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export const ADD_STAY_SUGGESTION = gql`
  mutation MyMutation($suggestions: [stay_inq_suggestion_insert_input!]!) {
    insert_stay_inq_suggestion(objects: $suggestions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export const ADD_CAR_SUGGESTION = gql`
  mutation MyMutation($suggestions: [car_inq_suggestion_insert_input!]!) {
    insert_car_inq_suggestion(objects: $suggestions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export const ADD_TRAIN_SUGGESTION = gql`
  mutation MyMutation($suggestions: [train_inq_suggestion_insert_input!]!) {
    insert_train_inq_suggestion(objects: $suggestions) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_INQ = gql`
  mutation MyMutation($inq: [inq_list_insert_input!]!) {
    insert_inq_list(objects: $inq) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const ADD_VISA_SUGGESTION = gql`
  mutation InsertVisaSuggestion($data: [visa_inq_suggestion_insert_input!]!) {
    insert_visa_inq_suggestion(objects: $data) {
      affected_rows
    }
  }
`;

