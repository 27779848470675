import React, { useState } from "react";
import StaySuggestionForm from "../../../AddSuggestion/components/SuggestionForm/Stay";
import AddSuggestion from "../../../AddSuggestion";
import SuggestionForm from "../../../AddSuggestion/components/SuggestionForm";
import { initialDefaultValue } from "~/data/InitalDefaultValue";
import CarSuggestionForm from "../../../AddSuggestion/components/SuggestionForm/Cars";
import TrainSuggestionForm from "../../../AddSuggestion/components/SuggestionForm/Train";
import VisaSuggestionForm from "../../../AddSuggestion/components/SuggestionForm/Visa";

const SuggestionTabComponent = ({
  tab,
  inquiry,
  setShowAddModal,
  setActive,
  passengers,
  stayPassengers,
  trainPassengers,
  carPassengers,
}: any) => {
  const [defaultValue, setDefaultValue] = useState<any>(initialDefaultValue);
  const [showModal, setShowModal] = useState(false);
  const [activeSuggestionId, setActiveSuggestionId] = useState(null); // Track active suggestion ID

  const closeModal = () => setShowAddModal(false);
  switch (tab) {
    case "Hotel":
      return (
        <div>
          <StaySuggestionForm
            showModal={setShowAddModal}
            onCloseModal={closeModal}
            setActive={setActiveSuggestionId}
            inquiry_id={inquiry.id}
            inquiry={inquiry}
            user_id={inquiry?.users?.id}
            passengers={stayPassengers}
            type={tab}
            setShowAddModal={setShowAddModal}
          />
        </div>
      );
    case "Cars":
      return (
        <>
          <CarSuggestionForm
            showModal={setShowAddModal}
            onCloseModal={closeModal}
            setActive={setActiveSuggestionId}
            inquiry_id={inquiry.id}
            inquiry={inquiry}
            user_id={inquiry?.users?.id}
            passengers={carPassengers}
            type={tab}
            setShowAddModal={setShowAddModal}
          />
        </>
      );
    case "Train":
      return (
        <>
          <TrainSuggestionForm
            showModal={setShowAddModal}
            onCloseModal={closeModal}
            setActive={setActiveSuggestionId}
            inquiry_id={inquiry.id}
            inquiry={inquiry}
            user_id={inquiry?.users?.id}
            passengers={trainPassengers}
            type={tab}
            setShowAddModal={setShowAddModal}
          />
        </>
      );
    // Add more cases for other tabs and their corresponding components
    case "Flights":
      return (
        <>
          <AddSuggestion
            setShowAddModal={setShowAddModal}
            inquiry_id={inquiry.id}
            inquiry={inquiry}
            brand_name={inquiry.brd_list.name}
            brand_url={inquiry.brd_list.subdomain}
            brand_domain={inquiry.brd_list.domain}
            passengers={passengers}
            arrival={inquiry?.to}
            user_id={inquiry?.users?.id}
            user_email={inquiry?.users?.email}
            user_phone={inquiry?.users?.phoneNumber}
            setActive={setActive}
            type={tab}
          />
          {/* <SuggestionForm
            setActive={setActive}
            defaultValue={defaultValue}
            inquiry_id={inquiry_id}
            inquiry={inquiry}
            user_phone={user_phone}
            user_email={user_email}
            brand_domain={brand_domain}
            brand_url={brand_url}
            brand_name={brand_name}
            passengers={passengers}
            user_id={user_id}
            setShowAddModal={setShowAddModal}
          /> */}
        </>
      ); // Fallback for unexpected tab
    case "Visa":
      return (
        <>
          <VisaSuggestionForm
            showModal={setShowAddModal}
            setShowAddModal={setShowAddModal}
            onCloseModal={closeModal}
            passengers={passengers}
            visaInqId={inquiry?.visa_inq_details?.[0]?.id}

          />
        </>
      )
  }
};

export default SuggestionTabComponent;
