import { gql } from "@apollo/client";

export const GET_FL_PROJECTS = gql`
  query getFlProjects($queryParam: String!) {
    flGetProjects(arg1: { queryParam: $queryParam }) {
      request_id
      result
      status
    }
  }
`;
