import SendIcon from "@mui/icons-material/Send";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useRef, useState } from "react";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import { useStopwatch } from "react-timer-hook";
import { useRecorder } from "~/hooks/useRecorder";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { nhost } from "~/lib/nhost";
import { CONVERT_FILE } from "~/graphql/actions/convert/Query";
import { URLs } from "~/config/enums";
import { useApolloClient, useMutation } from "@apollo/client";
import moment from "moment";
import { INSERT_MESSAGES } from "../../../graphql/Mutation";
import { useUserId } from "@nhost/react";
import { useParams } from "react-router-dom";
import { useWavesurfer } from "@wavesurfer/react";
import RecordedMessage from "./RecordedMessage";

const SendVoice = ({ setShowRecording, sendAutoReplyMsg, footerType }: any) => {
  const [showAudio, setShowAudio] = useState(false);
  const [fileId, setFileId] = useState<any>(null);
  const [loading, setIsLoading] = useState<any>(null);
  const [quickSend, setQuickSend] = useState<any>(false);
  const [showPauseIcon, setShowPauseIcon] = useState(false);
  const {
    audioUrl,
    waveSurferRef,
    recording,
    fileBlob,
    startRecording,
    stopRecording,
  } = useRecorder();
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  const containerRef = useRef(null);

  const { wavesurfer, isReady, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    url: URLs.FILE_URL + fileId,
    waveColor: "darkgray",
    progressColor: "darkgray",
    height: 40,
  });

  wavesurfer?.on("finish", () => {
    setShowPauseIcon(false);
  });

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause();
  };

  const client = useApolloClient();
  const [insertMessages] = useMutation(INSERT_MESSAGES);

  const { conversation_id } = useParams();

  const nhostUploadFile = async (fileBlob: any) => {
    // const file: any = await convertToAAC(fileBlob);
    // ;
    // Create a File object from the Blob
    const file = new File([fileBlob], "filename.webm", {
      type: "audio/webm",
    });
    // const form: any = new FormData();
    // form.append("bucketId", "default");
    // form.append("file", fileBlob);
    // // const file = form.file;
    const response: any = await nhost.storage.upload({ file });

    if (response?.fileMetadata?.id) {
      const convertFileResponse = await client.query({
        query: CONVERT_FILE,
        variables: {
          url: URLs.FILE_URL + response?.fileMetadata?.id,
        },
      });

      const fileBase64 = convertFileResponse?.data?.convertFile?.data;
      const base64Data = fileBase64.split(";base64,").pop();

      // Convert the base64 data to a binary array
      const binaryData = atob(base64Data);
      const uint8Array = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
      const file = new File([uint8Array], "filename.aac", {
        type: "audio/aac",
      });
      const responseConverted: any = await nhost.storage.upload({ file });
      const fileIdD = responseConverted?.fileMetadata?.id;

      setFileId(fileIdD);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fileBlob) {
      setIsLoading(quickSend ? "Sending.." : "Processing..");
      nhostUploadFile(fileBlob);
    }
    if (!audioUrl) {
      setFileId("");
    }
  }, [audioUrl]);

  useEffect(() => {
    startRecording();
    start();
  }, []);

  useEffect(() => {
    if (quickSend && fileId) {
      if (footerType === "autoReply") {
        insertAutoReplyMessage({ type: "audio", message: fileId });
      } else {
        insertMessageInDb();
      }
    }
  }, [quickSend, fileId]);

  const pauseRec = () => {
    setShowAudio(true);
    stopRecording();
    pause();
  };

  const insertMessageInDb = async () => {
    // Get the current time in UTC
    const currentTime = moment().utc();

    // Format the current time in the desired format
    const formattedTime = currentTime.format("YYYY-MM-DDTHH:mm:ssZ");

    const payload: any = [
      {
        conversation_id,
        sender_role: "brand",
        sender_id: userId,
        text: fileId,
        type: "audio",
        timestamp: formattedTime,
      },
    ];

    const resMessagesInsert = await insertMessages({
      variables: { data: payload },
    });
    setShowRecording(false);
  };

  const insertAutoReplyMessage = async ({ type, message }: any) => {
    await sendAutoReplyMsg({ type, message });
    setShowRecording(false);
  };

  const userId = useUserId();
  const sendMessage = async () => {
    if (fileId) {
      if (footerType === "autoReply") {
        await insertAutoReplyMessage({ type: "audio", message: fileId });
      } else {
        await insertMessageInDb();
      }
    } else {
      pauseRec();
      setQuickSend(true);
      setIsLoading("Sending...");
    }
  };
  // ;

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div className="bg-whatsappPrimaryBg dark:bg-dark-primary px-[16px] py-[10px] flex items-center text-primary dark:text-dark-primary z-10 justify-end gap-5">
      <DeleteIcon
        sx={{
          color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
          cursor: "pointer",
          fontSize: "30px",
        }}
        onClick={() => setShowRecording(false)}
      />
      {loading ? (
        <div className="bg-whatsappSecondaryBg dark:bg-dark-secondary px-[13px] shadow rounded-full py-[7px] flex  items-center gap-3 w-[250px] justify-center text-center">
          {loading}
        </div>
      ) : (
        ""
      )}
      {showAudio ? (
        <>
          {fileId && (
            <div className="bg-whatsappSecondaryBg dark:bg-dark-secondary px-[13px] shadow rounded-full py-[7px] flex  items-center gap-3">
              {showPauseIcon ? (
                <PauseOutlinedIcon
                  sx={{
                    color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                    cursor: "pointer",
                    fontSize: "30px",
                  }}
                  onClick={() => {
                    setShowPauseIcon(false);
                    onPlayPause();
                  }}
                />
              ) : (
                <PlayArrowIcon
                  sx={{
                    color: darkMode ? "#E7E3FCDE" : "#3A3541DE",
                    cursor: "pointer",
                    fontSize: "30px",
                  }}
                  onClick={() => setShowPauseIcon(true)}
                />
              )}

              <RecordedMessage
                fileId={fileId}
                onPlay={showPauseIcon}
                onPlayPause={onPlayPause}
                containerRef={containerRef}
              />

              <h1 className="text-primary dark:text-dark-primary text-[20px] font-medium">
                {" "}
                {days > 0 && (
                  <>
                    <span>{days}</span>:
                  </>
                )}
                {hours > 0 && (
                  <>
                    <span>{hours}</span>:
                  </>
                )}
                <span>{minutes}</span>:<span>{seconds}</span>
              </h1>
            </div>
          )}
        </>
      ) : (
        <>
          <h1 className="text-primary dark:text-dark-primary text-[20px] font-medium">
            {days > 0 && (
              <>
                <span>{days}</span>:
              </>
            )}
            {hours > 0 && (
              <>
                <span>{hours}</span>:
              </>
            )}
            <span>{minutes}</span>:<span>{seconds}</span>
          </h1>
          <div className="w-[180px] border-[#8696a0] border-t-2 border-dotted"></div>
          <div className="p-[3px] text-[#ff3b30] border-[#ff3b30] border-2 rounded-full cursor-pointer flex items-center justify-center">
            <PauseOutlinedIcon
              onClick={() => {
                !loading ? pauseRec() : setQuickSend(true);
              }}
            />
          </div>
        </>
      )}

      <div
        className="p-[7px] text-white bg-whatsappBasic rounded-full cursor-pointer flex items-center justify-center"
        onClick={() => {
          sendMessage();
        }}
      >
        <SendIcon />
      </div>
    </div>
  );
};

export default SendVoice;
