import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const BrandAllPages = () => {
  const { brd_id } = useParams();
  const navigate = useNavigate();

  const handleUpdateClick = (pageName: any) => {
    if (pageName === "Home Page") {
      navigate(`/admin/brands/${brd_id}/pages/home`);
    }

    if (pageName === "Privacy Policy") {
      navigate(`/admin/brands/${brd_id}/pages/privacy-policy`);
    }

    if (pageName === "Terms and Condition") {
      navigate(`/admin/brands/${brd_id}/pages/terms-and-condition`);
    }

    if (pageName === "Case Study") {
      navigate(`/admin/brands/${brd_id}/pages/case-study`);
    }

    if (pageName === "Umrah Packages") {
      navigate(`/admin/brands/${brd_id}/pages/umrah-packages`);
    }
  };

  const pages = [
    { name: "Home Page" },
    { name: "Privacy Policy" },
    { name: "Terms and Condition" },
    { name: "Case Study" },
    { name: "Umrah Packages" },
  ];

  return (
    <div className="tex-primary dark:text-dark-primary">
      <h1 className="mb-3">Brand Pages</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Pages</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pages.map((page, index) => (
              <TableRow key={index}>
                <TableCell>{page.name}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleUpdateClick(page.name)}
                  >
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BrandAllPages;
