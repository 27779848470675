import { GET_SELECTED_FL_COUNTRY_WITH_BRD } from "~/graphql/brd_fl_selected_countries/Query";
import { useQuery } from "@apollo/client";

export default function useSelectedFlCountryWithBrdId(brdId: any) {
  const { data, refetch } = useQuery(GET_SELECTED_FL_COUNTRY_WITH_BRD, {
    variables: {
      brdId,
    },
  });

  return {
    selectedFlCountryWithBrdId: data?.brd_fl_selected_countries,
    refetchSelectedCountry: refetch,
  };
}
