import { gql } from "@apollo/client";

export const GET_SERVICE_TESTIMONIALS = gql`
  query GetTestimonials($brdId: uuid!, $serviceId: uuid!) {
    brd_services_testimonials(
      where: {
        _and: [{ brd_id: { _eq: $brdId } }, { service_id: { _eq: $serviceId } }]
      }
    ) {
      name
      rating
      feedback
      id
      brd_id
      service_id
    }
  }
`;
