import { Option, Class, Field } from "../types/index"; // Adjust the path if necessary

export const options: Option[] = [
  {
    name: "Hotel",
    fields: [
      { label: "Where to", type: "text", name: "Where to" },
      { label: "Dates", type: "text", name: "Dates" },
      { label: "Travelers", type: "text", name: "Travelers" },
    ],
  },
  {
    name: "Train",
    fields: [
      { label: "Origin", type: "text", name: "Origin" },
      { label: "Destination", type: "text", name: "Destination" },
      { label: "Date", type: "text", name: "Date" },
      { label: "Flight-Travelers", type: "text", name: "Flight-Travelers" },
    ],
  },
  {
    name: "Cars",
    fields: [
      { label: "Pick-Up", type: "text", name: "pickUp" },
      { label: "Drop-Off", type: "text", name: "DropOff" },
      { label: "pickupDate&time", type: "text", name: "pickupDateTime" },
      { label: "dropOffDate&time", type: "text", name: "dropOffDateTime" },
      { label: "withDriver", type: "text", name: "withDriver" },
    ],
  },
  {
    name: "Flights",
    fields: [
      {
        label: "Trip Type",
        type: "select",
        name: "Trip Type",
        options: ["RoundTrip", "OneWay"],
      },
      { label: "cabin-class", type: "select", name: "cabin-class" },
      { label: "Going to", type: "text", name: "Going to" },
      { label: "Leaving from", type: "text", name: "Leaving from" },
      { label: "Flight-Travelers", type: "text", name: "Flight-Travelers" },
    ],
  },
  {
    name: "Visa",
    fields: [
      { label: "Name", type: "text", name: "name" },
      { label: "Country", type: "text",  name: "country" }
    ],
  }
];

// Convert options array to a fields object
export const fields: { [key: string]: Field[] } = options.reduce(
  (acc, option) => {
    acc[option.name] = option.fields;
    return acc;
  },
  {} as { [key: string]: Field[] }
);
