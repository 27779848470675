import { useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { INSERT_VISA_TICKET } from "~/graphql/visa_inq_ticket/Mutation";
import { ADD_TRANSACTION } from "../../../graphql/Mutation";
import { useFileUpload } from "@nhost/react";

const VisaRequestForm = ({
  ticekts,
  inquiry,
  tabs,
  vendors,
  refundable,
}: any) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  }: any = useForm();

  const [insertTicket] = useMutation(INSERT_VISA_TICKET);
  const [addTransaction] = useMutation(ADD_TRANSACTION);

  const [selectedFile, setSelectedFile] = useState<any>([]);

  const { upload, progress } = useFileUpload();

  const onSubmit = async (formData: any) => {
    try {
      const payload = {
        visa_inq_id: inquiry?.visa_inq_details?.[0]?.id,
        status: "issued",
        cost_price: formData?.cost_price,
        vendor_id: formData?.vendor_id,
        refundable,
        visa_inq_ticket_files: {
          data: selectedFile?.map((item: any) => ({
            inq_passenger_id: item?.passengerID,
            file_url: item?.file,
          })),
        },
      };

      const res = await insertTicket({
        variables: {
          data: payload,
        },
      });

      if (res?.data?.insert_visa_inq_ticket?.affected_rows) {
        toast.success("Ticket Generated Successfully");
      }

      const rawTransactions: any = [
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 23,
          inq_id: inquiry.id,
          transactions_no: "randomNumber",
          date: new Date(),
          created_at: new Date(),
          type: "debit",
          amount: parseFloat(formData?.cost_price),
        },
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 2,
          user_id: formData?.vendor_id,
          inq_id: inquiry.id,
          transactions_no: "randomNumber",
          date: new Date(),
          created_at: new Date(),
          type: "credit",
          amount: -parseFloat(formData?.cost_price),
        },
      ];

      const transactions = rawTransactions.filter(
        (obj: any) => obj.amount !== 0
      );

      const res1 = await addTransaction({
        variables: {
          transactions: transactions,
        },
      });

      if (res1?.data?.insert_acc_transactions?.affected_rows) {
        toast.success("Transactions Added Successfully");
      }
    } catch (err: any) {
      toast.error(err?.message);
    }
  };

  const handleFileUpload = async (event: any, passengerID: any) => {
    const file = event.target.files[0];

    const res = await upload({ file });

    if (!file.type.includes("pdf")) {
      toast.error("Invalid File format");
    } else {
      setSelectedFile((prevFiles: any) => [
        ...prevFiles,
        { file: res.id, passengerID: passengerID },
      ]);
    }
  };

  return (
    <div className="min-w-[500px] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-4">
          <div className="">
            <label htmlFor="" className="text-[14px] mb-2 block">
              Select Vendor {"*"}
            </label>
            <select
              {...register("vendor_id", {
                required: "Vendor is required",
              })}
              aria-invalid={errors.vendor_id ? "true" : "false"}
              className="w-full px-3 py-3 border rounded focus:outline-none focus:border-basic leading-tight border-gray-200 dark:bg-dark-primary"
            >
              <option value="">--Select Vendor--</option>
              {vendors.map((vendor: any) => {
                return (
                  <option key={`${vendor?.id}`} value={`${vendor?.id}`}>
                    {vendor.displayName}
                  </option>
                );
              })}
            </select>
            {errors.vendor_id && (
              <p role="alert" className="text-red-500 text-[12px] m-1">
                {errors.vendor_id.message}
              </p>
            )}
          </div>
          <TextField
            error={!!errors.cost_price}
            {...register(`cost_price`, {
              required: "Cost Price is required",
            })}
            label="Cost Price"
            fullWidth
            helperText={errors.cost_price?.message}
          />
        </div>
        {ticekts?.[0]?.visa_inq_details?.[0]?.selected_visa_suggestion?.visa_inq_suggestion_costs?.map(
          (item: any) => {
            return (
              <div className="pt-4">
                <label>Ticket File {"*"}</label>
                <input
                  id="fileUpload"
                  type="file"
                  onChange={(event: any) => {
                    handleFileUpload(event, item?.inq_passenger?.id);
                  }}
                  className="w-full px-2 py-2 border rounded dark:bg-dark-primary focus:outline-none focus:border-basic leading-tight border"
                />
                {progress && <p>Upload Progress: {progress}%</p>}

                <small className="text-[#b63434]">
                  Only PDF file is acceptable.
                </small>
              </div>
            );
          }
        )}
        <Button type="submit" sx={{ marginTop: "30px" }} variant="contained">
          Generate
        </Button>
      </form>
    </div>
  );
};

export default VisaRequestForm;
