import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

interface TravelersPopoverProps {
  open: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  adults: number;
  setAdults: React.Dispatch<React.SetStateAction<number>>;
  youth: number;
  setYouth: React.Dispatch<React.SetStateAction<number>>;
  children: number;
  setChildren: React.Dispatch<React.SetStateAction<number>>;
  infantsOnLap: number;
  setInfantsOnLap: React.Dispatch<React.SetStateAction<number>>;

  travelers: {
    children: string[];
    infantsOnLap: string[];
  };
  handleAgeChange: (
    category: "children" | "infantsOnLap",
    index: number,
    value: string
  ) => void;
  ageOptions: string[];
  infantOptions: string[];
  popoverWidth: string;
  label: string;
  updateTravelerCount: (
    type: "adults" | "youth" | "children" | "infantsOnLap",
    action: "increment" | "decrement"
  ) => void;
}

const TravelersPopover: React.FC<TravelersPopoverProps> = ({
  open,
  anchorEl,
  handleClose,
  handleClick,
  adults,
  setAdults,
  youth,
  setYouth,
  children,
  setChildren,
  infantsOnLap,
  setInfantsOnLap,
  travelers,
  handleAgeChange,
  ageOptions,
  infantOptions,
  popoverWidth,
  label,
  updateTravelerCount,
}) => (
  <div>
    <TextField
      aria-describedby="travelers-popover"
      label={label}
      value={`${adults + youth + children + infantsOnLap} travelers`}
      onClick={handleClick}
      aria-readonly
      fullWidth
    />
    <Popover
      id="travelers-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        style: { width: popoverWidth, padding: "10px" },
      }}
    >
      <Box className="p-4 max-w-md w-full md:max-w-xl overflow-auto max-h-96">
        {/* Adults Section */}
        <Box className="flex gap-4 mt-2 justify-between">
          <div>
            <Typography>Adults</Typography>
          </div>
          <div className="flex items-center justify-center gap-2">
            <IconButton
              onClick={() => updateTravelerCount("adults", "decrement")}
              sx={{
                border: "1px solid #ccc",
                borderRadius: "9999px",
                padding: "4px",
                "&:hover": {
                  backgroundColor: "#9e6dcf",
                },
              }}
            >
              <RemoveIcon />
            </IconButton>
            <span className="text-center w-12 text-lg font-medium">
              {adults}
            </span>
            <IconButton
              onClick={() => updateTravelerCount("adults", "increment")}
              sx={{
                border: "1px solid #ccc",
                borderRadius: "9999px",
                padding: "4px",
                "&:hover": {
                  backgroundColor: "#9e6dcf",
                },
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
        </Box>

        {/* Youth Section */ }
        <Box>
          <Box className="flex gap-4 mt-2 justify-between">
            <div>
              <Typography>Youth</Typography>
            </div>
            <div className="flex items-center justify-center gap-2">
              <IconButton
                onClick={() => updateTravelerCount("youth", "decrement")}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "9999px",
                  padding: "4px",
                  "&:hover": {
                    backgroundColor: "#9e6dcf",
                  },
                }}
              >
                <RemoveIcon />
              </IconButton>
              <span className="text-center w-12 text-lg font-medium">
                {youth}
              </span>
              <IconButton
                onClick={() => updateTravelerCount("youth", "increment")}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "9999px",
                  padding: "4px",
                  "&:hover": {
                    backgroundColor: "#9e6dcf",
                  },
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </Box>
        </Box>

        {/* Children Section */}
        <Box>
          <Box className="flex gap-4 mt-2 justify-between">
            <div>
              <Typography>Children</Typography>
            </div>
            <div className="flex items-center justify-center gap-2">
              <IconButton
                onClick={() => updateTravelerCount("children", "decrement")}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "9999px",
                  padding: "4px",
                  "&:hover": {
                    backgroundColor: "#9e6dcf",
                  },
                }}
              >
                <RemoveIcon />
              </IconButton>
              <span className="text-center w-12 text-lg font-medium">
                {children}
              </span>
              <IconButton
                onClick={() => updateTravelerCount("children", "increment")}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "9999px",
                  padding: "4px",
                  "&:hover": {
                    backgroundColor: "#9e6dcf",
                  },
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </Box>
        </Box>

        

        {/* Infants on Lap Section */}
        <Box>
          <Box className="flex gap-4 mt-2 justify-between">
            <div>
              <Typography>Infants on Lap</Typography>
            </div>
            <div className="flex items-center justify-center gap-2">
              <IconButton
                onClick={() => updateTravelerCount("infantsOnLap", "decrement")}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "9999px",
                  padding: "4px",
                  "&:hover": {
                    backgroundColor: "#9e6dcf",
                  },
                }}
              >
                <RemoveIcon />
              </IconButton>
              <span className="text-center w-12 text-lg font-medium">
                {infantsOnLap}
              </span>
              <IconButton
                onClick={() => updateTravelerCount("infantsOnLap", "increment")}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "9999px",
                  padding: "4px",
                  "&:hover": {
                    backgroundColor: "#9e6dcf",
                  },
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </Box>
        </Box>
      </Box>
    </Popover>
  </div>
);

export default TravelersPopover;
