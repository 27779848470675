import { Document, Page, View, Image, Text } from "@react-pdf/renderer";
import { getStyles } from "./styles";
import airlineData from "~/components/FormInputs/AirlineBox/data/airlines.json";
import airportData from "~/components/FormInputs/LocationBox/data/airports.json";
import moment from "moment";
import luggage from "../images/luggage.png";
import handCarry from "../images/HandCarry.png";
import vector81 from "../images/Vector8.png";
import { overallLegTimeDuration, timeDuration } from "~/utils/utilsFunctions";

const SuggestionPDF = ({ inquiry, logo, suggestions }: any) => {
  const timeDuration1 = (timestamp1: any, timestamp2: any) => {
    const start = moment(timestamp1);
    const end = moment(timestamp2);
    const duration = moment.duration(end.diff(start));

    // Extract hours and minutes
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    // Output
    // ;
    return { hours, minutes };
  };

  const getHoursAndMinutes = (timestamp1: any, timestamp2: any) => {
    const duration = timeDuration1(timestamp1, timestamp2);
    return `${duration?.hours} hours ${duration?.minutes} minutes`;
  };

  function getAirlineNameByIATA(iataCode: any) {
    const airline = airlineData.find(
      (airline: any) => airline.iata === iataCode
    );
    return airline?.marketing_name;
  }

  function getAirportNameByIATA(iataCode: any) {
    const airport = airportData.find(
      (airport: any) => airport.iata_code === iataCode
    );
    return airport?.name;
  }

  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerFirstContainer}>
            {logo && <Image style={styles.logo} src={logo} />}

            <View style={styles.brandDetail}>
              <Text style={styles.brandName}>{inquiry?.brd_list?.name}</Text>
              <Text>www.{inquiry?.brd_list?.domain}</Text>
              <Text>{inquiry?.brd_list?.brd_contact_details?.[0]?.email}</Text>
              <Text>{inquiry?.brd_list?.brd_contact_details?.[0]?.phone}</Text>
            </View>
          </View>
          <View style={styles.headerSecondContainer}>
            <Text style={styles.secondContainerText}>
              {inquiry?.brd_list?.brd_contact_details?.[0]?.address1}
            </Text>
            <Text style={styles.secondContainerText}>
              {inquiry?.brd_list?.brd_contact_details?.[0]?.city}{" "}
              {inquiry?.brd_list?.brd_contact_details?.[0]?.country}
            </Text>
          </View>
        </View>
        <View style={styles.inquiryDetailContainer}>
          {suggestions?.[0]?.inq_suggestion_legs?.data?.map(
            (suggestion: any, ind: any) => {
              return (
                <View>
                  <View style={styles.flightDetailHeader}>
                    <Text style={styles.flightTypeText}>
                      {suggestion?.type == "departing"
                        ? "Depart"
                        : suggestion?.type == "returning" && "Return"}
                    </Text>
                    <View style={styles.flightCabinContainer}>
                      <Text style={styles.flightCabinText}>
                        {suggestion?.cabin}
                      </Text>
                      <Text>
                        |{" "}
                        {(() => {
                          let totalHours = 0;
                          let totalMinutes = 0;

                          suggestion?.inq_suggestion_leg_stops?.data?.forEach(
                            (legs: any) => {
                              const duration = overallLegTimeDuration(
                                legs?.depart_datetime,
                                legs?.depart,
                                legs?.arrival_datetime,
                                legs?.arrival
                              );

                              totalHours += duration.hours;
                              totalMinutes += duration.minutes;
                            }
                          );

                          // Convert excess minutes into hours
                          totalHours += Math.floor(totalMinutes / 60);
                          totalMinutes %= 60;

                          return `${totalHours} hours ${totalMinutes} minutes`;
                        })()}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.flightDetailBody}>
                    {suggestion?.inq_suggestion_leg_stops?.data?.map(
                      (legs: any, ind: any) => {
                        return (
                          <View style={styles.flightLegsParentContainer}>
                            <View style={styles.flightLegs}>
                              <View style={styles.flightLegContainers}>
                                <View style={styles.flightLegSubContainers}>
                                  <Text style={styles.flightLegArrDep}>
                                    {legs?.depart}
                                  </Text>
                                  <Text>
                                    {`(${getAirportNameByIATA(legs?.depart)})`}
                                  </Text>
                                </View>
                                <Text style={styles.flightLegDateTime}>
                                  {moment(legs?.depart_datetime).format(
                                    "dddd DD MMM YYYY"
                                  )}{" "}
                                  |{" "}
                                  {moment(legs?.depart_datetime).format(
                                    "HH:mm"
                                  )}
                                </Text>
                              </View>
                              <Image style={styles.dottedImg} src={vector81} />
                              <View style={styles.airlineContainer}>
                                <Text style={styles.flightLegDateTime}>
                                  {timeDuration(
                                    legs?.depart_datetime,
                                    legs?.depart,
                                    legs?.arrival_datetime,
                                    legs?.arrival
                                  )}
                                  {/* {getHoursAndMinutes(
                                    legs?.depart_datetime,
                                    legs?.arrival_datetime
                                  )} */}
                                </Text>
                                <View>
                                  <Text style={styles.airlineText}>
                                    {getAirlineNameByIATA(legs?.airline_iata)}{" "}
                                    {`(${legs?.airline_iata})`}
                                  </Text>
                                </View>
                              </View>
                              <Image style={styles.dottedImg} src={vector81} />
                              <View style={styles.flightLegContainerRight}>
                                <View style={styles.flightLegSubContainerRight}>
                                  <Text style={styles.flightLegArrDep}>
                                    {legs?.arrival}
                                  </Text>
                                  <Text>
                                    {`(${getAirportNameByIATA(legs?.arrival)})`}
                                  </Text>
                                </View>
                                <Text style={styles.flightLegDateTime}>
                                  {moment(legs?.arrival_datetime).format(
                                    "dddd DD MMM YYYY"
                                  )}{" "}
                                  |{" "}
                                  {moment(legs?.arrival_datetime).format(
                                    "HH:mm"
                                  )}
                                </Text>
                              </View>
                            </View>
                            {suggestion?.inq_suggestion_leg_stops?.data?.[
                              ind + 1
                            ] && (
                              <Text style={styles.stayConatinerText}>
                                {timeDuration(
                                  legs?.arrival_datetime,
                                  legs?.arrival,
                                  suggestion?.inq_suggestion_leg_stops?.data?.sort(
                                    (a: any, b: any) => a.ordering - b.ordering
                                  )?.[ind + 1]?.depart_datetime,
                                  suggestion?.inq_suggestion_leg_stops?.data?.sort(
                                    (a: any, b: any) => a.ordering - b.ordering
                                  )?.[ind + 1]?.depart
                                )}{" "}
                                {/* {getHoursAndMinutes(
                                  legs?.arrival_datetime,
                                  suggestion?.inq_suggestion_leg_stops?.data?.[
                                    ind + 1
                                  ]?.depart_datetime
                                )} */}
                                (Stay)
                              </Text>
                            )}
                          </View>
                        );
                      }
                    )}
                    <View style={styles.priceLuggageContainer}>
                      <View style={styles.luggageContainer}>
                        <View style={styles.luggageSubContainer}>
                          <Image style={styles.luggageImg} src={luggage} />
                          <Text style={styles.luggageText}>
                            {suggestion?.luggage} Kg
                          </Text>
                        </View>
                        <View style={styles.luggageSubContainer}>
                          <Image style={styles.handCarryImg} src={handCarry} />
                          <Text style={styles.luggageText}>
                            {suggestion?.hand_carry} Kg
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              );
            }
          )}
          <View style={styles.priceLuggageContainer}>
            <View style={styles.luggageContainer}>
              <View style={styles.luggageSubContainer}>
                <Text style={styles.luggageText}>Total Price:</Text>
                <Text style={styles.luggageText}>
                  £
                  {suggestions?.[0]?.inq_suggestion_costs?.data?.reduce(
                    (sum: any, obj: any) => sum + parseFloat(obj.sale_price),
                    0
                  ) + suggestions?.[0]?.bookingFee}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default SuggestionPDF;
