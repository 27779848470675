import { useApolloClient, useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import { useUserId } from "@nhost/react";
import { useState } from "react";
import { toast } from "react-toastify";
import ConfrimPopup from "~/components/common/ConfirmPopup";
import { DELETE_CREDITS_PLANS } from "~/graphql/def_credits_plan/Mutation";
import { UPDATE_THP_HOUSE } from "~/graphql/thp_list/Mutation";
import { stripe } from "~/lib/stripe";
import { GetCustomUrl } from "~/utils/GetCustomUrl";

const PlanCredits = ({
  data,
  type,
  defPaymentMeth,
  curPlan,
  setOpenMod,
}: any) => {
  const [deleteCreditsPlan] = useMutation(DELETE_CREDITS_PLANS);

  const [confirmPopup, setConfirmPopup] = useState(false);

  const [updateThpHouse] = useMutation(UPDATE_THP_HOUSE);
  const client = useApolloClient();

  const user_id = useUserId();

  const customUrl = GetCustomUrl(window.location.host);

  const deleteFunc = async (id: any) => {
    const res = await deleteCreditsPlan({
      variables: {
        id,
      },
    });

    if (res?.data?.delete_def_credits_plan?.affected_rows) {
      client.refetchQueries({
        include: "active",
      });

      toast.success("Deleted SuccessFully");
    }
  };

  //

  const addCredits = async () => {
    const paymentIntent = await stripe.paymentIntents.create({
      amount: data?.price * 100,
      currency: "eur",
      payment_method: defPaymentMeth, // Pass the Payment Method ID here
      confirm: true, // Automatically confirms the payment
      customer: curPlan?.subscription_user_ref,
    });

    if (paymentIntent.status === "succeeded") {
      const res = await updateThpHouse({
        variables: {
          custom_url: customUrl,
          user_id: user_id,
          data: {
            invoice_credits_buy: curPlan?.invoice_credits_buy + data?.credits,
          },
        },
      });
      if (res?.data?.update_thp_list?.returning?.[0]?.id) {
        toast.success("Credits Added SuccessFully");
        setOpenMod(false);
      }
    }
  };

  return (
    <div className="flex flex-col shadow rounded w-[300px] justify-center relative border">
      {type !== "Add Credit" && (
        <div className="absolute right-[-7px] top-[-7px] flex items-center justify-center p-[3px] bg-red-600 rounded-full cursor-pointer">
          <CloseIcon
            sx={{ color: "white", fontSize: "15px" }}
            onClick={() => deleteFunc(data.id)}
          />
        </div>
      )}

      <div className="flex items-center justify-between px-[15px] py-[13px] border border-b">
        <h1 className="text-[22px] font-bold text-basic">
          {data.credit_type} Credits
        </h1>
        <p className="text-[26px] font-bold">{data.credits}</p>
      </div>
      <div className="px-[20px] py-[10px] flex items-cneter justify-between">
        <p className="text-[22px] font-semibold">£{data.price}</p>
        {type === "Add Credit" ? (
          <button
            onClick={() => setConfirmPopup(true)}
            className="text-[14px] font-bold bg-blue-600 text-white rounded px-2 py-[1px]"
          >
            ADD
          </button>
        ) : (
          <p className="text-[18px] font-bold text-secondary">
            {data.def_subscription_plan.plan_name}
          </p>
        )}
      </div>
      {confirmPopup && (
        <ConfrimPopup
          confirm={addCredits}
          closeModal={() => setConfirmPopup(false)}
        />
      )}
    </div>
  );
};

export default PlanCredits;
