import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import FlightDetail from "./components/FlightDetail";
import { useEffect, useState } from "react";
import WeightDetail from "./components/WeightDetail";
import FlightDates from "./components/FlightDates";
import PassengersCost from "./components/PassengersCost";
import { formatFormData } from "~/modules/InquiriesModule/segments/InquiryDetail/components/SuggestionCard/components/AddSuggestion/utils/formatFormData";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CabinClasses } from "~/data/cabinClasses";
import {
  GET_BRD_SEASONS,
  GET_SEASONS_WITH_ID,
} from "~/graphql/brd_seasons/Query";

const AddManually = ({
  submitSugesstion,
  mainHeading,
  passengers,
  type,
  suggestion,
  isLoading,
  isLoadingText,
  defaultValue,
  setSidePopup,
  seasons,
  fare,
}: any) => {
  // const defaultValue = suggestion ? formatFormData(suggestion) : null;
  const { brd_id } = useParams();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({});

  const depStopsLength = defaultValue?.departLeg?.stops?.length;
  const arrStopsLength = defaultValue?.returnLeg?.stops?.length;

  const [depEntries, setDepEntries] = useState(
    Array.from({ length: depStopsLength }, (_, index) => index + 1)
  );
  const [arrEntries, setArrEntries] = useState(
    Array.from({ length: arrStopsLength }, (_, index) => index + 1)
  );

  const onSubmit = (inpData: any) => {
    submitSugesstion(inpData);
  };

  const checkTripType = watch("tripType");

  const tripTypeOptions = [
    {
      key: 1,
      text: "One Way",
    },
    {
      key: 2,
      text: "Round Trip",
    },
  ];

  const CabinTypeOptions = CabinClasses.map((cabin) => ({
    value: cabin.value,
    text: cabin.title,
  }));

  useEffect(() => {
    defaultValue && setValue("id", defaultValue?.id);
  }, []);
  const { loading, error, data } = useQuery(GET_BRD_SEASONS, {
    variables: { brdId: brd_id },
  });

  // Prepare options for the Autocomplete
  const seasonOptions =
    data?.brd_seasons?.map((season: any) => ({
      id: season.id,
      name: season.name,
    })) || [];
  useEffect(() => {
    if (fare?.brd_season?.name) {
      const defaultOption = seasonOptions.find(
        (option: any) => option.name === fare.brd_season.name
      );
      if (defaultOption) {
        setValue("season", defaultOption.id);
      }
    }
  }, [fare, seasonOptions, setValue]);

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-5">
          {seasons === "available" && (
            // <Controller
            //   name="season"
            //   control={control}
            //   rules={{ required: true }}
            //   // {...register("season", { required: true })}
            //   render={({ field }) => (
            //     <div className="md:col-span-2">
            //       {" "}
            //       <Autocomplete
            //         {...field}
            //         disablePortal
            //         onChange={(_, selectedOption) => {
            //           field.onChange(selectedOption?.id); // Register the id
            //         }}
            //         options={seasonOptions}
            //         getOptionLabel={(option) => option?.name || ""}
            //         renderOption={(props, option) => (
            //           <Box component="li" {...props}>
            //             {option.name}
            //           </Box>
            //         )}
            //         defaultValue={seasonOptions.find(
            //           (option: any) => option.name === fare?.brd_season?.name
            //         )} // Find the matching option
            //         renderInput={(params) => (
            //           <TextField
            //             {...params}
            //             label="Season"
            //             inputRef={field.ref}
            //             error={!!errors["season"]}
            //           />
            //         )}
            //       />
            //     </div>
            //   )}
            // />
            <Controller
              name="season"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div className="md:col-span-2">
                  <Autocomplete
                    {...field}
                    value={
                      seasonOptions.find(
                        (option: any) => option.id === field.value
                      ) || null
                    }
                    onChange={(_, selectedOption) => {
                      field.onChange(selectedOption?.id);
                    }}
                    options={seasonOptions}
                    getOptionLabel={(option) => option?.name || ""}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Season"
                        inputRef={field.ref}
                        error={!!errors.season}
                        helperText={errors.season && "This field is required"}
                      />
                    )}
                  />
                </div>
              )}
            />
          )}
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("tripType", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.key);
                }}
                options={tripTypeOptions ? tripTypeOptions : []}
                getOptionLabel={(option: any) => option?.text}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.text}
                  </Box>
                )}
                // defaultValue={() => {
                //   const data = tripTypeOptions?.find(
                //     (option) => option?.key === defaultValue?.tripType
                //   );
                //   field.onChange(data?.key);
                //   return data;
                // }}
                defaultValue={() => {
                  const tripTypeKey =
                    (fare?.inq_suggestion_legs?.length || 0) > 1 ? 2 : 1;

                  const data = tripTypeOptions?.find(
                    (option) => option.key === tripTypeKey
                  );
                  field.onChange(data?.key);
                  return data;
                }}
                renderInput={(params) => (
                  <TextField
                    label="Trip Type"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["tripType"] ? true : false}
                    {...params}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("cabinType", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.value);
                }}
                options={CabinTypeOptions ? CabinTypeOptions : []}
                getOptionLabel={(option: any) => option?.text}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.text}
                  </Box>
                )}
                defaultValue={() => {
                  const data = CabinTypeOptions?.find(
                    (option) =>
                      option?.value === fare?.inq_suggestion_legs?.[0]?.cabin
                  );
                  field.onChange(data?.value);
                  return data;
                }}
                renderInput={(params) => (
                  <TextField
                    label="Cabin Class"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["cabinType"] ? true : false}
                    {...params}
                  />
                )}
              />
            )}
          />
        </div>

        {mainHeading === "Update Suggestion"
          ? fare?.inq_suggestion_legs
              ?.filter(
                (singleFare: any) => singleFare?.type === "departLeg"
              )?.[0]
              ?.inq_suggestion_leg_stops?.map((filteredFare: any, ind: any) => {
                return (
                  <FlightDetail
                    defData={defaultValue?.departLeg}
                    mainDefData={filteredFare}
                    ind={ind}
                    register={register}
                    control={control}
                    errors={errors}
                    name="departLeg"
                    setEntries={setDepEntries}
                    entries={depEntries}
                    mainHeading={mainHeading}
                    setValue={setValue}
                    seasons={seasons}
                  />
                );
              })
          : depEntries?.map((_: any, ind: any) => {
              return (
                <FlightDetail
                  defData={defaultValue?.departLeg}
                  ind={ind}
                  register={register}
                  control={control}
                  errors={errors}
                  name="departLeg"
                  setEntries={setDepEntries}
                  entries={depEntries}
                  setValue={setValue}
                  seasons={seasons}
                />
              );
            })}

        {type === "createUpdate" && (
          <WeightDetail
            defData={defaultValue?.departLeg}
            register={register}
            errors={errors}
            name="departLeg"
          />
        )}
        {!(mainHeading === "Update Suggestion") && (
          <Button
            sx={{ marginTop: "20px" }}
            onClick={() => {
              const newValue = depEntries.length + 1;
              setDepEntries([...depEntries, newValue]);
            }}
            variant="contained"
          >
            Add Stop
          </Button>
        )}

        {checkTripType === 2 && mainHeading === "Create New Suggestion" && (
          <>
            {mainHeading === "Update Suggestion"
              ? fare?.inq_suggestion_legs
                  ?.filter(
                    (singleFare: any) => singleFare?.type === "returnLeg"
                  )?.[0]
                  ?.inq_suggestion_leg_stops?.map(
                    (filteredFare: any, ind: any) => {
                      return (
                        <FlightDetail
                          defData={defaultValue?.returnLeg}
                          ind={ind}
                          mainDefData={filteredFare}
                          register={register}
                          control={control}
                          errors={errors}
                          name="returnLeg"
                          setEntries={setArrEntries}
                          entries={arrEntries}
                          mainHeading={mainHeading}
                          setValue={setValue}
                          seasons={seasons}
                        />
                      );
                    }
                  )
              : arrEntries?.map((_: any, ind: any) => {
                  return (
                    <FlightDetail
                      defData={defaultValue?.returnLeg}
                      ind={ind}
                      register={register}
                      control={control}
                      errors={errors}
                      name="returnLeg"
                      setEntries={setArrEntries}
                      entries={arrEntries}
                      setValue={setValue}
                      seasons={seasons}
                    />
                  );
                })}

            {type === "createUpdate" && (
              <WeightDetail
                defData={defaultValue?.returnLeg}
                register={register}
                errors={errors}
                name="returnLeg"
              />
            )}
            {!(mainHeading === "Update Suggestion") && (
              <Button
                sx={{ marginTop: "20px" }}
                onClick={() => {
                  const newValue = arrEntries.length + 1;
                  setArrEntries([...arrEntries, newValue]);
                }}
                variant="contained"
              >
                Add Stop
              </Button>
            )}
          </>
        )}
        {type === "createUpdate" && (
          <>
            <Divider sx={{ marginTop: "25px" }} />
            <FlightDates
              setValue={setValue}
              defData={defaultValue}
              register={register}
              errors={errors}
              control={control}
            />
          </>
        )}
        {type === "createUpdate"
          ? passengers?.map((passenger: any, index: any) => {
              return (
                <PassengersCost
                  defData={defaultValue?.suggestion_cost}
                  register={register}
                  errors={errors}
                  index={index}
                  control={control}
                  setValue={setValue}
                  passenger={passenger}
                  type={type}
                />
              );
            })
          : null}
        {fare?.inq_suggestion_legs?.length > 1 &&
          mainHeading === "Update Suggestion" && (
            <>
              {mainHeading === "Update Suggestion"
                ? fare?.inq_suggestion_legs
                    ?.filter(
                      (singleFare: any) => singleFare?.type === "returnLeg"
                    )?.[0]
                    ?.inq_suggestion_leg_stops?.map(
                      (filteredFare: any, ind: any) => {
                        return (
                          <FlightDetail
                            defData={defaultValue?.returnLeg}
                            ind={ind}
                            mainDefData={filteredFare}
                            register={register}
                            control={control}
                            errors={errors}
                            name="returnLeg"
                            setEntries={setArrEntries}
                            entries={arrEntries}
                            mainHeading={mainHeading}
                            setValue={setValue}
                            seasons={seasons}
                          />
                        );
                      }
                    )
                : arrEntries?.map((_: any, ind: any) => {
                    return (
                      <FlightDetail
                        defData={defaultValue?.returnLeg}
                        ind={ind}
                        register={register}
                        control={control}
                        errors={errors}
                        name="returnLeg"
                        setEntries={setArrEntries}
                        entries={arrEntries}
                        setValue={setValue}
                        seasons={seasons}
                      />
                    );
                  })}

              {type === "createUpdate" && (
                <WeightDetail
                  defData={defaultValue?.returnLeg}
                  register={register}
                  errors={errors}
                  name="returnLeg"
                />
              )}
              {!(mainHeading === "Update Suggestion") && (
                <Button
                  sx={{ marginTop: "20px" }}
                  onClick={() => {
                    const newValue = arrEntries.length + 1;
                    setArrEntries([...arrEntries, newValue]);
                  }}
                  variant="contained"
                >
                  Add Stop
                </Button>
              )}
            </>
          )}
        {type === "createUpdate" && (
          <>
            <Divider sx={{ marginTop: "25px" }} />
            <FlightDates
              setValue={setValue}
              defData={defaultValue}
              register={register}
              errors={errors}
              control={control}
            />
          </>
        )}
        {type === "createUpdate"
          ? passengers?.map((passenger: any, index: any) => {
              return (
                <PassengersCost
                  defData={defaultValue?.suggestion_cost}
                  register={register}
                  errors={errors}
                  index={index}
                  control={control}
                  setValue={setValue}
                  passenger={passenger}
                  type={type}
                />
              );
            })
          : null}
        <PassengersCost register={register} fare={fare} errors={errors} />
        <Button
          type="submit"
          sx={{ marginTop: "25px" }}
          variant="contained"
          fullWidth
          // onClick={() => setSidePopup(true)}
        >
          {isLoading ? isLoadingText : mainHeading}
        </Button>
      </Box>
    </>
  );
};

export default AddManually;
