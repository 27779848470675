import React from "react";
import { Button, MenuItem, Select, FormControl, InputLabel, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const PersonCost = ({
    passenger,
    passengers,
    onRemove,
    control,
    errors,
    index,
}: any) => {

    console.log("jdbcs", passenger)

    // const defaultValue = passengers?.filter((item: any) => item?.id === passenger?.inq_passenger_id)

    return (
        <div className="flex flex-col gap-3 p-4 border rounded mb-2">
            <div className="flex flex-col gap-3">
                {/* Select Person */}
                <FormControl className="w-60">
                    <InputLabel id={`passenger-select-label-${index}`}>Select Person</InputLabel>
                    <Controller
                        name={`visaSuggestion.persons[${index}].id`}
                        control={control}
                        defaultValue={passenger?.inq_passenger_id || ""}
                        rules={{ required: "Please select a person" }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                labelId={`passenger-select-label-${index}`}
                                label="Select Person"
                                error={!!errors?.persons?.[index]?.id}
                                value={field.value || ""}
                                onChange={(e) => field.onChange(e.target.value)}
                            >
                                {passengers.map((p: any) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {`${p.first_name} ${p.last_name}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                    {errors?.persons?.[index]?.id && (
                        <span className="text-red-500 text-xs">
                            {errors.persons[index].id.message}
                        </span>
                    )}
                </FormControl>

                {/* Offer Price and Cost Price */}
                <div className="flex flex-1 gap-4">
                    <Controller
                        name={`visaSuggestion.persons[${index}].offerPrice`}
                        control={control}
                        defaultValue={passenger?.sale_price || ""}
                        rules={{ required: "Offer Price is required" }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Offer Price"
                                type="number"
                                placeholder="Offer Price"
                                error={!!errors?.persons?.[index]?.offerPrice}
                                helperText={errors?.persons?.[index]?.offerPrice?.message}
                                fullWidth
                            />
                        )}
                    />

                    <Controller
                        name={`visaSuggestion.persons[${index}].costPrice`}
                        control={control}
                        defaultValue={passenger?.cost_price || ""}
                        rules={{ required: "Cost Price is required" }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Cost Price"
                                type="number"
                                placeholder="Cost Price"
                                error={!!errors?.persons?.[index]?.costPrice}
                                helperText={errors?.persons?.[index]?.costPrice?.message}
                                fullWidth
                            />
                        )}
                    />
                </div>
            </div>

            {/* Remove Button */}
            <div className="flex justify-end">
                <Button
                    variant="outlined"
                    color="error"
                    onClick={onRemove}
                    className="ml-4"
                >
                    Remove
                </Button>
            </div>
        </div>
    );
};

export default PersonCost;
