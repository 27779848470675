import { GET_FL_JOBS } from "~/graphql/flGetJobs/Query";
import { useQuery } from "@apollo/client";

export default function useFlJobs() {
  const { data } = useQuery(GET_FL_JOBS);

  return {
    flJobs: data?.flGetJobs?.result,
  };
}
