import React, { useState, useEffect } from "react";
import SideDrawer from "~/components/common/sideDrawer";
import ExtraBaggageForm from "./components/ExtraBaggageForm";

const ExtraBaggage = ({ inquiry }: any) => {
  const [showForm, setShowForm] = useState(false);
  const [selectValue, setSelectValue] = useState("no");

  useEffect(() => {
    if (!showForm) {
      setSelectValue("no");
    }
  }, [showForm]);

  return (
    <div className="self-stretch bg-white dark:bg-dark-secondary flex flex-col w-full p-[25px] items-start justify-start shadow text-left text-mini text-primary dark:text-dark-primary rounded-lg">
      <div className="self-stretch flex flex-col items-start justify-between">
        <div className="grid grid-cols-1 gap-4 w-full">
          <div className="">
            <label htmlFor="" className="text-[14px] mb-2 block">
              Extra Baggage
            </label>
            <select
              onChange={(e: any) => {
                setSelectValue(e.target.value);
                if (e.target.value === "yes") {
                  setShowForm(true);
                }
              }}
              value={selectValue}
              className="w-full px-3 py-3 border rounded focus:outline-none focus:border-basic leading-tight border-gray-200 dark:bg-dark-primary"
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
          </div>
        </div>
      </div>
      <SideDrawer
        setSidePopup={setShowForm}
        sidePopup={showForm}
        component={
          <ExtraBaggageForm setSidePopup={setShowForm} inquiry={inquiry} />
        }
      />
    </div>
  );
};

export default ExtraBaggage;
