import { useApolloClient, useMutation } from "@apollo/client";
import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import { ADD_PAYMENT_METHOD, EDIT_PAYMENT_METHOD } from "./graphql/Mutation";
import { toast } from "react-toastify";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";

const AddPaymentMethod = ({ setSidePopup, type, defData }: any) => {
  const { brands }: any = useAssigedBrands();

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [formValue, setFormValue] = useState<any>({
    brand: null,
    paymentMethod: null,
    cardTerminal: [defData?.card_terminal_type],
  });

  const paymentMethods = [
    { label: "Bank", value: "bank" },
    { label: "Card", value: "card" },
    { label: "Stripe (THP)", value: "stripe-thp" },
  ];

  const paymentMethodFunc = () => {
    const data = paymentMethods?.find(
      (option: any) => option?.value === defData?.type
    );

    return data;
  };

  const paymentMethod = paymentMethodFunc(); // Call the function to get the actual value
  const defPaymentMethodIndex = paymentMethod
    ? paymentMethods.indexOf(paymentMethod)
    : -1; // Check if data is not null/undefined

  const brandFunc = () => {
    const data =
      brands && brands?.find((option: any) => option?.id === defData?.brd_id);

    return data;
  };

  const brand = brandFunc(); // Call the function to get the actual value
  const defBrandIndex = brand ? brands.indexOf(brand) : -1; // Check if data is not null/undefined

  useEffect(() => {
    if (defPaymentMethodIndex !== -1) {
      setFormValue({
        ...formValue,
        paymentMethod: paymentMethods[defPaymentMethodIndex],
      });
    }
    if (defBrandIndex !== -1) {
      setFormValue({
        ...formValue,
        brand: brands?.[0],
      });
    }
  }, [defBrandIndex, defPaymentMethodIndex]);

  useEffect(() => {
    setValue("paymentMethod", formValue?.paymentMethod?.value);
    setValue("brand", formValue?.brand?.id);
  }, [formValue?.brand, formValue?.paymentMethod]);

  const [addPaymentMethod] = useMutation(ADD_PAYMENT_METHOD);
  const [editPaymentMethod] = useMutation(EDIT_PAYMENT_METHOD);

  const { travelHouse }: any = travelHouseStore();
  const client = useApolloClient();

  const addData = async (formData: any) => {
    try {
      // Create Suggestion function call
      const res = await addPaymentMethod({
        variables: {
          paymentMethod: {
            thp_id: travelHouse?.id,
            brd_id: formData?.brand,
            type: formData?.paymentMethod,
            name: formData?.paymentMethodName,
            default: false,
            account_no: formData?.accountNumber,
            account_title: formData?.accountTitle,
            swift_code: formData?.sortCode,
            stripe_pub: formData?.stripePublicKey,
            stripe_secret: formData?.stripeSecretKey,
            card_terminal_type: formData?.card_terminal_type,
          },
        },
      });
      if (res.data?.insert_acc_payment_methods?.returning?.length > 0) {
        toast.success("Payment method added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        await client.resetStore();
        setSidePopup(false);
      }
    } catch (e) {
      reset();
    }
  };

  const updateData = async (formData: any) => {
    try {
      // Create Suggestion function call
      const res = await editPaymentMethod({
        variables: {
          id: defData?.id,
          paymentMethod: {
            type: formData?.paymentMethod,
            name: formData?.paymentMethodName,
            account_no: formData?.accountNumber,
            account_title: formData?.accountTitle,
            swift_code: formData?.sortCode,
            stripe_pub: formData?.stripePublicKey,
            stripe_secret: formData?.stripeSecretKey,
            card_terminal_type: formData?.card_terminal_type,
          },
        },
      });

      if (res.data?.update_acc_payment_methods_by_pk?.id) {
        toast.success("Payment method Updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        await client.resetStore();
        setSidePopup(false);
      }
    } catch (e) {
      reset();
    }
  };

  return (
    <>
      <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">
          {" "}
          {type === "edit" ? "Update Payment Method" : "Add Payment Method"}
        </h1>
        <Divider />
        <Box
          component="form"
          onSubmit={handleSubmit(type === "edit" ? updateData : addData)}
          noValidate
        >
          <div className="grid grid-cols-1 mt-10 gap-5">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("brand", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data?.id);
                    setFormValue({
                      ...formValue,
                      brand: data,
                    });
                  }}
                  value={formValue.brand}
                  options={brands ? brands : []}
                  getOptionLabel={(option: any) => option?.name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option?.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Brand"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                        el && errors["brand"] && el.focus();
                      }}
                      error={errors["brand"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("paymentMethod", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data.value);
                    setFormValue({
                      ...formValue,
                      paymentMethod: data,
                    });
                  }}
                  value={formValue.paymentMethod}
                  options={paymentMethods}
                  getOptionLabel={(option: any) => option.label}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Payment Method"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["paymentMethod"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            {formValue.paymentMethod?.value === "bank" && (
              <>
                <TextField
                  error={errors["paymentMethodName"] ? true : false}
                  {...register("paymentMethodName", { required: true })}
                  label="Payment Method Name"
                  type="text"
                  defaultValue={defData?.name ? defData?.name : null}
                />
                <TextField
                  error={errors["accountTitle"] ? true : false}
                  {...register("accountTitle", { required: true })}
                  label="Account Title"
                  type="text"
                  defaultValue={
                    defData?.account_title ? defData?.account_title : null
                  }
                />
                <TextField
                  error={errors["accountNumber"] ? true : false}
                  {...register("accountNumber", { required: true })}
                  label="Account Number"
                  type="number"
                  defaultValue={
                    defData?.account_no ? defData?.account_no : null
                  }
                />
                <TextField
                  error={errors["sortCode"] ? true : false}
                  {...register("sortCode", { required: true })}
                  label="Sort Code"
                  defaultValue={
                    defData?.swift_code ? defData?.swift_code : null
                  }
                />
              </>
            )}
            {formValue.paymentMethod?.value === "card" && (
              <>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  {...register("card_terminal_type", { required: true })}
                  render={({ field }: any) => (
                    <Autocomplete
                      {...field}
                      disablePortal
                      ref={field.ref}
                      onChange={(_, data: any) => {
                        setFormValue({
                          ...formValue,
                          cardTerminal: [data],
                        });
                        field.onChange(data);
                      }}
                      value={formValue.cardTerminal}
                      options={["Auto", "Manual"]}
                      getOptionLabel={(option: any) => option}
                      renderOption={(props, option: any) => (
                        <Box component="li" {...props}>
                          {option}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          label="Type of Terminal"
                          ref={field.ref}
                          inputRef={(el) => {
                            field.ref(el);
                          }}
                          error={errors["card_terminal_type"] ? true : false}
                          {...params}
                        />
                      )}
                      noOptionsText=""
                    />
                  )}
                />

                <TextField
                  error={errors["paymentMethodName"] ? true : false}
                  {...register("paymentMethodName", { required: true })}
                  label="Payment Method Name"
                  type="text"
                  defaultValue={defData?.name ? defData?.name : null}
                />

                {formValue.cardTerminal?.[0] === "Auto" && (
                  <>
                    <TextField
                      error={errors["stripePublicKey"] ? true : false}
                      {...register("stripePublicKey", { required: true })}
                      label="Stripe Public Key"
                      type="text"
                      defaultValue={
                        defData?.stripe_pub ? defData?.stripe_pub : null
                      }
                    />
                    <TextField
                      error={errors["stripeSecretKey"] ? true : false}
                      {...register("stripeSecretKey", { required: true })}
                      label="Stripe Secret Key"
                      type="text"
                      defaultValue={
                        defData?.stripe_secret ? defData?.stripe_secret : null
                      }
                    />
                  </>
                )}
              </>
            )}
            {formValue.paymentMethod?.value === "stripe-thp" && (
              <>
                <TextField
                  error={errors["paymentMethodName"] ? true : false}
                  {...register("paymentMethodName", { required: true })}
                  label="Payment Method Name"
                  type="text"
                  defaultValue={defData?.name ? defData?.name : null}
                />
              </>
            )}
          </div>
          <Button
            type="submit"
            sx={{ width: "100%", marginTop: "25px" }}
            variant="contained"
          >
            {type === "edit" ? "Update Payment Method" : "Add Payment Method"}
          </Button>
        </Box>
      </div>
    </>
  );
};

export default AddPaymentMethod;
