import { gql } from "@apollo/client";

export const GET_SERVICES_FAQS = gql`
  query MyQuery($brd_id: uuid!, $service_id: uuid!) {
    services_faqs(
      where: {
        _and: [
          { brd_id: { _eq: $brd_id } }
          { service_id: { _eq: $service_id } }
        ]
      }
    ) {
      question
      description
      id
      brd_id
      service_id
    }
  }
`;
