import { useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";
import { Divider } from "@mui/material";

// Import Data
import {
  PassengersGenders,
  PassengersType,
} from "../../data/InitalDefaultValue";

// Import Components
import InputField from "components/FormInputs/InputField";
import CountryBox from "components/FormInputs/CountryBox";
import { CalendarBox } from "components/FormInputs/CalendarBox";

// Import GraphQl
import { ADD_PASSENGER } from "../../graphql/Mutation";
import useNotifications from "hooks/useNotifications";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import SelectBox from "components/FormInputs/SelectBox";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";

const PassengerAdd = ({ inquiry_id, hideForm, user_id, picked_by }: any) => {
  const client = useApolloClient();
  const [addPassenger] = useMutation(ADD_PASSENGER);
  //  Use react hook
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  // Notifications
  const alert = useNotifications();
  const userRole = useUserDefaultRole();

  const userId = useUserId();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  // Save Passsenger function
  const onSubmit = async (data: any) => {
    data["inq_id"] = inquiry_id;
    data["nationality"] = data.nationality.code;
    data["passport_expiry"] = data?.passport_expiry
      ? moment(data.passport_expiry).format("YYYY-MM-DD HH:mm:ss")
      : null;
    data["dob"] = data?.dob
      ? moment(data.dob).format("YYYY-MM-DD HH:mm:ss")
      : null;

    try {
      await updateInqListUser({
        variables: { id: inquiry_id, updated_by: userId },
      });

      // Create Suggestion function call
      const res = await addPassenger({
        variables: { passengers: [{ ...data }] },
      });

      if (res.data?.insert_inq_passengers?.returning?.length > 0) {
        toast.success("Passenger added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        if (userRole === "user") {
          await alert.newNotification(
            picked_by,
            `Your customer has add new passenger. Please check your portal`,
            "",
            ``
          );
        } else {
          await alert.newNotification(
            user_id,
            `Your consultant has add new passenger. Please check your portal`,
            "",
            ``
          );
        }

        await client.resetStore();
      }
      //   setIsloading(false); // change button loading state
      hideForm(false); // hide form modal
    } catch (e) {
      hideForm(false);
    }
  };

  return (
    <>
      <div className="self-stretch flex-1 min-w-[500px] h-full bg-white dark:bg-dark-primary flex flex-col p-[25px] items-center gap-[20px] text-left text-mini text-darkslategray shadow">
        <h2 className="text-center text-2xl text-basic dark:text-dark-primary">
          Add Passenger
        </h2>
        <Divider />

        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="self-stretch flex gap-5 flex-row pt-0  justify-between px-0 items-center">
            {/* <div className="grid grid-cols-2 gap-5 items-center"> */}
            <SelectBox
              errors={errors}
              control={control}
              fieldName={"type"}
              label="Type"
              options={PassengersType}
              selectedOption={PassengersType[2]}
              required={true}
              optionContainerClasses="dark:bg-dark-secondary"
              inputClasses="border rounded border-gray-200 pl-3 focus:outline-none focus:border-basic dark:text-dark-primary dark:bg-dark-primary font-normal text-primary"
              labelClasses="dark:text-dark-primary"
              checkIconClasses="text-basic"
              optionClasses="text-primary dark:text-dark-primary"
              optionHoverClasses="bg-primary dark:bg-dark-primary"
            />
            <SelectBox
              errors={errors}
              control={control}
              fieldName={"gender"}
              label="Gender"
              options={PassengersGenders}
              selectedOption={PassengersGenders[2]}
              required={true}
              optionContainerClasses="dark:bg-dark-secondary"
              inputClasses="border rounded border-gray-200 pl-3 focus:outline-none focus:border-basic dark:text-dark-primary dark:bg-dark-primary font-normal text-primary"
              labelClasses="dark:text-dark-primary"
              checkIconClasses="text-basic"
              optionClasses="text-primary dark:text-dark-primary"
              optionHoverClasses="bg-primary dark:bg-dark-primary"
            />
          </div>
          <div className="self-stretch flex flex-row pt-0 px-0 items-center justify-start">
            <div className="flex-1 rounded-8xs flex flex-col items-start justify-start">
              <div className="self-stretch relative leading-[130%]">
                <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 mt-2 items-center w-full">
                  <InputField
                    label="First Name"
                    register={register}
                    type="text"
                    errors={errors}
                    required={true}
                    placeHolder="First Name"
                    fieldName={`first_name`}
                    inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal"
                  />
                  <InputField
                    label="Last Name"
                    register={register}
                    type="text"
                    errors={errors}
                    placeHolder="Last Name"
                    fieldName={`last_name`}
                    inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row pt-0  justify-between px-0 items-center">
            <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 mt-2 items-center w-full">
              <CalendarBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName="dob"
                label="DOB"
                inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal focus:outline-none focus:border-basic border border-[#E5E7EB]"
                labelClasses="mb-2 text-primary dark:text-dark-primary font-normal"
              />
              <CountryBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName="nationality"
                label="Nationality"
                inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal focus:outline-none focus:border-basic"
                labelClasses="mb-2 text-primary dark:text-dark-primary font-normal"
                optionBoxClasses="dark:bg-dark-secondary"
                optionClasses="dark:text-dark-primary"
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 mt-2 items-center w-full">
              <InputField
                label="Passport No."
                register={register}
                type="text"
                errors={errors}
                placeHolder="Passport No."
                fieldName={`passport_no`}
                inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal"
                labelClasses="text-sm mb-2"
              />
              <CalendarBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName="passport_expiry"
                label="Passport Expiry"
                labelClasses="mb-2 text-primary dark:text-dark-primary font-normal"
                inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal focus:outline-none focus:border-basic border border-[#E5E7EB]"
              />
            </div>
          </div>

          <div className="flex gap-5 w-full mt-3">
            {/* <button
              className="w-full button btn bg-basic text-white  rounded-md p-2"
              onClick={() => {
                hideForm(false);
              }}
            >
              Close
            </button> */}
            <button
              className="w-full btn bg-basic text-white rounded-md p-2"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PassengerAdd;
