import { useState, useEffect } from "react";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useQuery } from "@apollo/client";
import { GET_VENDORS } from "./graphql/Query";
import { getSalePrice } from "~/modules/InquiriesModule/utils/getInquiryStats";
import SelectedRequestForm from "./components/RequestForm/SelectedRequestForm";
import SideDrawer from "~/components/common/sideDrawer";

export default function TicketRequestForm({ ticekts, inquiry, tabs }: any) {
  const { travelHouse }: any = travelHouseStore();
  const [open, setOpen] = useState(false);
  // get data based on query
  const { loading, data, error } = useQuery(GET_VENDORS, {
    variables: { th_id: travelHouse.id },
  });
  const [refundable, setRefundable] = useState("");

  const PaidAmount = inquiry?.inq_transections
    ?.filter((transaction: any) =>
      ["paid", "partiallyRefunded"].includes(transaction.status)
    )
    ?.map((transaction: any) =>
      transaction.status === "partiallyRefunded"
        ? transaction.amount - (transaction.refund_amount || 0)
        : transaction.amount
    )
    ?.reduce((sum: number, amount: number) => sum + amount, 0);

  useEffect(() => {
    if (!open) {
      setRefundable("");
    }
  }, [open]);

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <div
      className={`self-stretch bg-white dark:bg-dark-secondary  flex flex-col p-[25px] items-start justify-start shadow text-left text-mini text-primary dark:text-dark-primary rounded-lg ${
        tabs?.[0]?.label !== "Flights" ? "min-w-[600px]" : "w-full"
      }`}
    >
      <div className="self-stretch flex flex-col items-start justify-between">
        <div className="grid grid-cols-1 gap-4 w-full">
          <div className="">
            <label htmlFor="" className="text-[14px] mb-2 block">
              Refundable {"*"}
            </label>
            <select
              onChange={(e: any) => {
                if (e.target.value === "yes" || "no") {
                  setOpen(true);
                }
                setRefundable(e.target.value);
              }}
              className="w-full px-3 py-3 border rounded focus:outline-none focus:border-basic leading-tight border-gray-200 dark:bg-dark-primary"
              value={refundable}
            >
              <option value="">--Select--</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
        {refundable === "yes" ? (
          <SideDrawer
            sidePopup={open}
            setSidePopup={setOpen}
            component={
              <SelectedRequestForm
                refundable={refundable}
                PaidAmount={PaidAmount}
                inquiry={inquiry}
                tabs={tabs}
                data={data}
                ticekts={ticekts}
              />
            }
          />
        ) : refundable === "no" && PaidAmount >= getSalePrice(inquiry) ? (
          <SideDrawer
            sidePopup={open}
            setSidePopup={setOpen}
            component={
              <SelectedRequestForm
                refundable={refundable}
                PaidAmount={PaidAmount}
                inquiry={inquiry}
                tabs={tabs}
                data={data}
                ticekts={ticekts}
              />
            }
          />
        ) : (
          <p>Please pay your invoice first, then you can proceed further.</p>
        )}
      </div>
    </div>
  );
}
