import { useState } from "react"; // Import useState
import { useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";
import { Divider } from "@mui/material";

// Import Components
import InputField from "components/FormInputs/InputField";
import CountryBox from "components/FormInputs/CountryBox";
import { CalendarBox } from "components/FormInputs/CalendarBox";

import useNotifications from "hooks/useNotifications";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import SelectBox from "components/FormInputs/SelectBox";
import { table } from "console";
import { ADD_STAY_ROOMS } from "../../../graphql/Mutation";

const StayPassengerAdd = ({
  inquiry_id,
  hideForm,
  user_id,
  picked_by,
  tabLabel,
  inquiry,
}: any) => {
  const client = useApolloClient();

  // State to track active passenger
  const [activePassengerId, setActivePassengerId] = useState<string | null>(
    null
  );

  // Use the function to define the addPassenger mutation
  const [addPassenger] = useMutation(ADD_STAY_ROOMS);

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty }, // Correct destructuring here
    control,
    setValue,
  } = useForm();

  // Notifications
  const alert = useNotifications();
  const userRole = useUserDefaultRole();
  const userId = useUserId();

  const getPassengerAdd = (tabLabel: string, res: any) => {
    if (tabLabel === "Stay") {
      if (res?.data?.insert_stay_inq_room?.returning?.length > 0) {
        setActivePassengerId(res.data.insert_stay_inq_room.returning[0].id);
      }
    }
  };

  const onSubmit = async (data: any) => {
    if (tabLabel === "Stay") {
      data.stay_inq_id = inquiry?.stay_inq_details[0]?.id; // Set stay_inq_id for Stay tab
    }

    try {
      const res = await addPassenger({
        variables: { rooms: [{ ...data }] },
      });

      // Call function to handle active passenger based on tab
      getPassengerAdd(tabLabel, res);

      toast.success("Passenger added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      if (userRole === "user") {
        await alert.newNotification(
          picked_by,
          `Your customer has added a new passenger. Please check your portal`,
          "",
          ``
        );
      } else {
        await alert.newNotification(
          user_id,
          `Your consultant has added a new passenger. Please check your portal`,
          "",
          ``
        );
      }

      await client.resetStore();
      hideForm(false); // Hide form modal
    } catch (e) {
      hideForm(false);
    }
  };

  return (
    <div className="self-stretch flex-1 min-w-[500px] h-full bg-white dark:bg-dark-primary flex flex-col p-[25px] items-center gap-[20px] text-left text-mini text-darkslategray shadow">
      <h2 className="text-center text-2xl text-basic dark:text-dark-primary">
        Add Passenger
      </h2>
      <Divider />

      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="self-stretch flex flex-row pt-0 px-0 items-center justify-start">
          <div className="flex-1 rounded-8xs flex flex-col items-start justify-start">
            <div className="self-stretch relative leading-[130%]">
              <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 mt-2 items-center w-full">
                <InputField
                  label="Booked By"
                  register={register}
                  type="text"
                  errors={errors}
                  required={true}
                  placeHolder="Booked By"
                  fieldName={`booked_by`}
                  inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal"
                />
                <InputField
                  label="No of Days"
                  register={register}
                  type="number"
                  errors={errors}
                  placeHolder="No of Days"
                  fieldName={`no_of_days`}
                  inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal"
                />
                <InputField
                  label="Room Number"
                  register={register}
                  type="text"
                  errors={errors}
                  placeHolder="Room Number"
                  fieldName={`room_number`}
                  inputClasses="bg-white dark:bg-dark-primary text-primary dark:text-dark-primary font-normal"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-5">
          <button
            type="submit"
            className="w-full btn bg-basic text-white rounded-md p-2"
          >
            Add Passenger
          </button>
        </div>
      </form>
    </div>
  );
};

export default StayPassengerAdd;
