import React from "react";
import Layout from "~/components/common/Layout";
import DigitalAgenBrdModule from "~/modules/DigitalAgenBrdModule";
import ActivateSkillsComp from "~/modules/DigitalAgenBrdModule/segments/ActivateSkillsComp";

const ActivateSkillsPage = () => {
  return (
    <Layout>
      <DigitalAgenBrdModule>
        <ActivateSkillsComp />
      </DigitalAgenBrdModule>
    </Layout>
  );
};

export default ActivateSkillsPage;
