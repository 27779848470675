import { GET_FL_PROJECTS } from "~/graphql/flGetProjects/Query";
import { useQuery } from "@apollo/client";

export default function useFlProjects(queryParam: any) {
  const { data, refetch } = useQuery(GET_FL_PROJECTS, {
    variables: {
      queryParam,
    },
  });

  return {
    flProjects: data?.flGetProjects?.result || [],
    refetchProjects: refetch,
  };
}
