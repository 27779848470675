import React, { useState } from "react";
import { useParams } from "react-router-dom";
import SidePopup from "~/components/common/SidePopup";
import AddHotelPackages from "../Hotel/components/AddHotel";
import ShowHotelPackages from "../Hotel/components/ShowHotel";
import AddVisas from "./components/AddVisas";
import ShowVisas from "./components/ShowVisas";
import { Button } from "@mui/material";
import SideDrawer from "~/components/common/sideDrawer";
import AddTypes from "./components/AddTypes";

const Visas = () => {
  const { brd_id } = useParams();

  const [openPopup, setOpenPopup] = useState(false);

  return (
    <div>
      <div className="flex items-center justify-end mb-5">
        <Button onClick={() => setOpenPopup(true)} variant="contained">
          Add Types
        </Button>
      </div>
      <ShowVisas brd_id={brd_id} />
      <SidePopup component={<AddVisas brd_id={brd_id} />} />
      <SideDrawer
        component={<AddTypes brd_id={brd_id} />}
        sidePopup={openPopup}
        setSidePopup={setOpenPopup}
      />
    </div>
  );
};

export default Visas;
