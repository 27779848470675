import { Button, TextField } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import useFlProjects from "~/hooks/useFlProjects";
import useSelectedFlCountryWithBrdId from "~/hooks/useSelectedFlCountryWithBrdId";
import useSelectedFlJobsWithBrdId from "~/hooks/useSelectedFlJobsWithBrdId";
import brandsStore from "~/store/brandsStore/brandsStore";
import BidForm from "./components/BidForm";
import SideDrawer from "~/components/common/sideDrawer";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";

const FlProjects = () => {
  const [countries, setCountries] = useState<any>("");
  const [job, setJobs] = useState<any>("");

  const [bidSidePopup, setBidSidePopup] = useState<any>(false);

  const [analyzeRes, setAnalayzeRes] = useState<any>({});

  const [analyzeLoding, setAnalyzeLoad] = useState<any>({});

  const { brands }: any = brandsStore();

  const { selectedFlJobsWithBrdId, refetchSelectedJobs } =
    useSelectedFlJobsWithBrdId(brands?.[0]?.id);

  const { selectedFlCountryWithBrdId, refetchSelectedCountry } =
    useSelectedFlCountryWithBrdId(brands?.[0]?.id);
  useEffect(() => {
    const countryCodes = selectedFlCountryWithBrdId
      ?.map((item: any) => item?.country_code)
      ?.join("&countries[]=");

    setCountries(countryCodes);

    const jobIds = selectedFlJobsWithBrdId
      ?.map((item: any) => item?.project_id)
      ?.join("&jobs[]=");

    setJobs(jobIds);
  }, [selectedFlJobsWithBrdId, selectedFlCountryWithBrdId]);

  const { flProjects, refetchProjects }: any = useFlProjects(
    `compact=true&full_description=true&project_types[]=fixed&selected_bids=true&user_avatar=true&user_details=true&user_employer_reputation=true&user_employer_reputation_extra=true&user_status=true&countries[]=${countries}&jobs[]=${job}`
  );

  useEffect(() => {
    refetchSelectedCountry();
    refetchSelectedJobs();
    refetchProjects();
  }, []);

  function isRegistrationDateToday(registrationTimestamp: any) {
    // Convert the timestamp to a Moment.js date
    const registrationDate = moment.unix(registrationTimestamp); // Use .unix() if the timestamp is in seconds

    // Check if the registration date is the same as today's date
    return registrationDate.isSame(moment(), "day");
  }

  const anaLyzeProject = async (item: any) => {
    setAnalyzeLoad({ id: item.id, status: true });
    const prompt = `
      Analyze the provided project description to determine if it is a good fit for my team. Follow these steps:
      
      Skill Match:
      • Compare the required skills with my team’s expertise, which includes:
        - Development: React.js, Next.js, JavaScript, PHP, WordPress (themes and plugins), Shopify, eCommerce, performance, optimization, google page speed.
        - UI/UX Design: Figma, Tailwind CSS, Material UI.
        - APIs and Integrations: PayPal API, Stripe API, REST APIs, GraphQL, Hasura Actions.
        - Databases: MySQL, PostgreSQL, phpMyAdmin, MongoDB.
        - Backend: Node.js, Serverless Functions.
        - Marketing & Analytics:
          - Lead generation, organic views, lead follow-up.
          - Content creation, SEO, Google Analytics, Tag Manager, Facebook Pixel, retargeting, Meta Ads, Google Ads.
      
      Difficulty Level:
      • Assess the complexity of the project to determine if it aligns with our capabilities.
      
      Budget Feasibility:
      • Analyze the project’s budget and estimate if it is realistic for the required work.
      
      Final Status:
      Based on the above evaluation, determine one of the following statuses:
        • Place Bid: Your team can do this, and the budget is also reasonable.
        • Place Bid: Your team can do this, but the budget is not good.
        • Don’t Place Bid: Your team can’t do this, but the budget is fine.
        • Don’t Place Bid: Your team can’t do this, and the budget is also not good.

      And after analysis as a result the conclusion should be only from Final Status so we can read quickly rather then complete paragraphs

      Description: ${item?.description}
      Max Budget: ${item?.budget?.maximum}
    `;

    const res = await getAiData(prompt);
    setAnalayzeRes({ id: item.id, desc: res });
    setAnalyzeLoad({ id: null, status: false });
  };

  return (
    <div className="flex flex-col gap-5">
      {flProjects?.projects
        ?.filter((itemf: any) => {
          if (
            isRegistrationDateToday(
              flProjects?.users?.[itemf?.owner_id]?.registration_date
            )
          ) {
            const trueCount = Object.values(
              flProjects?.users?.[itemf?.owner_id]?.status
            ).filter((value) => value === true).length;

            if (trueCount >= 2 && itemf?.currency?.code !== "INR") {
              return true;
            }
          } else {
            const trueCount = Object.values(
              flProjects?.users?.[itemf?.owner_id]?.status
            ).filter((value) => value === true).length;

            if (trueCount >= 3 && itemf?.currency?.code !== "INR") {
              return true;
            }
          }
        })
        ?.map((item: any) => {
          return (
            <div className="bg-secondary p-5 shadow rounded flex flex-col gap-2 text-primary">
              <div className="bg-primary p-5 shadow  rounded flex flex-col gap-2 ">
                <p className="text-xl text-basic font-bold mb-2">
                  PROJECT DETAIL:
                </p>
                <a
                  target="_blank"
                  href={`https://www.freelancer.com/projects/${item?.id}`}
                  className="text-lg text-black font-bold"
                >
                  {item?.title}
                </a>
                <p>{item?.description}</p>
              </div>
              <div className="bg-primary p-5 shadow rounded">
                <p className="text-xl text-basic font-bold mb-4">
                  USER DETAIL:
                </p>
                <div className="grid grid-cols-4 gap-3">
                  <p>
                    <span className="font-bold text-black text-md">Name:</span>{" "}
                    <a
                      target="_blank"
                      href={`https://www.freelancer.com/u/${
                        flProjects?.users?.[item?.owner_id]?.username
                      }`}
                    >
                      {flProjects?.users?.[item?.owner_id]?.public_name}
                    </a>
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">
                      Registration Date:
                    </span>{" "}
                    {moment
                      .unix(
                        flProjects?.users?.[item?.owner_id]?.registration_date
                      )
                      .format("YYYY-MM-DD HH:mm")}
                  </p>
                  {flProjects?.users?.[item?.owner_id]?.company && (
                    <p>
                      company: {flProjects?.users?.[item?.owner_id]?.company}
                    </p>
                  )}
                  <p>
                    <span className="font-bold text-black text-md">
                      Avg Bid:
                    </span>{" "}
                    {item?.bid_stats?.bid_avg?.toFixed(0)}{" "}
                    {item?.currency?.code}
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">
                      Maximum:
                    </span>{" "}
                    {item?.budget?.maximum} {item?.currency?.code}
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">
                      Total Bids:
                    </span>{" "}
                    {item?.bid_stats?.bid_count}{" "}
                  </p>

                  <p>
                    <span className="font-bold text-black text-md">
                      Status:
                    </span>{" "}
                    {item?.status}
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">
                      Submition Date:
                    </span>{" "}
                    {moment.unix(item?.submitdate).format("YYYY-MM-DD HH:mm")}
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">Role:</span>{" "}
                    {flProjects?.users?.[item?.owner_id]?.chosen_role}
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">
                      Earning Score:
                    </span>{" "}
                    {
                      flProjects?.users?.[item?.owner_id]?.employer_reputation
                        ?.earnings_score
                    }
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">All:</span>{" "}
                    {
                      flProjects?.users?.[item?.owner_id]?.employer_reputation
                        ?.entire_history?.all
                    }
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">
                      Clarity:
                    </span>{" "}
                    {flProjects?.users?.[
                      item?.owner_id
                    ]?.employer_reputation?.entire_history?.category_ratings?.clarity_spec?.toFixed(
                      1
                    )}
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">
                      Completed Projects:
                    </span>{" "}
                    {
                      flProjects?.users?.[item?.owner_id]?.employer_reputation
                        ?.entire_history?.complete
                    }
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">
                      Completion Rate:
                    </span>{" "}
                    {
                      flProjects?.users?.[item?.owner_id]?.employer_reputation
                        ?.entire_history?.completion_rate
                    }
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">
                      Incomplete:
                    </span>{" "}
                    {
                      flProjects?.users?.[item?.owner_id]?.employer_reputation
                        ?.entire_history?.incomplete
                    }
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">
                      Overall:
                    </span>{" "}
                    {flProjects?.users?.[
                      item?.owner_id
                    ]?.employer_reputation?.entire_history?.overall?.toFixed(1)}
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">
                      Reviews:
                    </span>{" "}
                    {
                      flProjects?.users?.[item?.owner_id]?.employer_reputation
                        ?.entire_history?.reviews
                    }
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">City:</span>{" "}
                    {flProjects?.users?.[item?.owner_id]?.location.city}
                  </p>
                  <p>
                    <span className="font-bold text-black text-md">
                      Country:
                    </span>{" "}
                    {flProjects?.users?.[item?.owner_id]?.location.country.name}
                  </p>
                </div>
              </div>

              <div className="flex gap-3 items-center">
                <Button
                  onClick={() =>
                    setBidSidePopup({
                      ProjectId: item?.id,
                      desc: item?.description,
                      clientName:
                        flProjects?.users?.[item?.owner_id]?.public_name,
                    })
                  }
                  sx={{ maxWidth: "130px" }}
                  variant="contained"
                >
                  Place Bid
                </Button>
                <Button
                  onClick={() => {
                    anaLyzeProject(item);
                  }}
                  disabled={analyzeLoding?.id !== item?.id && analyzeLoding?.id}
                  sx={{ maxWidth: "130px" }}
                  variant="outlined"
                >
                  {analyzeLoding.status === true &&
                  analyzeLoding?.id === item?.id
                    ? "Analyzing..."
                    : "Analyze"}
                </Button>
              </div>
              {analyzeRes?.desc && analyzeRes?.id === item?.id && (
                <TextField multiline value={analyzeRes?.desc} />
              )}
            </div>
          );
        })}
      <SideDrawer
        component={<BidForm prjData={bidSidePopup} />}
        sidePopup={bidSidePopup}
        setSidePopup={setBidSidePopup}
      />
    </div>
  );
};

export default FlProjects;
