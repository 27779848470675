import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import UmrahPackages from "~/modules/BrandModule/segments/UmrahPackages";

const UmrahPackagesPage = () => {
  return (
    <Layout>
      <BrandModule>
        <UmrahPackages />
      </BrandModule>
    </Layout>
  );
};

export default UmrahPackagesPage;
