import { gql } from "@apollo/client";

export const GET_BRD_VISAS_WITH_BRD_ID = gql`
  query GetBrdVisasWithBrdId($brd_id: uuid!) {
    brd_visas(where: { brd_id: { _eq: $brd_id } }) {
      id
      brd_visa_type {
        name
      }
      type
      validity
      brd_id
      price
    }
  }
`;
