import { useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Divider,
  TextField,
  Checkbox,
  Chip,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { useState } from "react";
import useBrdHotelsByBrdId from "~/hooks/useBrdHotelsByBrdId";
import useUmrahVisasById from "~/hooks/useUmrahVisasById";
import SideDrawer from "~/components/common/sideDrawer";
import AddUmrahFares from "../AddUmrahFares";
import FareCard from "~/modules/BrandModule/segments/AirlinesUpdate/components/AirlineFares/components/FareCard";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useMutation } from "@apollo/client";
import { INSERT_UMRAH_PACKAGES } from "~/graphql/brd_umrah_packages/Mutation";
import moment from "moment";
import { toast } from "react-toastify";

const AddUmrahPackages = () => {
  const { brd_id } = useParams();
  const [date, setDate] = useState<any>([
    dayjs("2022-04-17"),
    dayjs("2024-04-21"),
  ]);
  const [madinahHotel, setMadinahHotel] = useState<any>([]);
  const [makkahHotel, setMakkahHotel] = useState<any>([]);
  const [visas, setVisas] = useState<any>([]);
  const [farePopup, setFarePopup] = useState<any>(false);
  const [fares, setFares] = useState<any>([]);

  const [departure, setDeparture] = useState<any>(null);
  const [destination, setDestination] = useState<any>(null);
  const [airline, setAirline] = useState<any>(null);

  const navigate = useNavigate();

  const [addUmrahPackages] = useMutation(INSERT_UMRAH_PACKAGES);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { brdHotels }: any = useBrdHotelsByBrdId(brd_id);
  const { brdVisas }: any = useUmrahVisasById(brd_id);

  const onSubmit = async (formData: any) => {
    try {
      const hotels = [
        ...madinahHotel.map((hotel: any) => ({
          ...hotel,
          nights: formData?.madinahHotelNights,
        })),
        ...makkahHotel.map((hotel: any) => ({
          ...hotel,
          nights: formData?.makkahHotelNights,
        })),
      ];

      const payload = {
        start_date: moment(date?.[0]?.$d).format("YYYY-MM-DD"),
        end_date: moment(date?.[1]?.$d).format("YYYY-MM-DD"),
        name: formData.name,
        brd_id: brd_id,
        slug: formData.slug,
        umrah_fares: {
          data: fares?.map((fare: any) => ({
            fare_id: fare?.id,
          })),
        },
        umrah_hotels: {
          data: hotels?.map((hotel: any) => ({
            hotel_id: hotel?.id,
            nights: hotel?.nights,
          })),
        },
        umrah_visas: {
          data: visas?.map((visa: any) => ({
            visa_id: visa?.id,
          })),
        },
      };

      const res = await addUmrahPackages({
        variables: {
          data: payload,
        },
      });

      if (res?.data?.insert_brd_umrah_packages?.affected_rows) {
        toast.success("Package Added SuccessFully");
        navigate(
          `/admin/brands/${brd_id}/umrah-packages/update/${res?.data?.insert_brd_umrah_packages?.returning?.[0]?.id}`
        );
      } else {
        toast.error("Getting Some Error");
      }
    } catch (err: any) {
      toast.error(err?.message);
    }
  };

  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
      <h1 className="text-center text-xl">Umrah Package Details</h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 gap-5 mt-10"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateRangePicker", "DateRangePicker"]}>
            <DemoItem component="DateRangePicker">
              <DateRangePicker
                value={date}
                onChange={(newValue: any) => setDate(newValue)}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
        <div className="grid grid-cols-2 gap-5">
          <TextField
            error={errors["name"] ? true : false}
            {...register("name", { required: true })}
            label="Name"
          />
          <TextField
            error={errors["slug"] ? true : false}
            {...register("slug", { required: true })}
            onChange={(e) => {
              const formattedValue = e.target.value
                .replace(/\s+/g, "-")
                .toLowerCase();
              e.target.value = formattedValue;
              // Call the registered onChange to update the form value
              register("slug").onChange(e);
            }}
            label="Slug"
          />
        </div>

        <div className="grid grid-cols-2 gap-5">
          <Autocomplete
            value={madinahHotel}
            onChange={(event: any, newValue: any) => {
              setMadinahHotel(newValue);
            }}
            multiple
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option: any, value: any) =>
              option?.id === value?.id
            }
            renderOption={(props, option: any) => {
              const isSelected = madinahHotel?.some(
                (hotel: any) => hotel?.id === option?.id
              );
              return (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={isSelected}
                  />
                  {option.name}
                </li>
              );
            }}
            renderTags={(selected, getTagProps) => {
              return (
                <Chip
                  key="selected"
                  label={`${selected.length} Hotels Selected`}
                />
              );
            }}
            options={
              brdHotels?.filter((item: any) => item.city === "madinah") || []
            }
            renderInput={(params) => (
              <TextField {...params} label="Madinah Hotels" />
            )}
          />
          <TextField
            error={errors["madinahHotelNights"] ? true : false}
            {...register("madinahHotelNights", { required: true })}
            label="Nights"
          />
        </div>
        <div className="grid grid-cols-2 gap-5">
          <Autocomplete
            value={makkahHotel}
            onChange={(event: any, newValue: any) => {
              setMakkahHotel(newValue);
            }}
            multiple
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option: any, value: any) =>
              option?.id === value?.id
            }
            renderOption={(props, option: any) => {
              const isSelected = makkahHotel?.some(
                (hotel: any) => hotel?.id === option?.id
              );
              return (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={isSelected}
                  />
                  {option.name}
                </li>
              );
            }}
            renderTags={(selected, getTagProps) => {
              return (
                <Chip
                  key="selected"
                  label={`${selected.length} Hotels Selected`}
                />
              );
            }}
            options={
              brdHotels?.filter((item: any) => item.city === "makkah") || []
            }
            renderInput={(params) => (
              <TextField {...params} label="Makkah Hotels" />
            )}
          />
          <TextField
            error={errors["makkahHotelNights"] ? true : false}
            {...register("makkahHotelNights", { required: true })}
            label="Nights"
          />
        </div>
        <Autocomplete
          value={visas}
          onChange={(event: any, newValue: any) => {
            setVisas(newValue);
          }}
          multiple
          getOptionLabel={(option) => option?.type}
          isOptionEqualToValue={(option: any, value: any) =>
            option?.id === value?.id
          }
          renderOption={(props, option: any) => {
            const isSelected = visas?.some(
              (visa: any) => visa?.id === option?.id
            );
            return (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={isSelected}
                />
                {option?.brd_visa_type?.name}
              </li>
            );
          }}
          renderTags={(selected, getTagProps) => {
            if (selected.length > 2) {
              return [
                <Chip
                  label={selected[0]?.brd_visa_type?.name}
                  {...getTagProps({ index: 0 })}
                />,
                <Chip
                  label={selected[1]?.brd_visa_type?.name}
                  {...getTagProps({ index: 1 })}
                />,
                <Chip key="more" label={`+${selected.length - 2} more`} />,
              ];
            }

            return selected.map((option: any, index: number) => (
              <Chip
                label={option?.brd_visa_type?.name}
                {...getTagProps({ index })}
              />
            ));
          }}
          options={brdVisas || []}
          renderInput={(params) => <TextField {...params} label="Visa Types" />}
        />
        <div className="gird grid-cols-1 gap-10">
          {fares?.map((item: any, ind: any) => {
            return <FareCard key={ind} sg={item} hideAction={true} />;
          })}
        </div>
        <Button
          onClick={() => setFarePopup(true)}
          sx={{ width: "200px" }}
          variant="contained"
        >
          Add / Remove Fares
        </Button>
        <Button type="submit" variant="contained">
          Add Package
        </Button>
      </form>
      <SideDrawer
        component={
          <AddUmrahFares
            setFares={setFares}
            fares={fares}
            setDeparture={setDeparture}
            departure={departure}
            destination={destination}
            setDestination={setDestination}
            airline={airline}
            setAirline={setAirline}
          />
        }
        sidePopup={farePopup}
        setSidePopup={setFarePopup}
      />
    </div>
  );
};

export default AddUmrahPackages;
