import React from "react";
import DomainVerificationBrand from "./Brand";
import DomainVerificationEmail from "./Email";
import { useQuery } from "@apollo/client";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_DOMAINS_BRANDS } from "./graphql/Query";
import { Loader } from "@mantine/core";

const DomainVerification = ({ brand }: any) => {
  const { travelHouse }: any = travelHouseStore();

  const { loading, data, error } = useQuery(GET_DOMAINS_BRANDS, {
    variables: { thp_id: travelHouse.id },
  });
  const getAdminUsers = (data: any) => {
    const uniqueAdmins: any = new Set();

    data?.brd_list?.forEach((brd: any) => {
      brd?.thp_list.brd_users?.forEach((userWrapper: any) => {
        const user = userWrapper?.users;
        if (user?.defaultRole === "admin") {
          uniqueAdmins.add(user?.email);
        }
      });
    });

    return [...uniqueAdmins];
  };

  const adminUsers = getAdminUsers(data);
  // return loading while loading data
  if (loading) return <Loader w="200px" />;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;
  return (
    <>
      <DomainVerificationBrand brand={brand} adminUsers={adminUsers} />
      {/* {brand?.domain !== null && <DomainVerificationEmail brand={brand} />} */}
    </>
  );
};

export default DomainVerification;
