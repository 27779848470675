import { useQuery } from "@apollo/client";
import { GET_HOTELS_BY_BRD_ID } from "~/graphql/brd_hotels/Query";

export default function useBrdHotelsByBrdId(brdId: any) {
  const { data } = useQuery(GET_HOTELS_BY_BRD_ID, {
    variables: {
      brd_id: brdId,
    },
  });

  return { brdHotels: data?.brd_hotels };
}
