import { gql } from "@apollo/client";

export const UPDATE_SUGGESTION_LEG = gql`
  mutation UpdateSuggestionLeg(
    $id: uuid!
    $data: inq_suggestion_leg_set_input!
  ) {
    update_inq_suggestion_leg(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;
