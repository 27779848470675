import { useMutation } from "@apollo/client";
import React from "react";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { DELETE_FLIGHT_INQ_EXTRA_BAGGAGE_SELECTION } from "~/graphql/flightInq_extraBaggage/Mutation";

interface Baggage {
  inq_id: string;
  weight: number;
  cost_price: number;
  sale_price: number;
}

interface Inquiry {
  id: any;
  flightInq_extraBaggages?: Baggage[];
}

interface BaggageCardProps {
  inquiry: Inquiry;
}

const BaggageCard: React.FC<BaggageCardProps> = ({ inquiry }) => {
  const [deleteBaggageSelection] = useMutation(
    DELETE_FLIGHT_INQ_EXTRA_BAGGAGE_SELECTION
  );

  const handleDelete = async () => {
    const inq_id = inquiry?.id;
    if (inq_id) {
      const { data } = await deleteBaggageSelection({
        variables: { data: inq_id },
      });

      const affectedRows = data?.delete_flightInq_extraBaggage?.affected_rows;

      if (affectedRows > 0) {
        toast.success("Baggage selection deleted successfully!");
      } else {
        toast.error("No baggage selection was deleted.");
      }
    }
  };

  const totalSalePrice = inquiry?.flightInq_extraBaggages?.reduce(
    (total, baggage) => total + baggage.sale_price,
    0
  );

  return (
    <div className="rounded-lg shadow w-full flex flex-col">
      {/* Card Header */}
      <div className="self-stretch flex-1 bg-secondary dark:bg-dark-secondary flex flex-col p-[25px] rounded-t-lg pt-[25px] items-start justify-center text-left text-mini text-darkslategray">
        <div className="self-stretch container w-full flex flex-col gap-[12px] justify-between">
          <div className="self-stretch flex items-center justify-between gap-1 text-basic dark:text-dark-primary">
            <b>Extra Baggage</b>
            <div className="flex items-center justify-end gap-[8px]">
              <MdDelete
                className="text-basic dark:text-dark-primary cursor-pointer h-6 w-6"
                onClick={handleDelete}
              />
            </div>
          </div>

          <div className="self-stretch flex flex-col gap-[12px] w-full">
            {inquiry?.flightInq_extraBaggages
              ?.slice(0, 4)
              .map((baggage, index) => (
                <div
                  key={baggage.inq_id}
                  className="self-stretch relative flex flex-col border-b border-solid border-basic dark:border-dark-primary text-basic dark:text-dark-primary"
                >
                  <div className="flex justify-between">
                    <span className="font-bold mr-1">{`${index + 1}.`}</span>
                    <div className="flex justify-between gap-5 w-full">
                      <div>
                        <span>Weight:</span>
                        <b className="ml-2">{baggage.weight} kg</b>
                      </div>
                      <div>
                        <span>Cost Price:</span>
                        <b className="ml-2">{`£${baggage.cost_price}`}</b>
                      </div>
                      <div>
                        <span>Sale Price:</span>
                        <b className="ml-2">{`£${baggage.sale_price}`}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-2 w-full bg-basic px-[25px] py-[20px] rounded-b-lg">
        <div className="relative flex gap-1 text-basic rounded-2xl font-medium bg-white px-2">
          <p className="capitalize">
            Total Sale Price:
            <b className="ml-1">{`£${totalSalePrice || 0}`}</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BaggageCard;
