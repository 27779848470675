import { useState } from "react";
import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import SideDrawer from "~/components/common/sideDrawer";
import EditTrain from "./components/EditTrain";
import PassengerRecord from "../InquiryCard/components/PassengerRecord";
import moment from "moment";

const TrainCard = ({
  trainId,
  origin,
  destination,
  date,
  passengers,
  inqId,
  editAble
}: any) => {

  const [open, setOpen] = useState(false)

  return (
    <div className="bg-secondary dark:bg-dark-secondary text-primary rounded-2xl dark:text-dark-primary self-stretch flex flex-col h-full">
      <div className="self-stretch flex flex-col my-5 px-[30px] mt-5 flex-grow">
        <div className="h-full flex flex-col items-start justify-center gap-5">
          <div className="bg-basic h-20 w-20 rounded-full flex items-center justify-center">
            <DirectionsTransitIcon
              className="text-center text-white"
              fontSize="large"
            />
          </div>
          <div className="flex flex-row justify-between items-center w-full gap-3">
            <div className=" flex gap-3 flex-col">
              <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base">
                ORIGIN
              </div>
              <div className="relative text-3xl font-medium text-basic dark:text-dark-primary">
                {origin}
              </div>
            </div>

            <div className=" flex gap-3 flex-col">
              <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base text-right">
                DESTINATION
              </div>
              <div className="relative text-3xl font-medium text-basic dark:text-dark-primary text-right">
                {destination}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-start gap-[9px] absolute top-[5%] right-[2%]">
            {editAble && (
              <BiSolidMessageSquareEdit
                onClick={() => {
                  setOpen(true);
                }}
                className="cursor-pointer text-basic dark:text-dark-primary relative w-5 h-5"
              />
            )}
          </div>
        </div>
      </div>
      <div className="self-stretch flex md:flex-row px-8 py-5 items-center justify-between bg-basic rounded-b-2xl h-[150px]">
        <div className="self-stretch flex items-center justify-between w-full">
          <div className="flex flex-col items-start justify-start gap-1 text-white font-medium">
            <div className="self-stretch relative text-base">
              {moment(date).format('DD-MM-YYYY')}
            </div>
          </div>
          <div>
            <div>
              <PassengerRecord inq_passengers={passengers} />
            </div>
          </div>
        </div>
      </div>
      {open && (
        <SideDrawer
          sidePopup={open}
          setSidePopup={setOpen}
          component={
            <EditTrain
              setSidePopup={setOpen}
              inqId={inqId}
              trainId={trainId}
              origin={origin}
              destination={destination}
              date={date}
              passengers={passengers}
            />
          }
        />
      )}
    </div>
  );
};

export default TrainCard;
