import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import { DateTimeBox } from "components/FormInputs/DateTimeBox";
import CarPurchaseDetails from "../../../components/SelectedPurchaseDetails/components/carPurchaseDetails";
import { CAR_ADD_TICKET, ADD_TRANSACTION } from "../../../graphql/CarMutation";

export default function CarRequestForm({
  refundable,
  paidAmount,
  inquiry,
  vendors,
  ticekts,
}: any) {
  const [isloading, setIsloading] = useState(false);
  const [addTicket] = useMutation(CAR_ADD_TICKET);
  const [addTransaction] = useMutation(ADD_TRANSACTION);
  const client = useApolloClient();

  const [ticketPurchase, setTicketPurchase] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  }: any = useForm();

  const onSubmit = async (formData: any) => {
    try {
      const passengers =
        inquiry?.car_inq_details?.[0]?.car_inq_passengers || [];

      if (passengers.length === 0) {
        toast.error("No ticket can be added since no passengers were found.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }

      formData["car_inq_id"] = inquiry?.car_inq_details?.id;
      formData["status"] = "pending_approval";

      formData["void_time"] = moment(formData["void_time"]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      formData["issue_date"] = moment(formData["issue_date"]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      setIsloading(true);

      const payload = {
        car_inq_id: inquiry?.car_inq_details[0]?.id,
        ticket_cost_price: formData["ticket_cost_price"] || null,
        ticket_attol_price: formData["ticket_attol_price"] || null,
        void_time: formData["void_time"],
        vendor_id: formData["vendor_id"],
        created_at: formData["issue_date"],
        refundable: refundable,
        status: "pending_approval",
        car_inq_ticket_files: {
          data: inquiry?.car_inq_details?.[0]?.car_inq_passengers?.map(
            (item: any) => ({
              car_passenger_id: item?.id,
            })
          ),
        },
      };

      let ticket_cost_price = formData["ticket_cost_price"];

      const rawTransactions: any = [
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 18,
          inq_id: inquiry.id,
          transactions_no: "randomNumber",
          date: formData["issue_date"],
          created_at: formData["issue_date"],
          type: "debit",
          amount: parseFloat(ticket_cost_price),
        },
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 2,
          user_id: formData["vendor_id"],
          inq_id: inquiry.id,
          transactions_no: "randomNumber",
          date: formData["issue_date"],
          created_at: formData["issue_date"],
          type: "credit",
          amount: -parseFloat(ticket_cost_price),
        },
      ];

      const transactions = rawTransactions.filter(
        (obj: any) => obj.amount !== 0
      );

      try {
        const res = await addTransaction({
          variables: {
            transactions: transactions,
          },
        });
        toast.success("Transaction added", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();
      } catch (e) {}
      try {
        const res = await addTicket({
          variables: { ticket: { ...payload } },
        });

        if (res.data?.insert_car_inq_ticket?.returning?.length > 0) {
          toast.success("Ticket Request generated successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          await client.resetStore();
        }
        setIsloading(false);
      } catch (e) {
        setIsloading(false);
      }
    } catch (error) {
      toast.error("An error occurred during submission.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsloading(false);
    }
  };

  return (
    <div className="min-w-[500px] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <>
          <div className="grid grid-cols-1 gap-4">
            <div className="">
              <label htmlFor="" className="text-[14px] mb-2 block">
                Select Vendor {"*"}
              </label>
              <select
                {...register("vendor_id", {
                  required: "Vendor is required",
                })}
                aria-invalid={errors.vendor_id ? "true" : "false"}
                className="w-full px-3 py-3 border rounded focus:outline-none focus:border-basic leading-tight border-gray-200 dark:bg-dark-primary"
              >
                <option value="">--Select Vendor--</option>
                {vendors.map((vendor: any) => {
                  return (
                    <option key={`${vendor?.id}`} value={`${vendor?.id}`}>
                      {vendor.displayName}
                    </option>
                  );
                })}
              </select>
              {errors.vendor_id && (
                <p role="alert" className="text-red-500 text-[12px] m-1">
                  {errors.vendor_id.message}
                </p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <DateTimeBox
              control={control}
              setValue={setValue}
              errors={errors}
              fieldName={`void_time`}
              label="Void Time *"
              labelClasses="text-primary dark:text-dark-primary font-normal"
              inputClasses={
                "bg-white dark:bg-dark-primary focus:outline-none focus:border-basic py-2 mt-[2px]"
              }
            />
          </div>

          <div className="grid grid-cols-1 gap-4">
            <DateTimeBox
              control={control}
              setValue={setValue}
              errors={errors}
              fieldName={`issue_date`}
              label="Issued Date *"
              labelClasses="text-primary dark:text-dark-primary font-normal"
              inputClasses={
                "bg-white dark:bg-dark-primary focus:outline-none focus:border-basic py-2 mt-[2px]"
              }
            />
          </div>

          <CarPurchaseDetails
            ticketPurchase={ticketPurchase}
            setTicketPurchase={setTicketPurchase}
            register={register}
            errors={errors}
            inquiry={inquiry}
            ticekts={ticekts}
          />

          <button
            type="submit"
            className={`text-white px-5 py-2 rounded hover:cursor-pointer bg-teal-700 transition-all mt-[20px]`}
          >
            {isloading ? "Requesting ..." : "Request"}
          </button>
        </>
      </form>
    </div>
  );
}
