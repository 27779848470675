import { gql } from "@apollo/client";

export const INSERT_STAY_INQUIRY_DETAIL = gql`
  mutation InsertStayInquiryDetail($data: [stay_inq_detail_insert_input!]!) {
    insert_stay_inq_detail(objects: $data) {
        affected_rows
        returning {
            id
        }
    }
    }
`;

export const UPDATE_STAY_INQUIRY_DETAIL = gql`
  mutation updateStayInquiryDetail(
    $id: uuid!, 
    $adults: Int!, 
    $children: Int!, 
    $end_date: date!, 
    $rooms: Int!, 
    $location: String!, 
    $start_date: date!
  ) {
    update_stay_inq_detail(
      where: { id: { _eq: $id } }, 
      _set: {
        adults: $adults,
        children: $children,
        end_date: $end_date,
        rooms: $rooms,
        location: $location,
        start_date: $start_date
      }
    ) {
      returning {
        id
      }
    }
  }
`;

