import { Text, View } from "@react-pdf/renderer";
import { getStyles } from "../styles";

const PassengerComponent = ({ invoice, inquiry }: any) => {
  const stayPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "stay"
    ) || [];
  const carPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "car"
    ) || [];
  const trainPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "train"
    ) || [];
  const flightSuggestion =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "flights"
    ) || [];
  const styles = getStyles(
    inquiry?.brd_list?.brd_details?.[0]?.secondary_color
  );

  return (
    <>
      {stayPassengers.length > 0 && (
        <>
          {stayPassengers.map((item: any, index: number) => (
            <View key={index}>
              {/* Ensure that car_inq_suggestion_costs is correctly accessed */}
              {item?.stay_inq_suggestion?.stay_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <View style={styles.passengerBody}>
                    <Text>{cost?.stay_inq_room?.booked_by || "N/A"} </Text>
                    <Text>£{cost?.sale_price}</Text>
                  </View>
                )
              )}
            </View>
          ))}
        </>
      )}
      {carPassengers.length > 0 && (
        <>
          {carPassengers.map((item: any, index: number) => (
            <View key={index}>
              {item?.car_inq_suggestion?.car_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <View key={costIndex} style={styles.passengerBody}>
                    <Text>
                      {cost?.car_inq_passenger?.first_name}{" "}
                      {cost?.car_inq_passenger?.last_name}
                    </Text>
                    <Text>£{cost?.sale_price}</Text>
                  </View>
                )
              )}
            </View>
          ))}
        </>
      )}

      {trainPassengers.length > 0 && (
        <>
          {trainPassengers.map((item: any, index: number) => (
            <View key={index}>
              {/* Ensure that car_inq_suggestion_costs is correctly accessed */}
              {item?.train_inq_suggestion?.train_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <View key={costIndex} style={styles.passengerBody}>
                    <Text>
                      {cost?.train_inq_passenger?.first_name}{" "}
                      {cost?.train_inq_passenger?.last_name}
                    </Text>
                    <Text>£{cost?.sale_price}</Text>
                  </View>
                )
              )}
            </View>
          ))}
        </>
      )}
      {flightSuggestion.length > 0 && (
        <>
          <View style={styles.passengerBody}>
            <Text>Flight</Text>
            <Text>
              {
                flightSuggestion?.[0]?.inq_suggestion?.inq_suggestion_costs
                  ?.length
              }{" "}
              {flightSuggestion?.[0]?.inq_suggestion?.inq_suggestion_costs
                ?.length > 1
                ? "Passengers"
                : "Passenger"}
            </Text>
            <Text>
              £
              {flightSuggestion?.[0]?.inq_suggestion?.inq_suggestion_costs?.reduce(
                (acc: any, item: any) => {
                  return acc + item.sale_price;
                },
                0
              )}
            </Text>
          </View>
        </>
      )}
    </>
  );
};

export default PassengerComponent;
