import { Button, TextField, Autocomplete, Grid } from "@mui/material";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { useEffect, useState } from "react";
import ContentEditor from "~/components/common/ContentEditor";
import brandsStore from "~/store/brandsStore/brandsStore";
import { useMutation, useQuery } from "@apollo/client";
import { GET_MAILJET_CONTACT_LIST } from "~/graphql/get_mailjet_contact_list/Query";
import { GET_MAILJET_SENDERS_LIST } from "~/graphql/get_mailjet_sender_list/Query";
import { SEND_MAILJET_EMAILS } from "~/graphql/send_mailJet_emails/Mutation";
import { toast } from "react-toastify";

type Brand = {
  id: string;
  name: string;
  selected: boolean;
};

type SenderInfo = {
  Email: string;
  Name: string;
};

type Contact = {
  Name: string;
  SubscriberCount: number;
};

const AddEmailCampaign = () => {
  const { handleSubmit, register, reset } = useForm();
  const { brands: allBrands, setBrands } = brandsStore();
  const [content, setContent] = useState<string | null>(null);
  const [contactListOptions, setContactListOptions] = useState<Contact[]>([]);
  const [senderListOptions, setSenderListOptions] = useState<SenderInfo[]>([]);
  const [selectedSenderInfo, setSelectedSenderInfo] =
    useState<SenderInfo | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<any>(null);

  const {
    data: contactListData,
    loading: contactListLoading,
    error: contactListError,
  } = useQuery(GET_MAILJET_CONTACT_LIST, {
    skip: !selectedBrand?.id,
    variables: { brdId: selectedBrand?.id },
  });

  const {
    data: senderListData,
    loading: senderListLoading,
    error: senderListError,
  } = useQuery(GET_MAILJET_SENDERS_LIST, {
    skip: !selectedBrand?.id,
    variables: { brdId: selectedBrand?.id },
  });

  useEffect(() => {
    if (contactListData?.get_mailjet_contact_list?.Data) {
      setContactListOptions(contactListData.get_mailjet_contact_list.Data);
    }
  }, [contactListData]);

  useEffect(() => {
    if (senderListData?.get_mailjet_sender_list?.Data) {
      setSenderListOptions(senderListData.get_mailjet_sender_list.Data);
    }
  }, [senderListData]);

  const [sendMail, { data: sendMailData, loading: sendMailLoading }] =
    useMutation(SEND_MAILJET_EMAILS);

  const onSubmit: SubmitHandler<FieldValues> = async (formData: any) => {
    const submitData = {
      ...formData,
      content,
      selectedBrand,
      selectedSenderInfo,
    };

    const mutationVariables = {
      brdId: submitData?.selectedBrand?.id,
      campaignName: submitData?.campaign_name,
      from: {
        Email: submitData?.selectedSenderInfo?.Email,
        Name: submitData?.selectedSenderInfo?.Name || "No name",
      },
      subject: submitData?.subject,
      body: submitData?.content,
      listId: JSON.stringify(submitData.selectedSenderInfo?.ID),
      replyEmail: submitData.replay_email,
      previewText: submitData.preview_text,
    };
    try {
      const response = await sendMail({ variables: mutationVariables });

      if (response.data.send_mailJet_emails.status) {
        toast.success("Send Mail Successfully");
      }
    } catch (error) {
      toast.error("Sending failed");
    }
  };

  return (
    <div>
      <h1 className="text-xl font-semibold text-primary dark:text-dark-primary sm:text-2xl">
        Add Email Campaign
      </h1>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-4 space-y-4">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="brand-filter"
              options={allBrands}
              value={selectedBrand}
              onChange={(event, newValue: Brand | null) => {
                setSelectedBrand(newValue);
                setBrands(
                  allBrands.map((brand: Brand) => ({
                    ...brand,
                    selected: newValue ? brand.id === newValue.id : false,
                  }))
                );
              }}
              getOptionLabel={(option: Brand) => option.name}
              isOptionEqualToValue={(option: Brand, value: Brand) =>
                option.id === value.id
              }
              renderOption={(props, option: Brand) => (
                <li {...props}>{option.name}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Brand"
                  placeholder="Choose a brand"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Campaign Name"
              {...register("campaign_name")}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField label="Subject" {...register("subject")} fullWidth />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="sender-list"
              options={senderListOptions}
              value={selectedSenderInfo}
              getOptionLabel={(option: SenderInfo) =>
                `${option.Email} - ${option.Name || "No Name"}`
              }
              renderOption={(props, option: SenderInfo) => (
                <li {...props}>
                  {option.Email} - {option.Name || "No Name"}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Sender Email"
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                setSelectedSenderInfo(newValue || null);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Replay Email"
              {...register("replay_email")}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="contact-list"
              options={contactListOptions}
              getOptionLabel={(option: Contact) =>
                `${option.Name} - Subscribers: ${option.SubscriberCount}`
              }
              renderOption={(props, option: Contact) => (
                <li {...props}>
                  {option.Name} (Subscribers: {option.SubscriberCount})
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Contact"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Preview Text"
              {...register("preview_text")}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <label className="mb-2 block">Post Content</label>
            <ContentEditor content={content} setContent={setContent} />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              {sendMailLoading ? "Submitting...." : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AddEmailCampaign;
