import { gql } from "@apollo/client";

export const GET_BRD_VISA_TYPES = gql`
  query getBrdVisaTypes($brd_id: uuid!) {
    brd_visa_types(where: { brd_id: { _eq: $brd_id } }) {
      id
      name
    }
  }
`;
