import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, TablePagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import brandsStore from "~/store/brandsStore/brandsStore";
import { GET_MAILJET_CAMPAIGNS } from "~/graphql/get_mailjet_campaigns/Query";
import { useLazyQuery } from "@apollo/client";

const EmailCampaigns = () => {
  const { brands } = brandsStore();
  const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fetchCampaigns] = useLazyQuery(GET_MAILJET_CAMPAIGNS);
  const [loading, setLoading] = useState(false);

  const handleCreateCampaign = () => {
    navigate("/admin/email-campaigns/add");
  };

  const handleContactsList = () => {
    navigate("/admin/email-campaigns/contacts-list");
  };

  const handleSendersList = () => {
    navigate("/admin/email-campaigns/senders-list");
  };

  useEffect(() => {
    const loadCampaigns = async () => {
      if (!brands || brands.length === 0) return;
      setLoading(true);
      try {
        const results = await Promise.all(
          brands.map((brand: any) =>
            fetchCampaigns({ variables: { brdId: brand.id } })
          )
        );

        const allCampaigns = results.flatMap(({ data }) =>
          (data?.get_mailjet_campaigns?.Data || []).map((campaign: any) => {
            const date = new Date(campaign.SendTimeStart * 1000);
            return {
              ...campaign,
              SendDate: date.toLocaleDateString(),
              SendTime: date.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
            };
          })
        );

        setRows(allCampaigns);
      } catch (error) {
      } finally {
        setLoading(false); // Stop loading
      }
    };

    loadCampaigns();
  }, [brands, fetchCampaigns]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-semibold text-primary dark:text-dark-primary">
          Email Campaigns
        </h1>
        <div className="flex gap-3">
          <Button variant="contained" onClick={handleSendersList}>
            Senders List
          </Button>
          <Button variant="contained" onClick={handleContactsList}>
            Contacts List
          </Button>
          <Button variant="contained" onClick={handleCreateCampaign}>
            Create a Campaign
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="center">Opened</TableCell>
              <TableCell align="center">Clicked</TableCell>
              <TableCell align="center">Delivered</TableCell>
              <TableCell align="center">Processed</TableCell>
              <TableCell align="center">Delivery Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Found
                </TableCell>
              </TableRow>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => (
                  <TableRow
                    key={row.ID}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.ID}
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-col gap-1">
                        <span className="underline text-[17px]">
                          {row.Title}
                        </span>
                        <span>{row.Subject}</span>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {`${(
                        (row.OpenedCount / row.ProcessedCount) *
                        100
                      ).toFixed(0)}% (${row.OpenedCount})`}
                    </TableCell>
                    <TableCell align="center">{`${(
                      (row.ClickedCount / row.ProcessedCount) *
                      100
                    ).toFixed(0)}% (${row.ClickedCount})`}</TableCell>
                    <TableCell align="center">{`${(
                      (row.DeliveredCount / row.ProcessedCount) *
                      100
                    ).toFixed(0)}% (${row.DeliveredCount})`}</TableCell>
                    <TableCell align="center">{row.ProcessedCount}</TableCell>
                    <TableCell align="center">
                      <div className="flex flex-col">
                        <span>{row.SendDate}</span>
                        <span>{row.SendTime}</span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default EmailCampaigns;
