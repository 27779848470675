import SidePopup from "~/components/common/SidePopup";
import ShowHotel from "./components/ShowHotel";
import AddHotel from "./components/AddHotel";
import { useParams } from "react-router-dom";

const Hotel = () => {
  const { brd_id } = useParams();
  return (
    <div>
      <ShowHotel brd_id={brd_id} />
      <SidePopup component={<AddHotel brd_id={brd_id} />} />
    </div>
  );
};

export default Hotel;
