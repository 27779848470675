import { useMutation } from "@apollo/client";
import { Divider, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { INSERT_BRD_VISAS_TYPES } from "~/graphql/brd_visa_types/Mutation";

const AddTypes = ({ brd_id, setSidePopup }: any) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [addTypes] = useMutation(INSERT_BRD_VISAS_TYPES);

  const onSubmit = async (formData: any) => {
    try {
      const res = await addTypes({
        variables: {
          data: {
            name: formData?.name,
            brd_id,
          },
        },
      });

      if (res?.data?.insert_brd_visa_types?.affected_rows) {
        toast.success("Type Added SuccessFully");
        setSidePopup(false);
      }
    } catch (err: any) {
      toast.error(err?.message);
    }
  };

  return (
    <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic">Add Visa Types</h1>
      <Divider />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 gap-5 mt-10"
      >
        <TextField
          error={errors["name"] ? true : false}
          {...register("name", { required: true })}
          label="Name"
        />
        <Button type="submit" variant="contained">
          Add
        </Button>
      </form>
    </div>
  );
};

export default AddTypes;
