// import SidePopup from "~/components/common/SidePopup";
// import AddPageHeadings from "./components/AddPageHeadings";
// import ShowPageHeadings from "./components/ShowPageHeadings";
// import { GET_BRD_PAGES_HEADINGS } from "~/graphql/brd_pages_headings/Query";
// import { useParams } from "react-router-dom";
// import { useQuery } from "@apollo/client";

// const PageHeadings = () => {
//   const { brd_id, pageType } = useParams();
//   const { data } = useQuery(GET_BRD_PAGES_HEADINGS, {
//     variables: { brd_id, pageType },
//   });
//
//   const SectionTypes = [
//     { name: "Testinmonials Section", value: "testimonial_section" },
//     { name: "Tips Section", value: "tips_section" },
//     { name: "Services Section", value: "services_section" },
//     { name: "FAQs Section", value: "faqs_section" },
//     { name: "Case Studies Section", value: "caseStudies_section" },
//     { name: "Our Partners", value: "our_partners" },
//   ];

//   return (
//     <>
//       <ShowPageHeadings
//         data={data}
//         SectionTypes={SectionTypes}
//         pageHeadings={data?.brd_pages_headings}
//       />
//       <SidePopup
//         component={
//           <AddPageHeadings
//             pageHeadings={data?.brd_pages_headings}
//             SectionTypes={SectionTypes}
//           />
//         }
//       />
//     </>
//   );
// };

// export default PageHeadings;

import SidePopup from "~/components/common/SidePopup";
import AddPageHeadings from "./components/AddPageHeadings";
import ShowPageHeadings from "./components/ShowPageHeadings";
import { GET_BRD_PAGES_HEADINGS } from "~/graphql/brd_pages_headings/Query";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

const PageHeadings = () => {
  const { brd_id, pageType } = useParams();
  const { data } = useQuery(GET_BRD_PAGES_HEADINGS, {
    variables: { brd_id, pageType },
  });

  const SectionTypes = [
    { name: "Testimonials Section", value: "testimonial_section" },
    { name: "Tips Section", value: "tips_section" },
    { name: "Services Section", value: "services_section" },
    { name: "FAQs Section", value: "faqs_section" },
    { name: "Case Studies Section", value: "caseStudies_section" },
    { name: "Our Partners", value: "our_partners" },
  ];

  // Filter based on pageType
  const filteredSectionTypes = (() => {
    if (pageType === "home-page") {
      return SectionTypes.filter(
        (section) =>
          section.value !== "tips_section" && section.value !== "our_partners"
      );
    } else if (pageType === "case-study") {
      return SectionTypes.filter(
        (section) => section.value === "testimonial_section"
      );
    }
    return SectionTypes; // Default case includes all sections
  })();

  return (
    <>
      <ShowPageHeadings
        data={data}
        SectionTypes={filteredSectionTypes} // Use filtered sections here
        pageHeadings={data?.brd_pages_headings}
      />
      <SidePopup
        component={
          <AddPageHeadings
            pageHeadings={data?.brd_pages_headings}
            SectionTypes={filteredSectionTypes} // Use filtered sections here
          />
        }
      />
    </>
  );
};

export default PageHeadings;
