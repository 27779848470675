import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UpdateSeasFaresTable from "./components/UpdateSeasFaresTable";
import { Box, Button, Modal } from "@mui/material";
import { useState } from "react";
import { DELETE_BRD_SEAS_FARES } from "~/graphql/brd_seasonal_fares/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
  ADD_INQ_SUGGESTION,
  DELETE_SEASONAL_FARES,
  UPDATE_INQ_SUGGESTION,
} from "~/graphql/inq_suggestion/Mutation";
import AddSugesstion from "~/components/common/AddSugesstion";
import SideDrawer from "~/components/common/sideDrawer";
import { useParams } from "react-router-dom";
import { UPDATE_SUGGESTION } from "../../../DestinationUpdate/components/DestinationFares/graphql/Mutation";
import { UPDATE_SUGGESTION_COST } from "~/graphql/inq_suggestion_cost/Mutation";
import { UPDATE_SUGGESTION_LEG } from "~/graphql/inq_suggestion_leg/Mutation";
import { UPDATE_SUGGESTION_LEG_STOPS } from "~/graphql/inq_suggestion_leg_stops/Mutation";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

export default function SeasonalFaresTable({
  seasonalFares,
  reftechBrdSeasFares,
}: any) {
  const [openMod, setOpenMod] = useState<any>(false);
  const { brd_id } = useParams();
  const [fare, setFare] = useState<any>(null);
  const [deleteDefSeasFares] = useMutation(DELETE_SEASONAL_FARES);
  const [updateSuggestionCost, { error: costDataError }] = useMutation(
    UPDATE_SUGGESTION_COST
  );

  const [updateSuggestionLeg] = useMutation(UPDATE_SUGGESTION_LEG);
  const [isopen, setisopen] = useState(false);
  const deleteFunc = async (id: any) => {
    const res = await deleteDefSeasFares({
      variables: {
        id,
      },
    });

    if (res?.data?.delete_inq_suggestion?.affected_rows) {
      toast.success("Season Deleted SuccessFully");
      reftechBrdSeasFares();
    }
  };
  const getLegData = (data: any, leg: string, cabin: string) => {
    let stops: any = [];
    let obj: any = {
      type: leg,
      cabin: cabin,
    };
    data?.stops?.map((stop: any, i: number) => {
      const item = {
        airline_iata: stop?.airline?.iata,
        depart: stop?.departCity?.iata_code,
        arrival: stop?.arrivalCity?.iata_code,
        flight_no: stop?.airlineNumber,
        ordering: i,
      };

      stops.push(item);
    });
    let temp = {
      data: [...stops],
    };
    obj["inq_suggestion_leg_stops"] = temp;
    return obj;
  };
  const [updateSuggestion] = useMutation(UPDATE_SUGGESTION);
  const [updateLeg] = useMutation(UPDATE_SUGGESTION_LEG);
  const [updateLegStops] = useMutation(UPDATE_SUGGESTION_LEG_STOPS);
  const client = useApolloClient();
  let updateSuccess = true;
  const [
    updateInqSuggestion,
    {
      data: dataInqSuggestion,
      loading: loadingInqSuggestion,
      error: errorInqSuggestion,
    },
  ] = useMutation(UPDATE_INQ_SUGGESTION);
  const [addInqSuggestion, { loading, error }] =
    useMutation(ADD_INQ_SUGGESTION);
  const submitSugesstion = async (inpData: any, id: string) => {
    updateInqSuggestion({
      variables: {
        id: fare?.id,
        season_id: inpData?.season,
      },
    })
      .then(() => {
        // Show success toast on successful mutation
        // toast.success("Suggestion updated successfully!");
      })
      .catch((err) => {
        // Show error toast on failure
        // toast.error(`Error updating Suggesion`);
        updateSuccess = false;
      });
    let finalObj: any = {
      brd_id: brd_id,
      season_id: inpData.season,
    };

    const updateFareData = {
      basic_fare: inpData?.costPrice,
      sale_price: inpData?.offerPrice,
    };

    if (Number(inpData?.tripType) === 1) {
      const leg = getLegData(
        inpData?.departLeg,
        "departLeg",
        inpData?.cabinType
      );

      finalObj["inq_suggestion_legs"] = {
        data: [leg],
      };
    } else if (Number(inpData?.tripType) === 2) {
      const leg = getLegData(
        inpData?.departLeg,
        "departLeg",
        inpData?.cabinType
      );
      const leg2 = getLegData(
        inpData?.returnLeg,
        "returnLeg",
        inpData?.cabinType
      );

      finalObj["inq_suggestion_legs"] = {
        data: [leg, leg2],
      };
    }
    try {
      const res = await updateSuggestionCost({
        variables: {
          id: fare?.inq_suggestion_costs?.[0]?.id,
          data: updateFareData,
        },
      });

      // if (res?.data?.update_inq_suggestion_cost?.affected_rows > 0) {
      //   toast.success("Suggestion cost updated successfully");
      // } else {
      //   toast.error("Failed to update suggestion cost");
      // }
    } catch (error: any) {
      toast.error("Failed to update suggestion cost");
      updateSuccess = false;
    }

    const mappedLegs = finalObj?.inq_suggestion_legs?.data?.map(
      (leg: any, index: number) => {
        const legId = fare?.inq_suggestion_legs?.[index]?.id;
        return {
          id: legId,
          cabin: leg?.cabin,
          type: leg?.type,
          inq_suggestion_leg_stops: leg?.inq_suggestion_leg_stops?.data?.map(
            (stop: any, ind: any) => ({
              id: fare?.inq_suggestion_legs?.[index]
                ?.inq_suggestion_leg_stops?.[ind]?.id,
              airline_iata: stop?.airline_iata,
              arrival: stop?.arrival,
              depart: stop?.depart,
              flight_no: stop?.flight_no,
            })
          ),
        };
      }
    );

    try {
      // let updateSuccess = false;

      for (const legData of mappedLegs) {
        const { data } = await updateLeg({
          variables: {
            id: legData.id,
            data: {
              cabin: legData.cabin,
              type: legData.type,
            },
          },
        });

        if (data.update_inq_suggestion_leg.affected_rows > 0) {
          // updateSuccess = true;

          if (legData?.inq_suggestion_leg_stops?.length > 0) {
            for (const stopData of legData.inq_suggestion_leg_stops) {
              try {
                const { data: stopDataResponse } = await updateLegStops({
                  variables: {
                    id: stopData.id,
                    data: {
                      airline_iata: stopData.airline_iata,
                      arrival: stopData.arrival,
                      depart: stopData.depart,
                      flight_no: stopData.flight_no,
                    },
                  },
                });
              } catch (stopError) {
                updateSuccess = false;
                // console.error(
                //   `Error updating leg stop with ID: ${stopData.id} -`,
                //   stopError
                // );
              }
            }
          }
        } else {
          console.error(`No rows were affected for leg with ID: ${legData.id}`);
        }
      }

      // Show a single toast based on the success of any update
      if (updateSuccess) {
        await client.refetchQueries({
          include: "all",
        });
        toast.success("Suggestion legs updated successfully!");
      } else {
        toast.error("No rows were affected. Please check the IDs.");
      }
    } catch (error) {
      toast.error(`Error updating suggestion legs`);
    }
  };

  return (
    <div>
      <h1 className="ml-2 mb-2 font-bold">SEASONAL FARES</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>SEASON</TableCell>
              <TableCell align="center">TYPE</TableCell>
              <TableCell align="center">STOPS</TableCell>
              <TableCell align="center">SALE PRICE</TableCell>
              <TableCell align="center">ACTIONS</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {seasonalFares &&
              seasonalFares?.map((fare: any, fareIndex: number) => (
                <TableRow key={fareIndex}>
                  <TableCell>{fare?.brd_season?.name}</TableCell>
                  <TableCell align="center">
                    {fare?.inq_suggestion_legs?.length === 1
                      ? "One Way"
                      : "Round Trip"}
                  </TableCell>
                  <TableCell align="center">
                    {`Depart (${fare?.inq_suggestion_legs?.[0]?.inq_suggestion_leg_stops?.length})`}{" "}
                    {fare?.inq_suggestion_legs?.[1] &&
                      `Return (${fare?.inq_suggestion_legs?.[1]?.inq_suggestion_leg_stops?.length})`}
                  </TableCell>
                  <TableCell align="center">
                    £
                    {fare?.inq_suggestion_costs?.reduce(
                      (total: any, item: any) => {
                        return total + (item.sale_price || 0); // Add sale_price, default to 0 if undefined
                      },
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <div className="flex items-center justify-center gap-5">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setisopen(true);
                          setFare(fare);
                        }}
                      >
                        EDIT
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          deleteFunc(fare?.id);
                        }}
                      >
                        DELETE
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Modal open={openMod} onClose={() => setOpenMod(false)}>
        <Box sx={{ ...style, width: "70%" }}>
          <UpdateSeasFaresTable
            setOpenMod={setOpenMod}
            id={openMod}
            refetch={reftechBrdSeasFares}
          />
        </Box>
      </Modal> */}

      {isopen && (
        <SideDrawer
          setSidePopup={setisopen}
          sidePopup={isopen}
          component={
            <AddSugesstion
              mainHeading="Update Suggestion"
              suggestionSend={submitSugesstion}
              seasons={"available"}
              fare={fare}
            />
          }
        />
      )}
    </div>
  );
}
