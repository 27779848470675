import React, { useState } from "react";
import PassengerBox from "../components/PassengerBox";
import PassengerAdd from "../components/PassengerAdd";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import SideDrawer from "~/components/common/sideDrawer";
import NewPassangerAdd from "../components/NewPassengerAdd";
import NewPassengerAdd from "../components/NewPassengerAdd";
import NewPassengerBox from "../components/PassengerBox/NewPassengerBox";
import StayPassengerBox from "./StayPassengerCard";
import StayPassengerAdd from "../components/NewPassengerAdd/StayPassengerAdd";

const NewPassengerCard = ({ tabs, inquiry }: any) => {
  const getPassengersForTab = (tabLabel: any) => {
    switch (tabLabel) {
      case "Stay":
        return inquiry?.stay_inq_details[0]?.stay_inq_rooms || [];
      case "Cars":
        return inquiry?.car_inq_details[0]?.car_inq_passengers || [];
      case "Train":
        return inquiry?.train_inq_details[0]?.train_inq_passengers || [];
      case "Flights":
      default:
        return inquiry?.inq_passengers || [];
    }
  };

  const getSuggestion = (tabLabel: any) => {
    switch (tabLabel) {
      case "Stay":
        return (
          inquiry?.stay_inq_details[0]?.stay_inq_suggestions[0]
            ?.stay_inq_suggestion_costs[0]?.stay_suggestion_id || []
        );
      case "Cars":
        return (
          inquiry?.car_inq_details[0]?.car_inq_suggestions[0]
            ?.car_inq_suggestion_costs[0]?.car_suggestion_id || []
        );
      case "Train":
        return (
          inquiry?.train_inq_details[0]?.train_inq_suggestions[0]
            ?.train_inq_suggestion_costs[0]?.train_suggestion_id || []
        );
      case "Flights":
      default:
        return inquiry?.suggestion_id;
    }
  };

  const userDefaultRole = useUserDefaultRole();
  const user_id = useUserId();
  const [sidePopup, setSidePopup] = useState(false);

  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
      <div className="self-stretch flex items-center dark:text-primary mt-[30px]">
        <hr className="w-[2%] dark:border-[#E7E3FC61]" />
        <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
          {tabs?.map((tab: any) => {
            {
              const dynamicLabel =
                tab?.label === "Hotel" ? "ROOMS" : "PASSENGERS";
              return dynamicLabel;
            }
          })}
        </span>
        <hr className="w-full dark:border-[#E7E3FC61]" />
        {inquiry.status !== "completed" && (
          <div className="min-w-fit pl-2">
            {!inquiry?.selected_suggestion &&
              (user_id === inquiry?.picked_by ||
                userDefaultRole === "admin") && (
                <button
                  onClick={() => setSidePopup(true)}
                  className="block border border-basic rounded-full px-3 py-1 hover:text-basic cursor-pointer font-medium bg-basic dark:hover:bg-dark-primary hover:bg-white text-white"
                >
                  {tabs?.map((tab: any) => {
                    {
                      const dynamicLabel =
                        tab?.label === "Stay" ? "Add Rooms" : "Add Passenger";
                      return dynamicLabel;
                    }
                  })}
                </button>
              )}
          </div>
        )}
      </div>

      {/* Passenger Display for Each Tab */}
      <div className="self-stretch grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 items-start justify-start gap-[33px] text-mini">
        {tabs?.map((tab: any) => {
          // Get the filtered passengers and suggestion based on the current tab
          const filteredSuggestions = getPassengersForTab(tab?.label);
          const selectedSuggestionId = getSuggestion(tab?.label);

          if (filteredSuggestions.length === 0) {
            return (
              <div key={tab?.label}>No passengers found for {tab?.label}.</div>
            );
          }

          // Render passengers for the current tab only
          return filteredSuggestions.map((passenger: any, i: any) => {
            const PassengerComponent =
              tab?.label === "Stay" ? StayPassengerBox : NewPassengerBox; // Select the component dynamically
            return (
              <PassengerComponent
                key={`${tab?.label}-${i}`} // Ensure a unique key for each passenger
                selected_suggestion_id={selectedSuggestionId} // Pass the correct selected suggestion ID
                passenger={passenger} // Render individual passenger
                inquiry={inquiry}
                inq_id={inquiry.id} // Dynamic inquiry ID
                totalPassengers={filteredSuggestions.length} // Total passengers for this tab
                tabLabel={tab?.label}
                roomNumber={i + 1}
              />
            );
          });
        })}
      </div>

      {sidePopup && (
        <SideDrawer
          setSidePopup={setSidePopup}
          sidePopup={sidePopup}
          component={
            <>
              {tabs?.map((tab: any, i: number) => {
                const PassengerAddComponent =
                  tab.label === "Stay" ? StayPassengerAdd : NewPassengerAdd;
                return (
                  <div key={tab?.label}>
                    {/* Render passengers for the current tab */}
                    <PassengerAddComponent
                      key={`${tab?.label}-${i}`} // Ensure a unique key for each NewPassengerAdd
                      setAdd={setSidePopup}
                      inquiry_id={inquiry?.id}
                      user_id={inquiry?.users.id}
                      picked_by={inquiry?.picked_by}
                      hideForm={setSidePopup}
                      tabLabel={tab?.label}
                      inquiry={inquiry}
                    />
                  </div>
                );
              })}
            </>
          }
        />
      )}
    </div>
  );
};

export default NewPassengerCard;
