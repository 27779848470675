import { useParams } from "react-router-dom";
import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import _airportsData from "~/components/FormInputs/LocationBox/data/airports.json";
import airlines from "~/components/FormInputs/AirlineBox/data/airlines.json";
import { customSearch } from "~/components/FormInputs/LocationBox/utils";
import { useQuery } from "@apollo/client";
import { GET_INQ_SUGGESTION } from "~/graphql/inq_suggestion/Query";
import FareCard from "~/modules/BrandModule/segments/AirlinesUpdate/components/AirlineFares/components/FareCard";
import Checkbox from "@mui/material/Checkbox";

const AddUmrahFares = ({
  setFares,
  fares,
  departure,
  setDeparture,
  destination,
  setDestination,
  airline,
  setAirline,
  setSidePopup,
}: any) => {
  const { brd_id } = useParams();

  const condition = {
    brd_id: { _eq: brd_id },
    season_id: { _is_null: false },
  };

  const { data } = useQuery(GET_INQ_SUGGESTION, {
    variables: { condition },
  });

  const handleCheckboxChange = (fare: any) => {
    setFares((prev: any) =>
      prev.some((item: any) => item?.id === fare?.id)
        ? prev.filter((item: any) => item?.id !== fare?.id)
        : [...prev, fare]
    );
  };

  const validFares = data?.inq_suggestion?.filter((suggestion: any) => {
    const stops = suggestion?.inq_suggestion_legs?.filter(
      (leg: any) => leg.type === "returning"
    )?.[0]
      ? suggestion?.inq_suggestion_legs?.filter(
          (leg: any) => leg.type === "returning"
        )?.[0]?.inq_suggestion_leg_stops
      : suggestion?.inq_suggestion_legs?.[0]?.inq_suggestion_leg_stops;

    return (
      suggestion?.inq_suggestion_legs?.filter(
        (leg: any) => leg.type === "departLeg" || leg.type === "departing"
      )?.[0]?.inq_suggestion_leg_stops?.[0]?.depart === departure?.iata_code &&
      stops[stops.length - 1]?.arrival === destination?.iata_code
    );
  });

  return (
    <div className="md:w-[700px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic">Add Umrah Fares</h1>
      <Divider />
      <div className="grid grid-cols-2 gap-5 mt-10">
        {/* <Autocomplete
          value={airline}
          onChange={(event: any, newValue: string | null) => {
            setAirline(newValue);
          }}
          filterOptions={(options, { inputValue }: any) => {
            if (!inputValue.trim()) {
              return [];
            }

            const inputValueLowerCase = inputValue.toLowerCase();
            return options.filter((option: any) =>
              option.marketing_name
                .toLowerCase()
                .startsWith(inputValueLowerCase)
            );
          }}
          getOptionLabel={(option: any) => option.marketing_name}
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {option.marketing_name}
            </Box>
          )}
          options={airlines || []}
          renderInput={(params) => <TextField {...params} label="Airline" />}
        /> */}
        <Autocomplete
          value={departure}
          onChange={(event: any, newValue: string | null) => {
            setDeparture(newValue);
          }}
          filterOptions={(options, { inputValue }: any) => {
            if (!inputValue.trim()) {
              return [];
            }

            const matchedAirports = customSearch(inputValue);

            return matchedAirports;
          }}
          getOptionLabel={(option: any) =>
            `${option.city_name || ""}-${option.iata_code}`
          }
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {option.city_name}-{option.iata_code}
            </Box>
          )}
          options={_airportsData || []}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Departure"
              helperText="Departure is Required *"
            />
          )}
        />
        <Autocomplete
          value={destination}
          onChange={(event: any, newValue: string | null) => {
            setDestination(newValue);
          }}
          filterOptions={(options, { inputValue }: any) => {
            if (!inputValue.trim()) {
              return [];
            }

            const matchedAirports = customSearch(inputValue);

            return matchedAirports;
          }}
          getOptionLabel={(option: any) =>
            `${option.city_name || ""}-${option.iata_code}`
          }
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {option.city_name}-{option.iata_code}
            </Box>
          )}
          options={_airportsData || []}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Destination"
              helperText="Destination is Required *"
            />
          )}
        />
      </div>
      <div className="gird grid-cols-1 gap-10 mt-10">
        {validFares?.map((item: any, ind: any) => {
          const fareIds = fares?.map((fare: any) => fare.id);
          const isChecked = fareIds.includes(item.id);
          return (
            <div className="flex items-center gap-3 w-full">
              <Checkbox
                checked={isChecked}
                onChange={() => handleCheckboxChange(item)}
              />
              <FareCard key={ind} sg={item} hideAction={true} />
            </div>
          );
        })}
      </div>
      <div className="flex items-center mt-10 justify-center">
        <Button
          onClick={() => setSidePopup(false)}
          variant="contained"
          sx={{ minWidth: "200px" }}
        >
          Update Fares
        </Button>
      </div>
    </div>
  );
};

export default AddUmrahFares;
