import { GET_SOCIAL_AUTHENTICATION_INFO_BRD_ID_AND_PLATFORM } from "~/graphql/brd_social_authentication/Query";

// Separate function to fetch data
export const getSocialAuthenticationInfoWithPlatform = async (
  brdIds: any,
  selectedPlatform: string[],
  client: any
) => {
  try {
    const { data } = await client.query({
      query: GET_SOCIAL_AUTHENTICATION_INFO_BRD_ID_AND_PLATFORM,
      variables: {
        brd_id: brdIds,
        platform: selectedPlatform,
      },
    });

    const accountsData =
      data?.brd_social_authentication?.map((item: any) => ({
        id: item.profile_id,
        label: item.user_name || `Account ${item.profile_id}`,
        platform: item.platform,
        platformType: item.platform_type,
        accessToken: item.access_token,
      })) || []; // Default to empty array if no accounts

    return {
      brd_id: brdIds,
      platform: selectedPlatform,
      accountsData, // Array of accounts
    };
  } catch (error) {
    return {
      brd_id: brdIds,
      platform: selectedPlatform,
      accountsData: [], // Return empty array in case of error
    };
  }
};
