import { useState } from "react";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import { splitLocation } from "~/utils/splitLocation";
import moment from "moment-timezone";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SideDrawer from "~/components/common/sideDrawer";
import EditCar from "./components/EditCar";

const CarCard = ({
  carId,
  origin,
  destination,
  Pick_Date_Time,
  Drop_Date_Time,
  withDriver,
  height,
  editAble
}: any) => {
  // Split origin into city and remaining address
  const { cityName: originCity, remainingAddress: originAddress } =
    splitLocation(origin);

  // Split destination into city and remaining address
  const { cityName: destinationCity, remainingAddress: destinationAddress } =
    splitLocation(destination);

  const [open, setOpen] = useState(false)

  console.log("jacs", origin,
    destination,
    Pick_Date_Time,
    Drop_Date_Time,
    withDriver)

  return (
    <div
      className={`h-full w-full rounded-2xl  bg-secondary dark:bg-dark-secondary text-primary dark:to-dark-primary shadow flex flex-col pt-0 px-0 box-border items-end justify-between text-left text-xs
  `}
    >
      <div className="w-full text-primary dark:text-dark-primary flex flex-col gap-3.5 justify-between rounded-t-2xl flex-1">
        <div className="self-stretch flex flex-col my-5 px-[30px] mt-5 flex-grow">
          <div className="h-full flex flex-col items-start justify-center gap-5">
            <div className="bg-basic h-20 w-20 rounded-full flex items-center justify-center">
              <DirectionsCarIcon
                className="text-center text-white"
                fontSize="large"
              />
            </div>
            <div className="flex flex-row justify-between items-center w-full gap-3">
              <div className="flex gap-3 flex-col">
                <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base">
                  ORIGIN
                </div>
                <div className="relative text-3xl font-medium text-basic dark:text-dark-primary" title={originCity}>
                {originCity.length > 10 ? `${originCity.slice(0, 10)}...` : originCity}

                </div>
              </div>
              <div className="flex gap-3 flex-col">
                <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base text-right">
                  DESTINATION
                </div>
                <div className="relative text-3xl font-medium text-basic dark:text-dark-primary text-right" title={destinationCity}>
                  {destinationCity.length > 10 ? `${destinationCity.slice(0, 10)}...` : destinationCity}

                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-start gap-[9px] absolute top-[5%] right-[2%]">
            {editAble && (
              <BiSolidMessageSquareEdit
                onClick={() => {
                  setOpen(true);
                }}
                className="cursor-pointer text-basic dark:text-dark-primary relative w-5 h-5"
              />
            )}
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col px-8 py-5 bg-basic rounded-b-2xl h-[150px] text-white">
        <div className="w-full flex justify-between">
          <div className="self-stretch relative text-base">
            {" "}
            {moment(Pick_Date_Time).tz("UTC").format("DD-MM-YYYY")}
          </div>
          <div className="relative inline-block text-base shrink-0">
            {moment(Drop_Date_Time).tz("UTC").format("DD-MM-YYYY")}
          </div>
        </div>
        <div className="w-full flex justify-between mt-2">
          <div className="self-stretch relative text-base" title={originAddress}>
            Address:
            {" "}
            {originAddress.length > 15 ? `${originAddress.slice(0, 15)}...` : originAddress}
          </div>
          <div className="relative inline-block text-base shrink-0" title={destinationAddress}>
            Address:
            {" "}
            {destinationAddress.length > 15 ? `${destinationAddress.slice(0, 15)}...` : destinationAddress}
          </div>
        </div>
        <div className="flex flex-row justify-start gap-1 mt-2">
          <div className="relative inline-block text-base shrink-0">
            <span>With Driver: </span>
            <b> {withDriver}</b>
          </div>
        </div>
      </div>
      {open && (
        <SideDrawer
          sidePopup={open}
          setSidePopup={setOpen}
          component={
            <EditCar
              carId={carId}
              setSidePopup={setOpen}
              origin={origin}
              destination={destination}
              pickDateTime={Pick_Date_Time}
              dropDateTime={Drop_Date_Time}
              withDriver={withDriver}
            />
          }
        />
      )}
    </div>
  );
};

export default CarCard;
