import { gql, useMutation } from "@apollo/client";

export const DELETE_BRD_HOTELS_GALLERY = gql`
  mutation DeleteHotelGallery($img_id: uuid!) {
    delete_brd_hotels_gallery(where: { img_id: { _eq: $img_id } }) {
      affected_rows
    }
  }
`;

export const INSERT_BRD_HOTELS_GALLERY = gql`
  mutation InsertBrdHotelsGallery($img_id: uuid!, $hotel_id: uuid!) {
    insert_brd_hotels_gallery(
      objects: { img_id: $img_id, hotel_id: $hotel_id }
    ) {
      affected_rows
    }
  }
`;
