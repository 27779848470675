import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Button, TextField, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify"; // Make sure to install react-toastify
import {
  INSERT_CASESTUDY_PAGE,
  UPDATE_CASESTUDY_PAGE,
} from "~/graphql/serv_main_casestudy_page/Mutation";
import { GET_CASESTUDY_PAGE } from "~/graphql/serv_main_casestudy_page/Query";
import { nhost } from "~/lib/nhost";

const AddMainCaseStudy = () => {
  const { register, handleSubmit, setValue } = useForm();
  const { brd_id } = useParams();
  const client = useApolloClient();
  const { data } = useQuery(GET_CASESTUDY_PAGE, {
    variables: { brd_id },
  });
  const [NewImageId, setNewImageId] = useState<string | null>(null);
  const [imgUrl, setImgUrl] = useState("");

  const [updateCaseStudy] = useMutation(UPDATE_CASESTUDY_PAGE);
  const [insertCaseStudy] = useMutation(INSERT_CASESTUDY_PAGE);

  useEffect(() => {
    if (data) {
      setValue("bg_color", data.serv_main_casestudy_page[0]?.bg_color || "");
      setValue(
        "text_color",
        data.serv_main_casestudy_page[0]?.text_color || ""
      );
      setValue("heading", data.serv_main_casestudy_page[0]?.heading || "");
      setValue(
        "sub_heading",
        data.serv_main_casestudy_page[0]?.sub_heading || ""
      );
    }
  }, [data, setValue]);

  const onSubmit = async (formData: any) => {
    try {
      if (data && data.serv_main_casestudy_page.length > 0) {
        // Update the existing case study page
        await updateCaseStudy({
          variables: {
            brd_id: brd_id,
            data: {
              bg_color: formData.bg_color,
              text_color: formData.text_color,
              heading: formData.heading,
              sub_heading: formData.sub_heading,
              image_id: NewImageId || data.serv_main_casestudy_page[0].image_id,
            },
          },
        });
        toast.success("Case study page updated successfully!");
      } else {
        await insertCaseStudy({
          variables: {
            data: {
              brd_id: brd_id,
              bg_color: formData.bg_color,
              text_color: formData.text_color,
              heading: formData.heading,
              sub_heading: formData.sub_heading,
              image_id: NewImageId,
            },
          },
        });
        toast.success("Case study page inserted successfully!");
      }

      await client.refetchQueries({ include: "all" });
    } catch (error: any) {
      toast.error("Failed to process case study page. Please try again.");
    }
  };
  const convertImageToWebP = async (file: any) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(
                new File([blob], file.name.replace(/\.\w+$/, ".webp"), {
                  type: "image/webp",
                })
              );
            } else {
              reject(new Error("Conversion to WebP failed"));
            }
          },
          "image/webp",
          0.8
        );
      };

      img.onerror = (error) => reject(error);
    });
  };
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const webpFile = (await convertImageToWebP(file)) as File;
      const response = await nhost.storage.upload({ file: webpFile });
      if (response.error) {
        toast.error("Failed to upload image. Please try again.");
      } else {
        setNewImageId(response.fileMetadata.id);
      }
    } catch (error) {
      toast.error("Failed to upload image. Please try again.");
    }
  };
  // const imgUrl = NewImageId
  //   ? `${process.env.REACT_APP_NHOST_STORAGE_URL}${NewImageId}`
  //   : data?.serv_main_casestudy_page[0]?.image_id
  //   ? `${process.env.REACT_APP_NHOST_STORAGE_URL}${data.serv_main_casestudy_page[0].image_id}`
  //   : "";
  useEffect(() => {
    const url = NewImageId
      ? `${process.env.REACT_APP_NHOST_STORAGE_URL}${NewImageId}`
      : data?.serv_main_casestudy_page?.[0]?.image_id
      ? `${process.env.REACT_APP_NHOST_STORAGE_URL}${data.serv_main_casestudy_page[0].image_id}`
      : "";

    setImgUrl(url);
  }, [data, NewImageId]);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary flex-col rounded-lg shadow p-5 mb-5"
      >
        <h1 className="text-2xl mb-8 text-basic dark:text-dark-primary">
          Case Study Colors
        </h1>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Background Color</Typography>
            <TextField
              variant="outlined"
              fullWidth
              {...register("bg_color", { required: true })}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Text Color</Typography>
            <TextField
              variant="outlined"
              fullWidth
              {...register("text_color", { required: true })}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Heading</Typography>
            <TextField variant="outlined" fullWidth {...register("heading")} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Sub Heading</Typography>
            <TextField
              variant="outlined"
              fullWidth
              {...register("sub_heading")}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              component="label"
              sx={{ marginTop: "16px" }}
              style={{ marginBottom: "16px" }}
            >
              Replace Image
              <input
                type="file"
                onChange={handleImageChange}
                hidden
                accept="image/*"
              />
            </Button>
            {imgUrl && (
              <img
                src={imgUrl}
                alt="CaseStudy Image"
                className="max-h-[200px] rounded-lg  mb-4"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save Case Study color
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddMainCaseStudy;
