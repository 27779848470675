import { gql } from "@apollo/client";

export const INSERT_TRAIN_INQUIRY_DETAIL = gql`
  mutation InsertTrainInquiryDetail($data: [train_inq_detail_insert_input!]!) {
    insert_train_inq_detail(objects: $data) {
      affected_rows
    }
  }
`;

export const UPDATE_TRAIN_INQUIRY_DETAIL = gql`
  mutation updateTrainInqDetail(
    $id: uuid!
    $origin: String
    $destination: String
    $date: date!
  ) {
    update_train_inq_detail(
      where: { id: { _eq: $id } }
      _set: { date: $date, origin: $origin, destination: $destination }
    ) {
      affected_rows
    }
  }
`;
