import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { toast } from "react-toastify";
import { GET_HOTELS_BY_BRD_ID } from "~/graphql/brd_hotels/Query";
import { DELETE_HOTEL_WITH_ID } from "~/graphql/brd_hotels/Mutation";
import { useState } from "react";
import SideDrawer from "~/components/common/sideDrawer";

import EditHotel from "../EditHotel";

const ShowHotel = ({ brd_id }: any) => {
  const client = useApolloClient();
  const { data, loading, error } = useQuery(GET_HOTELS_BY_BRD_ID, {
    variables: { brd_id },
  });

  const [deleteHotel] = useMutation(DELETE_HOTEL_WITH_ID, {
    onCompleted: () => {
      client.refetchQueries({
        include: "all",
      });
      toast.success("Hotel package deleted successfully.");
    },
    onError: () => {
      toast.error("Error deleting hotel package.");
    },
  });

  const [openPopup, setOpenPopup] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);

  const handleDelete = async (id: any) => {
    try {
      await deleteHotel({
        variables: { id },
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching hotel packages: {error.message}</div>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Hotel Name</TableCell>
              <TableCell>Coordinates</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Price Per Night</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.brd_hotels?.map((hotel: any) => (
              <TableRow key={hotel.id}>
                <TableCell>{hotel.name}</TableCell>
                <TableCell>{hotel.coordinates}</TableCell>
                <TableCell>{hotel.city}</TableCell>
                <TableCell>{hotel.price}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setOpenPopup(true);
                      setSelectedHotel(hotel);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(hotel.id)}
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && (
        <SideDrawer
          component={
            <EditHotel
              selectedHotel={selectedHotel}
              setSidePopup={setOpenPopup}
              brd_id={brd_id}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowHotel;
