import { gql } from "@apollo/client";

export const INSERT_HOTEL = gql`
  mutation InsertHotel($data: brd_hotels_insert_input!) {
    insert_brd_hotels(objects: [$data]) {
      affected_rows
    }
  }
`;

export const DELETE_HOTEL_WITH_ID = gql`
  mutation DeleteHotel($id: uuid!) {
    delete_brd_hotels(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_BRD_HOTEL = gql`
  mutation UpdateBrdHotel(
    $id: uuid!
    $name: String!
    $coordinates: String!
    $city: String!
    $price: float8!
  ) {
    update_brd_hotels(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        coordinates: $coordinates
        city: $city
        price: $price
      }
    ) {
      affected_rows
    }
  }
`;
