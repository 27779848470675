import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { ADD_TRANSACTIONS } from "../../graphql/Mutations";
import { toast } from "react-toastify";
import {
  createCustomer,
  createInvoiceItems,
  createInvoice,
  sendInvoice,
} from "~/utils/Stripe/stripeFunctions";
import useNotifications from "~/hooks/useNotifications";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import Stripe from "stripe";
import { STRIPE_SK } from "~/config/enums";
import { SEND_EMAIL } from "~/config/Mutation";
import { CustomerTemplates } from "~/utils/TemplatesAll";
import { GET_PAYMENT_METHODS } from "../../graphql/Query";
import { sendGenericEmail } from "~/utils/sendEmail";
import {
  GET_EMAILS,
  GET_TRAVEL_HOUSE_BY_ID,
} from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import { useUserData } from "@nhost/react";
import { CalendarBox } from "~/components/FormInputs/CalendarBox";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";
import { Autocomplete, Box, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { INSERT_USER_CARD_DETAILS } from "~/graphql/user_card_details/Mutation";
import { MdFormatUnderlined } from "react-icons/md";

export default function StripePaymentForm({
  stripe,
  inquiry_id,
  setHideForm,
  user,
  brd_id,
  user_email,
  brand_url,
  brand_name,
  brd_domain,
  picked_by,
  paymentMethods,
  paytype,
  setStripe,
  inquiry,
}: any) {
  const { travelHouse }: any = travelHouseStore();

  const [addTransactions] = useMutation(ADD_TRANSACTIONS);
  const [sendEmail] = useMutation(SEND_EMAIL);
  const client = useApolloClient();
  const [buttonText, setButtonText] = useState("Add Payment");
  const todayUTC = new Date().toISOString().split("T")[0];
  const { data } = useQuery(GET_PAYMENT_METHODS, {
    variables: { th_id: travelHouse.id, brd_id: brd_id },
  });
  const userData = useUserData();

  const [selectedPaymentMeth, setSelectedPaymentMeth] = useState<any>();
  const [insertUserCardDetails] = useMutation(INSERT_USER_CARD_DETAILS);

  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );
  let StripeKey: any;

  const userId = useUserId();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const alert = useNotifications();
  const userRole = useUserDefaultRole();

  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData: any) => {
    if (selectedPaymentMeth?.card_terminal_type === "Manual") {
      const tenDigitCode: any = generateRandom10DigitCode();

      const transPayload = {
        amount: formData?.amount,
        brd_id: inquiry?.brd_id,
        // due_date: formData?.due_date,
        inq_id: inquiry_id,
        payment_method_id: selectedPaymentMeth.id,
        ref_no: "",
        status: "pending",
        transactions_no: tenDigitCode,
      };

      const res = await addTransactions({
        variables: { transactions: [transPayload] },
      });

      await updateInqListUser({
        variables: { id: inquiry_id, updated_by: userId },
      });

      if (res.data?.insert_inq_transection?.returning?.length > 0) {
        toast.success("Transaction added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        if (userRole === "user") {
          await alert.newNotification(
            picked_by,
            `Customer created the stripe payment, please check your portal`,
            "",
            ``
          );
        } else {
          await alert.newNotification(
            user?.id,
            `Consultant created the stripe payment,, please check your portal`,
            "",
            ``
          );
        }
        try {
          // Inserting user card details
          await insertUserCardDetails({
            variables: {
              objects: [
                {
                  name: formData.name,
                  card_num: formData.cardNumber,
                  billing_address: formData.billingAddress,
                  cvv: formData.cvv,
                  exp: formData.exp,
                  user_id: userId,
                  inq_transection_id:
                    res.data?.insert_inq_transection?.returning?.[0]?.id,
                },
              ],
            },
          });
        } catch (e) {}

        await client.refetchQueries({
          include: "all",
        });
      }
      setHideForm(false);
    } else {
      // formData["brd_id"] = inquiry?.brd_id;
      // formData["inq_id"] = inquiry_id;
      // formData["payment_method_id"] = stripe.id || stripe.id;
      // formData["status"] = "pending";

      const tenDigitCode: any = generateRandom10DigitCode();
      // formData["transactions_no"] = tenDigitCode;
      // const customer = await createCustomer();
      setButtonText("Creating Stripe Customer..");
      const stripeHelper = new Stripe(stripe.stripe_secret, {
        apiVersion: "2022-11-15",
      });

      createCustomer(stripeHelper, user.email, user.displayName).then(
        (customer: any) => {
          setButtonText("Creating Stripe Invoice..");
          createInvoice(
            stripeHelper,
            customer.id,
            new Date(formData["due_date"])
          ).then((invoice: any) => {
            setButtonText("Creating Stripe Invoice Item..");
            createInvoiceItems(
              stripeHelper,
              customer.id,
              formData["amount"] * 100,
              "gbp",
              invoice.id
            ).then((invoiceItem: any) => {
              setButtonText("Sending Stripe Invoice..");
              //
              sendInvoice(stripeHelper, invoice?.id).then(
                async (invoiceRes: any) => {
                  // Invoice Crated
                  formData["receipt"] = invoiceRes.hosted_invoice_url;
                  formData["ref_no"] = invoice?.id; // add receipt to formdata
                  delete formData["due_date"];

                  setButtonText("Adding Payment..");
                  try {
                    const queryData = {
                      brd_id: inquiry?.brd_id,
                      inq_id: inquiry_id,
                      payment_method_id: stripe.id || stripe.id,
                      status: "pending",
                      transactions_no: tenDigitCode,
                      amount: formData?.amount,
                      due_date: formData?.due_date,
                    };
                    const res = await addTransactions({
                      variables: { transactions: [queryData] },
                    });

                    await updateInqListUser({
                      variables: { id: inquiry_id, updated_by: userId },
                    });

                    if (
                      res.data?.insert_inq_transection?.returning?.length > 0
                    ) {
                      toast.success("Transaction added successfully", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });

                      if (userRole === "user") {
                        await alert.newNotification(
                          picked_by,
                          `Customer created the stripe payment, please check your portal`,
                          "",
                          ``
                        );
                      } else {
                        await alert.newNotification(
                          user?.id,
                          `Consultant created the stripe payment,, please check your portal`,
                          "",
                          ``
                        );
                      }

                      await client.refetchQueries({
                        include: "all", // Consider using "active" instead!
                      });
                    }
                    setButtonText("Add Payment");
                    setHideForm(false); // hide form modal
                  } catch (e) {
                    setButtonText("Add Payment");
                  }
                }
              );
            });
          });
        }
      );
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        {userRole !== "user" && paytype === "card" && (
          <div className="mb-2">
            <Autocomplete
              disablePortal
              onChange={(_, data) => {
                setSelectedPaymentMeth(data);
                setStripe({
                  id: data?.id,
                  stripe_pub: data?.stripe_pub,
                  stripe_secret: data?.stripe_secret,
                });
              }}
              options={paymentMethods}
              getOptionLabel={(option: any) => option.name}
              renderOption={(props, option: any) => (
                <Box component="li" {...props}>
                  {option.name}
                </Box>
              )}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Select Card"
                  className="dark:bg-dark-primary"
                />
              )}
            />
          </div>
        )}
        {/* Render fields in specified order if card terminal type is 'Manual' */}
        {selectedPaymentMeth?.card_terminal_type === "Manual" && (
          <>
            <div className="mb-2">
              <TextField
                {...register("name")}
                type="text"
                label="Name"
                variant="outlined"
                fullWidth
                className="dark:bg-dark-primary"
              />
            </div>

            <div className="mb-2">
              <TextField
                {...register("cardNumber")}
                type="text"
                label="Card Number"
                variant="outlined"
                fullWidth
                className="dark:bg-dark-primary"
              />
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <Controller
                  control={control}
                  name="exp"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value, ref } }) => {
                    const formattedValue = value ? dayjs(value) : null;

                    return (
                      <DatePicker
                        value={formattedValue}
                        inputRef={ref}
                        onChange={(date) => {
                          const formattedDate = date
                            ? dayjs(date).format("YYYY-MM-DD")
                            : null;

                          onChange(formattedDate);
                        }}
                        label="Expiration Date"
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            fullWidth: true,
                            error: Boolean(errors.exp),
                            helperText: errors.exp
                              ? "Expiration date is required."
                              : "",
                            className: "dark:bg-dark-primary",
                          },
                        }}
                        sx={{ width: "100%" }}
                      />
                    );
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>

            <div className="mb-2 mt-2">
              <TextField
                {...register("billingAddress")}
                type="text"
                label="Billing Address"
                variant="outlined"
                fullWidth
                className="dark:bg-dark-primary"
              />
            </div>

            <div className="mb-2">
              <TextField
                {...register("cvv")}
                type="number"
                label="CVV"
                variant="outlined"
                fullWidth
                className="dark:bg-dark-primary"
              />
            </div>
          </>
        )}

        {selectedPaymentMeth?.card_terminal_type !== "Manual" && (
          <div className="mb-2">
            <CalendarBox
              control={control}
              setValue={setValue}
              errors={errors}
              fieldName="due_date"
              label="Due Date"
              labelClasses="font-normal dark:text-dark-primary"
              inputClasses="focus:outline-none focus:border-basic dark:bg-dark-primary dark:text-dark-primary"
            />
          </div>
        )}

        <div className="grid grid-cols-1 gap-4">
          <div className="">
            <label>Amount</label>
            <input
              className="border border-slate-300 py-[5px] h-[44px] px-[15px] text-[14px] rounded-[5px] w-full focus:outline-none focus:border-basic dark:bg-dark-primary dark:text-dark-primary"
              type="number"
              placeholder="Amount"
              // defaultValue={res?.data?.travel_house?.brand}
              {...register("amount", {
                required: "Amount is required",
              })}
              aria-invalid={errors.amount ? "true" : "false"}
            />
          </div>
        </div>
        <button
          type="submit"
          className="bg-basic block w-full text-white px-5 py-2 rounded hover:cursor-pointer hover:bg-purple-700 transition-all mt-[20px]"
        >
          {buttonText}
        </button>
      </form>
    </>
  );
}
