import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ADD_ACCOUNT } from "./graphql/Mutation";
import { useMutation } from "@apollo/client";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import useRefetch from "~/hooks/useRealtime";
import { toast } from "react-toastify";

const AddAccount = ({ setSidePopup }: any) => {
  const [addAccount] = useMutation(ADD_ACCOUNT);
  const { travelHouse }: any = travelHouseStore();
  const refetch = useRefetch();
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData: any) => {
    try {
      const res = await addAccount({
        variables: {
          acc_list: [
            {
              thp_id: travelHouse.id,
              name: formData.name,
              type: formData.type,
            },
          ],
        },
      });
      await refetch();
      toast.success("Account added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setSidePopup(false);
    } catch (e) {
      reset();
    }
  };

  return (
    <>
      <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">Add Account</h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid grid-cols-1 mt-10 gap-5">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("type", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data);
                  }}
                  options={[
                    "Asset",
                    "Liability",
                    "Income",
                    "Equity",
                    "Expense",
                  ]}
                  getOptionLabel={(option: any) => option}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Type"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                        el && errors["type"] && el.focus();
                      }}
                      error={errors["type"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <TextField
              error={errors["name"] ? true : false}
              {...register("name", { required: true })}
              label="Name"
            />
          </div>
          <Button
            type="submit"
            sx={{ marginTop: "20px", width: "100%" }}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </div>
    </>
  );
};

export default AddAccount;
