import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import BrandAllPages from "~/modules/BrandModule/segments/BrandAllPages";


export default function BrandPages() {
    return (
        <Layout>
            <BrandModule>
                <BrandAllPages />
            </BrandModule>
        </Layout>
    )
}