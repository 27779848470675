import { gql } from "@apollo/client";

export const GET_HOTELS_BY_BRD_ID = gql`
  query GetHotelsByBrdId($brd_id: uuid!) {
    brd_hotels(where: { brd_id: { _eq: $brd_id } }) {
      id
      name
      coordinates
      brd_id
      price
      city
      brd_hotels_galleries {
        img_id
        hotel_id
        id
      }
    }
  }
`;
