import React, { Children, useState } from "react";
import { Autocomplete, Box, Button, Divider, TextField, MenuItem } from "@mui/material";
import {
  LocalizationProvider,
  DateRangePicker,
  DatePicker,
} from "@mui/x-date-pickers-pro";
import { Controller, useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import moment from "moment";
import { toast } from "react-toastify";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import useAssignedUsers from "~/hooks/useAssignedUsers";
import useBrandPaymentMethod from "~/hooks/useBrandPaymentMethod";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { INSERT_INQUIRY } from "../graphql/Mutation";
import { generateRandomNumberString } from "~/utils/generateRandomId";
import NewInquiryTypes from "../NewInquiryTypes";
import InquirySelector from "../InquirySelector";
import { Field } from "../types";
import { options } from "../data/options";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const NewInquiryAdd = ({ setSidePopup, defaultTabs }: any) => {
  // const tabs = defaultTabs.map((tab: any, index: any) => tab?.label);
  const tabs = defaultTabs ? defaultTabs.map((tab: any) => tab.label) : [];
  const { brands }: any = useAssigedBrands();
  const [formValue, setFormValue] = useState({
    brand: "",
    user: "",
    paymentMethod: "",
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();

  const tripType = watch("tripType");
  const [selectedDate, setSelectedDate] = useState(null);

  const foundBrand =
    brands && brands.find((brand: any) => brand.name === formValue.brand);
  const brd_id = foundBrand ? foundBrand.id : null;

  const { users }: any = useAssignedUsers(brd_id);

  const foundUser =
    users && users.find((user: any) => user.displayName === formValue.user);
  const uid = foundUser ? foundUser.id : null;

  const { paymentMethod }: any = useBrandPaymentMethod(brd_id);

  const foundPaymentMethod =
    paymentMethod &&
    paymentMethod.find(
      (paymentMethod: any) => paymentMethod.name === formValue.paymentMethod
    );
  const pid = foundPaymentMethod ? foundPaymentMethod.id : null;
  const [selectedOptions, setSelectedOptions] = useState<string[]>(tabs);
  const [insertInquiry] = useMutation(INSERT_INQUIRY);
  const client = useApolloClient();
  const { travelHouse }: any = travelHouseStore();

  const onSubmit = async (data: any) => {
    console.log("sdjbsd", data)
    try {
      const inquiryData: any = {
        approx_budget: data?.approxBudget,
        payment_method_id: pid,
        flag_id: 64,
        status: "pending",
        brd_id,
        inquiry_no: generateRandomNumberString(),
        thp_id: travelHouse?.id,
        user_id: uid,
        created_at: moment(data.createdAt.$d).format("YYYY-MM-DD HH:mm:ss"),
        source: data?.source
      };

      if (data?.stay) {
        const rooms = data?.stay?.Travelers?.length || 0;
        let totalAdults = 0;
        let totalChildren = 0;

        if (data.stay.Travelers) {
          data.stay.Travelers.forEach((traveler: any) => {
            totalAdults += traveler.adults;
            totalChildren += traveler.children;
          });
        }
        inquiryData.stay_inq_details = {
          data: {
            location: data?.stay?.whereTo,
            start_date: moment(data?.stay?.dates?.[0]?.$d).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            end_date: moment(data?.stay?.dates?.[1]?.$d).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            rooms: rooms,
            adults: totalAdults,
            children: totalChildren,
            stay_inq_rooms: {
              data:
                rooms > 0
                  ? [
                    ...Array(data?.stay?.Travelers.length || 0)
                      .fill(null)
                      .map((_, index) => ({
                        stay_inq_id: inquiryData?.stay_inq_details?.id,
                        booked_by: index === 0 ? data?.user : null, // Set name for the first adult
                      })),
                    // ...Array(data?.stay?.Travelers.length || 0)
                    //   .fill(null)
                    //   .map(() => ({
                    //     stay_inq_id: inquiryData?.stay_inq_details?.id,
                    //     booked_by: data?.user,
                    //   })),
                  ]
                  : [],
            },
          },
        };
      }
      // Add car details if 'cars' option is selected
      if (data?.cars) {
        inquiryData.car_inq_details = {
          data: {
            origin: data?.cars?.pickUp,
            destination: data?.cars?.DropOff,
            pick_date_time: moment(data?.cars?.pickupDateTime?.$d).format(
              "YYYY-MM-DD HH:mm"
            ),
            drop_date_time: moment(data?.cars?.dropOffDateTime?.$d).format(
              "YYYY-MM-DD HH:mm"
            ),
            with_driver: data?.cars?.withDriver,
          },
        };
      }
      // Add train details if 'train' option is selected
      if (data?.train) {
        const travelers = data?.train?.Travelers || {}; // Ensure Travelers is defined
        inquiryData.train_inq_details = {
          data: {
            origin: data?.train?.Origin,
            destination: data?.train?.Destination,
            date: moment(data?.train?.Date?.$d).format("YYYY-MM-DD HH:mm:ss"),
            train_inq_passengers: {
              data: [
                ...Array(travelers.adults || 0)
                  .fill(null)
                  .map((_, index) => ({
                    type: "adult",
                    first_name: index === 0 ? data?.user : null, // Set name for the first adult
                  })),

                ...Array(travelers.youth || 0)
                  .fill(null)
                  .map(() => ({
                    type: "youth",
                  })),

                ...Array(travelers.children || 0)
                  .fill(null)
                  .map(() => ({
                    type: "child",
                  })),
                ...Array(travelers.infantsOnLap || 0)
                  .fill(null)
                  .map(() => ({
                    type: "infant",
                  })),
              ],
            },
          },
        };
      }
      // Add flight details if 'flights' option is selected
      if (data?.flights) {

        inquiryData.trip_type = data?.flights?.tripType;
        inquiryData.to = data?.flights?.GoingTo;
        inquiryData.from = data?.flights?.LeavingFrom;
        inquiryData.cabin_class = data?.flights?.cabinClass;
        inquiryData.departure_datetime =
          data?.flights?.roundTrip?.Dates?.[0]?.$d ||
          data?.flights?.OneWay?.Dates;
        inquiryData.arrival_datetime = data?.flights?.roundTrip?.Dates?.[1]?.$d;
        inquiryData.inq_passengers = {
          data: [
            // Mapping adults
            ...Array(data?.flights?.Travelers?.adults || 0)
              .fill(null)
              .map((_, index) => ({
                type: "adult",
                first_name: index === 0 ? data?.user : null, // Set name for the first adult
              })),
            // ...Array(data?.flights?.Travelers?.adults || 0)
            //   .fill(null)
            //   .map(() => ({
            //     type: "adult",
            //   })),
            // Mapping children
            ...Array(data?.flights?.Travelers?.youth || 0)
              .fill(null)
              .map(() => ({
                type: "youth",
              })),
            ...Array(data?.flights?.Travelers?.children || 0)
              .fill(null)
              .map(() => ({
                type: "child",
              })),
            // Mapping infants
            ...Array(data?.flights?.Travelers?.infantsOnLap || 0)
              .fill(null)
              .map(() => ({
                type: "infant",
              })),
          ],
        };
      }

      if (data?.visa) {
        inquiryData.visa_inq_details = {
          data: {
            name: data?.visa?.name,
            country: data?.visa?.country
          },
        };
      }
      // Insert the inquiry with the constructed data
      const res = await insertInquiry({
        variables: {
          data: inquiryData,
        },
      });
      // Success response
      if (res?.data?.insert_inq_list_one?.id) {
        toast.success("Inquiry inserted successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
        setSidePopup(false); // Close the side popup on success
      }
    } catch (error) {
      toast.error("Error inserting inquiry", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    setSelectedOptions((prev) =>
      checked ? [...prev, value] : prev.filter((option) => option !== value)
    );
    if (!checked) {
      reset({ [value]: undefined }); // Clear the data for unselected options
    }
  };

  const [fields, setFields] = useState<{ [key: string]: Field[] }>({});

  // Define options for the InquirySelector component
  const handleNewInquiryTypesSubmit = (inquiryTypes: any) => {
    setSelectedOptions(inquiryTypes);
  };

  return (
    <div className="md:w-[600px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic">Add Inquiry</h1>
      <Divider />

      {/* Inquiry Selector */}
      <Box sx={{ mb: 3 }}>
        <InquirySelector
          options={options}
          selectedOptions={selectedOptions}
          onOptionChange={handleOptionChange}
        />
      </Box>

      {/* Form Fields */}
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10 ">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("brand", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.name);
                  setFormValue({
                    ...formValue,
                    brand: data?.name,
                  });
                }}
                options={brands ? brands : []}
                getOptionLabel={(option: any) => option?.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Brand"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                      el && errors["brand"] && el.focus();
                    }}
                    error={errors["brand"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />

          {/* test */}

          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("user", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.displayName);
                  setFormValue({
                    ...formValue,
                    user: data?.displayName,
                  });
                }}
                options={users ? users : []}
                getOptionLabel={(option: any) => option?.displayName}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.displayName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="User"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["user"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />
          <TextField
            error={errors["approxBudget"] ? true : false}
            {...register("approxBudget", { required: true })}
            label="Approx Budget"
            type="number"
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("paymentMethod", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                // sx={{ marginTop: "7px" }}
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.name);
                  setFormValue({
                    ...formValue,
                    paymentMethod: data?.displayName,
                  });
                }}
                options={paymentMethod ? paymentMethod : []}
                getOptionLabel={(option: any) => option?.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Payment Method"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["paymentMethod"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />

          <Controller
            control={control}
            name="createdAt"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Created At"
                    value={value} // Ensure value is not undefined
                    onChange={(newValue) => {
                      onChange(newValue); // Update the value in react-hook-form
                    }}
                  />
                </LocalizationProvider>
              );
            }}
          />

          <TextField
            select
            label="Source"
            error={!!errors["source"]}
            {...register("source", { required: true })}
          >
            <MenuItem value="whatsapp">WhatsApp</MenuItem>
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="phone call">Phone Call</MenuItem>
            <MenuItem value="old customer">Old Customer</MenuItem>
          </TextField>


        </div>

        {/* New Inquiry Types */}

        <NewInquiryTypes
          register={register}
          control={control}
          handleSubmit={handleSubmit}
          errors={errors}
          watch={watch}
          setValue={setValue}
          selectedOptions={selectedOptions}
          onSubmit={handleNewInquiryTypesSubmit}
          defaultValues={defaultTabs}
        />

        <Button
          type="submit"
          sx={{ width: "100%", marginTop: "25px" }}
          variant="contained"
        >
          Add
        </Button>
      </Box>
    </div>
  );
};

export default NewInquiryAdd;
