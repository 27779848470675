import { gql } from "@apollo/client";

export const GET_SELECTED_FL_JOBS_WITH_BRD = gql`
  query getSelectedFlJobs($brdId: uuid!) {
    brd_fl_selected_jobs(where: { brd_id: { _eq: $brdId } }) {
      id
      brd_id
      project_id
    }
  }
`;
